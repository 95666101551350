import UserTag from '../../../components/partials/user/UserTag';
import UserTraining from '../../../components/partials/user/UserTraining';
import UserAssessment from '../../../components/partials/user/UserAssessment';
import UserOvertime from '@/components/partials/user/UserOvertime.vue';
import * as api from '../../services/api';
import * as dateFormat from '../../../helpers/dateFormat';
import * as blockui from '../../../helpers/block-ui';

export default {
  data() {
    return {
      user: {},
      formData: {},
    };
  },
  props: [
    'selectedUser',
    'isUpdate',
    'isContratVendor',
    'vendorIdFromContract'
  ],
  components: {
    'user-tag': UserTag,
    'user-training': UserTraining,
    'user-assessment': UserAssessment,
    'user-overtime': UserOvertime
  },
  computed: {
  },
  created() {
    const self = this;
    self.user = self.selectedUser;
  },
  mounted() {
    const self = this;
    const form = $("form[name='stepForm']");
    form.validate();

    $('#user_overlay_modal').on('shown.bs.collapse', (e) => {
      $($.fn.dataTable.tables(true)).DataTable()
        .columns.adjust()
        .responsive.recalc();
    });

    $('#user_overlay_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
  },
  methods: {
    async saveUserTag() {
      const self = this;
      const form = $("form[name='userTagForm']");

      if (!form.valid()) {
        toastr.error(self.translate('please_fill_all_required_fields'));
        return;
      }
      self.formData.IsSendPasswordResetNotification = undefined;
      $.map(form.serializeArray(), (item) => {
        if (item.name == 'DateOfEmployment') {
          Object.assign(self.formData, { [item.name]: dateFormat.getDate(item.value) });
        } else if (item.name == 'sendMail') {
          Object.assign(self.formData, { IsSendPasswordResetNotification: true });
        } else {
          Object.assign(self.formData, { [item.name]: item.value });
        }
      });
      if (!self.isUpdate && self.formData.IsSendPasswordResetNotification === undefined) {
        Object.assign(self.formData, { IsSendPasswordResetNotification: false });
      }
      Object.assign(self.user, self.formData);
      self.addUserWizard(e, 'submit');
      blockui.blockModal(self);
      self.formData.DateOfEmployment = dateFormat.postDateFormat(self.formData.DateOfEmployment);
      api.saveUser(self.formData).then((response) => {
        if (response.body.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
          $('#user_modal').modal('toggle');
          self.reloadDataTable();
        } else {
          toastr.error(response.body.Message);
        }
        blockui.unBlockModal();
      }, (error) => {
        blockui.unBlockModal();
      });
    },
  },
  watch: {
    selectedUser(e) {
      this.user = e;
    },
  },
};