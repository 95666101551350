








































































































































































































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import {Vue, Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import BootstrapSwitchComponent from '@/components/shared/BootstrapSwitchComponent';
import MinutePicker from '@/components/shared/minutepicker.vue';

import * as dateFormat from '@/helpers/dateFormat';
import * as swal from '@/helpers/swal'

import {required, requiredIf, minValue} from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'
import { CFMDTOWorkOrder, CFMModelWorkWithBreadCrumbView, CFMCoreContractListComboSource, CFMCoreEntityComboSource, CFMDTOMaintenancePlan, CFMDTOMaintenancePlanUpdate, CFMDTOWorkForMaintenancePlan, CFMDTOContract, CFMModelSuitableWorkUserSP } from '@/scripts/services/ClientApiAuto'
import { mixinz } from '@/scripts/global/mixinz';

@Component<MaintenancePlanDialog>({
    data() {
      return {
        selectedMaintenancePeriod: 6,
        value: 1,
        selectedMonthlyDays: [],
        monthlyDaysList: Array.from({ length: 31 }, (_, index) => ({ id: index + 1, value: index + 1 })),
        selectedDays: [],
        repeatedDays: 1
      }
    },
    
    methods: {
      selectedMaintenance() {
        this.$v.$reset();
        this.repeatedDays = 1;
      }
    },
    
    components: {
        'cfm-multiselect': CFMMultiselect,
        'vue-multiselect': Multiselect,
        'bootstrap-switch-component': BootstrapSwitchComponent,
        'minutepicker': MinutePicker,
    },

    validations() {
      return {
        startDate:{required},
        maintenancePlan:{
          OrderTypeId: {
            required
          },
          Duration: {
            required,
            minValue: minValue(1),
          },
          VendorId: {
            // @ts-ignore
            requiredIf: requiredIf(() => this.sendMail)
          },
          VendorNotificationRemainingDay: {
            // @ts-ignore
            requiredIf: requiredIf(() => this.sendMail),
            minValue: (value:any) => !this.sendMail || value > 0,
          },
          // Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
          // DeviceId: {
          //   // @ts-ignore
          //   requiredIf: requiredIf(() => !this.isUpdate && this.capacityEquipmentList!==null && this.capacityEquipmentList!==null),
          //   minValue: (value:any) => this.isUpdate || this.capacityEquipmentList===null || this.capacityEquipmentList!==null || value > 0,
          // }
        },
        workId: {
          // @ts-ignore
          requiredIf: requiredIf(() => !this.isUpdate)
        },
			  selectedDays: {
          // @ts-ignore
          requiredIf: requiredIf(() => this.selectedMaintenancePeriod == 5 && !this.isUpdate)
          },
        selectedMonthlyDays: {
          // @ts-ignore
          requiredIf: requiredIf(() => this.selectedMaintenancePeriod == 1 && !this.isUpdate)
        },  
        maintenanceCount: {
          // @ts-ignore
          requiredIf: requiredIf(() => !this.isUpdate),
          minValue: (value:any) => this.isUpdate || value > 0,
        },
      }
    },
    mixins: [mixinz]

    
})
export default class MaintenancePlanDialog extends CFMDialog {
    maintenancePlan: CFMDTOWorkOrder = {Name: '', Duration: 0, DeviceId: null};
    selectedMaintenance: any;
    value: null | number = null;
    fullWorkList : CFMDTOWorkForMaintenancePlan[]= [];
    selectedDays: number[] = [];
    repeatedDays: null | number = null;
    selectedMonthlyDays: number[] = [];
    workList : CFMCoreEntityComboSource[] = [];
    contractList : CFMCoreContractListComboSource[] = [];
    vendorList : {Name:string, Id:number}[] = [];
    maintenancePeriodList : CFMCoreEntityComboSource[] = [];
    maintenanceTypeList : CFMCoreEntityComboSource[] = [];
    //Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
    //capacityEquipmentList : null | CFMCoreEntityComboSource[] = null;
    selectedForMasterUser = [];
    selectedForObserverUser = [];
    startDate: null | Date = null;
    maintenanceCount: number = 1;
    selectedMaintenancePeriod: null | number = null;
    workId: null | number = null;

    contract: CFMCoreContractListComboSource | null = null;
    sendMail = false;
    
    updateGetResponse: any = {};
    
    userData: CFMModelSuitableWorkUserSP[] = [];
    observerUserData: CFMModelSuitableWorkUserSP[] = [];

    userFields = { groupBy: "Role", text: "UserDisplayText", value: "Id" };
    observerUserFields = { groupBy: "Role", text: "ObserverUserDisplayText", value: "Id" };

    userDataPlaceHolder = "İşi Yapacak Personel Seçiniz";
    observerUserDataPlaceHolder = "Refakatçi Personel Seçiniz";    
    userDataForSelect = [];
    observerUserDataForSelect = [];

    loading = false;
    groupBy(list:any, keyGetter:any) {
      const map = new Map();
      list.forEach((item:any) => {
          const key = keyGetter(item);
          const collection = map.get(key);
          if (!collection) {
              map.set(key, [item]);
          } else {
              collection.push(item);
          }
      });
      return map;
  }
  get selectOptions() {
    return {
      searchable: true,
      placeholder: this.translate("please_select"),
      "select-label": this.translate("select_label"),
      "deselect-label": this.translate("deselect_label"),
      "selected-label": this.translate("selected_label"),
      "show-no-results": false,
    };
  }  
    async created() {
      blockui.blockModal(this);

      if (this.isUpdate) {
        const res = await this.apiClient.maintenancePlan.maintenancePlanGetById({ id: <number>this.id });
        this.maintenancePlan = res.data;
        this.sendMail = this.maintenancePlan.VendorNotificationRemainingDay != undefined && this.maintenancePlan.VendorNotificationRemainingDay != null ? true : false;
        if (this.maintenancePlan.WorkId) {
          this.getContracts(this.maintenancePlan.WorkId);
        }
      }

      const promises = [
        this.getWorks(),
        this.getVendors(),
        this.getMaintenanceTypes(),
      ];

      if (this.isUpdate) {
        if (this.maintenancePlan.ScheduledTime) {
          // @ts-ignore
          this.startDate = moment(this.maintenancePlan.ScheduledTime).toDate();
        }
        
        promises.push(this.getMultipleMaintenancePlanUpdate());
      }

      await Promise.all(promises);

      blockui.unBlockModal();
    }
    removeForMasterUser(args:any) {
      if (this.selectedForMasterUser.length === 1) {
        toastr.error(this.translate("cannot_personnel_appointed"));
        return;
      }
      this.selectedForMasterUser.splice(args.Id, 1);    
    }
    removeForObserverUser(args:any) {
      // if (this.selectedForObserverUser.length === 1) {
      //   toastr.error(this.translate("cannot_personnel_appointed"));
      //   return;
      // }
      this.selectedForObserverUser.splice(args.Id, 1);    
    }
    async getMultipleMaintenancePlanUpdate() {
      blockui.blockModal(this);
      this.loading = true;
      let id = this.maintenancePlan.Id;
      //@ts-ignore
      this.apiClient.maintenancePlan.getMultipleMaintenancePlanUpdate({workOrderIds:id}).then((response) => {
          //@ts-ignore
          this.maintenanceTypeList = response.data.WorkOrderTypes;
          this.updateGetResponse = response.data;
          //@ts-ignore
          this.maintenancePlan = response.data.WorkOrders.find((item:any) => item.WorkOder.Id === id).WorkOder;
          //@ts-ignore
          this.startDate = moment(this.maintenancePlan.ScheduledTime).toDate();
          //@ts-ignore
          this.contractList = this.updateGetResponse.WorkOrders.find((item:any) => item.WorkOder.Id === id).Contracts.map(x=>(
            {
              Text:x.Name + `\n(${dateFormat.viewDateFormat(x.StartDate)} - ${dateFormat.viewDateFormat(x.EndDate)})`, 
              Value: x.Id, 
              StartDate: x.StartDate,
              EndDate: x.EndDate
            }
          ))
          this.vendorList = this.updateGetResponse.Vendors.map((x:any)=>({Name:<string>x.Text, Id:<number>x.Value}));

          this.userData = [];
          this.observerUserData = [];

          //@ts-ignore
          response.data.SuitableWorkUsers.forEach((user) => {
            user.ShiftDescription = user.IsHaveShift ? "Shift: Yes" : "Shift: No";
            user.OverlappedDescription = user.IsHaveWorkOrder ? "Workload Availability: No" : "Workload Availability: Yes";

            if (user.IsAssignableToMaster) {
              user.UserTrainedDescription = user.IsTrainedForMaster ? "Trained: Yes" : "Trained: No";
              user.UserDisplayText = `${user.Name} - ${user.UserTrainedDescription} - ${user.ShiftDescription} - ${user.OverlappedDescription}`;
              this.userData.push(user);
            }
            
            if (user.IsAssignableToObserver) {
              user.ObserverUserTrainedDescription = user.IsTrainedForObserver ? "Trained: Yes" : "Trained: No";
              user.ObserverUserDisplayText = `${user.Name} - ${user.ObserverUserTrainedDescription} - ${user.ShiftDescription} - ${user.OverlappedDescription}`;
              this.observerUserData.push(user);
            }
          });
          //@ts-ignore
          this.userDataForSelect = Array.from(this.groupBy(this.userData, user => user.Role), ([role, value]) => ({ role, value }));
          //@ts-ignore
          this.observerUserDataForSelect = Array.from(this.groupBy(this.observerUserData, user => user.Role), ([role, value]) => ({ role, value }));
          //@ts-ignore
          const workOrderUsers = response.data.WorkOrders.find((item:any) => item.WorkOder.Id === id).WorkOder.WorkOrderUsers;
          this.selectedForMasterUser = workOrderUsers
              //@ts-ignore
              .filter(user => user.UserPositionId === 1)
              //@ts-ignore
              .map(user => {
                const matchingUser = this.userData.find(u => u.Id === user.UserId);
                return matchingUser ? {
                  Id: user.UserId,
                  UserDisplayText: matchingUser.UserDisplayText
                } : null;
                //@ts-ignore
              }).filter(x => x !== null);

            this.selectedForObserverUser = workOrderUsers
            //@ts-ignore
            .filter(user => user.UserPositionId === 2)
              //@ts-ignore
              .map(user => {
                const matchingUser = this.observerUserData.find(u => u.Id === user.UserId);
                return matchingUser ? {
                  Id: user.UserId,
                  ObserverUserDisplayText: matchingUser.ObserverUserDisplayText
                } : null;
                //@ts-ignore
              }).filter(x => x !== null);
            blockui.unBlockModal();
            this.loading = false;
      })
    }  
    async onWorkChanged(newValue: null | {Value: number, Text: string}){
      if(newValue) {
        const selectedWork = this.fullWorkList.find(x=>x.Id == newValue.Value);
        
        await this.getContracts(newValue.Value);
        
        this.maintenancePlan.Duration = selectedWork && selectedWork.Duration ? selectedWork.Duration : this.maintenancePlan.Duration
        this.maintenancePlan.DeviceId = null;
        //Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
        //this.capacityEquipmentList = [];
        //this.capacityEquipmentList = null;
        // if(selectedWork && !!selectedWork.ModelId){
        //   blockui.blockElement(this, this.$el);
        //   await this.getCapacityEquipments(selectedWork.ModelId);
        //   blockui.unBlockElement(this.$el);
        // }else{
        //   this.capacityEquipmentList = null;
        // }
      } else {
        this.maintenancePlan.DeviceId = null;
        //Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
        //this.capacityEquipmentList = null;
      }
    }

    async getWorks(){
      const response = await this.apiClient.work.workGetListForMaintenancePlan();
      this.fullWorkList = response.data;
      this.workList = response.data.map(x=>({Text:x.BreadCrumb?`${x.BreadCrumb} | ${x.Name}` : x.Name, Value:x.Id}));
    }
    async getVendors(){
      const response = await this.apiClient.vendor.vendorGetVendors();
      this.vendorList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getMaintenanceTypes(){
      const response = await this.apiClient.workOrderType.workOrderTypeGetWorkOrderTypes();
      this.maintenanceTypeList = response.data;
    }
    async getContracts(workId:number){
      var contractListResult = await this.apiClient.contract.contractGetListByWorkId({workId : workId});
      if(contractListResult.data.IsSuccess && contractListResult.data.Data)
      this.contractList = contractListResult.data.Data.map(x=>(
        {
          Text:x.Name + `\n(${dateFormat.viewDateFormat(x.StartDate)} - ${dateFormat.viewDateFormat(x.EndDate)})`, 
          Value: x.Id, 
          StartDate: x.StartDate,
          EndDate: x.EndDate
        }
      ))
    }
    //Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
    // async getCapacityEquipments(modelId: number){
    //   const response = await this.apiClient.capacityEquipment.capacityEquipmentGetCapacityEquipmentsBreadCrumb({ modelId: modelId });
    //   this.capacityEquipmentList = response.data;
    // }

    get StartDate(){
      if (this.startDate) {
        // @ts-ignore
        return moment(this.startDate).format(this.pickerDateTimeFormat);
      }
      return null;
    }
    set StartDate(val){
      if (val) {
        // @ts-ignore
        Vue.set(this, 'startDate', moment(val, this.pickerDateTimeFormat).toDate());
      } else {
        Vue.set(this, 'startDate', val);
      }
    }
     
    //Metin => (#26182) talep nedeniyle kapasite ekipmanı seçimi kaldırıldı.
    // get selectedDevice() {
    //   // @ts-ignore
    //   return this.selectGet('capacityEquipmentList', 'maintenancePlan.DeviceId', 'Value', 'Text');
    // }
    // set selectedDevice(newValue) {
    //   // @ts-ignore
    //   return this.selectSet('maintenancePlan.DeviceId', 'Value', newValue);
    // }

    get selectedContract() {
      // @ts-ignore
      return this.selectGet('contractList', 'maintenancePlan.ContractId', 'Value', 'Text');
    }

    set selectedContract(newValue){
      this.contract = newValue;
      // @ts-ignore
      return this.selectSet('maintenancePlan.ContractId', 'Value', newValue);
    }

    get selectedWork() {
      // @ts-ignore
      return this.selectGet('workList', 'workId', 'Value', 'Text');
    }
    set selectedWork(newValue) {
      // @ts-ignore
      return this.selectSet('workId', 'Value', newValue);
    }

  getMaintenanceLastPeriodDate(){
    let periodId = this.selectedMaintenancePeriod;
    // @ts-ignore
    let date = moment(this.startDate);

      if(periodId == 6) { // Daily
        date.add('d', this.maintenanceCount-1);
      } 
      
      else if (periodId == 5){ // Weekly
        let dayOfWeekLastPeriod = this.selectedDays[(this.selectedDays.length - 1)]
        date.add((this.maintenanceCount-1) * 7, 'd');
        let dayOfWeekDate = date.weekday();
        date.add((<number>dayOfWeekLastPeriod - dayOfWeekDate), 'd')
      } 
      
      else if (periodId == 1) { // Monthly
        date.add(this.maintenanceCount-1, 'M')
        let monthLastDay = date.daysInMonth();
        let nowDay = date.date();
        //@ts-ignore
        let dayOfMonthLastPeriod = this.selectedMonthlyDays.length > 0 ? this.selectedMonthlyDays.sort(function(a, b){return a - b})[(this.selectedMonthlyDays.length - 1)].value : 0;
  
        if(monthLastDay < dayOfMonthLastPeriod){
          date.add(monthLastDay - nowDay, 'd');
        } 
  
        else if (monthLastDay >= dayOfMonthLastPeriod){
          date.add(dayOfMonthLastPeriod - nowDay, 'd');
        }
      }

    return date.toDate();
  }
  
  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    blockui.blockModal(this);
    if(this.isUpdate){
      // @ts-ignore
      this.contract = this.contractList.find(x=>x.Value == this.maintenancePlan.ContractId);
    }
    if(this.contract !== null && this.contract !== undefined){
        // @ts-ignore
        const date = this.isUpdate ? this.startDate : this.getMaintenanceLastPeriodDate();
      //@ts-ignore
      if(date > moment(this.contract?.EndDate).toDate()){
        const e = await swal.qaqccheck(this, this.translate('contract_control_warning_message'));
        if(!e.value){
          blockui.unBlockModal();
          return;
        }
      }
    }
    if (this.isUpdate) {
      let hasUserShiftWarning: boolean = false;
      let hasUserOverlapWarning: boolean = false;

      let hasObserverUserShiftWarning: boolean = false;
      let hasObserverOverlapWarning: boolean = false;

      this.selectedForMasterUser?.forEach(async (selectedUser) => {
        if (selectedUser !== undefined && selectedUser !== null) {
          //@ts-ignore
          hasUserShiftWarning = (selectedUser.IsHaveShift ?? false) == false;
          //@ts-ignore
          hasUserOverlapWarning = (selectedUser.IsHaveWorkOrder ?? false) == true;
        }
      });

      this.selectedForObserverUser?.forEach(async (selectedObserverUser) => {
        if (selectedObserverUser !== undefined && selectedObserverUser !== null) {
          //@ts-ignore
          hasObserverUserShiftWarning = (selectedObserverUser.IsHaveShift ?? false) == false;
          //@ts-ignore
          hasObserverOverlapWarning = (selectedObserverUser.IsHaveWorkOrder ?? false) == true;
        }
      })

      if(hasUserShiftWarning || hasUserOverlapWarning || hasObserverUserShiftWarning || hasObserverOverlapWarning) {
        const alertTitle: string = "Atanmak istenen iş emirleri !!!";
        const alertMessage: string = 
          (hasUserShiftWarning || hasUserOverlapWarning ? "İşi Yapacak Personelin(Personellerin) " : "") +
          (hasUserShiftWarning ? "vardiyası ile eşleşmemektedir, " : "") +
          (hasUserOverlapWarning ? "diğer bir iş emriyle çakışan işleri bulunmaktadır. " : "") + "<br>" +
          (hasObserverUserShiftWarning || hasObserverOverlapWarning ? "Refakatçi Personelin(Personellerin) " : "") +
          (hasObserverUserShiftWarning ? "vardiyası ile eşleşmemektedir, " : "") + 
          (hasObserverOverlapWarning ? "diğer bir iş emriyle çakışan işleri bulunmaktadır." : "");

          // @ts-ignore
          let swalResponse = await swal.checkHtml(this, alertTitle, alertMessage)
          swalResponse.dismiss
          if (swalResponse !== undefined && swalResponse.dismiss !== undefined && swalResponse.dismiss === "cancel") { // if (!swalResponse.value) {
            return;
          }
      }
  
      let AssigneeUsers = [];
      blockui.blockModal(this);
      //@ts-ignore
      for (let j = 0; j < this.selectedForMasterUser.length; j++) {
        AssigneeUsers.push({
          // @ts-ignore
          WorkOrderId: this.maintenancePlan.Id,
          //@ts-ignore
          UserId: this.selectedForMasterUser[j].Id,
          UserPositionId: 1
        });
      }
    
      // @ts-ignore
      for (let j = 0; j < this.selectedForObserverUser.length; j++) {
          AssigneeUsers.push({
            // @ts-ignore
            WorkOrderId: this.maintenancePlan.Id,
            //@ts-ignore
            UserId: this.selectedForObserverUser[j].Id,
            UserPositionId: 2,
          });
        }

      let MaintenancePlans:any = [];
      let dataList = this.updateGetResponse.WorkOrders.map((x:any) => (x.WorkOder))
      dataList.forEach((dataItem:any) => {
          MaintenancePlans.push({
              Id : dataItem.Id,
              Note : dataItem.Note,
              //@ts-ignore
              StartDate : dataItem.ScheduledTime,
              Duration : dataItem.Duration,
              MaintenanceTypeId : dataItem.OrderTypeId,
              VendorNotificationRemainingDay : dataItem.VendorNotificationRemainingDay,
              VendorId : dataItem.VendorId,
              ContractId: dataItem.ContractId
          })
      })
      try {
        let response = await this.apiClient.maintenancePlan.updateMultipleMaintenance({AssigneeUsers,MaintenancePlans});
        if(response.data.IsSuccess){
          toastr.success(this.translate('save_successfully'));
        }else{
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
        blockui.unBlockModal();
        this.saveSucceeded();
        this.close();
      } 
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
    
        // TODO: Muhammet Rami ARTAŞ - Bu bölüm artık pasif, hızlı personel atama eklendi.
        // Kodun bu kısmı hızlı personel atama nedeniyle artık kullanılmamaktadır.
        //  try{
        //   let updateFormData : CFMDTOMaintenancePlanUpdate = {
        //     Id : this.maintenancePlan.Id,
        //     Note : this.maintenancePlan.Note,
        //     StartDate : dateFormat.postDateTimeFormat(<Date>this.startDate),
        //     Duration : this.maintenancePlan.Duration,
        //     MaintenanceTypeId : this.maintenancePlan.OrderTypeId,
        //     VendorNotificationRemainingDay : this.maintenancePlan.VendorNotificationRemainingDay,
        //     VendorId : this.maintenancePlan.VendorId,
        //     ContractId: this.maintenancePlan.ContractId
        //   };
        //   if (!this.sendMail) {
        //     updateFormData.VendorId = null;
        //     updateFormData.VendorNotificationRemainingDay = null;
        //   }
          
        //   const response  =  await this.apiClient.maintenancePlan.maintenancePlanUpdateMaintenance(updateFormData);
        //   if (response.data.IsSuccess) {
        //     toastr.success(this.translate('save_successfully'));

        //     // TODO : Eren - fix xyz
        //     // @ts-ignore
        //     if (this.$route.meta.xyz) {
        //       // @ts-ignore
        //       Vue.getDatatables('.maintenance_plan');
        //     }
        //     this.saveSucceeded();
        //     this.close();
        //   } else {
        //     const errorMessage = response.data.Message ? response.data.Message : "Error";
        //     toastr.error(errorMessage);
        //   }
        // }
        // catch(e){
        //   console.log("err",e)
        //   toastr.error(e);
        // }
        // finally{
        //   blockui.unBlockModal();
        // }
      }
    else {
        try {
          let formData : CFMDTOMaintenancePlan = {
            Id : this.maintenancePlan.Id,
            Note : this.maintenancePlan.Note,
            StartDate : dateFormat.postDateTimeFormat(<Date>this.startDate),
            Duration : this.maintenancePlan.Duration,
            MaintenanceTypeId : this.maintenancePlan.OrderTypeId,
            VendorNotificationRemainingDay : this.maintenancePlan.VendorNotificationRemainingDay,
            VendorId : this.maintenancePlan.VendorId,
            PeriodId : this.selectedMaintenancePeriod,
            WorkId : <number>this.workId,
            MaintenanceCount : this.maintenanceCount,
            ContractId : this.maintenancePlan.ContractId,
            DaysOfWeek : this.selectedDays,
            RepeatedDays : <number>this.repeatedDays,
            //@ts-ignore
           DaysOfMonth: this.selectedMonthlyDays.map(day => day.id) 
          };
          if (!this.sendMail) {
            formData.VendorId = null;
            formData.VendorNotificationRemainingDay = null;
          }
          
          const response  =  await this.apiClient.maintenancePlan.maintenancePlanSave(formData);
          if (response.data.IsSuccess) {
            toastr.success(this.translate('save_successfully'));
            // @ts-ignore
             response.data.Message ? toastr.error(response.data.Message) : '';
            // TODO : Eren - fix xyz
            // @ts-ignore
            if (this.$route.meta.xyz) {
              // @ts-ignore
              Vue.getDatatables('.maintenance_plan');
              // @ts-ignore
              this.$parent.slc.works.MaintenancePlanCount = this.$parent.slc.works.MaintenancePlanCount ? this.$parent.slc.works.MaintenancePlanCount + this.maintenanceCount: this.maintenanceCount;
            }
            this.saveSucceeded();
            this.close();
          } else {
            const errorMessage = response.data.Message ? response.data.Message : "Error";
            toastr.error(errorMessage);
          }
        }
        catch(e){
          console.log("err",e)
          toastr.error(e);
        }
        finally{
          blockui.unBlockModal();
        }
      }
    }
}
