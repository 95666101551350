/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * @format int32
 */
export type CFMCommonEnumsEnumToolStatus = 1 | 2 | 3;

export interface CFMCoreEntityComboSource {
  /** @format int32 */
  Value?: number;
  Text?: string | null;
}

export interface CFMCoreContractListComboSource {
  /** @format int32 */
  Value?: number;
  Text?: string | null;
  StartDate?: string | null;
  EndDate?: string | null;

}

export interface CFMCoreEntityComboSourceSystemString {
  Value?: string | null;
  Text?: string | null;
}

export interface CFMCoreEntityGeneratedValue {
  /** @format int32 */
  Index?: number;
  Property?: string | null;
  Value?: any;
}

export interface CFMCoreEntityMeta {
  /** @format int32 */
  Page?: number;

  /** @format int32 */
  Pages?: number;

  /** @format int32 */
  PerPage?: number;

  /** @format int32 */
  Total?: number;
  Sort?: string | null;
  Field?: string | null;
}

export interface CFMCoreEntityResultMessage {
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityCheckResultMessage {
  IsSuccess?: boolean;
  Data?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOCoveragePosition {
  Data?: CFMDTOCoveragePosition;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTODeviceView {
  Data?: CFMDTODeviceView;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOFile {
  Data?: CFMDTOFile;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOFormProcess {
  Data?: CFMDTOFormProcess;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOHourlyProcessingCapability {
  Data?: CFMDTOHourlyProcessingCapability;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTONetAvailableWorkHour {
  Data?: CFMDTONetAvailableWorkHour;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTORoom {
  Data?: CFMDTORoom;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOShift {
  Data?: CFMDTOShift;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOTrainingCondition {
  Data?: CFMDTOTrainingCondition;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOVolumeInfluencedPosition {
  Data?: CFMDTOVolumeInfluencedPosition;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOWarehouse {
  Data?: CFMDTOWarehouse;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOWorkOrderHtmlContent {
  Data?: CFMDTOWorkOrderHtmlContent;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMModelBuildingView {
  Data?: CFMModelBuildingView;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMModelInfrastructureTypeView {
  Data?: CFMModelInfrastructureTypeView;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMModelMyWorkOrders {
  Data?: CFMModelMyWorkOrders;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMModelWorkOrderView {
  Data?: CFMModelWorkOrderView;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMCoreEntityComboSource {
  Data?: CFMCoreEntityComboSource[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOAnswerProcess {
  Data?: CFMDTOAnswerProcess[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOAssessment {
  Data?: CFMDTOAssessment[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOAssessmentType {
  Data?: CFMDTOAssessmentType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOBrand {
  Data?: CFMDTOBrand[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOCampus {
  Data?: CFMDTOCampus[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOCapacityType {
  Data?: CFMDTOCapacityType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOColor {
  Data?: CFMDTOColor[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOComponent {
  Data?: CFMDTOComponent[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOComponentCheckItem {
  Data?: CFMDTOComponentCheckItem[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContinent {
  Data?: CFMDTOContinent[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContract {
  Data?: CFMDTOContract[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContractDevice {
  Data?: CFMDTOContractDevice[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContractSection {
  Data?: CFMDTOContractSection[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContractSla {
  Data?: CFMDTOContractSla[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODescription {
  Data?: CFMDTODescription[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODevice {
  Data?: CFMDTODevice[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODeviceMaintenancePeriod {
  Data?: CFMDTODeviceMaintenancePeriod[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODeviceModel {
  Data?: CFMDTODeviceModel[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODeviceType {
  Data?: CFMDTODeviceType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODeviceView {
  Data?: CFMDTODeviceView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODocumentStatus {
  Data?: CFMDTODocumentStatus[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODocumentValidityStatus {
  Data?: CFMDTODocumentValidityStatus[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOFile {
  Data?: CFMDTOFile[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOFinancial {
  Data?: CFMDTOFinancial[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOFormProcess {
  Data?: CFMDTOFormProcess[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOFormProcessStatus {
  Data?: CFMDTOFormProcessStatus[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOInfrastructure {
  Data?: CFMDTOInfrastructure[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOInfrastructureGroup {
  Data?: CFMDTOInfrastructureGroup[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOJob {
  Data?: CFMDTOJob[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOMaintenanceGroup {
  Data?: CFMDTOMaintenanceGroup[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOPointType {
  Data?: CFMDTOPointType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOProcedure {
  Data?: CFMDTOProcedure[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOProcedureType {
  Data?: CFMDTOProcedureType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOProductFamily {
  Data?: CFMDTOProductFamily[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOQualification {
  Data?: CFMDTOQualification[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOQualificationType {
  Data?: CFMDTOQualificationType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOQuestionProcess {
  Data?: CFMDTOQuestionProcess[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOReferenceLibrary {
  Data?: CFMDTOReferenceLibrary[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOReservation {
  Data?: CFMDTOReservation[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTORoom {
  Data?: CFMDTORoom[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTORoomSubType {
  Data?: CFMDTORoomSubType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTORoomType {
  Data?: CFMDTORoomType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOSchedule {
  Data?: CFMDTOSchedule[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleDevice {
  Data?: CFMDTOScheduleDevice[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleRoom {
  Data?: CFMDTOScheduleRoom[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleType {
  Data?: CFMDTOScheduleType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleWork {
  Data?: CFMDTOScheduleWork[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOSelection {
  Data?: CFMDTOSelection[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOServiceProvider {
  Data?: CFMDTOServiceProvider[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTool {
  Data?: CFMDTOTool[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOToolHistory {
  Data?: CFMDTOToolHistory[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOToolStatus {
  Data?: CFMDTOToolStatus[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOToolSubType {
  Data?: CFMDTOToolSubType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOToolType {
  Data?: CFMDTOToolType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTraining {
  Data?: CFMDTOTraining[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTrainingMethod {
  Data?: CFMDTOTrainingMethod[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTrainingRole {
  Data?: CFMDTOTrainingRole[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTrainingType {
  Data?: CFMDTOTrainingType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTurnOver {
  Data?: CFMDTOTurnOver[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUnit {
  Data?: CFMDTOUnit[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUser {
  Data?: CFMDTOUser[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUserAssessment {
  Data?: CFMDTOUserAssessment[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUserPosition {
  Data?: CFMDTOUserPosition[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUserTraining {
  Data?: CFMDTOUserTraining[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUserType {
  Data?: CFMDTOUserType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOVendor {
  Data?: CFMDTOVendor[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWarehouse {
  Data?: CFMDTOWarehouse[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWork {
  Data?: CFMDTOWork[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkCategory {
  Data?: CFMDTOWorkCategory[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkForm {
  Data?: CFMDTOWorkForm[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkGroupRole {
  Data?: CFMDTOWorkGroupRole[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrder {
  Data?: CFMDTOWorkOrder[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderDetailView {
  Data?: CFMDTOWorkOrderDetailView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderStatus {
  Data?: CFMDTOWorkOrderStatus[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderStatusDescription {
  Data?: CFMDTOWorkOrderStatusDescription[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderType {
  Data?: CFMDTOWorkOrderType[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderUser {
  Data?: CFMDTOWorkOrderUser[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkProcedure {
  Data?: CFMDTOWorkProcedure[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelAssignedWorkOrderView {
  Data?: CFMModelAssignedWorkOrderView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelBuildingView {
  Data?: CFMModelBuildingView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelCampusView {
  Data?: CFMModelCampusView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelCityView {
  Data?: CFMModelCityView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelContractView {
  Data?: CFMModelContractView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelCountryView {
  Data?: CFMModelCountryView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelDistrictView {
  Data?: CFMModelDistrictView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelInfrastructureTypeView {
  Data?: CFMModelInfrastructureTypeView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelInventoryView {
  Data?: CFMModelInventoryView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelMassMaintenancePlanView {
  Data?: CFMModelMassMaintenancePlanView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelMostNeededTrainingWithStaffCountSp {
  Data?: CFMModelMostNeededTrainingWithStaffCountSp[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelShiftView {
  Data?: CFMModelShiftView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelTrainingDemand {
  Data?: CFMModelTrainingDemand[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelTrainingSp {
  Data?: CFMModelTrainingSp[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkOrderView {
  Data?: CFMModelWorkOrderView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkPeriodView {
  Data?: CFMModelWorkPeriodView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkRecommendationView {
  Data?: CFMModelWorkRecommendationView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkView {
  Data?: CFMModelWorkView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableSystemString {
  Data?: string[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemCollectionsGenericListCFMModelAnsweredFormView {
  Data?: CFMModelAnsweredFormView[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageSystemDecimal {
  /** @format double */
  Data?: number;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOWorkOrderFormVendor {
  Data?: CFMDTOWorkOrderFormVendor | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOWorkOrderFormVendorList {
  Data?: CFMDTOWorkOrderFormVendor[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOWorkOrderFormVendorCount {
  Data?: number | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMCoreEntityResultMessageCFMDTOQAQCList {
  Data?: CFMDTOQAQC[] | null;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMModelSparePartUsingHistorySP {
  /** @format int32 */
  SparePartId?: number | null;
  SparePartName?: string | null;
  WarehouseName?: string | null;
  /** @format int32 */
  OrderSparePartId?: number | null;
  SerialNumber?: string | null;
  BrandName?: string | null;
  ModelName?: string | null;
  /** @format int32 */
  SparePartUsingStatusId?: number | null;
  SparePartUsingStatusName?: string | null;
  /** @format int32 */
  WorkOrderId?: number | null;
  WorkOrderName?: string | null;
  /** @format date-time */
  CreatedDate?: Date | null;
  UsingTime?: string | null;
}

export interface CFMDTOSparePartUsingHistory {
  /** @format int32 */
  Id?: number | null;
  /** @format int32 */
  SparePartUsingStatusId?: number | null;
  /** @format int32 */
  OrderSparePartId?: number | null;
}

export interface CFMCoreEntitySaveResult {
  /** @format int32 */
  AffectedCount?: number;
  GeneratedValues?: CFMCoreEntityGeneratedValue[] | null;
  GeneratedEntities?: any[] | null;
  UpdatedEntities?: any[] | null;
  DeletedEntities?: any[] | null;
}

export interface CFMDTOActionActor {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ActionId?: number;

  /** @format int32 */
  ActorId?: number;
  IsActive?: boolean;
}

export interface CFMDTOActionGroup {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;
  Name: string;
  Actions?: CFMDTOSecurityAction[] | null;
}

export interface CFMDTOActor {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;
  Name: string;
}

export interface CFMDTOActorRole {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ActorId?: number;

  /** @format int32 */
  RoleId?: number;
  IsActive?: boolean;
  Actor?: CFMDTOActor;
  Role?: CFMDTORole;
}

export interface CFMDTOActorUser {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ActorId?: number;

  /** @format int32 */
  UserId?: number;
  IsActive?: boolean;
  Actor?: CFMDTOActor;
  User?: CFMDTOUser;
}

export interface CFMDTOAlternativeTool {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ToolId?: number;

  /** @format int32 */
  AlternativeToolId?: number;
}

export interface CFMDTOAnswerCondition {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  OperandTypeId?: number;
  Parent?: CFMDTOAnswerCondition;
  OperandType?: CFMDTOOperandType;
  AnswerConditionRules?: CFMDTOAnswerConditionRule[] | null;
  Children?: CFMDTOAnswerCondition[] | null;
}

export interface CFMDTOAnswerConditionRule {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  AnswerConditionId?: number;

  /** @format int32 */
  ComparisonOperatorId?: number;
  FirstValue?: string | null;
  SecondValue?: string | null;
  AnswerCondition?: CFMDTOAnswerCondition;
  ComparisonOperator?: CFMDTOComparisonOperator;
}

export interface CFMDTOAnswerProcess {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  SelectionId?: number | null;

  /** @format int32 */
  PointSelectionId?: number | null;

  /** @format int32 */
  QuestionProcessId?: number;
  Value?: string | null;
  IsValid?: boolean | null;

  /** @format date-time */
  CreatedDate?: string;

  /** @format date-time */
  ModifiedDate?: string | null;

  /** @format int32 */
  CreatedUserId?: number | null;

  /** @format int32 */
  ModifiedUserId?: number | null;
  CreatedUser?: CFMDTOUser;
  ModifiedUser?: CFMDTOUser;

  /** @format int32 */
  FileId?: number | null;
  Selection?: CFMDTOSelection;
  PointSelection?: CFMDTOPointSelection;
  QuestionProcess?: CFMDTOQuestionProcess;
  File?: CFMDTOFile;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOApproveWorkOrderDTO {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  WorkOrderStatusDescriptionId?: number;

  /** @format int32 */
  StatusId?: number;
}

export interface CFMDTOAssessment {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format int32 */
  EvaluationScore?: number;

  /** @format int32 */
  PassingScore?: number;

  /** @format int32 */
  AssessmentTypeId?: number;
  IsActive?: boolean;
  AssessmentType?: CFMDTOAssessmentType;
}

export interface CFMDTOAssessmentType {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  HasEvaluation?: boolean;
  IsActive?: boolean;
}

export interface CFMDTOAttendedUser {
  /** @format int32 */
  Id?: number;
  NameSurname?: string | null;

  /** @format int32 */
  UserPositionId?: number;
  UserPosition?: string | null;

  /** @format int32 */
  UserTypeId?: number;
  UserTypeName?: string | null;
  UserExperience?: string | null;
  Vendor?: string | null;
}

export interface CFMDTOBrand {
  /** @format int32 */
  Id?: number;
  Name: string;
  IsForTool?: boolean;
  IsForCapacityEquipment?: boolean;
  IsForDevice?: boolean;
  IsForSparePart?: boolean;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;
  DeviceModels?: CFMDTODeviceModel[] | null;
}

export interface CFMDTOBuilding {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  CampusId?: number;

  /** @format int32 */
  DisplayOrder?: number;

  /** @format int32 */
  FloorCount?: number;
  Campus?: CFMDTOCampus;
  Floors?: CFMDTOFloor[] | null;
}

export interface CFMDTOCampus {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  Altitude?: number | null;

  /**
   * @format double
   * @min 0
   * @max 10000000000000000
   * @pattern ^\d+(\.|\,)\d{0,2}$
   */
  RelativeHumidity?: number | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  DistrictId?: number;
  IsActive?: boolean;
  District?: CFMDTODistrict;
  Buildings?: CFMDTOBuilding[] | null;
  
  LogoFile?: string | null;
  Logo?: string | null;
  LogoFileType?: string | null;
}

export interface CFMDTOCapacityEquipment {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  Devices?: CFMDTODevice[] | null;
  Points?: CFMDTOPoint[] | null;
}

export interface CFMDTOCapacityType {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  InfrastructureTypeId?: number;
  IsActive?: boolean;
  InfrastructureType?: CFMDTOInfrastructureType;
}

export interface CFMDTOChangePasswordDTO {
  CurrentPassword?: string | null;
  Password?: string | null;
  PasswordConfirm?: string | null;
}

export interface CFMDTOChangePointDTO {
  /** @format int32 */
  QuestionId?: number;

  /** @format int32 */
  PointId?: number;
}

export interface CFMDTOCity {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  CountryId?: number;
  IsActive?: boolean;
  Country?: CFMDTOCountry;
}

export interface CFMDTOColor {
  /** @format int32 */
  Id?: number;
  Name: string;
  Code?: string | null;
}

export interface CFMDTOCompany {
  /** @format int32 */
  Id?: number;
  Name: string;
  DomainName?: string | null;
}

export interface CFMDTOComparisonOperator {
  /** @format int32 */
  Id?: number;
  Name: string;
  Code: string;
  IsSecondValueNeeded?: boolean;
}

export interface CFMDTOComponent {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  ModelId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  CompanyId?: number;
  Model?: CFMDTODeviceModel;
  ComponentCheckItems?: CFMDTOComponentCheckItem[] | null;
}

export interface CFMDTOComponentCheckItem {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  ComponentId?: number;

  /** @format int32 */
  PeriodId?: number;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOContinent {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;
}

export interface CFMDTOContract {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  ContractNumber: string;

  /** @format date-time */
  StartDate?: string | null;

  /** @format date-time */
  EndDate?: string | null;

  /** @format double */
  ContractPrice?: number | null;

  /** @format int32 */
  CurrencyUnitId?: number | null;

  /** @format int32 */
  VendorId?: number | null;
  IncludePublicHolidays?: boolean | null;
  Vendor?: CFMDTOVendor;
  CurrencyUnit?: CFMDTOUnit;
  ContractPersonels?: CFMDTOContractPersonel[] | null;
  ContractDevices?: CFMDTOContractDevice[] | null;
  ContractWorkDays?: CFMDTOContractWorkDay[] | null;
  ContractFiles?: CFMDTOContractFile[] | null;
  ContractSlas?: CFMDTOContractSla[] | null;
  ContractMaintenances?: CFMDTOContractMaintenance[] | null;
}

export interface CFMDTOContractDevice {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  DeviceId?: number;

  /** @format int32 */
  ContractId?: number;
  Device?: CFMDTODevice;
  Contract?: CFMDTOContract;
}

export interface CFMDTOContractFile {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ContractId?: number;

  /** @format int32 */
  FileId?: number;

  /** @format int32 */
  TypeId?: number;
  Description?: string | null;
  File?: CFMDTOFile;
  Contract?: CFMDTOContract;
}

export interface CFMDTOContractMaintenance {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  WorkId?: number | null;

  /** @format int32 */
  Duration?: number | null;

  /** @format int32 */
  DeviceId?: number;

  /** @format date-time */
  ReferenceDate?: string;

  /** @format int32 */
  ReferenceTypeId?: number;

  /** @format int32 */
  PeriodId?: number;

  /** @format int32 */
  Tolerance?: number;

  /** @format int32 */
  TotalCount?: number;

  /** @format int32 */
  RemainedCount?: number | null;
  Name: string;

  /** @format int32 */
  ContractId?: number;

  /** @format int32 */
  SectionId?: number;
  Period?: CFMDTOWorkPeriod;
  Contract?: CFMDTOContract;
  Device?: CFMDTODevice;
  Work?: CFMDTOWork;
  Section?: CFMDTOContractSection;
}

export interface CFMDTOContractPersonel {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  ContractId?: number;
  User?: CFMDTOContractUser;
  Contract?: CFMDTOContract;
}

export interface CFMDTOContractSection {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format int32 */
  ContractId?: number;

  /** @format int32 */
  Order?: number;
  IsEditable?: boolean;
  Contract?: CFMDTOContract;
}

export interface CFMDTOContractSla {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Condition: string;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  CriticalityLevel?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  ReactionTime?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  WorkAroundTime?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  PermanentSolutionTime?: number;
  ContactPerson: string;
  ContactPersonTel: string;
  ContactPersonMail: string;

  /** @format int32 */
  ContractId?: number;

  /** @format int32 */
  SectionId?: number;
  Contract?: CFMDTOContract;
  Section?: CFMDTOContractSection;
}

export interface CFMDTOContractUser {
  /** @format int32 */
  Id?: number;
  Email?: string | null;
  Phone?: string | null;
  Username?: string | null;
  Name?: string | null;
  Surname?: string | null;
  NameSurname?: string | null;

  /** @format date-time */
  DateOfEmployment?: string | null;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;

  /** @format int32 */
  JobId?: number;

  /** @format int32 */
  RoleId?: number;

  /** @format int32 */
  UserTypeId?: number;

  /** @format int32 */
  VendorId?: number | null;

  /** @format int32 */
  UserExperienceId?: number;
  IsUserTypeChangeable?: boolean;
  UserExperience?: CFMDTOUserExperience;
  Role?: CFMDTORole;
  Vendor?: CFMDTOVendor;
  Job?: CFMDTOJob;
  UserType?: CFMDTOUserType;
  Token?: string | null;

  /** @format date-time */
  TokenExpireDate?: string | null;
  PasswordResetLink?: string | null;
  IsSendPasswordResetNotification?: boolean | null;
  IsDomainUser?: boolean | null;
  UserTrainings?: CFMDTOUserTraining[] | null;
  UserQualifications?: CFMDTOUserQualification[] | null;
  OrganizationUsers?: CFMDTOOrganizationUser[] | null;
}

export interface CFMDTOContractWorkDay {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  DayOfWeekId?: number;

  /** @format int32 */
  ContractId?: number;

  /** @format date-span */
  StartTime?: string | null;

  /** @format date-span */
  EndTime?: string | null;
  DayOfWeek?: CFMDTODayOfWeek;
  Contract?: CFMDTOContract;
}

export interface CFMDTOCountry {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ContinentId?: number;
  IsActive?: boolean;
  Continent?: CFMDTOContinent;
}

export interface CFMDTOCoveragePosition {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  ConsoleCount?: number;

  /**
   * @format int32
   * @min 0
   * @max 24
   */
  NumberOfHoursPerDay?: number;

  /**
   * @format int32
   * @min 0
   * @max 7
   */
  NumberOfDaysPerWeek?: number;

  /**
   * @format int32
   * @min 0
   * @max 52
   */
  NumberOfWeeksPerYear?: number;

  /** @format int32 */
  TotalHoursNeedingCoverage?: number;

  /** @format int32 */
  NetAvailableWorkHours?: number;

  /** @format double */
  FullTimeEquivalent?: number;

  /** @format double */
  TurnOverRate?: number;

  /** @format double */
  FullTimeEquivalentPlus?: number;
}

export interface CFMDTODayOfMonth {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTODayOfWeek {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTODefinition {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  PointTypeId?: number;

  /** @format int32 */
  UnitId?: number | null;

  /** @format int32 */
  CompanyId?: number;
  IsDeletable?: boolean;
  PointType?: CFMDTOPointType;
}

export interface CFMDTODescription {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Text: string;

  /** @format int32 */
  DeviceId?: number;

  /** @format int32 */
  RelatedDeviceId?: number | null;
  Device?: CFMDTODevice;
  RelatedDevice?: CFMDTODevice;
}

export interface CFMDTODevice {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  Explanation?: string | null;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  RoomId?: number;

  /** @format int32 */
  CapacityTypeId?: number | null;

  /** @format int32 */
  ProductFamilyId?: number | null;

  /** @format int32 */
  ModelId?: number | null;
  SerialNumber?: string | null;

  /** @format int32 */
  ProductionYear?: number | null;

  /** @format date-time */
  DateOfInstallation?: string | null;

  /** @format date-time */
  DateOfWarrantyStart?: string | null;

  /** @format int32 */
  WarrantyDuration?: number | null;

  /** @format date-time */
  ExpectedLifeEndDate?: string | null;

  /** @format int32 */
  SuggestedRunHour?: number | null;

  /** @format int32 */
  MeanTimeToFailureTheoreticalHour?: number | null;

  /** @format int32 */
  MeanTimeToFailureStatisticalHour?: number | null;

  /** @format int32 */
  MeanTimeBetweenFailureTheoreticalHour?: number | null;

  /** @format int32 */
  MeanTimeBetweenFailureStatisticalHour?: number | null;

  /** @format int32 */
  MeanTimeToRepairTheoreticalHour?: number | null;

  /** @format int32 */
  MeanTimeToRepairStatisticalHour?: number | null;

  /** @format int32 */
  PointCount?: number | null;

  /** @format int32 */
  Input?: number | null;

  /** @format int32 */
  Output?: number | null;

  /** @format int32 */
  SourceMatrixId?: number | null;

  /** @format double */
  TotalEffectiveCapacity?: number | null;

  /** @format int32 */
  TotalEffectiveCapacityUnitId?: number | null;

  /** @format double */
  DryBulbTemperatureMin?: number | null;

  /** @format double */
  DryBulbTemperatureMax?: number | null;

  /** @format int32 */
  DryBulbTemperatureUnitId?: number | null;

  /** @format double */
  WetBulbTemperatureMin?: number | null;

  /** @format double */
  WetBulbTemperatureMax?: number | null;

  /** @format int32 */
  WetBulbTemperatureUnitId?: number | null;

  /** @format int32 */
  DeviceTypeId?: number | null;
  TotalEffectiveCapacityUnit?: CFMDTOUnit;
  CapacityType?: CFMDTOCapacityType;
  ProductFamily?: CFMDTOProductFamily;
  Model?: CFMDTODeviceModel;
  Room?: CFMDTORoom;
  SourceMatrix?: CFMDTOSourceMatrix;
  DeviceType?: CFMDTODeviceType;
  Descriptions?: CFMDTODescription[] | null;
  ParentCapacityEquipment?: CFMDTODevice;
  Parameters?: CFMDTOParameter[] | null;
  ParameterValues?: CFMDTOParameterValue[] | null;
  InputDevices?: CFMDTOFromTo[] | null;
  OutputDevices?: CFMDTOFromTo[] | null;
  Points?: CFMDTOPoint[] | null;
}

export interface CFMDTODeviceMaintenancePeriod {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  DeviceId?: number;

  /** @format int32 */
  PeriodId?: number;

  /** @format int32 */
  CompanyId?: number;
  Device?: CFMDTODevice;
  Period?: CFMDTOWorkPeriod;
}

export interface CFMDTODeviceModel {
  /** @format int32 */
  Id?: number;
  Name: string;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  BrandId?: number;
  IsForTool?: boolean;
  IsForCapacityEquipment?: boolean;
  IsForDevice?: boolean;
  IsForSparePart?: boolean;
  Brand?: CFMDTOBrand;
  Components?: CFMDTOComponent[] | null;
  ModelMaintenancePeriods?: CFMDTOModelMaintenancePeriod[] | null;
}

export interface CFMDTODeviceType {
  /** @format int32 */
  Id?: number;
  Name: string;
  IsDeletable?: boolean;
}

export interface CFMDTODeviceView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ParentId?: number | null;
  Name?: string | null;
  Explanation?: string | null;
  Location?: string | null;
  BreadCrumb?: string | null;

  /** @format int32 */
  RoomId?: number;
  SerialNumber?: string | null;

  /** @format int32 */
  ProductionYear?: number | null;

  /** @format date-time */
  DateOfInstallation?: string | null;

  /** @format date-time */
  DateOfWarrantyStart?: string | null;

  /** @format int32 */
  WarrantyDuration?: number | null;

  /** @format date-time */
  ExpectedLifeEndDate?: string | null;

  /** @format int32 */
  SuggestedRunHour?: number | null;

  /** @format int32 */
  MeanTimeToFailureTheoreticalHour?: number | null;

  /** @format int32 */
  MeanTimeToFailureStatisticalHour?: number | null;

  /** @format int32 */
  MeanTimeBetweenFailureTheoreticalHour?: number | null;

  /** @format int32 */
  MeanTimeBetweenFailureStatisticalHour?: number | null;

  /** @format int32 */
  MeanTimeToRepairTheoreticalHour?: number | null;

  /** @format int32 */
  MeanTimeToRepairStatisticalHour?: number | null;

  /** @format int32 */
  Input?: number | null;

  /** @format int32 */
  Output?: number | null;

  /** @format int32 */
  InfrastructureId?: number | null;

  /** @format int32 */
  InfrastructureGroupId?: number | null;

  /** @format int32 */
  InfrastructureTypeId?: number | null;

  /** @format int32 */
  CapacityTypeId?: number | null;
  CapacityTypeBreadCrumb?: string | null;

  /** @format int32 */
  ModelId?: number | null;

  /** @format int32 */
  BrandId?: number | null;

  /** @format int32 */
  ProductFamilyId?: number | null;

  /** @format double */
  TotalEffectiveCapacity?: number | null;

  /** @format int32 */
  TotalEffectiveCapacityUnitId?: number | null;
  TotalEffectiveCapacityUnitName?: string | null;

  /** @format double */
  DryBulbTemperatureMin?: number | null;

  /** @format double */
  DryBulbTemperatureMax?: number | null;

  /** @format int32 */
  DryBulbTemperatureUnitId?: number | null;

  /** @format double */
  WetBulbTemperatureMin?: number | null;

  /** @format double */
  WetBulbTemperatureMax?: number | null;

  /** @format int32 */
  WetBulbTemperatureUnitId?: number | null;

  /** @format int32 */
  ChildCount?: number;
  BrandName?: string | null;
  ModelName?: string | null;

  /** @format int32 */
  WorkCount?: number;

  /** @format int32 */
  PointCount?: number;

  /** @format int32 */
  FinancialCount?: number;

  /** @format int32 */
  ServiceProviderCount?: number;

  /** @format int32 */
  ReferenceLibraryCount?: number;

  /** @format int32 */
  DescriptionCount?: number;

  /** @format int32 */
  PMPCount?: number;
  Parameters?: CFMDTOParameter[] | null;
  ParameterValues?: CFMDTOParameterValue[] | null;
  InputDevices?: CFMDTOFromTo[] | null;
  OutputDevices?: CFMDTOFromTo[] | null;
}

export interface CFMDTODistrict {
  /** @format int32 */
  Id?: number;
  Name: string;
  Code: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  CityId?: number;
  IsActive?: boolean;
  City?: CFMDTOCity;
}

export interface CFMDTODocumentStatus {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTODocumentValidityStatus {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTOEndProcessDTO {
  MassQuestionProcesses?: CFMDTOMassQuestionProcess[] | null;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  WorkOrderId?: number;
  Feedback?: string | null;
}

export interface CFMDTOFile {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  ContentType: string;

  /** @format byte */
  Data?: string | null;
  Base64?: string | null;

  /** @format int32 */
  FileTypeId?: number;
  FileType?: CFMDTOFileType;
}

export interface CFMDTOFileType {
  /** @format int32 */
  Id?: number;
  Name: string;
  Extension: string;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOFinancial {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ServiceProviderId?: number;

  /** @format int32 */
  ReferenceLibraryId?: number;

  /** @format int32 */
  Sla?: number;

  /** @format date-time */
  ContractStartDate?: string;

  /** @format int32 */
  ContractDuration?: number;

  /** @format double */
  AnnualContractPrice?: number | null;

  /** @format int32 */
  AnnualContractPriceUnitId?: number | null;

  /** @format double */
  RequiredManHourPrice?: number | null;

  /** @format int32 */
  RequiredManHourPriceUnitId?: number | null;

  /** @format double */
  PartsReplacementPrice?: number | null;

  /** @format int32 */
  PartsReplacementPriceUnitId?: number | null;

  /** @format double */
  SparePartsStockPrice?: number | null;

  /** @format int32 */
  SparePartsStockPriceUnitId?: number | null;
  ReferenceLibrary?: CFMDTOReferenceLibrary;
  ServiceProvider?: CFMDTOServiceProvider;
  AnnualContractPriceUnit?: CFMDTOUnit;
  RequiredManHourPriceUnit?: CFMDTOUnit;
  PartsReplacementPriceUnit?: CFMDTOUnit;
  SparePartsStockPriceUnit?: CFMDTOUnit;
}

export interface CFMDTOFloor {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  BuildingId?: number;
  Building?: CFMDTOBuilding;
  Rooms?: CFMDTORoom[] | null;
}

export interface CFMDTOForm {
  /** @format int32 */
  Id?: number;
  Name: string;
  Code: string;
  Description?: string | null;

  /** @format int32 */
  Version?: number;

  /** @format int32 */
  ParentId?: number | null;
  ResultText?: string | null;

  /** @format int32 */
  FormStatusId?: number;

  /** @format int32 */
  ProcedureTypeId?: number;

  /** @format int32 */
  CompanyId?: number;
  IsTargetEditable?: boolean | null;
  IsEditable?: boolean | null;

  /** @format int32 */
  RemainingDayUntilExpiry?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;

  /** @format date-time */
  CreatedDate?: string;

  /** @format date-time */
  RevisionDate?: string | null;
  ProcedureType?: CFMDTOProcedureType;
  FormStatus?: CFMDTOFormStatus;
  FormProcesses?: CFMDTOFormProcess[] | null;
  QuestionGroups?: CFMDTOQuestionGroup[] | null;
  FormFiles?: CFMDTOFormFile[] | null;
  FormRooms?: CFMDTOFormRoom[] | null;
  FormBrands?: CFMDTOFormBrand[] | null;
  FormModels?: CFMDTOFormModel[] | null;
  FormDevices?: CFMDTOFormDevice[] | null;
  WorkOrderView?: CFMModelWorkOrderView;
}

export interface CFMDTOFormBrand {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  BrandId?: number;

  /** @format int32 */
  CompanyId?: number;
  Form?: CFMDTOForm;
  Brand?: CFMDTOBrand;
}

export interface CFMDTOFormClone {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
  Code?: string | null;
  FormDevices?: CFMDTOFormDevice[] | null;
  FormBrands?: CFMDTOFormBrand[] | null;
  FormModels?: CFMDTOFormModel[] | null;
  FormRooms?: CFMDTOFormRoom[] | null;
}

export interface CFMDTOFormDevice {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  DeviceId?: number;

  /** @format int32 */
  CompanyId?: number;
  Form?: CFMDTOForm;
  Device?: CFMDTODevice;
}

export interface CFMDTOFormFile {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  FileId?: number;

  /** @format int32 */
  CompanyId?: number;
  Form?: CFMDTOForm;
  File?: CFMDTOFile;
}

export interface CFMDTOFormModel {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  ModelId?: number;

  /** @format int32 */
  CompanyId?: number;
  Form?: CFMDTOForm;
  Model?: CFMDTODeviceModel;
}

export interface CFMDTOFormProcess {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  FormProcessStatusId?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  CreatedUserId?: number;

  /** @format date-time */
  CreatedDate?: string;
  WorkOrder?: CFMDTOWorkOrder;
  CreatedUser?: CFMDTOUser;
  Form?: CFMDTOForm;
  FormProcessStatus?: CFMDTOFormProcessStatus;
  QuestionProcesses?: CFMDTOQuestionProcess[] | null;
  FormProcessFiles?: CFMDTOFormProcessFile[] | null;
  WorkOrderHtmlContents?: CFMDTOWorkOrderHtmlContent[] | null;

  /** @format int32 */
  InvalidAnswerCount?: number;
}

export interface CFMDTOFormProcessFile {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  FormProcessId?: number;

  /** @format int32 */
  FileId?: number;

  /** @format int32 */
  CompanyId?: number;
  Form?: CFMDTOFormProcess;
  File?: CFMDTOFile;
}

export interface CFMDTOFormProcessFileDTO {
  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  FormId?: number;
  FileIds?: number[] | null;
}

export interface CFMDTOFormProcessStatus {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  ColorId?: number;
  Color?: CFMDTOColor;
}

export interface CFMDTOFormQuestionType {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTOFormQuestionTypeComparisonOperator {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  FormQuestionTypeId?: number;

  /** @format int32 */
  ComparisonOperatorId?: number;
  FormQuestionType?: CFMDTOFormQuestionType;
  ComparisonOperator?: CFMDTOComparisonOperator;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOFormRoom {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  RoomId?: number;
  Form?: CFMDTOForm;
  Room?: CFMDTORoom;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOFormSection {
  /** @format int32 */
  Id?: number;
  Name: string;
  Description?: string | null;

  /** @format int32 */
  Order?: number | null;
  IsRelatedWithWorkOrderStart?: boolean;

  /** @format int32 */
  CompanyId?: number;
  QuestionGroups?: CFMDTOQuestionGroup[] | null;
  FormSectionProcedureTypes?: CFMDTOFormSectionProcedureType[] | null;
}

export interface CFMDTOFormSectionProcedureType {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  FormSectionId?: number;

  /** @format int32 */
  ProcedureTypeId?: number;
  FormSection?: CFMDTOFormSection;
  ProcedureType?: CFMDTOProcedureType;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOFormStatus {
  /** @format int32 */
  Id?: number;
  Name: string;
  TransitionRules?: string | null;

  /** @format int32 */
  ColorId?: number;
  Color?: CFMDTOColor;
}

export interface CFMDTOFromTo {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  FromDeviceId?: number;

  /** @format int32 */
  DeviceId?: number;
}

export interface CFMDTOHourlyProcessingCapability {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  AverageMaintenanceDuration?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  AverageMaintenanceCompletionTime?: number;

  /** @format int32 */
  AverageProcessingTime?: number;

  /** @format int32 */
  AverageHourlyProcessingCapability?: number;
}

export interface CFMDTOHtmlContent {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Content: string;
}

export interface CFMDTOIncident {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  OrderSourceId?: number;

  /** @format int32 */
  OrderStatusId?: number;

  /** @format int32 */
  OrderSubStatusId?: number | null;

  /** @format int32 */
  OrderTypeId?: number | null;
  Note?: string | null;
  Name?: string | null;
  Description?: string | null;

  /** @format int32 */
  OrderStatusDescriptionId?: number | null;

  /** @format int32 */
  PriorityTypeId?: number | null;

  /** @format date-time */
  ScheduledTime?: string | null;

  /** @format int32 */
  Duration?: number;

  /** @format int32 */
  CreatedUserId?: number;

  /** @format date-time */
  CreatedDate?: string;

  /** @format int32 */
  WorkOrderCategoryId?: number | null;
  OrderStatusNote?: string | null;
  OrderStatus?: CFMDTOWorkOrderStatus;
  OrderSubStatus?: CFMDTOWorkOrderSubStatus;
  OrderType?: CFMDTOWorkOrderType;
  AssignedUserIds?: number[] | null;
  FormIds?: number[] | null;
}

export interface CFMDTOInfrastructure {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;
  InfrastructureGroups?: CFMDTOInfrastructureGroup[] | null;
}

export interface CFMDTOInfrastructureGroup {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  InfrastructureId?: number;
  IsActive?: boolean;
  Infrastructure?: CFMDTOInfrastructure;
  InfrastructureTypes?: CFMDTOInfrastructureType[] | null;
}

export interface CFMDTOInfrastructureType {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  InfrastructureGroupId?: number;
  IsActive?: boolean;
  InfrastructureGroup?: CFMDTOInfrastructureGroup;
  CapacityTypes?: CFMDTOCapacityType[] | null;
}

export interface CFMDTOItem {
  /** @format int32 */
  Id?: number;
  Name: string;
  Description?: string | null;
  IsUIItem?: boolean;

  /** @format int32 */
  ItemTypeId?: number | null;
  IsActive?: boolean;
  ItemType?: CFMDTOSecurityItemType;
}

export interface CFMDTOJob {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;
  Name: string;
  Abbreviation?: string | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOMaintenanceGroup {
  /** @format int32 */
  Id?: number;

  /** @format date-time */
  Date?: string | null;

  /** @format int32 */
  TotalCount?: number | null;

  /** @format int32 */
  TotalDuration?: number | null;

  /** @format int32 */
  CompanyId?: number;
  Description: string;
  CancellationDescription?: string | null;
  MassWorkOrders?: CFMDTOMassWorkOrder[] | null;
  WorkOrders?: CFMDTOWorkOrder[] | null;
}

export interface CFMDTOMaintenancePlan {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  PeriodId?: number | null;

  /** @format int32 */
  MaintenanceTypeId?: number | null;

  /** @format int32 */
  MaintenanceCount?: number | null;

  /** @format date-time */
  StartDate?: string;

  /** @format date-time */
  EndDate?: string;

  /** @format int32 */
  Duration?: number;

  /** @format int32 */
  VendorId?: number | null;

  /** @format int32 */
  ContractId?: number | null;

  /** @format int32 */
  VendorNotificationRemainingDay?: number | null;
  Note?: string | null;
  DaysOfWeek?: number[] | null;
  DaysOfMonth?: number[] | null;
  
  /** @format int32 */
  WorkGroupId?: number | null;

  BreadCrumb?: string | null;

  /** @format int32 */
  RepeatedDays?: number;
}

export interface CFMDTOMaintenancePlanUpdate {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  MaintenanceTypeId?: number | null;

  /** @format date-time */
  StartDate?: string;

  /** @format int32 */
  Duration?: number;

  /** @format int32 */
  VendorId?: number | null;

  /** @format int32 */
  ContractId?: number | null;

  /** @format int32 */
  VendorNotificationRemainingDay?: number | null;
  Note?: string | null;
}

export interface CFMDTOMassPointClone {
  /** @format int32 */
  Id?: number;
  Name: string;
  Description?: string | null;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;
}

export interface CFMDTOMassQuestionProcess {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  QuestionId?: number;
  Value?: string | null;

  /** @format int32 */
  FileId?: number | null;
  SelectedSelectionIds?: number[] | null;
}

export interface CFMDTOMassWorkOrder {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  OrderStatusId?: number | null;

  /** @format int32 */
  WorkId?: number | null;

  /** @format int32 */
  DeviceId?: number;

  /** @format int32 */
  VendorId?: number | null;

  /** @format date-time */
  Date?: string;

  /** @format int32 */
  Duration?: number;
  WorkOrderUsers?: CFMDTOWorkOrderUser[] | null;
}

export interface CFMDTOModelMaintenancePeriod {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ModelId?: number;

  /** @format int32 */
  PeriodId?: number;

  /** @format int32 */
  CompanyId?: number;
  Model?: CFMDTODeviceModel;
  Period?: CFMDTOWorkPeriod;
}

export interface CFMDTOMonth {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTONetAvailableWorkHour {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  TotalHoursForOneFullTimeEmployee?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  AverageVacaionAndHolidayLeave?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  AverageSickLeave?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  AveragePersonelLeave?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  AverageTrainingLeave?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  AverageMilitaryLeave?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  AverageLunchAndBreak?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  AverageOther?: number;

  /** @format int32 */
  TotalUnAvailableTime?: number;

  /** @format int32 */
  NetAvailableWorkHours?: number;
}

export interface CFMDTOOperandType {
  /** @format int32 */
  Id?: number;
  Name: string;
  Symbol: string;
}

export interface CFMDTOOrganization {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  CampusId?: number;
  Campus?: CFMDTOCampus;
  OrganizationUsers?: CFMDTOOrganizationUser[] | null;
  ChildOrganizations?: CFMDTOOrganization[] | null;
}

export interface CFMDTOOrganizationUser {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  OrganizationId?: number;

  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  Order?: number | null;
  User?: CFMDTOUser;
  Organization?: CFMDTOOrganization;
}

export interface CFMDTOParameter {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ParentId?: number | null;
  Abbreviation: string;
  Description: string;
  ReadOnly?: boolean;
  Required?: boolean;
  Disabled?: boolean;

  /** @format int32 */
  ParameterTypeId?: number;

  /** @format int32 */
  ParameterCategoryId?: number;
  ParameterType?: CFMDTOParameterType;
  ParameterCategory?: CFMDTOParameterCategory;
  ParameterBoolean?: CFMDTOParameterBoolean;
  ParameterDatetime?: CFMDTOParameterDatetime;
  ParameterList?: CFMDTOParameterList;
  ParameterNumeric?: CFMDTOParameterNumeric;
  ParameterString?: CFMDTOParameterString;
}

export interface CFMDTOParameterBoolean {
  /** @format int32 */
  Id?: number;

  /** @format double */
  OnValue?: number | null;

  /** @format double */
  OffValue?: number | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOParameterCategory {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ParentId?: number | null;
  Name: string;

  /** @format int32 */
  ReferenceId?: number | null;
  TableName?: string | null;
  SchemaName?: string | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOParameterDatetime {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ParameterFormatId?: number;

  /** @format date-time */
  MinDate?: string | null;

  /** @format date-time */
  MaxDate?: string | null;
  ParameterFormat?: CFMDTOParameterFormat;
}

export interface CFMDTOParameterFormat {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  Pattern: string;
}

export interface CFMDTOParameterList {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  MultiSelectable?: boolean;
  Items?: CFMCoreEntityComboSource[] | null;
}

export interface CFMDTOParameterNumeric {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format double */
  Increment?: number | null;

  /** @format double */
  Maximum?: number | null;

  /** @format double */
  Minimum?: number | null;

  /** @format int32 */
  UnitId?: number;

  /** @format int32 */
  ParameterFormatId?: number;
  Unit?: CFMDTOUnit;
  ParameterFormat?: CFMDTOParameterFormat;
}

export interface CFMDTOParameterString {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Body?: string | null;

  /** @format int32 */
  MinLength?: number | null;

  /** @format int32 */
  MaxLength?: number | null;
}

export interface CFMDTOParameterType {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTOParameterValue {
  /** @format int32 */
  ReferenceId?: number;
  TableName?: string | null;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ParameterId?: number;
  Value?: string | null;
  Value2?: string | null;

  /** @format int32 */
  ParameterTypeId?: number;

  /** @format int32 */
  CompanyId?: number;
  ItemsIds?: number[] | null;
  Parameter?: CFMDTOParameter;
}

export interface CFMDTOPoint {
  /** @format int32 */
  Id?: number;
  Name: string;
  Description?: string | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  FormQuestionTypeId?: number;

  /** @format int32 */
  DefinitionId?: number;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;
  IsEditable?: boolean;
  FormQuestionType?: CFMDTOFormQuestionType;
  Device?: CFMDTODevice;
  Room?: CFMDTORoom;
  Definition?: CFMDTODefinition;
  PointSelections?: CFMDTOPointSelection[] | null;
}

export interface CFMDTOPointSelection {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  PointId?: number;

  /** @format int32 */
  Order?: number;

  /** @format int32 */
  Value?: number | null;

  /** @format int32 */
  CompanyId?: number;
  Point?: CFMDTOPoint;
  AnswerProcesses?: CFMDTOAnswerProcess[] | null;
}

export interface CFMDTOPointType {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTOPriorityType {
  /** @format int32 */
  Id?: number;
  Name: string;
  ColorId?: string | null;

  /** @format int32 */
  Order?: number;
  Color?: CFMDTOColor;
}

export interface CFMDTOProcedure {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format int32 */
  FileId?: number | null;

  /** @format int32 */
  ProcedureTypeId?: number;
  File?: CFMDTOFile;
  ProcedureType?: CFMDTOProcedureType;
}

export interface CFMDTOProcedureType {
  /** @format int32 */
  Id?: number;
  Name: string;
  FormSectionProcedureTypes?: CFMDTOFormSectionProcedureType[] | null;
}

export interface CFMDTOProductFamily {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOQualification {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format int32 */
  QualificationTypeId?: number;
  IsActive?: boolean;
  QualificationType?: CFMDTOQualificationType;
  UserQualifications?: CFMDTOUserQualification[] | null;
  WorkQualifications?: CFMDTOWorkQualification[] | null;
}

export interface CFMDTOQualificationType {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  IsActive?: boolean;
  Qualifications?: CFMDTOQualification[] | null;
}

export interface CFMDTOQuestion {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ParentId?: number | null;
  Name: string;
  Description?: string | null;

  /** @format int32 */
  QuestionGroupId?: number;

  /** @format int32 */
  Order?: number;

  /** @format int32 */
  QuestionTypeId?: number;

  /** @format int32 */
  ImageId?: number | null;

  /** @format int32 */
  PointId?: number | null;

  /** @format int32 */
  TempPointId?: number | null;

  /** @format int32 */
  AnswerConditionId?: number | null;

  /** @format int32 */
  CompanyId?: number;
  IsRequired?: boolean | null;
  AnswerCondition?: CFMDTOAnswerCondition;
  QuestionGroup?: CFMDTOQuestionGroup;
  QuestionType?: CFMDTOFormQuestionType;
  Image?: CFMDTOFile;
  Point?: CFMDTOPoint;
  TempPoint?: CFMDTOPoint;
  QuestionProcess?: CFMDTOQuestionProcess[] | null;
  PointView?: CFMModelPointView;
  TempPointView?: CFMModelPointView;
  Selections?: CFMDTOSelection[] | null;
  PointSelections?: CFMDTOPointSelection[] | null;
}

export interface CFMDTOQuestionGroup {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ParentId?: number | null;
  Name: string;

  /** @format int32 */
  Order?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  FormSectionId?: number;

  /** @format int32 */
  CompanyId?: number;
  Form?: CFMDTOForm;
  FormSection?: CFMDTOFormSection;
  Questions?: CFMDTOQuestion[] | null;
}

export interface CFMDTOQuestionProcess {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  QuestionId?: number;

  /** @format int32 */
  FormProcessId?: number;

  /** @format int32 */
  QuestionGroupId?: number;

  /** @format int32 */
  Version?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  CreatedUserId?: number;

  /** @format date-time */
  CreatedDate?: string;

  /** @format date-time */
  ModifiedDate?: string | null;

  /** @format int32 */
  ModifiedUserId?: number | null;
  CreatedUser?: CFMDTOUser;
  ModifiedUser?: CFMDTOUser;
  Question?: CFMDTOQuestion;
  FormProcess?: CFMDTOFormProcess;
  IsValid?: boolean | null;
  AnswerProcesses?: CFMDTOAnswerProcess[] | null;
}

export interface CFMDTOReferenceLibrary {
  /** @format int32 */
  Id?: number;
  Name: string;
  Content?: string | null;

  /** @format date-time */
  LastCheckDate?: string;
  Version: string;

  /** @format int32 */
  ControlFrequency?: number;

  /** @format int32 */
  ControlFrequencyUnitId?: number;

  /** @format int32 */
  DeviceId?: number;

  /** @format int32 */
  FileId?: number;

  /** @format int32 */
  DocumentStatusId?: number;

  /** @format int32 */
  ValidityStatusId?: number;
  File?: CFMDTOFile;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOReOrderParameter {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  Order?: number;
}

export interface CFMDTOReservation {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId?: number;

  /** @format int32 */
  ToolId?: number;

  /** @format date-time */
  BeginDate?: string;

  /** @format date-time */
  EndDate?: string;
  Description?: string | null;

  /** @format date-time */
  LendDate?: string | null;

  /** @format date-time */
  EstimatedReturnDate?: string | null;

  /** @format date-time */
  ActualReturnDate?: string | null;
  LendDescription?: string | null;

  /** @format int32 */
  LendedUserId?: number | null;
  ReturnedDescription?: string | null;

  /** @format int32 */
  ReturnedUserId?: number | null;
  Tool?: CFMDTOTool;
  CreatedUser?: CFMDTOUser;
  LendedUser?: CFMDTOUser;
  ReturnedUser?: CFMDTOUser;
  ToolMaintenances?: CFMDTOToolMaintenance[] | null;
  ReservationWorkOrders?: CFMDTOReservationWorkOrder[] | null;
}

export interface CFMDTOReservationWorkOrder {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ReservationId?: number;

  /** @format int32 */
  WorkOrderId?: number;
  Reservation?: CFMDTOReservation;
  WorkOrder?: CFMDTOWorkOrder;
}

export interface CFMDTOResetPasswordDTO {
  /** @format int32 */
  Id?: number;
  Token?: string | null;
  Password?: string | null;
  PasswordConfirm?: string | null;
}

export interface CFMDTORole {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;
  Name: string;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  CompanyId?: number;
  JobDescription?: string | null;
  IsShowOnCalendar?: boolean;
  IsShowOnOrganizationChart?: boolean;

  /** @format int32 */
  OrderNumber?: number | null;
  Parent?: CFMDTORole;
  Predecessors?: CFMDTORoleInheritance[] | null;
  Successors?: CFMDTORoleInheritance[] | null;
  Children?: CFMDTORole[] | null;
  WorkGroupRoles?: CFMDTOWorkGroupRole[] | null;
  Users?: CFMDTOUser[] | null;
  RoleQualifications?: CFMDTORoleQualification[] | null;
}

export interface CFMDTORoleInheritance {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  PredecessorId?: number;

  /** @format int32 */
  SuccessorId?: number;
  Predecessor?: CFMDTORole;
  Successor?: CFMDTORole;
}

export interface CFMDTORoleQualification {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  RoleId?: number;

  /** @format int32 */
  QualificationId?: number;
  Role?: CFMDTORole;
  Qualification?: CFMDTOQualification;
}

export interface CFMDTORoom {
  /** @format int32 */
  Id?: number;
  Name: string;
  Code: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  AreaSize?: number;

  /** @format int32 */
  FloorId?: number;

  /** @format int32 */
  RoomSubTypeId?: number;
  Floor?: CFMDTOFloor;
  RoomSubType?: CFMDTORoomSubType;
  Points?: CFMDTOPoint[] | null;
  CapacityEquipments?: CFMDTOCapacityEquipment[] | null;
  Devices?: CFMDTODevice[] | null;
}

export interface CFMDTORoomSubType {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  RoomTypeId?: number;
  IsActive?: boolean;
  RoomType?: CFMDTORoomType;
}

export interface CFMDTORoomType {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOSaveQuestionProcessDTO {
  MassQuestionProcesses?: CFMDTOMassQuestionProcess[] | null;

  /** @format int32 */
  WorkOrderId?: number;
}

export interface CFMDTOSaveRaciDTO {
  /** @format int32 */
  WorkGroupId?: number;
  Racies?: CFMDTOSharedKeyValueSystemInt32SystemInt32[] | null;
}

export interface CFMDTOSchedule {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format int32 */
  ScheduleTypeId?: number;

  /** @format int32 */
  Interval?: number | null;

  /** @format int32 */
  Duration?: number | null;
  ScheduleType?: CFMDTOScheduleType;
  ScheduleWeeks?: CFMDTOScheduleWeek[] | null;
  ScheduleDayOfWeeks?: CFMDTOScheduleDayOfWeek[] | null;
  ScheduleMonths?: CFMDTOScheduleMonth[] | null;
  ScheduleDayOfMonths?: CFMDTOScheduleDayOfMonth[] | null;
}

export interface CFMDTOScheduleDayOfMonth {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ScheduleId?: number;

  /** @format int32 */
  DayOfMonthId?: number;
  Schedule?: CFMDTOSchedule;
  DayOfMonth?: CFMDTODayOfMonth;
}

export interface CFMDTOScheduleDayOfWeek {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ScheduleId?: number;

  /** @format int32 */
  DayOfWeekId?: number;
  Schedule?: CFMDTOSchedule;
  DayOfWeek?: CFMDTODayOfWeek;
}

export interface CFMDTOScheduleDevice {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format date-time */
  BeginDate?: string;

  /** @format date-time */
  ExpireDate?: string;

  /** @format date-time */
  ScheduledBeginDate?: string;

  /** @format date-time */
  ScheduledEndDate?: string;
  IsSuitable?: boolean;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  ScheduleId?: number;
  Device?: CFMDTODevice;
  Schedule?: CFMDTOSchedule;
}

export interface CFMDTOScheduleMonth {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ScheduleId?: number;

  /** @format int32 */
  MonthId?: number;
  Month?: CFMDTOMonth;
}

export interface CFMDTOScheduleRoom {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format date-time */
  BeginDate?: string;

  /** @format date-time */
  ExpireDate?: string;

  /** @format date-time */
  ScheduledBeginDate?: string;

  /** @format date-time */
  ScheduledEndDate?: string;
  IsSuitable?: boolean;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  ScheduleId?: number;
  Room?: CFMDTORoom;
  Schedule?: CFMDTOSchedule;
}

export interface CFMDTOScheduleType {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTOScheduleWeek {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ScheduleId?: number;

  /** @format int32 */
  WeekId?: number;
  Schedule?: CFMDTOSchedule;
  Week?: CFMDTOWeek;
}

export interface CFMDTOScheduleWork {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format date-time */
  BeginDate?: string;

  /** @format date-time */
  ExpireDate?: string;

  /** @format date-time */
  ScheduledBeginDate?: string;

  /** @format date-time */
  ScheduledEndDate?: string;
  IsSuitable?: boolean;

  /** @format int32 */
  WorkId?: number | null;

  /** @format int32 */
  ScheduleId?: number;
  Work?: CFMDTOWork;
  Schedule?: CFMDTOSchedule;
}

export interface CFMDTOSecurityDimensionActorRolesAndUsers {
  /** @format int32 */
  ActorId?: number;
  ActorRoles?: CFMDTOActorRole[] | null;
  ActorUsers?: CFMDTOActorUser[] | null;
}

export interface CFMDTOSecurityTokenResponse {
  Token?: string | null;
  TokenType?: string | null;

  /** @format date-time */
  ExpireDate?: string;

  /** @format int32 */
  UserId?: number;
  NameSurname?: string | null;
  Email?: string | null;
  MenuItems?: string[] | null;
  UrlItems?: string[] | null;
  ServerSideItems?: string[] | null;
  ClientSideItems?: string[] | null;
  Company?: CFMDTOCompany;
}

export interface CFMDTOSecurityAction {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;
  Name?: string | null;

  /** @format int32 */
  ActionGroupId?: number;
}

export interface CFMDTOSecurityItemType {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
}

export interface CFMDTOSelection {
  /** @format int32 */
  Id?: number;
  Name: string;
  IsExpectedValue?: boolean | null;

  /** @format int32 */
  QuestionId?: number;

  /** @format int32 */
  Order?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  PointSelectionId?: number | null;
  PointSelection?: CFMDTOPointSelection;
  Question?: CFMDTOQuestion;
  AnswerProcesses?: CFMDTOAnswerProcess[] | null;
}

export interface CFMDTOServiceProvider {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  DeviceId?: number;

  /** @format int32 */
  VendorId?: number;
  Device?: CFMDTODevice;
  Vendor?: CFMDTOVendor;
  Financials?: CFMDTOFinancial[] | null;
}

export interface CFMDTOSharedKeyValueSystemInt32SystemInt32 {
  /** @format int32 */
  Key?: number;

  /** @format int32 */
  Value?: number;
}

export interface CFMDTOShift {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ShiftTypeId?: number;

  /** @format int32 */
  UserId?: number;

  /** @format date-time */
  ShiftDate?: string;
  IsActive?: boolean;
  ShiftType?: CFMDTOShiftType;
  User?: CFMDTOUser;
}

export interface CFMDTOShiftDeletableDayCount {
  Data?: number;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMDTOShiftType {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  ColorId?: number;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;

  /** @format date-span */
  StartTime?: string;

  /** @format date-span */
  EndTime?: string;
  Color?: CFMDTOColor;
}

export interface CFMDTOSourceMatrix {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMDTOSparePart {
  /** @format int32 */
  Id ?: number;
  SparePartId?: number;
  Name?: string | null;

  WarehouseId?: number;
  
  /** @format int32 */
  BrandId?: number | null;
  /** @format int32 */
  ModelId ?: number | null;
  
  /** @format int32 */
  CategoryId?: number | null;

  /** @format int32 */
  SubCategoryId?: number | null;

  /** @format int32 */
  KindId?: number | null;

  /** @format int32 */
  UnitId?: number | null;

  /** @format int32 */
  SupplyDayCount ?: number | null;
  
  Description ?: string | null;
  
  /** @format int32 */
  CriticalStockCount ?: number | null;
  SparePartDevices?: CFMDTOSparePartDevice[] | null;
  CapacityEquipment ?: CFMCoreEntityComboSource[] | null;

  /** @format int32 */
  SerialNumber?: string | null;
  
  /** @format double */
  UnitPrice?: number | null;
  UnitPriceCurrency?: string | null;

  Brand?: CFMDTOBrand;
  Model?: CFMDTODeviceModel;
  Category?: CFMDTOSparePartCategory;
  SubCategory?: CFMDTOSparePartSubCategory;
  Type?: CFMDTOSparePartType;
  Kind?: CFMDTOSparePartKind;
  Unit?: CFMDTOUnit;
  SparePartWarehouses?: CFMDTOSparePartWarehouse;
  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;
}

export interface CFMDTOSparePartComment{
  /** @format int32 */
  Id?: number;
  SparePartId?: number;
  Text: string;

  /** @format int32 */
  CreatedDate?: string;
  CompanyId?: number;
  IsActive?: boolean;

}

export interface CFMDTOSparePartHistory{
  /** @format int32 */
  Id?: number;
  SparePartId?: number;
  Text: string;

  /** @format int32 */
  CreatedDate?: string;
  CompanyId?: number;
  IsActive?: boolean;
}
export interface CFMDTODeviceHistory{
  /** @format int32 */
  Id?: number | null;

  /** @format int32 */
  CreatedUserId?: number | null;

  /** @format date-time */
  CreatedDate?: string | null;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive?: boolean | null;

  /** @format int32 */
  CompanyId?: number | null;

  /** @format date-time */
  DateOfInstallation: Date | null;
  Device?: CFMDTODevice | null;
  User?: CFMDTOUser | null;
}
export interface CFMDTOSparePartCategory {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;
}

export interface CFMDTOSparePartDevice {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  SparePartTypeId?: number;

  /** @format int32 */
  DeviceId?: number;
  SparePartType?: CFMDTOSparePart;
  Device?: CFMDTODevice;
}

export interface CFMDTOSparePartKind {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;
}

export interface CFMDTOSparePartSubCategory {
  /** @format int32 */
  Id?: number;
  Name: string;

  /** @format int32 */
  CategoryId?: number;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;
  Category?: CFMDTOSparePartCategory;
}

export interface CFMDTOSparePartType {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;
  Name: string;

  /** @format int32 */
  CompanyId?: number;
  DeviceId?: number;

  KindId?: number;
  Kind?: CFMDTOSparePartKind;

  CategoryId?: number;
  Category?: CFMDTOSparePartCategory;

  SubCategoryId?: number;
  SubCategory?: CFMDTOSparePartSubCategory;

  SparePartDevices?: CFMDTOSparePartDevice[];
}

export interface CFMDTOSparePartWarehouse {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  SparePartId?: number;

  /** @format int32 */
  WarehouseId?: number;

  /** @format int32 */
  StockCount: number;
  SparePart?: CFMDTOSparePart;
  Warehouse?: CFMDTOWarehouse;
}

export interface CFMDTOStartProcessDTO {
  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  WorkOrderId?: number;
}

export interface CFMDTORestartProcessDTO {
  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  WorkOrderId?: number;
}

export interface CFMDTOTokenDTO {
  Username?: string | null;
  Password?: string | null;
  RememberMe?: boolean;
}

export interface CFMDTOTool {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  SerialNumber?: string | null;

  /** @format int32 */
  TypeId?: number;

  /** @format int32 */
  SubTypeId?: number | null;

  /** @format int32 */
  BrandId?: number | null;

  /** @format int32 */
  ModelId?: number | null;
  IsNeedCalibration?: boolean;

  /** @format date-time */
  NextCalibrationDate?: string | null;
  IsNeedPeriodicMaintenance?: boolean;

  /** @format date-time */
  NextMaintenanceDate?: string | null;
  Description?: string | null;

  /** @format int32 */
  PictureId?: number | null;

  /** @format int32 */
  ToolStatusId?: number;

  /** @format int32 */
  WarehouseId?: number;
  Type?: CFMDTOToolType;
  SubType?: CFMDTOToolSubType;
  Brand?: CFMDTOBrand;
  Model?: CFMDTODeviceModel;
  ToolStatus?: CFMDTOToolStatus;
  Warehouse?: CFMDTOWarehouse;
  ToolHistories?: CFMDTOToolHistory[] | null;
}

export interface CFMDTOToolHistory {
  /** @format int32 */
  Id?: number;
  LogType: string;
  Description?: string | null;

  /** @format int32 */
  ToolId?: number;

  /** @format date-time */
  CreatedDate?: string;
  Tool?: CFMDTOTool;
  CreatedUser?: CFMDTOUser;
}

export interface CFMDTOToolMaintenance {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  VendorId?: number;

  /** @format int32 */
  CertificateId?: number | null;

  /** @format int32 */
  ToolMaintenanceType?: number;

  /** @format int32 */
  ReservationId?: number;
  Reservation?: CFMDTOReservation;
}

export interface CFMDTOToolSaveDto {
  tool?: CFMDTOTool;

  /** @format int32 */
  quantity?: number | null;
}

export interface CFMDTOToolStatus {
  /** @format int32 */
  Id?: number;
  Name: string;
  TransitionRules?: string | null;
}

export interface CFMDTOToolSubType {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format int32 */
  TypeId?: number;
  Type?: CFMDTOToolType;
  Tools?: CFMDTOTool[] | null;
}

export interface CFMDTOToolType {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  ToolSubTypes?: CFMDTOToolSubType[] | null;
  Tools?: CFMDTOTool[] | null;
}

export interface CFMDTOTraining {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  Code: string;
  Purpose: string;
  Content: string;

  /** @format int32 */
  RoleId?: number | null;

  /** @format int32 */
  TrainingTypeId?: number;

  /** @format int32 */
  TrainingMethodId?: number;

  /** @format int32 */
  Duration?: number;
  IsCertificatedTraining?: boolean;
  Role?: CFMDTORole;
  TrainingType?: CFMDTOTrainingType;
  TrainingMethod?: CFMDTOTrainingMethod;
  TrainingMaterials?: CFMDTOTrainingMaterial[] | null;
  TrainingAssessments?: CFMDTOTrainingAssessment[] | null;
}

export interface CFMDTOTrainingAssessment {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  AssessmentId?: number;

  /** @format int32 */
  TrainingId?: number;
  Assessment?: CFMDTOAssessment;
  Training?: CFMDTOTraining;
}

export interface CFMDTOTrainingCondition {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  TrainingId?: number;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  OperandTypeId?: number;
  Training?: CFMDTOTraining;
  Parent?: CFMDTOTrainingCondition;
  OperandType?: CFMDTOOperandType;
  TrainingConditionRules?: CFMDTOTrainingConditionRule[] | null;
  Children?: CFMDTOTrainingCondition[] | null;
}

export interface CFMDTOTrainingConditionRule {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ConditionId?: number;

  /** @format int32 */
  AssessmentTypeId?: number;
  Operator: string;

  /** @format int32 */
  FieldId?: number | null;

  /** @format int32 */
  FieldValue?: number | null;
  IsIgnored?: boolean;
  TrainingCondition?: CFMDTOTrainingCondition;
  AssessmentType?: CFMDTOAssessmentType;
}

export interface CFMDTOTrainingMaterial {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  Url?: string | null;
  Description?: string | null;

  /** @format int32 */
  FileId?: number | null;

  /** @format int32 */
  TrainingId?: number;
  File?: CFMDTOFile;
  Training?: CFMDTOTraining;
}

export interface CFMDTOTrainingMethod {
  /** @format int32 */
  Id?: number;
  Name: string;
  IsActive?: boolean;
}

export interface CFMDTOTrainingRole {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Duration?: number;

  /** @format int32 */
  RoleId?: number;

  /** @format int32 */
  TrainingId?: number;
  IsActive?: boolean;
  Role?: CFMDTORole;
  Training?: CFMDTOTraining;
}

export interface CFMDTOTrainingType {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  IsActive?: boolean;
}

export interface CFMDTOTreeViewSource {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  TypeId?: number;
  IsLeaf?: boolean;
  ChildItems?: CFMDTOTreeViewSource[] | null;
}

export interface CFMDTOTurnOver {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Year?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  HighestEmployeeCount?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  FailedToCompleteProbationaryPeriodCount?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  LeftExperiencedEmployeeCount?: number;

  /** @format double */
  TurnOverRate?: number;

  /** @format double */
  RetentionRate?: number;
}

export interface CFMDTOUnit {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;
  Name: string;

  /** @format int32 */
  CompanyId?: number;
  Sign: string;

  /** @format int32 */
  UnitTypeId?: number;

  /** @format int32 */
  FirstUnitId?: number | null;

  /** @format int32 */
  SecondUnitId?: number | null;
  UnitType?: CFMDTOUnitType;
}

export interface CFMDTOUnitType {
  /** @format int32 */
  Id?: number;
  Name: string;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Units?: CFMDTOUnit[] | null;
}

export interface CFMDTOUpdateFeedbackDTO {
  /** @format int32 */
  FormProcessId?: number;
  Feedback?: string | null;
}

export interface CFMDTOUpdateStatus {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  OrderStatusId?: number;

  /** @format int32 */
  OrderSubStatusId?: number | null;
  Note?: string | null;
}

export interface CFMDTOUser {
  /** @format int32 */
  Id?: number;
  Email: string;
  Phone?: string | null;
  Username?: string | null;
  Name: string;
  Surname: string;
  NameSurname?: string | null;

  /** @format date-time */
  DateOfEmployment?: string | null;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;

  /** @format int32 */
  JobId?: number;

  /** @format int32 */
  RoleId?: number;

  /** @format int32 */
  UserTypeId?: number;

  /** @format int32 */
  VendorId?: number | null;

  /** @format int32 */
  UserExperienceId?: number;
  IsUserTypeChangeable?: boolean;
  Token?: string | null;
  
  /** @format date-time */
  IsActiveDateTime?: Date | null;

  /** @format date-time */
  TokenExpireDate?: string | null;
  PasswordResetLink?: string | null;
  IsSendPasswordResetNotification?: boolean | null;
  IsDomainUser?: boolean | null;
  UserExperience?: CFMDTOUserExperience;
  Role?: CFMDTORole;
  Vendor?: CFMDTOVendor;
  Job?: CFMDTOJob;
  UserType?: CFMDTOUserType;
  UserTrainings?: CFMDTOUserTraining[] | null;
  UserQualifications?: CFMDTOUserQualification[] | null;
  OrganizationUsers?: CFMDTOOrganizationUser[] | null;
}

export interface CFMDTOUserAssessment {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  AssessmentScore?: number;

  /** @format date-time */
  AssessmentDate?: string;

  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  AssessmentId?: number;
  User?: CFMDTOUser;
  Assessment?: CFMDTOAssessment;
}

export interface CFMDTOUserExperience {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTOUserPosition {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;
  IsTrained?: boolean | null;
  Name: string;
  IsAssigned?: boolean | null;
}

export interface CFMDTOUserQualification {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  QualificationId?: number;
  User?: CFMDTOUser;
  Qualification?: CFMDTOQualification;
}

export interface CFMDTOUserTraining {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  TrainingId?: number;

  /** @format int32 */
  InstructorId?: number | null;

  /** @format date-time */
  IssueDate: string;

  /** @format date-time */
  ExpireDate?: string | null;

  /** @format int32 */
  RemainingDays?: number | null;
  Instructor?: CFMDTOUser;
  User?: CFMDTOUser;
  Training?: CFMDTOTraining;
}

export interface CFMDTOUserOvertime {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format date-time */
  OvertimeDate?: Date | null;

  /** @format int32 */
  Duration?: number | null;

  /** @format int32 */
  UserId?: number;

  User?: CFMDTOUser;
}

export interface CFMDTOUserType {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTOVendor {
  /** @format int32 */
  Id?: number;
  Name: string;
  Email?: string | null;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Users?: CFMDTOUser[] | null;
}

export interface CFMDTOVolumeInfluencedPosition {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  TotalMaintenanceCount?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  AverageProcessingTime?: number;

  /** @format double */
  HourlyProcessingCapability?: number;

  /** @format double */
  WorkloadInHours?: number;

  /** @format int32 */
  NetAvailableWorkHours?: number;

  /** @format double */
  AgentOccupancy?: number;

  /** @format int32 */
  AvailabilityPerPerson?: number;

  /** @format double */
  FullTimeEquivalent?: number;

  /** @format double */
  TurnOverRate?: number;

  /** @format double */
  FullTimeEquivalentPlus?: number;
}

export interface CFMDTOWarehouse {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format int32 */
  ParentId?: number | null;
  Parent?: CFMDTOWarehouse;
  Children?: CFMDTOWarehouse[] | null;
}

export interface CFMDTOWeek {
  /** @format int32 */
  Id?: number;
  Name: string;
}

export interface CFMDTOWork {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
  Description?: string | null;
  Breadcrumb?: string | null;

  /** @format int32 */
  WorkGroupId?: number;

  /** @format int32 */
  CategoryId?: number;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  ContractId?: number | null;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  PeriodId?: number | null;

  /** @format int32 */
  Duration?: number;
  IsShowOnCalendar?: boolean;

  /**
   * @format int32
   * @min 0
   * @max 400
   */
  DefermentCondition?: number | null;
  WorkGroup?: CFMDTOWorkGroup;
  Category?: CFMDTOWorkCategory;
  Device?: CFMDTODevice;
  Room?: CFMDTORoom;
  Period?: CFMDTOWorkPeriod;
  WorkTrainings?: CFMDTOWorkTraining[] | null;
  WorkQualifications?: CFMDTOWorkQualification[] | null;
  WorkProcedures?: CFMDTOWorkProcedure[] | null;
  WorkForms?: CFMDTOWorkForm[] | null;
  QAQCWorks?: CFMDTOWorkQaqc[] | null;
  ObserverRoles?: number[] | null;
}

export interface CFMDTOWorkCategory {
  /** @format int32 */
  Id?: number;
  Name: string;
  IsDailyWorkOrderCategory?: boolean;
  IsActive?: boolean;
  Works?: CFMDTOWork[] | null;
}

export interface CFMDTOWorkForm {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  Order?: number;
  Work?: CFMDTOWork;
  Form?: CFMDTOForm;
}

export interface CFMDTOWorkOrderFormVendor{
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  UserId?: number;

  SignatureFileBase64?: string | null;
  SignatureFileType?: string | null;
  SignatureFileName?: string | null;

  CreatedDate?: Date | null;
  ModifiedDate?: Date | null;

  WorkOrder?: CFMDTOWorkOrder;
  Form?: CFMDTOForm;
  User?: CFMDTOUser;
}

export interface CFMDTOWorkForMaintenancePlan {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name?: string | null;

  /** @format int32 */
  ModelId?: number | null;
  ModelName?: string | null;

  /** @format int32 */
  PeriodId?: number | null;
  PeriodName?: string | null;

  /** @format int32 */
  Duration?: number | null;
  BreadCrumb?: string | null;
}

export interface CFMDTOWorkFormView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  AvailableFormId?: number;

  /** @format int32 */
  BaseFormId?: number;

  /** @format int32 */
  Order?: number;
  Name?: string | null;

  /** @format int32 */
  Version?: number;
  ProcedureTypeName?: string | null;

  /** @format int32 */
  WorkId?: number;
  FormStatusName?: string | null;
  FormStatusColor?: string | null;
}

export interface CFMDTOWorkGroup {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name?: string | null;
  WorkGroupRoles?: CFMDTOWorkGroupRole[] | null;
  Works?: CFMDTOWork[] | null;
}

export interface CFMDTOWorkGroupRole {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  UserPositionId?: number | null;
  IsObserver?: boolean;
  IsResponsible?: boolean;
  IsAccountable?: boolean;
  IsConsulted?: boolean;
  IsInformed?: boolean;

  /** @format int32 */
  WorkGroupId?: number;

  /** @format int32 */
  RoleId?: number;
  Role?: CFMDTORole;
}

export interface CFMDTOWorkOrder {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  SequenceNumber?: number | null;

  /** @format int32 */
  OrderSourceId?: number;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  ContractId?: number | null;

  /** @format int32 */
  WorkId?: number | null;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  OrderStatusId?: number;

  /** @format int32 */
  OrderSubStatusId?: number | null;

  /** @format int32 */
  OrderTypeId?: number | null;

  /** @format int32 */
  MaintenanceGroupId?: number | null;

  /** @format int32 */
  VendorId?: number | null;
  IsMainWork?: boolean | null;
  Note?: string | null;

  /** @format int32 */
  OrderStatusDescriptionId?: number | null;

  /** @format date-time */
  ScheduledTime?: string | null;

  /** @format int32 */
  Duration?: number;
  ModifiedUser?: CFMDTOUser;

  /** @format int32 */
  ModifiedUserId?: number;

  /** @format date-time */
  ModifiedDate?: string | null;
  CreatedUser?: CFMDTOUser;

  /** @format int32 */
  CreatedUserId?: number;

  /** @format date-time */
  CreatedDate?: string;
  Name?: string | null;

  /** @format int32 */
  WorkOrderCategoryId?: number | null;
  OrderStatusNote?: string | null;

  /** @format int32 */
  VendorNotificationRemainingDay?: number | null;
  IsSentVendorNotification?: boolean | null;

  /** @format int32 */
  SpentMinutes?: number | null;
  IsHistoricalRecord?: boolean | null;
  Work?: CFMDTOWork;
  Vendor?: CFMDTOVendor;
  OrderStatus?: CFMDTOWorkOrderStatus;
  OrderSubStatus?: CFMDTOWorkOrderSubStatus;
  OrderType?: CFMDTOWorkOrderType;
  WorkOrderUsers?: CFMDTOWorkOrderUser[] | null;
  ChildWorkOrders?: CFMModelWorkOrderView[] | null;
}
export interface CFMModelWorkOrderSparePartView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  SparePartId?: number;

  /** @format int32 */
  WarehouseId?: number;

  WareHouseBreadCrumb?: string | null;

  /** @format int32 */
  Name?: string | null;

  /** @format int32 */
  KindId?: number;
  KindName?: string | null;

  /** @format int32 */
  ModelId?: number | null;
  ModelName?: string | null;

  /** @format int32 */
  BrandId?: number | null;
  BrandName?: string | null;

  /** @format int32 */
  CategoryId?: number | null;
  CategoryName?: string | null;

  /** @format int32 */
  SubCategoryId?: number | null;
  SubCategoryName?: string | null;

  /** @format int32 */
  UnitId?: number;
  UnitName?: string | null;

  /** @format int32 */
  CriticalStockCount?: number | null;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;

  /** @format int32 */
  SerialNumber?: string | null;

  /** @format int32 */
  StockCount?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  ReservedCount?: number;

  /** @format int32 */
  DeliveredCount?: number;

  /** @format int32 */
  ReturnedCount?: number;

  ReserveStatus?: number;
}
export interface CFMDTOWorkOrderSparePart {
  /** @format int32 */
  SparePartId?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  WarehouseId?: number;

  SerialNumber?: string | null;  

  /** @format int32 */
  ReservedCount?: number; 
  
  /** @format int32 */
  DeliveredCount?: number;

  /** @format int32 */
  ReturnedCount?: number;
  
  /** @format int32 */
  Status?: number; 
}
export interface CFMDTOWorkOrderDetailView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  OrderStatusId?: number;
  OrderStatusName?: string | null;
  OrderStatusDescription?: string | null;

  /** @format int32 */
  OrderTypeId?: number | null;
  BreadCrumb?: string | null;
  InfrastructureName?: string | null;
  InfrastructureTypeName?: string | null;
  InfrastructureGroupName?: string | null;
  CapacityTypeName?: string | null;
  OrderType?: string | null;

  /** @format date-time */
  ScheduledTime?: string;
  Description?: string | null;
  Note?: string | null;

  /** @format int32 */
  ModelId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;
  ModelName?: string | null;

  /** @format int32 */
  WorkId?: number;
  WorkName?: string | null;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  Duration?: number | null;

  /** @format date-time */
  StartDate?: string;

  /** @format date-time */
  EndDate?: string;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  SequenceNumber?: number | null;
  ColorCode?: string | null;
  ColorName?: string | null;

  /** @format date-time */
  StartedDate?: string | null;

  /** @format int32 */
  SpentTimeMinutes?: number | null;
  IsHistoricalRecord?: boolean | null;
  IsConvertibleToApprove?: boolean;
  IsConvertibleToPending?: boolean;
  IsConvertibleToStarted?: boolean;
  IsConvertibleToCancelled?: boolean;
  IsEditable?: boolean;
  BreadCrumbWithCampus?: string | null;
  WhoSendToApprove?: string | null;
  WhoApproved?: string | null;
  AssignedUsers?: CFMDTOAttendedUser[] | null;
  Forms?: CFMDTOForm[] | null;
}

export interface CFMDTOWorkOrderFormDTO {
  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  BaseFormId?: number;
}

export interface CFMDTOWorkOrderHtmlContent {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  FormProcessId?: number | null;

  /** @format int32 */
  QuestionId?: number | null;

  /** @format int32 */
  HtmlContentId?: number;

  /** @format int32 */
  CreatedUserId?: number;

  /** @format date-time */
  CreatedDate?: string;
  CreatedUser?: CFMDTOUser;
  WorkOrder?: CFMDTOWorkOrder;
  HtmlContent?: CFMDTOHtmlContent;
  FormProcess?: CFMDTOFormProcess;
  Question?: CFMDTOQuestion;
}

export interface CFMDTOWorkOrderStatus {
  /** @format int32 */
  Id?: number;
  Name: string;
  ColorId?: string | null;
  TransitionRules?: string | null;
  Icon?: string | null;
  ApprovalRequired?: boolean;
  IsDeletable?: boolean;
  IsFormsAnswerable?: boolean;
  IsActive?: boolean;
  Color?: CFMDTOColor;
}

export interface CFMDTOWorkOrderStatusDescription {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Description: string;

  /** @format int32 */
  OrderStatusId?: number;
  OrderStatus?: CFMDTOWorkOrderStatus;
}

export interface CFMDTOWorkOrderSubStatus {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format int32 */
  OrderStatusId?: number;
}

export interface CFMDTOWorkOrderType {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name: string;
}

export interface CFMDTOWorkOrderUser {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  UserPositionId?: number;

  /** @format date-time */
  ExecutionTime?: string | null;
  Comment?: string | null;
  WorkOrder?: CFMDTOWorkOrder;
  User?: CFMDTOUser;
  UserPosition?: CFMDTOUserPosition;
}

export interface CFMDTOWorkOrderUserDTO {
  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  UserId?: number;
}

export interface CFMDTOWorkPeriod {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  Name: string;

  /** @format int32 */
  Period?: number;

  /** @format int32 */
  UnitId?: number;
  Unit?: CFMDTOUnit;
}

export interface CFMDTOWorkProcedure {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ProcedureId?: number;

  /** @format int32 */
  WorkId?: number;
  Work?: CFMDTOWork;
  Procedure?: CFMDTOProcedure;
}

export interface CFMDTOWorkQualification {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  UserPositionId?: number;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  QualificationId?: number;
  Work?: CFMDTOWork;
  Qualification?: CFMDTOQualification;
}

export interface CFMDTOWorkTraining {
  /** @format int32 */
  Id?: number;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  UserPositionId?: number;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  TrainingId?: number;
  Work?: CFMDTOWork;
  Training?: CFMDTOTraining;
}

export interface CFMModelPointAnalysisReport {
  WorkGroupName?: string | null;
  WorkName?: string | null;
  Room?: string | null;
  Inventory?: string | null;
  Device?: string | null;
  /** @format int32 */
  PointId?: number | null;
  PointName?: string | null;
  /** @format date-time */
  ScheduledTime?: Date | null;
  /** @format int32 */
  WorkOrderId?: number | null;
  AnswerValue?: string | null;
  ExpectableValue?: string | null;
}

export interface CFMDTOWorkView {
  /** @format int32 */
  Id?: number;
  Name: string;
  Description?: string | null;
  BreadCrumb?: string | null;

  /** @format int32 */
  WorkGroupId?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  CategoryId?: number;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  ContractId?: number | null;
  
  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  PeriodId?: number | null;

  /** @format int32 */
  Duration?: number;
  IsShowOnCalendar?: boolean;

  /**
   * @format int32
   * @min 0
   * @max 400
   */
  DefermentCondition?: number | null;
  WorkGroup?: CFMDTOWorkGroup;
  Category?: CFMDTOWorkCategory;
  Device?: CFMDTODevice;
  Room?: CFMDTORoom;
  Period?: CFMDTOWorkPeriod;
  WorkTrainings?: CFMDTOWorkTraining[] | null;
  WorkQualifications?: CFMDTOWorkQualification[] | null;
  WorkProcedures?: CFMDTOWorkProcedure[] | null;
  WorkForms?: CFMDTOWorkFormView[] | null;
  WorkQaqcs?: CFMDTOQAQCWorkView[] | null;
  ObserverRoles?: number[] | null;
}

export interface CFMDTOQAQC {

  /** @format int32 */
  Id?: number;

  Name: string;
  Description: string;

  QAQCWorks?: CFMDTOQAQCWork[];
  QAQCWorkOrders?: CFMDTOQAQCWorkOrder[];
}

export interface CFMDTOQAQCWork {
  /** @format int32 */
  Id?: number;
  /** @format int32 */
  WorkId?: number;
  /** @format int32 */
  QAQCId?: number;

  QAQC?: CFMDTOQAQC;
  Work?: CFMDTOWork;
}

export interface CFMDTOQAQCWorkOrder {
  /** @format int32 */
  Id?: number;
  /** @format int32 */
  WorkOrderId?: number;
  /** @format int32 */
  QAQCId?: number;

  QAQC: CFMDTOQAQC;
  WorkOrder: CFMDTOWorkOrder;
}

export interface CFMModelAnswerCondition {
  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  OperandTypeId?: number;
  Parent?: CFMModelAnswerCondition;
  OperandType?: CFMModelOperandType;
  AnswerConditionRules?: CFMModelAnswerConditionRule[] | null;
  Children?: CFMModelAnswerCondition[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelAnswerConditionRule {
  /** @format int32 */
  AnswerConditionId?: number;

  /** @format int32 */
  ComparisonOperatorId?: number;
  FirstValue?: string | null;
  SecondValue?: string | null;
  AnswerCondition?: CFMModelAnswerCondition;
  ComparisonOperator?: CFMModelComparisonOperator;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelAnsweredFormView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  ProcedureTypeId?: number;

  /** @format int32 */
  WorkOrderId?: number;
  WorkOrderStatus?: string | null;
  WorkOrderStatusColorCode?: string | null;
  FormName?: string | null;

  /** @format int32 */
  ParentId?: number | null;

  /** @format date-time */
  CreatedDate?: string;
  CreatedUser?: string | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  ModifiedUser?: string | null;
  WorkName?: string | null;

  /** @format int32 */
  Version?: number;

  /** @format int32 */
  FormProcessStatusId?: number;
  StatusName?: string | null;
  StatusColor?: string | null;

  /** @format int32 */
  FeedbackCount?: number;

  /** @format int32 */
  InvalidAnswerCount?: number;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelAnswerProcess {
  /** @format int32 */
  SelectionId?: number | null;

  /** @format int32 */
  PointSelectionId?: number | null;

  /** @format int32 */
  QuestionProcessId?: number;
  Value?: string | null;
  CreatedUser?: CFMModelUser;
  ModifiedUser?: CFMModelUser;

  /** @format int32 */
  FileId?: number | null;
  File?: CFMModelFile;
  PointSelection?: CFMModelPointSelection;
  Selection?: CFMModelSelection;
  QuestionProcess?: CFMModelQuestionProcess;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelAssessment {
  Name: string;

  /** @format int32 */
  EvaluationScore?: number;

  /** @format int32 */
  PassingScore?: number;

  /** @format int32 */
  AssessmentTypeId?: number;
  AssessmentType?: CFMModelAssessmentType;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelAssessmentType {
  Name: string;
  HasEvaluation?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelAssetReportView {
  /** @format int32 */
  Id?: number | null;

  /** @format int32 */
  CompanyId?: number | null;

  Name?: string | null;
  ParentDevice?: string | null;
  Domain?: string | null;
  AssetType?: string | null;
  ManufacturerName?: string | null;
  ModelName?: string | null;
  SerialNumber?: string | null;
  Critical?: string | null;
  AssetCapacity?: string | null;
  
  /** @format int32 */
  ProductionYear?: number | null;

  /** @format date-time */
  DateOfInstallation?: string | null;
    
  Location?: string | null;

  /** @format date-time */
  GuaranteeStartYear?: string | null;

  /** @format date-time */
  GuaranteeExpireYear?: string | null;

  MaintenancePeriod?: string | null;
  MOP?: string | null;
  SOP?: string | null;

  /** @format date-time */
  MaintenanceContractStartDate?: string | null;
  /** @format date-time */
  MaintenanceContractExpireDate?: string | null;

  /** @format int32 */
  MaintenanceRenewalRemainingDay?: number | null;

  /** @format int32 */
  ApproximateLifeTime?: number | null;

  /** @format int32 */
  ApproximateRemainingYear?: number | null;
}

export interface CFMDTOSparePartUsingReport {
  /** @format int32 */
  SparePartId? : number | null;
  SparePartName?: string | null; 
  WarehouseName?: string | null; 
  SerialNumber?: string | null; 
  SparePartCategoryName?: string | null; 
  SparePartSubCategoryName?: string | null; 
  DeviceName?: string | null; 
  SparePartKindName?: string | null; 
  BrandName?: string | null; 
  ModelName?: string | null;
  /** @format int32 */
  WorkOrderId? : number | null;
  WorkOrderName?: string | null;
  /** @format date-time */
  CreatedDate?: Date | null;
  /** @format int32 */
  Amount? : number | null;
  UnitName?: string | null; 
}

export interface CFMModelAssignedWorkOrderView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  OrderStatusId?: number;
  OrderStatusName?: string | null;

  /** @format int32 */
  UserId?: number;
  Staff?: string | null;

  /** @format int32 */
  OrderTypeId?: number | null;
  OrderType?: string | null;

  /** @format date-time */
  ScheduledTime?: string;
  BreadCrumb?: string | null;
  Note?: string | null;

  /** @format int32 */
  DeviceId?: number | null;
  ModelName?: string | null;

  /** @format int32 */
  WorkId?: number | null;
  WorkName?: string | null;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  Duration?: number | null;

  /** @format date-time */
  StartDate?: string;

  /** @format date-time */
  EndDate?: string;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  SequenceNumber?: number | null;

  /** @format int32 */
  UserPositionId?: number;
  UserPosition?: string | null;
  ColorCode?: string | null;
  ColorName?: string | null;
  IsShowOnCalendar?: boolean;
  IsDailyWorkOrderCategory?: boolean;

  /** @format int32 */
  OrderSourceId?: number;
  OrderSourceName?: string | null;
  IsConvertibleToApprove?: boolean;
  IsConvertibleToPending?: boolean;
  IsConvertibleToStarted?: boolean;
  IsConvertibleToCancelled?: boolean;
}

export interface CFMModelAttendedUser {
  /** @format int32 */
  Id?: number;
  NameSurname?: string | null;

  /** @format int32 */
  UserPositionId?: number;
  UserPosition?: string | null;

  /** @format int32 */
  UserTypeId?: number;
  UserTypeName?: string | null;
  UserExperience?: string | null;
  Vendor?: string | null;
}

export interface CFMModelAvailableFormView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  AvailableFormId?: number;

  /** @format int32 */
  BaseFormId?: number;
  Name?: string | null;

  /** @format int32 */
  Version?: number;
  ProcedureTypeName?: string | null;
  FormStatusName?: string | null;
  FormStatusColor?: string | null;
}

export interface CFMModelBrand {
  Name: string;
  IsForTool?: boolean;
  IsForCapacityEquipment?: boolean;
  IsForDevice?: boolean;
  IsForSparePart?: boolean;
  DeviceModels?: CFMModelDeviceModel[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelBuilding {
  Name: string;

  /** @format int32 */
  CampusId?: number;

  /** @format int32 */
  DisplayOrder?: number;

  /** @format int32 */
  FloorCount?: number;
  Campus?: CFMModelCampus;
  Organizations?: CFMModelOrganization[] | null;
  Floors?: CFMModelFloor[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelBuildingView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
  BreadCrumb?: string | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  DisplayOrder?: number;

  /** @format int32 */
  FloorCount?: number;

  /** @format int32 */
  ContinentId?: number;

  /** @format int32 */
  CountryId?: number;

  /** @format int32 */
  CityId?: number;

  /** @format int32 */
  CampusId?: number;

  /** @format int32 */
  DistrictId?: number;
  IsActive?: boolean;
  Floors?: CFMModelFloor[] | null;
  Campus?: CFMModelCampus;
}

export interface CFMModelCampus {
  Name: string;

  /** @format int32 */
  Altitude?: number | null;

  /**
   * @format double
   * @min 0
   * @max 10000000000000000
   * @pattern ^\d+(\.|\,)\d{0,2}$
   */
  RelativeHumidity?: number | null;

  /** @format int32 */
  DistrictId?: number;
  District?: CFMModelDistrict;
  Buildings?: CFMModelBuilding[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelCampusView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
  BreadCrumb?: string | null;

  /** @format int32 */
  Altitude?: number | null;

  /** @format double */
  RelativeHumidity?: number | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  DistrictId?: number;

  /** @format int32 */
  CityId?: number;

  /** @format int32 */
  ContinentId?: number;

  /** @format int32 */
  CountryId?: number;
  IsActive?: boolean;
}

export interface CFMModelCapacityEquipmentCountWithInfrastructureView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  CapacityEquipmentCount?: number;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelCapacityType {
  Name: string;

  /** @format int32 */
  InfrastructureTypeId?: number;
  InfrastructureType?: CFMModelInfrastructureType;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelCategoryIncidentCountSP {
  Name?: string | null;

  /** @format int32 */
  IncidentCount?: number;
}

export interface CFMModelCategoryIncidentCountWithStatusSP {
  Name?: string | null;

  /** @format int32 */
  IncidentCount?: number;
  WorkOrderStatus?: string | null;
  ColorCode?: string | null;
}

export interface CFMModelCategoryWorkOrderCountSP {
  Name?: string | null;

  /** @format int32 */
  WorkOrderCount?: number;
}

export interface CFMModelCategoryWorkOrderCountWithStatusSP {
  Name?: string | null;

  /** @format int32 */
  WorkOrderCount?: number;
  WorkOrderStatus?: string | null;
  ColorCode?: string | null;
}

export interface CFMModelCategoryWorkOrderDurationSP {
  Name?: string | null;

  /** @format int32 */
  SpentTimeMinutes?: number;
}

export interface CFMModelCity {
  Name: string;

  /** @format int32 */
  CountryId?: number;
  Country?: CFMModelCountry;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelCityView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
  BreadCrumb?: string | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ContinentId?: number;

  /** @format int32 */
  CountryId?: number;
  IsActive?: boolean;
}

export interface CFMModelColor {
  Name: string;
  Code?: string | null;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelComparisonOperator {
  Name: string;
  Code: string;
  IsSecondValueNeeded?: boolean;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelComponent {
  Name: string;

  /** @format int32 */
  ModelId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;
  Model?: CFMModelDeviceModel;
  ComponentCheckItems?: CFMModelComponentCheckItem[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelComponentCheckItem {
  Name: string;

  /** @format int32 */
  ComponentId?: number;

  /** @format int32 */
  PeriodId?: number;
  Component?: CFMModelComponent;
  Period?: CFMModelWorkPeriod;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelContinent {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelContract {
  Name: string;
  ContractNumber: string;

  /** @format date-time */
  StartDate?: string | null;

  /** @format date-time */
  EndDate?: string | null;

  /**
   * @format double
   * @min 0
   */
  ContractPrice?: number | null;

  /** @format int32 */
  CurrencyUnitId?: number | null;

  /** @format int32 */
  VendorId?: number | null;
  IncludePublicHolidays?: boolean | null;
  Vendor?: CFMModelVendor;
  CurrencyUnit?: CFMModelUnit;
  ContractPersonels?: CFMModelContractPersonel[] | null;
  ContractDevices?: CFMModelContractDevice[] | null;
  ContractWorkDays?: CFMModelContractWorkDay[] | null;
  ContractFiles?: CFMModelContractFile[] | null;
  ContractSlas?: CFMModelContractSla[] | null;
  ContractMaintenances?: CFMModelContractMaintenance[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelContractDevice {
  /** @format int32 */
  DeviceId?: number;

  /** @format int32 */
  ContractId?: number;
  Device?: CFMModelDevice;
  Contract?: CFMModelContract;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelContractFile {
  /** @format int32 */
  ContractId?: number;

  /** @format int32 */
  FileId?: number;

  /** @format int32 */
  TypeId?: number;
  Description?: string | null;
  File?: CFMModelFile;
  Contract?: CFMModelContract;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelContractFileView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ContractId?: number;

  /** @format int32 */
  FileId?: number;

  /** @format int32 */
  TypeId?: number;
  ContractFileType?: string | null;
  Description?: string | null;
  Name?: string | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelContractMaintenance {
  /** @format int32 */
  WorkId?: number | null;

  /** @format int32 */
  Duration?: number | null;

  /** @format int32 */
  DeviceId?: number;

  /** @format date-time */
  ReferenceDate?: string;

  /** @format int32 */
  ReferenceTypeId?: number;

  /** @format int32 */
  PeriodId?: number;

  /** @format int32 */
  Tolerance?: number;

  /** @format int32 */
  TotalCount?: number;

  /** @format int32 */
  RemainedCount?: number | null;
  Name: string;

  /** @format int32 */
  ContractId?: number;

  /** @format int32 */
  SectionId?: number;
  Period?: CFMModelWorkPeriod;
  Contract?: CFMModelContract;
  Device?: CFMModelDevice;
  Work?: CFMModelWork;
  Section?: CFMModelContractSection;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelContractMaintenanceView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  SectionId?: number;
  SectionName?: string | null;

  /** @format int32 */
  ContractId?: number;

  /** @format int32 */
  DeviceId?: number;
  DeviceName?: string | null;

  /** @format int32 */
  WorkId?: number | null;
  WorkName?: string | null;

  /** @format int32 */
  Duration?: number | null;

  /** @format int32 */
  ReferenceTypeId?: number;

  /** @format date-time */
  ReferenceDate?: string;

  /** @format int32 */
  Tolerance?: number;

  /** @format int32 */
  RemainedCount?: number | null;

  /** @format int32 */
  TotalCount?: number;

  /** @format int32 */
  PeriodId?: number;
  PeriodName?: string | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelContractPersonel {
  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  ContractId?: number;
  User?: CFMModelUser;
  Contract?: CFMModelContract;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelContractSection {
  Name: string;

  /** @format int32 */
  Order?: number;

  /** @format int32 */
  ContractId?: number;
  Contract?: CFMModelContract;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelContractSla {
  Condition: string;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  CriticalityLevel?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  ReactionTime?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  WorkAroundTime?: number;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  PermanentSolutionTime?: number;
  ContactPerson: string;
  ContactPersonTel: string;
  ContactPersonMail: string;

  /** @format int32 */
  ContractId?: number;

  /** @format int32 */
  SectionId?: number;
  Contract?: CFMModelContract;
  Section?: CFMModelContractSection;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelContractView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
  ContractNumber?: string | null;

  /** @format date-time */
  StartDate?: string;

  /** @format date-time */
  EndDate?: string;
  IncludePublicHolidays?: boolean | null;

  /** @format int32 */
  VendorId?: number;
  VendorName?: string | null;

  /** @format int32 */
  ContractFileCount?: number;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelContractWorkDay {
  /** @format int32 */
  DayOfWeekId?: number;

  /** @format int32 */
  ContractId?: number;

  /** @format date-span */
  StartTime?: string | null;

  /** @format date-span */
  EndTime?: string | null;
  DayOfWeek?: CFMModelDayOfWeek;
  Contract?: CFMModelContract;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelCountry {
  Name: string;

  /** @format int32 */
  ContinentId?: number;
  Continent?: CFMModelContinent;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelCountryView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
  BreadCrumb?: string | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ContinentId?: number;
  IsActive?: boolean;
}

export interface CFMModelDayOfMonth {
  Name: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelDayOfWeek {
  Name: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelDefinition {
  Name: string;

  /** @format int32 */
  PointTypeId?: number;

  /** @format int32 */
  UnitId?: number | null;
  IsDeletable?: boolean;
  PointType?: CFMModelPointType;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelDescription {
  Text: string;

  /** @format int32 */
  DeviceId?: number;

  /** @format int32 */
  RelatedDeviceId?: number | null;
  Device?: CFMModelDevice;
  RelatedDevice?: CFMModelDevice;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelDevice {
  Name: string;
  Explanation?: string | null;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  RoomId?: number;

  /** @format int32 */
  CapacityTypeId?: number | null;

  /** @format int32 */
  ProductFamilyId?: number | null;

  /** @format int32 */
  ModelId?: number | null;
  SerialNumber?: string | null;

  /** @format int32 */
  ProductionYear?: number | null;

  /** @format date-time */
  DateOfInstallation?: string | null;

  /** @format date-time */
  DateOfWarrantyStart?: string | null;

  /** @format int32 */
  WarrantyDuration?: number | null;

  /** @format date-time */
  ExpectedLifeEndDate?: string | null;

  /** @format int32 */
  SuggestedRunHour?: number | null;

  /** @format int32 */
  MeanTimeToFailureTheoreticalHour?: number | null;

  /** @format int32 */
  MeanTimeToFailureStatisticalHour?: number | null;

  /** @format int32 */
  MeanTimeBetweenFailureTheoreticalHour?: number | null;

  /** @format int32 */
  MeanTimeBetweenFailureStatisticalHour?: number | null;

  /** @format int32 */
  MeanTimeToRepairTheoreticalHour?: number | null;

  /** @format int32 */
  MeanTimeToRepairStatisticalHour?: number | null;

  /** @format int32 */
  Input?: number | null;

  /** @format int32 */
  Output?: number | null;

  /** @format int32 */
  SourceMatrixId?: number | null;

  /** @format double */
  TotalEffectiveCapacity?: number | null;

  /** @format int32 */
  TotalEffectiveCapacityUnitId?: number | null;

  /** @format double */
  DryBulbTemperatureMin?: number | null;

  /** @format double */
  DryBulbTemperatureMax?: number | null;

  /** @format int32 */
  DryBulbTemperatureUnitId?: number | null;

  /** @format double */
  WetBulbTemperatureMin?: number | null;

  /** @format double */
  WetBulbTemperatureMax?: number | null;

  /** @format int32 */
  WetBulbTemperatureUnitId?: number | null;

  /** @format int32 */
  DeviceTypeId?: number | null;
  TotalEffectiveCapacityUnit?: CFMModelUnit;
  CapacityType?: CFMModelCapacityType;
  ProductFamily?: CFMModelProductFamily;
  Model?: CFMModelDeviceModel;
  Room?: CFMModelRoom;
  SourceMatrix?: CFMModelSourceMatrix;
  DeviceType?: CFMModelDeviceType;
  Descriptions?: CFMModelDescription[] | null;
  Points?: CFMModelPoint[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelDeviceCountWithScpFormCountView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  DeviceCountWhichHasScpForm?: number;

  /** @format int32 */
  DeviceCount?: number;

  /** @format int32 */
  DeviceCountWhichHasNoScpForm?: number;
}

export interface CFMModelDeviceMaintenancePeriod {
  /** @format int32 */
  DeviceId?: number;

  /** @format int32 */
  PeriodId?: number;
  Device?: CFMModelDevice;
  Period?: CFMModelWorkPeriod;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelDeviceModel {
  Name: string;

  /** @format int32 */
  BrandId?: number;
  IsForTool?: boolean;
  IsForCapacityEquipment?: boolean;
  IsForDevice?: boolean;
  IsForSparePart?: boolean;
  Brand?: CFMModelBrand;
  Devices?: CFMModelDevice[] | null;
  Components?: CFMModelComponent[] | null;
  ModelMaintenancePeriods?: CFMModelModelMaintenancePeriod[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelDeviceType {
  Name: string;
  IsDeletable?: boolean;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelDistrict {
  Name: string;
  Code: string;

  /** @format int32 */
  CityId?: number;
  City?: CFMModelCity;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelDistrictView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
  Code?: string | null;
  BreadCrumb?: string | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ContinentId?: number;

  /** @format int32 */
  CountryId?: number;

  /** @format int32 */
  CityId?: number;
  IsActive?: boolean;
}

export interface CFMModelFile {
  Name: string;
  ContentType: string;

  /** @format byte */
  Data?: string | null;

  /** @format int32 */
  FileTypeId?: number;
  FileType?: CFMModelFileType;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFileType {
  Name: string;
  Extension: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFloor {
  Name: string;

  /** @format int32 */
  BuildingId?: number;
  Building?: CFMModelBuilding;
  Rooms?: CFMModelRoom[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelForm {
  Name: string;
  Code: string;
  Description?: string | null;

  /** @format int32 */
  Version?: number;

  /** @format int32 */
  ParentId?: number | null;
  ResultText?: string | null;

  /** @format int32 */
  FormStatusId?: number;

  /** @format int32 */
  ProcedureTypeId?: number;

  /** @format date-time */
  RevisionDate?: string | null;
  ProcedureType?: CFMModelProcedureType;
  FormStatus?: CFMModelFormStatus;
  QuestionGroups?: CFMModelQuestionGroup[] | null;
  FormFiles?: CFMModelFormFile[] | null;
  FormRooms?: CFMModelFormRoom[] | null;
  FormBrands?: CFMModelFormBrand[] | null;
  FormModels?: CFMModelFormModel[] | null;
  FormDevices?: CFMModelFormDevice[] | null;
  FormProcesses?: CFMModelFormProcess[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormBrand {
  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  BrandId?: number;
  Form?: CFMModelForm;
  Brand?: CFMModelBrand;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormDetailView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
  Code?: string | null;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  Version?: number;
  ProcedureTypeName?: string | null;

  /** @format int32 */
  FormStatusId?: number;
  FormStatusName?: string | null;
  FormStatusColorCode?: string | null;

  /** @format int32 */
  MaxVersion?: number | null;

  /** @format date-time */
  InUseRelativeRevisionDate?: string | null;

  /** @format date-time */
  RevisionDate?: string | null;
  IsEditable?: boolean | null;

  /** @format int32 */
  RemainingDayUntilExpiry?: number | null;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;
}

export interface CFMModelFormDevice {
  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  DeviceId?: number;
  Form?: CFMModelForm;
  Device?: CFMModelDevice;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormFile {
  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  FileId?: number;
  Form?: CFMModelForm;
  File?: CFMModelFile;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormModel {
  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  ModelId?: number;
  Form?: CFMModelForm;
  Model?: CFMModelDeviceModel;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormProcess {
  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  FormProcessStatusId?: number;
  WorkOrder?: CFMModelWorkOrder;
  CreatedUser?: CFMModelUser;
  ModifiedUser?: CFMModelUser;
  Form?: CFMModelForm;
  FormProcessStatus?: CFMModelFormProcessStatus;
  QuestionProcesses?: CFMModelQuestionProcess[] | null;
  FormProcessFiles?: CFMModelFormProcessFile[] | null;
  WorkOrderHtmlContents?: CFMModelWorkOrderHtmlContent[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormProcessFile {
  /** @format int32 */
  FormProcessId?: number;

  /** @format int32 */
  FileId?: number;
  FormProcess?: CFMModelFormProcess;
  File?: CFMModelFile;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormProcessStatus {
  Name: string;

  /** @format int32 */
  ColorId?: number;
  Color?: CFMModelColor;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormQuestionType {
  Name: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormRoom {
  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  RoomId?: number;
  Form?: CFMModelForm;
  Room?: CFMModelRoom;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormSection {
  Name: string;
  Description?: string | null;

  /** @format int32 */
  Order?: number | null;
  IsRelatedWithWorkOrderStart?: boolean;
  QuestionGroups?: CFMModelQuestionGroup[] | null;
  FormSectionProcedureTypes?: CFMModelFormSectionProcedureType[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormSectionProcedureType {
  /** @format int32 */
  FormSectionId?: number;

  /** @format int32 */
  ProcedureTypeId?: number;
  FormSection?: CFMModelFormSection;
  ProcedureType?: CFMModelProcedureType;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormStatus {
  Name: string;
  TransitionRules?: string | null;

  /** @format int32 */
  ColorId?: number;
  Color?: CFMModelColor;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelFormView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  Version?: number;
  ProcedureTypeName?: string | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelFromToView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  FromDeviceId?: number;
  FromDevice?: string | null;

  /** @format int32 */
  ToDeviceId?: number;
  ToDevice?: string | null;

  /** @format int32 */
  BuildingId?: number;
  DeviceType?: string | null;

  /** @format int32 */
  ConnectionCount?: number;
}

export interface CFMModelWorkOrderIncidentReport {
  RoomName?: string | null;
  /** @format int32 */
  InventoryId?: number | null;
  InventoryCode?: string | null;
  InventoryName?: string | null;
  InfrastructureName?: string | null;
  CapacityTypeName?: string | null;
  DeviceSerialNumber?: string | null;
  DeviceBrandName?: string | null;
  DeviceModelName?: string | null;
  OrderSourceName?: string | null;
  /** @format int32 */
  OrderId?: number | null;
  OrderStatusName?: string | null;
  WorkCategoryName?: string | null;
  WorkGroupName?: string | null;
  PeriodName?: string | null;
  OrderName?: string | null;
  ContractName?: string | null;
  /** @format int32 */
  OrderPriority?: number | null;
  AssignedBy?: string | null;
  /** @format date-time */
  ScheduledTime?: string | null;
  /** @format date-time */
  ActualDate?: string | null;
  /** @format int32 */
  Duration?: number | null;
  /** @format int32 */
  ActualDuration?: number | null;
  ApprovalBy?: string | null;
  ApprovedBy?: string | null;
  HasFeedback?: boolean | null;
}

export interface CFMModelGapReport {
  /** @format int32 */
  RoundCount?: number;

  /** @format int32 */
  InvalidAnswerCount?: number;

  /** @format int32 */
  InventoryCount?: number;

  /** @format int32 */
  ControlledInventoryCount?: number;

  /** @format int32 */
  AverageControlCountPerInventory?: number;
  Rounds?: CFMModelRoundGapReportSP[] | null;
  Inventories?: CFMModelRoundGapReportForPoints[] | null; //CFMModelRoundGapReportForDeviceSP[] | null;
  Personnel?: CFMModelRoundGapReportForStaffSP[] | null;
}

export interface CFMModelHtmlContent {
  Content: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelIncidentDetailView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  OrderSourceId?: number;
  OrderSource?: string | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  OrderStatusId?: number;
  OrderStatusName?: string | null;
  OrderStatusDescription?: string | null;

  /** @format int32 */
  OrderTypeId?: number | null;
  BreadCrumb?: string | null;
  InfrastructureName?: string | null;
  InfrastructureTypeName?: string | null;
  InfrastructureGroupName?: string | null;
  CapacityTypeName?: string | null;
  OrderType?: string | null;

  /** @format date-time */
  ScheduledTime?: string;
  Note?: string | null;

  /** @format int32 */
  ModelId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;
  ModelName?: string | null;
  WorkName?: string | null;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  Duration?: number | null;

  /** @format date-time */
  StartDate?: string;

  /** @format date-time */
  EndDate?: string;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  SequenceNumber?: number | null;
  ColorCode?: string | null;
  ColorName?: string | null;

  /** @format date-time */
  StartedDate?: string | null;

  /** @format int32 */
  SpentTimeMinutes?: number | null;
  IsConvertibleToApprove?: boolean;
  IsConvertibleToPending?: boolean;
  IsConvertibleToStarted?: boolean;
  IsConvertibleToCancelled?: boolean;
  IsFormsAnswerable?: boolean;
  IsEditable?: boolean;
  BreadCrumbWithCampus?: string | null;
  WhoSendToApprove?: string | null;
  WhoApproved?: string | null;
  Name?: string | null;

  /** @format int32 */
  PriorityTypeId?: number | null;
  PriorityType?: string | null;
  Description?: string | null;
  OrderStatusNote?: string | null;
  AssignedUsers?: CFMModelAttendedUser[] | null;
  Forms?: CFMModelForm[] | null;
  PropertyCounts?: CFMModelWorkOrderPropertyCountsSP;
  WorkVendorWorkTime?: CFMWorkVendorWorkTime;
}

export interface CFMModelIncidentDeviationView {
  /** @format int32 */
  Id?: number;
  DeviceName?: string | null;
  RoomName?: string | null;
  Name?: string | null;
  PriorityName?: string | null;
  CategoryName?: string | null;

  /** @format date-time */
  ScheduledTime?: string;

  /** @format int32 */
  Duration?: number;

  /** @format date-time */
  StartedDate?: string | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Deviation?: number;

  /** @format int32 */
  StartTimeDeviation?: number;

  /** @format int32 */
  SpentTimeMinutes?: number;
}

export interface CFMModelIncidentReport {
  CategoryIncidentCountList?: CFMModelCategoryIncidentCountSP[] | null;
  CategoryIncidentDurationList?: CFMModelCategoryWorkOrderDurationSP[] | null;
  CategoryIncidentCountWithStatusList?: CFMModelCategoryIncidentCountWithStatusSP[] | null;
  MostDeviationStartDateOfIncidentList?: CFMModelIncidentDeviationView[] | null;
  MostDeviationDurationOfIncidentList?: CFMModelIncidentDeviationView[] | null;
}

export interface CFMModelInfrastructure {
  Name: string;
  InfrastructureGroups?: CFMModelInfrastructureGroup[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelInfrastructureGroup {
  Name: string;

  /** @format int32 */
  InfrastructureId?: number;
  Infrastructure?: CFMModelInfrastructure;
  InfrastructureTypes?: CFMModelInfrastructureType[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelInfrastructureType {
  Name: string;

  /** @format int32 */
  InfrastructureGroupId?: number;
  InfrastructureGroup?: CFMModelInfrastructureGroup;
  CapacityTypes?: CFMModelCapacityType[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelInfrastructureTypeView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name?: string | null;

  /** @format int32 */
  InfrastructureGroupId?: number;
  InfrastructureGroupName?: string | null;

  /** @format int32 */
  InfrastructureId?: number;
  InfrastructureName?: string | null;
  BreadCrumb?: string | null;
}

export interface CFMModelInventoryView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ParentId?: number | null;

  /** @format double */
  TotalEffectiveCapacity?: number | null;
  TotalEffectiveCapacityUnitSign?: string | null;
  SerialNumber?: string | null;

  /** @format int32 */
  ProductionYear?: number | null;

  /** @format date-time */
  DateOfInstallation?: string | null;
  DeviceType?: string | null;
  ParentDevice?: string | null;
  Name?: string | null;
  Explanation?: string | null;

  /** @format int32 */
  CapacityTypeId?: number | null;
  CapacityTypeName?: string | null;

  /** @format int32 */
  ProductFamilyId?: number | null;
  ProductFamilyName?: string | null;
  BreadCrumb?: string | null;

  /** @format int32 */
  BuildingId?: number;

  /** @format int32 */
  FloorId?: number;

  /** @format int32 */
  RoomId?: number;

  /** @format int32 */
  ChildCount?: number;

  /** @format int32 */
  PointCount?: number;
  BrandName?: string | null;

  /** @format int32 */
  BrandId?: number | null;
  ModelName?: string | null;

  /** @format int32 */
  WorkCount?: number;

  /** @format int32 */
  ContractId?: number | null;
  ContractName?: string | null;

  /** @format date-time */
  ContractEndDate?: string | null;
  ContractVendorName?: string | null;
}

export interface CFMModelJob {
  Name: string;
  Abbreviation?: string | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelMassMaintenancePlanView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  ModelId?: number | null;

  /** @format int32 */
  BrandId?: number | null;

  /** @format int32 */
  BuildingId?: number | null;

  /** @format int32 */
  ProductFamilyId?: number | null;
  ProductFamily?: string | null;

  /** @format int32 */
  CapacityTypeId?: number | null;
  DeviceName?: string | null;
  BrandModel?: string | null;
  Location?: string | null;

  /** @format date-time */
  LastMaintenanceDate?: string | null;
  Works?: CFMModelWork[] | null;
  Vendors?: CFMModelVendor[] | null;
}

export interface CFMModelModelMaintenancePeriod {
  /** @format int32 */
  ModelId?: number;

  /** @format int32 */
  PeriodId?: number;
  Model?: CFMModelDeviceModel;
  Period?: CFMModelWorkPeriod;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelMonth {
  Name: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelMostNeededTrainingWithStaffCountSp {
  /** @format int32 */
  Id?: number;
  TrainingName?: string | null;
  TrainingTypeName?: string | null;

  /** @format int32 */
  NeededStaffCount?: number;
}

export interface CFMModelMyWorkOrders {
  /** @format date-time */
  OverdueDate?: string;

  /** @format date-time */
  UpcomingDate?: string;
  OverdueWorkOrders?: CFMModelAssignedWorkOrderView[] | null;
  TodaysWorkOrders?: CFMModelAssignedWorkOrderView[] | null;
  UpcomingWorkOrders?: CFMModelAssignedWorkOrderView[] | null;
}

export interface CFMModelOperandType {
  Name: string;
  Symbol: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelOrganization {
  Name: string;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  CampusId?: number;
  Campus?: CFMModelCampus;
  OrganizationUsers?: CFMModelOrganizationUser[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelOrganizationUser {
  /** @format int32 */
  OrganizationId?: number;

  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  Order?: number | null;
  User?: CFMModelUser;
  Organization?: CFMModelOrganization;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelPendingIncidentApprovalsView {
  /** @format int32 */
  Id?: number;
  Note?: string | null;

  /** @format date-time */
  ScheduledTime?: string;

  /** @format date-time */
  ExpectedEndTime?: string;

  /** @format int32 */
  Duration?: number;

  /** @format int32 */
  ModifiedUserId?: number;
  ModifiedUserName?: string | null;

  /** @format date-time */
  ModifiedDate?: string | null;

  /** @format int32 */
  DeviceId?: number | null;
  DeviceName?: string | null;

  /** @format int32 */
  RoomId?: number | null;
  RoomName?: string | null;

  /** @format int32 */
  FloorId?: number | null;
  FloorName?: string | null;

  /** @format int32 */
  BuildingId?: number | null;
  BuildingName?: string | null;

  /** @format int32 */
  CampusId?: number | null;
  CampusName?: string | null;

  /** @format int32 */
  TypeId?: number | null;
  TypeName?: string | null;

  /** @format int32 */
  SourceId?: number;
  SourceName?: string | null;

  /** @format int32 */
  InvalidAnswerCount?: number;

  /** @format int32 */
  FlagCount?: number;
  AssignedMasterNames?: string | null;
  AssignedObserverNames?: string | null;

  /** @format int32 */
  SpentTimeMinutes?: number;
}

export interface CFMModelPendingWorkOrderApprovalsView {
  /** @format int32 */
  Id?: number;
  Note?: string | null;
  Name?: string | null;

  /** @format int32 */
  WorkId?: number;
  WorkGroupName?: string | null;

  /** @format int32 */
  WorkGroupId?: number;

  /** @format date-time */
  ScheduledTime?: string;

  /** @format date-time */
  ExpectedEndTime?: string;

  /** @format int32 */
  Duration?: number;

  /** @format int32 */
  ModifiedUserId?: number;
  ModifiedUserName?: string | null;

  /** @format date-time */
  ModifiedDate?: string | null;

  /** @format int32 */
  DeviceId?: number | null;
  DeviceName?: string | null;

  /** @format int32 */
  RoomId?: number | null;
  RoomName?: string | null;

  /** @format int32 */
  FloorId?: number | null;
  FloorName?: string | null;

  /** @format int32 */
  BuildingId?: number | null;
  BuildingName?: string | null;

  /** @format int32 */
  CampusId?: number | null;
  CampusName?: string | null;

  /** @format int32 */
  TypeId?: number | null;
  TypeName?: string | null;

  /** @format int32 */
  SourceId?: number;
  SourceName?: string | null;

   /** @format int32 */
   StatusId?: number;
   StatusName?: string | null;

  /** @format int32 */
  WorkCategoryId?: number;
  WorkCategoryName?: string | null;

  /** @format int32 */
  InvalidAnswerCount?: number;

  /** @format int32 */
  FlagCount?: number;
  AssignedMasterNames?: string | null;
  AssignedObserverNames?: string | null;

  /** @format int32 */
  SpentTimeMinutes?: number;
  IsActive?: boolean;
}

export interface CFMModelPeriodicMaintenanceView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  CategoryId?: number;
  WorkCategoryName?: string | null;

  /** @format int32 */
  PeriodId?: number;
  PeriodName?: string | null;

  /** @format int32 */
  Period?: number;
  StatusName?: string | null;
  SubStatusName?: string | null;

  /** @format date-time */
  PlannedMaintenanceDate?: string;

  /** @format date-time */
  PerformedMaintenanceDate?: string | null;

  /** @format int32 */
  DefermentCondition?: number | null;

  /** @format date-time */
  ScheduledTime?: string;

  /** @format int32 */
  WorkGroupId?: number;
  WorkGroupName?: string | null;

  /** @format int32 */
  MaintenanceGroupId?: number | null;

  /** @format int32 */
  OrderSourceId?: number;
  DeviceName?: string | null;
  CapacityTypeName?: string | null;
  InfrastructureTypeName?: string | null;
  InfrastructureGroupName?: string | null;
  InfrastructureName?: string | null;
}

export interface CFMModelPoint {
  Name: string;
  Description?: string | null;

  /** @format int32 */
  DefinitionId?: number;

  /** @format int32 */
  FormQuestionTypeId?: number;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;
  FormQuestionType?: CFMModelFormQuestionType;
  Device?: CFMModelDevice;
  Room?: CFMModelRoom;
  Definition?: CFMModelDefinition;
  PointSelections?: CFMModelPointSelection[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelPointSelection {
  Name: string;

  /** @format int32 */
  PointId?: number;

  /** @format int32 */
  Order?: number;

  /** @format int32 */
  Value?: number | null;
  Point?: CFMModelPoint;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelPointType {
  Name: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelPointView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
  Description?: string | null;

  /** @format int32 */
  DefinitionId?: number;

  /** @format int32 */
  FormQuestionTypeId?: number;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;
  IsActive?: boolean;

  /** @format int32 */
  CompanyId?: number;
  BreadCrumb?: string | null;
}

export interface CFMModelProcedure {
  Name: string;

  /** @format int32 */
  FileId?: number | null;

  /** @format int32 */
  ProcedureTypeId?: number;
  File?: CFMModelFile;
  ProcedureType?: CFMModelProcedureType;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelProcedureType {
  Name: string;
  FormSectionProcedureTypes?: CFMModelFormSectionProcedureType[] | null;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelProductFamily {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelQualification {
  Name: string;

  /** @format int32 */
  QualificationTypeId?: number;
  QualificationType?: CFMModelQualificationType;
  UserQualifications?: CFMModelUserQualification[] | null;
  WorkQualifications?: CFMModelWorkQualification[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelQualificationType {
  Name: string;
  Qualifications?: CFMModelQualification[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelQuestion {
  /** @format int32 */
  ParentId?: number | null;
  Name: string;
  Description?: string | null;

  /** @format int32 */
  QuestionGroupId?: number;

  /** @format int32 */
  Order?: number;

  /** @format int32 */
  QuestionTypeId?: number;

  /** @format int32 */
  ImageId?: number | null;

  /** @format int32 */
  PointId?: number | null;

  /** @format int32 */
  TempPointId?: number | null;

  /** @format int32 */
  AnswerConditionId?: number | null;
  IsRequired?: boolean | null;
  AnswerCondition?: CFMModelAnswerCondition;
  QuestionGroup?: CFMModelQuestionGroup;
  QuestionType?: CFMModelFormQuestionType;
  Image?: CFMModelFile;
  Point?: CFMModelPoint;
  TempPoint?: CFMModelPoint;
  Selections?: CFMModelSelection[] | null;
  QuestionProcesses?: CFMModelQuestionProcess[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelQuestionGroup {
  /** @format int32 */
  ParentId?: number | null;
  Name: string;

  /** @format int32 */
  Order?: number;

  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  FormSectionId?: number;
  Form?: CFMModelForm;
  FormSection?: CFMModelFormSection;
  Questions?: CFMModelQuestion[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelQuestionProcess {
  /** @format int32 */
  QuestionId?: number;

  /** @format int32 */
  FormProcessId?: number;

  /** @format int32 */
  QuestionGroupId?: number;

  /** @format int32 */
  Version?: number;
  IsValid?: boolean | null;
  CreatedUser?: CFMModelUser;
  ModifiedUser?: CFMModelUser;
  Question?: CFMModelQuestion;
  FormProcess?: CFMModelFormProcess;
  AnswerProcesses?: CFMModelAnswerProcess[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelRole {
  Name: string;

  /** @format int32 */
  ParentId?: number | null;
  JobDescription?: string | null;
  IsShowOnCalendar?: boolean;
  IsShowOnOrganizationChart?: boolean;

  /** @format int32 */
  OrderNumber?: number | null;
  Parent?: CFMModelRole;
  Predecessors?: CFMModelRoleInheritance[] | null;
  Successors?: CFMModelRoleInheritance[] | null;
  Children?: CFMModelRole[] | null;
  WorkGroupRoles?: CFMModelWorkGroupRole[] | null;
  RoleQualifications?: CFMModelRoleQualification[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelRoleInheritance {
  /** @format int32 */
  PredecessorId?: number;

  /** @format int32 */
  SuccessorId?: number;
  Predecessor?: CFMModelRole;
  Successor?: CFMModelRole;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelRoleQualification {
  /** @format int32 */
  RoleId?: number;

  /** @format int32 */
  QualificationId?: number;
  Role?: CFMModelRole;
  Qualification?: CFMModelQualification;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelRoom {
  Name: string;
  Code: string;

  /** @format int32 */
  AreaSize?: number;

  /** @format int32 */
  FloorId?: number;

  /** @format int32 */
  RoomSubTypeId?: number;
  Floor?: CFMModelFloor;
  RoomSubType?: CFMModelRoomSubType;
  Points?: CFMModelPoint[] | null;
  Devices?: CFMModelDevice[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelRoomSubType {
  Name: string;

  /** @format int32 */
  RoomTypeId?: number;
  RoomType?: CFMModelRoomType;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelRoomType {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelRoomView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;
  Code?: string | null;

  /** @format int32 */
  CompanyId?: number;
  BreadCrumb?: string | null;

  /** @format int32 */
  AreaSize?: number;
  RoomType?: string | null;
  RoomSubType?: string | null;

  /** @format int32 */
  RoomTypeId?: number;

  /** @format int32 */
  RoomSubTypeId?: number;

  /** @format int32 */
  ContinentId?: number;

  /** @format int32 */
  CountryId?: number;

  /** @format int32 */
  CityId?: number;

  /** @format int32 */
  DistrictId?: number;

  /** @format int32 */
  CampusId?: number;

  /** @format int32 */
  BuildingId?: number;

  /** @format int32 */
  FloorId?: number;

  /** @format int32 */
  ChildCount?: number;

  /** @format int32 */
  PointCount?: number;
}

export interface CFMModelRoundDurationStaffReport {
  /** @format int32 */
  Id?: number;

  /** @format date-time */
  StartedDate?: string;
  Name?: string | null;

  /** @format int32 */
  SpentTimeMinutes?: number;
  AssignedUsers?: string | null;
}

export interface CFMModelRoundGapReportForDeviceSP {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  ControlCount?: number;

  /** @format int32 */
  InvalidPointCount?: number;
  MostInvalidAnsweredPointName?: string | null;

  /** @format int32 */
  MostInvalidAnsweredPointInvalidCount?: number | null;
}

export interface CFMModelStaffPerformanceReport {
  PreventativeMaintenenceList?: CFMModelPerformanceReport[] | null;
  RoundList?: CFMModelPerformanceReport[] | null;
  IncidentList?: CFMModelPerformanceReport[] | null;
}

export interface CFMModelPerformanceReport {
  /** @format int32 */
  StaffId?: number;

  StaffName?: string | null;

  /** @format int32 */
  OrderCount?: number;

  /** @format int32 */
  SpentTime?: number;

  /** @format int32 */
  ShiftTime?: number;

  /** @format int32 */
  Overtime?: number;

  /** @format int32 */
  InvalidAnswerCount?: number;

  /** @format int32 */
  FlagCount?: number;
}

export interface CFMModelRoundGapReportForPoints {
  /** @format int32 */
  Id?: number;
  
  DeviceName?: string | null;
  
  /** @format int32 */
  ParentDeviceId?: number;
  
  ParentDeviceName?: string | null;
  
  RoomName?: string | null;
  
  PointName?: string | null;

  /** @format int32 */
  ControlCount?: number;

  /** @format int32 */
  InvalidPointCount?: number;
}

export interface CFMModelRoundGapReportForStaffSP {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  FoundInvalid?: number;

  /** @format int32 */
  AddedFlagCount?: number;

  /** @format int32 */
  RoundCount?: number;
}

export interface CFMModelRoundGapReportSP {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  RoundCount?: number;

  /** @format int32 */
  FoundInvalid?: number;

  /** @format int32 */
  AddedFlagCount?: number;
}

export interface CFMModelSchedule {
  Name: string;

  /** @format int32 */
  ScheduleTypeId?: number;

  /** @format int32 */
  Interval?: number | null;

  /** @format int32 */
  Duration?: number | null;
  ScheduleType?: CFMModelScheduleType;
  ScheduleWeeks?: CFMModelScheduleWeek[] | null;
  ScheduleDayOfWeeks?: CFMModelScheduleDayOfWeek[] | null;
  ScheduleMonths?: CFMModelScheduleMonth[] | null;
  ScheduleDayOfMonths?: CFMModelScheduleDayOfMonth[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelScheduleDayOfMonth {
  /** @format int32 */
  ScheduleId?: number;

  /** @format int32 */
  DayOfMonthId?: number;
  Schedule?: CFMModelSchedule;
  DayOfMonth?: CFMModelDayOfMonth;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelScheduleDayOfWeek {
  /** @format int32 */
  ScheduleId?: number;

  /** @format int32 */
  DayOfWeekId?: number;
  Schedule?: CFMModelSchedule;
  DayOfWeek?: CFMModelDayOfWeek;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelScheduleDevice {
  Name: string;

  /** @format date-time */
  BeginDate?: string;

  /** @format date-time */
  ExpireDate?: string;

  /** @format date-time */
  ScheduledBeginDate?: string;

  /** @format date-time */
  ScheduledEndDate?: string;
  IsSuitable?: boolean;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  ScheduleId?: number;
  Device?: CFMModelDevice;
  Schedule?: CFMModelSchedule;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelScheduledVsActualDurationSP {
  Name?: string | null;

  /** @format int32 */
  ScheduledDurationTimeMinutes?: number;

  /** @format int32 */
  ActualDurationTimeMinutes?: number;
}

export interface CFMModelScheduleMonth {
  /** @format int32 */
  ScheduleId?: number;

  /** @format int32 */
  MonthId?: number;
  Schedule?: CFMModelSchedule;
  Month?: CFMModelMonth;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelScheduleRoom {
  Name: string;

  /** @format date-time */
  BeginDate?: string;

  /** @format date-time */
  ExpireDate?: string;

  /** @format date-time */
  ScheduledBeginDate?: string;

  /** @format date-time */
  ScheduledEndDate?: string;
  IsSuitable?: boolean;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  ScheduleId?: number;
  Room?: CFMModelRoom;
  Schedule?: CFMModelSchedule;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelScheduleType {
  Name: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelScheduleWeek {
  /** @format int32 */
  ScheduleId?: number;

  /** @format int32 */
  WeekId?: number;
  Schedule?: CFMModelSchedule;
  Week?: CFMModelWeek;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelScheduleWork {
  Name: string;

  /** @format date-time */
  BeginDate?: string;

  /** @format date-time */
  ExpireDate?: string;

  /** @format date-time */
  ScheduledBeginDate?: string;

  /** @format date-time */
  ScheduledEndDate?: string;
  IsSuitable?: boolean;

  /** @format int32 */
  WorkId?: number | null;

  /** @format int32 */
  ScheduleId?: number;
  Work?: CFMModelWork;
  Schedule?: CFMModelSchedule;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelSecurityActionView {
  /** @format int32 */
  Id?: number;
  ActionName?: string | null;
  ActionGroupName?: string | null;

  /** @format int32 */
  ActionGroupId?: number;
}

export interface CFMModelSelection {
  Name: string;
  IsExpectedValue?: boolean | null;

  /** @format int32 */
  QuestionId?: number;

  /** @format int32 */
  Order?: number;

  /** @format int32 */
  PointSelectionId?: number | null;
  PointSelection?: CFMModelPointSelection;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelShiftType {
  Name: string;

  /** @format int32 */
  ColorId?: number;

  /** @format date-span */
  StartTime?: string;

  /** @format date-span */
  EndTime?: string;
  Color?: CFMModelColor;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelShiftView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format date-time */
  ShiftDate?: string;

  /** @format date-time */
  StartDate?: string;

  /** @format date-time */
  EndDate?: string;

  /** @format int32 */
  ShiftTypeId?: number;
  ShiftType?: string | null;
  ColorCode?: string | null;
  ColorName?: string | null;

  /** @format int32 */
  UserId?: number;
  NameSurname?: string | null;
}

export interface CFMModelSourceMatrix {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelSparePartCategory {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelSparePartKind {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelSparePartSubCategory {
  Name: string;

  /** @format int32 */
  CategoryId?: number;
  Category?: CFMModelSparePartCategory;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelSparePartType {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelSparePartView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  StockCount?: number;

  /** @format int32 */
  WarehouseId?: number;
  WarehouseBreadCrumb?: string | null;

  /** @format int32 */
  SparePartId?: number;

  /** @format int32 */
  TypeId?: number;
  Name?: string | null;

  /** @format int32 */
  KindId?: number;
  KindName?: string | null;

  /** @format int32 */
  ModelId?: number | null;
  ModelName?: string | null;

  /** @format int32 */
  BrandId?: number | null;
  BrandName?: string | null;

  /** @format int32 */
  CategoryId?: number | null;
  CategoryName?: string | null;

  /** @format int32 */
  SubCategoryId?: number | null;
  SubCategoryName?: string | null;

  /** @format double */
  UnitPrice?: number | null;
  UnitPriceCurrency?: string | null;

  /** @format int32 */
  UnitId?: number;
  UnitName?: string | null;

  /** @format int32 */
  SupplyDayCount?: number | null;
  SerialNumber?: string | null;

  /** @format int32 */
  CriticalStockCount?: number | null;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;
}

export interface CFMModelStaffCountInShiftSP {
  Name?: string | null;

  /** @format int32 */
  StaffCount?: number;
  ColorCode?: string | null;

  /** @format date-time */
  ShiftDate?: string;
}

export interface CFMModelStaffRoundCountsReport {
  NameSurname?: string | null;

  /** @format int32 */
  RoundCount?: number;
}

export interface CFMModelVendorWorkTimeReport {
  /** @format int32 */
  WorkOrderId?: number;

  OrderType?: string | null;

  VendorName?: string | null;

  /** @format int32 */
  UserId?: number;

  UserName?: string | null;

  /** @format date-time */
  VendorStartDate?: string | null;

  /** @format date-time */
  VendorEndDate?: string | null;

  /** @format int32 */
  VendorSpentTime?: number | null;

  InventoryName?: string | null;
}

export interface CFMModelSuitableWorkUserSP {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  RoleId?: number;
  Role?: string | null;

  /** @format int32 */
  UserExperienceId?: number;
  UserExperience?: string | null;
  UserType?: string | null;

  /** @format int32 */
  UserTypeId?: number;
  IsVendor?: boolean;
  IsHaveShift?: boolean;
  IsHaveWorkOrder?: boolean;
  IsAssignableToMaster?: boolean;
  IsMaster?: boolean;
  IsTrainedForMaster?: boolean;
  IsObserver?: boolean;
  IsAssignableToObserver?: boolean;
  IsTrainedForObserver?: boolean;
  Vendor?: string | null;
  Shifts?: CFMModelShiftView[] | null;
  WorkOrders?: CFMModelWorkOrderUserView[] | null;
  UserTrainedDescription?: string | null;
  ObserverUserTrainedDescription?: string | null;
  ShiftDescription?: string | null;
  OverlappedDescription?: string | null;
  UserDisplayText?: string | null;
  ObserverUserDisplayText?: string | null;
}

export interface CFMModelTool {
  SerialNumber?: string | null;

  /** @format int32 */
  TypeId?: number;

  /** @format int32 */
  SubTypeId?: number | null;

  /** @format int32 */
  BrandId?: number | null;

  /** @format int32 */
  ModelId?: number | null;
  IsNeedCalibration?: boolean;

  /** @format date-time */
  NextCalibrationDate?: string | null;
  IsNeedPeriodicMaintenance?: boolean;

  /** @format date-time */
  NextMaintenanceDate?: string | null;
  Description?: string | null;

  /** @format int32 */
  PictureId?: number | null;

  /** @format int32 */
  ToolStatusId?: number;

  /** @format int32 */
  WarehouseId?: number;
  Type?: CFMModelToolType;
  SubType?: CFMModelToolSubType;
  Brand?: CFMModelBrand;
  Model?: CFMModelDeviceModel;
  ToolStatus?: CFMModelToolStatus;
  Warehouse?: CFMModelWarehouse;
  ToolHistories?: CFMModelToolHistory[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelToolHistory {
  LogType: string;
  Description?: string | null;

  /** @format int32 */
  ToolId?: number;
  Tool?: CFMModelTool;
  CreatedUser?: CFMModelUser;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelToolStatus {
  Name: string;
  TransitionRules?: string | null;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelToolSubType {
  Name: string;

  /** @format int32 */
  TypeId?: number;
  Type?: CFMModelToolType;
  Tools?: CFMModelTool[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelToolType {
  Name: string;
  ToolSubTypes?: CFMModelToolSubType[] | null;
  Tools?: CFMModelTool[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelTraining {
  Name: string;
  Code: string;
  Purpose: string;
  Content: string;

  /** @format int32 */
  RoleId?: number | null;

  /** @format int32 */
  TrainingTypeId?: number;

  /** @format int32 */
  TrainingMethodId?: number;

  /** @format int32 */
  Duration?: number;
  IsCertificatedTraining?: boolean;
  Role?: CFMModelRole;
  TrainingType?: CFMModelTrainingType;
  TrainingMethod?: CFMModelTrainingMethod;
  TrainingMaterials?: CFMModelTrainingMaterial[] | null;
  TrainingAssessments?: CFMModelTrainingAssessment[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelTrainingAssessment {
  /** @format int32 */
  AssessmentId?: number;

  /** @format int32 */
  TrainingId?: number;
  Assessment?: CFMModelAssessment;
  Training?: CFMModelTraining;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelTrainingDemand {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  ParentId?: number;
  ParentName?: string | null;

  /** @format int32 */
  Total?: number;
  IsUntrained?: boolean | null;
}

export interface CFMModelTrainingMaterial {
  Name: string;
  Url?: string | null;
  Description?: string | null;

  /** @format int32 */
  FileId?: number | null;

  /** @format int32 */
  TrainingId?: number;
  File?: CFMModelFile;
  Training?: CFMModelTraining;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelTrainingMethod {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelTrainingSp {
  /** @format int32 */
  RoleId?: number;
  RoleName?: string | null;

  /** @format int32 */
  TrainingId?: number;
  TrainingName?: string | null;

  /** @format int32 */
  UserId?: number;
  UserName?: string | null;
  UserSurname?: string | null;

  /** @format int32 */
  TrainingTypeId?: number;
  TrainingTypeName?: string | null;

  /** @format int32 */
  Duration?: number;

  /** @format date-time */
  IssueDate?: string | null;

  /** @format date-time */
  ExpireDate?: string | null;

  /** @format int32 */
  RemainingDay?: number | null;
  IsValid?: boolean;
}

export interface CFMModelTrainingType {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelUnit {
  Name: string;
  Sign: string;

  /** @format int32 */
  UnitTypeId?: number;

  /** @format int32 */
  FirstUnitId?: number | null;

  /** @format int32 */
  SecondUnitId?: number | null;
  UnitType?: CFMModelUnitType;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelUnitType {
  Name: string;
  Units?: CFMModelUnit[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelUser {
  Email: string;
  Phone?: string | null;
  Password: string;
  Username?: string | null;
  Name: string;
  Surname: string;
  Token?: string | null;

  /** @format date-time */
  TokenExpireDate?: string | null;

  /** @format date-time */
  DateOfEmployment?: string | null;

  /** @format int32 */
  JobId?: number;

  /** @format int32 */
  RoleId?: number;

  /** @format int32 */
  VendorId?: number | null;

  /** @format int32 */
  UserTypeId?: number;

  /** @format int32 */
  UserExperienceId?: number;
  UserExperience?: CFMModelUserExperience;
  Role?: CFMModelRole;
  Vendor?: CFMModelVendor;
  Job?: CFMModelJob;
  UserType?: CFMModelUserType;
  UserTrainings?: CFMModelUserTraining[] | null;
  UserQualifications?: CFMModelUserQualification[] | null;
  OrganizationUsers?: CFMModelOrganizationUser[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelUserExperience {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelUserInfoView {
  /** @format int32 */
  Id?: number;
  NameSurname?: string | null;
  Email?: string | null;
  Phone?: string | null;
  JobName?: string | null;

  /** @format int32 */
  RoleId?: number;
  RoleName?: string | null;

  /** @format int32 */
  VendorId?: number | null;
  VendorName?: string | null;

  /** @format int32 */
  UserTypeId?: number;
  UserTypeName?: string | null;

  /** @format int32 */
  UserExperienceId?: number;
  UserExpreienceName?: string | null;

  /** @format int32 */
  CompanyId?: number;
  IsActive?: boolean;
}

export interface CFMModelUserPosition {
  Name: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelUserQualification {
  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  QualificationId?: number;
  User?: CFMModelUser;
  Qualification?: CFMModelQualification;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelUserTraining {
  /** @format date-time */
  IssueDate: string;

  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  TrainingId?: number;

  /** @format int32 */
  InstructorId?: number | null;
  Instructor?: CFMModelUser;
  User?: CFMModelUser;
  Training?: CFMModelTraining;
  UserTrainingFiles?: CFMModelUserTrainingFile[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelUserTrainingFile {
  /** @format int32 */
  UserTrainingId?: number;

  /** @format int32 */
  FileId?: number;
  UserTraining?: CFMModelUserTraining;
  File?: CFMModelFile;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelUserType {
  Name: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelVendor {
  Name: string;
  Email?: string | null;
  Users?: CFMModelUser[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWarehouse {
  Name: string;

  /** @format int32 */
  ParentId?: number | null;
  Parent?: CFMModelWarehouse;
  Children?: CFMModelWarehouse[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWeek {
  Name: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWork {
  Name: string;
  Description?: string | null;

  /** @format int32 */
  WorkGroupId?: number;

  /** @format int32 */
  CategoryId?: number;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  Duration?: number;

  /** @format int32 */
  PeriodId?: number | null;
  IsShowOnCalendar?: boolean;

  /**
   * @format int32
   * @min 0
   * @max 400
   */
  DefermentCondition?: number | null;
  WorkGroup?: CFMModelWorkGroup;
  Category?: CFMModelWorkCategory;
  Device?: CFMModelDevice;
  Room?: CFMModelRoom;
  Period?: CFMModelWorkPeriod;
  WorkTrainings?: CFMModelWorkTraining[] | null;
  WorkQualifications?: CFMModelWorkQualification[] | null;
  WorkProcedures?: CFMModelWorkProcedure[] | null;
  WorkForms?: CFMModelWorkForm[] | null;
  ObserverRoles?: number[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkCategory {
  Name: string;
  IsDailyWorkOrderCategory?: boolean;
  Works?: CFMModelWork[] | null;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkForm {
  /** @format int32 */
  FormId?: number;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  Order?: number;
  Work?: CFMModelWork;
  Form?: CFMModelForm;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkFormView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  AvailableFormId?: number;

  /** @format int32 */
  BaseFormId?: number;

  /** @format int32 */
  Order?: number;
  Name?: string | null;

  /** @format int32 */
  Version?: number;
  ProcedureTypeName?: string | null;

  /** @format int32 */
  WorkId?: number;
  FormStatusName?: string | null;
  FormStatusColor?: string | null;
}

export interface CFMModelWorkGroup {
  Name: string;
  WorkGroupRoles?: CFMModelWorkGroupRole[] | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkGroupRole {
  /** @format int32 */
  UserPositionId?: number | null;
  IsObserver?: boolean;
  IsResponsible?: boolean;
  IsAccountable?: boolean;
  IsConsulted?: boolean;
  IsInformed?: boolean;

  /** @format int32 */
  WorkGroupId?: number;

  /** @format int32 */
  RoleId?: number;
  Role?: CFMModelRole;
  WorkGroup?: CFMModelWorkGroup;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkOrder {
  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  WorkId?: number | null;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  SequenceNumber?: number | null;

  /** @format int32 */
  OrderSourceId?: number;

  /** @format int32 */
  OrderStatusId?: number;

  /** @format int32 */
  OrderSubStatusId?: number | null;

  /** @format int32 */
  OrderTypeId?: number | null;

  /** @format int32 */
  MaintenanceGroupId?: number | null;

  /** @format int32 */
  VendorId?: number | null;
  Note?: string | null;

  /** @format int32 */
  OrderStatusDescriptionId?: number | null;

  /** @format date-time */
  ScheduledTime?: string;

  /** @format int32 */
  Duration?: number;
  Name?: string | null;
  Description?: string | null;

  /** @format int32 */
  PriorityTypeId?: number | null;

  /** @format int32 */
  WorkOrderCategoryId?: number | null;
  OrderStatusNote?: string | null;

  /** @format int32 */
  VendorNotificationRemainingDay?: number | null;
  IsSentVendorNotification?: boolean | null;

  /** @format int32 */
  SpentMinutes?: number | null;
  IsHistoricalRecord?: boolean | null;
  ModifiedUser?: CFMModelUser;
  CreatedUser?: CFMModelUser;
  Work?: CFMModelWork;
  Vendor?: CFMModelVendor;
  OrderSubStatus?: CFMModelWorkOrderSubStatus;
  OrderStatus?: CFMModelWorkOrderStatus;
  OrderType?: CFMModelWorkOrderType;
  WorkOrderUsers?: CFMModelWorkOrderUser[] | null;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelWorkOrderAssignedUserView {
  /** @format int32 */
  Id?: number;
  NameSurname?: string | null;
  Email?: string | null;
  Phone?: string | null;
  JobName?: string | null;
  RoleName?: string | null;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  UserPositionId?: number;
  UserPosition?: string | null;

  /** @format int32 */
  UserTypeId?: number;
  UserTypeName?: string | null;
  UserExperience?: string | null;

  /** @format int32 */
  VendorId?: number | null;
  VendorName?: string | null;
  IsActiveUser?: boolean;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelWorkOrderConvertibleStatusView {
  /** @format int32 */
  Id?: number;
  IsConvertibleToApprove?: boolean;
  IsConvertibleToPending?: boolean;
  IsConvertibleToStarted?: boolean;
  IsConvertibleToCancelled?: boolean;
}

export interface CFMModelWorkOrderCountSummarySP {
  /** @format int32 */
  CapacityEquipmentCount?: number;

  /** @format int32 */
  PreventiveMaintenanceCount?: number;

  /** @format int32 */
  DevicePreventiveMaintenanceCount?: number;

  /** @format int32 */
  RoundCount?: number;

  /** @format int32 */
  DeviceCountForRound?: number;

  /** @format double */
  DailyAvarageRoundCount?: number;
}

export interface CFMModelWorkOrderDetailView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  OrderStatusId?: number;
  OrderStatusName?: string | null;
  OrderSubStatusName?: string | null;
  OrderStatusDescription?: string | null;

  /** @format int32 */
  OrderSourceId?: number;
  OrderSourceName?: string | null;
  OrderCategoryName?: string | null;

  /** @format int32 */
  OrderTypeId?: number | null;
  BreadCrumb?: string | null;
  InfrastructureName?: string | null;
  InfrastructureTypeName?: string | null;
  InfrastructureGroupName?: string | null;
  CapacityTypeName?: string | null;
  OrderType?: string | null;

  /** @format date-time */
  ScheduledTime?: string;
  Note?: string | null;

  /** @format int32 */
  ModelId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;
  ModelName?: string | null;

  /** @format int32 */
  WorkId?: number | null;
  WorkName?: string | null;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  Duration?: number | null;

  /** @format date-time */
  StartDate?: string;

  /** @format date-time */
  EndDate?: string;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  SequenceNumber?: number | null;
  ColorCode?: string | null;
  ColorName?: string | null;

  /** @format date-time */
  StartedDate?: string | null;

  /** @format int32 */
  SpentTimeMinutes?: number | null;
  IsHistoricalRecord?: boolean | null;
  IsConvertibleToApprove?: boolean;
  IsConvertibleToPending?: boolean;
  IsConvertibleToStarted?: boolean;
  IsConvertibleToCancelled?: boolean;
  WorkCategory?: string | null;
  IsFormsAnswerable?: boolean;
  IsEditable?: boolean;
  BreadCrumbWithCampus?: string | null;
  WhoSendToApprove?: string | null;
  WhoApproved?: string | null;
  Name?: string | null;

  /** @format int32 */
  PriorityTypeId?: number | null;
  PriorityType?: string | null;
  Description?: string | null;
  OrderStatusNote?: string | null;
  AssignedUsers?: CFMModelAttendedUser[] | null;
  Forms?: CFMModelForm[] | null;
  PropertyCounts?: CFMModelWorkOrderPropertyCountsSP;
  WorkVendorWorkTime?: CFMWorkVendorWorkTime;
}

export interface CFMModelWorkOrderDeviationView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  OrderStatusId?: number;
  OrderStatusName?: string | null;

  /** @format date-time */
  ScheduledTime?: string;
  DeviceName?: string | null;
  WorkName?: string | null;

  /** @format int32 */
  WorkCategoryId?: number | null;
  WorkCategory?: string | null;

  /** @format int32 */
  Duration?: number;

  /** @format date-time */
  ScheduledStartDate?: string;

  /** @format date-time */
  ScheduledEndDate?: string;

  /** @format date-time */
  StartedDate?: string | null;

  /** @format int32 */
  SpentTimeMinutes?: number;

  /** @format int32 */
  Deviation?: number | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelWorkOrderForm {
  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  FormId?: number;
  WorkOrder?: CFMModelWorkOrder;
  Form?: CFMModelForm;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkOrderFormStatusSummarySP {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  BaseFormId?: number;
  Name?: string | null;
  Code?: string | null;

  /** @format int32 */
  ProcedureTypeId?: number;
  ProcedureType?: string | null;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  FormProcessStatusId?: number | null;
  FormProcess?: string | null;
  ColorCode?: string | null;

  /** @format int32 */
  FormProcessFileCount?: number;

  /** @format int32 */
  FormVendorSignatureCount?: number;

  /** @format int32 */
  InvalidAnswerCount?: number;

  /** @format int32 */
  FlagCount?: number;
  IsCurrentForm?: boolean;
}

export interface CFMModelWorkOrderHistoryView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  CreatedUserId?: number;

  /** @format date-time */
  CreatedDate?: string;
  CreatedUser?: string | null;

  /** @format date-time */
  ModifiedDate?: string | null;

  /** @format int32 */
  ModifiedUserId?: number | null;
  ModifiedUser?: string | null;

  /** @format int32 */
  Duration?: number;

  /** @format date-time */
  ScheduledTime?: string;
    /** @format int32 */
    WorkOrderStatusId?: number;
  WorkOrderStatus?: string | null;
  ColorCode?: string | null;
  WorkOrderSubStatus?: string | null;

  /** @format int32 */
  AssignedUserId?: number;
  AssignedUserName?: string | null;
  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelWorkOrderHtmlContent {
  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  FormProcessId?: number | null;

  /** @format int32 */
  QuestionId?: number | null;

  /** @format int32 */
  HtmlContentId?: number;
  CreatedUser?: CFMModelUser;
  WorkOrder?: CFMModelWorkOrder;
  HtmlContent?: CFMModelHtmlContent;
  FormProcess?: CFMModelFormProcess;
  Question?: CFMModelQuestion;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkOrderHtmlContentView {
  /** @format int32 */
  Id?: number;

  /** @format date-time */
  CreatedDate?: string;

  /** @format int32 */
  CompanyId?: number;
  CreatedUserName?: string | null;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  FormId?: number | null;
  FormName?: string | null;
  QuestionName?: string | null;

  /** @format int32 */
  QuestionOrder?: number | null;
  QuestionGroupName?: string | null;
  Preview?: string | null;
}

export interface CFMModelWorkOrderProcedureView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  FileId?: number;
  FileName?: string | null;
  ProcedureType?: string | null;
  ProcedureName?: string | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelWorkOrderPropertyCountsSP {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  AssignedUserCount?: number;

  /** @format int32 */
  ProcedureCount?: number;

  /** @format int32 */
  FormCount?: number;

  /** @format int32 */
  FeedBackCount?: number;

  /** @format int32 */
  HistoryCount?: number;

  /** @format int32 */
  QAQCCount?: number;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMWorkVendorWorkTime {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format date-time */
  VendorStartDate?: string | null;

  /** @format date-time */
  VendorEndDate?: string | null;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate?: string | null;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
 
  IsActive: boolean;
}

export interface CFMCoreEntityResultMessageCFMDTOWorkVendorWorkTime {
  Data?: CFMDTOWorkVendorWorkTime;
  meta?: CFMCoreEntityMeta;
  IsSuccess?: boolean;
  IncidentId?: string | null;
  Messages?: SystemCollectionsGenericKeyValuePairSystemStringSystemString[] | null;
  Message?: string | null;
}

export interface CFMDTOWorkVendorWorkTime {
    /** @format int32 */
    Id?: number;

    /** @format int32 */
    WorkOrderId?: number;
  
    /** @format date-time */
    VendorStartDate?: string | null;
  
    /** @format date-time */
    VendorEndDate?: string | null;
  
    /** @format int32 */
    CreatedUserId: number;
  
    /** @format date-time */
    CreatedDate?: string | null;
  
    /** @format int32 */
    ModifiedUserId?: number | null;
  
    /** @format date-time */
    ModifiedDate?: string | null;
   
    IsActive: boolean;
}

export interface CFMModelWorkOrderReport {
  WorkOrderCountSummary?: CFMModelWorkOrderCountSummarySP;
  CategoryWorkOrderCountList?: CFMModelCategoryWorkOrderCountSP[] | null;
  CategoryWorkOrderDurationList?: CFMModelCategoryWorkOrderDurationSP[] | null;
  CategoryWorkOrderCountWithStatusList?: CFMModelCategoryWorkOrderCountWithStatusSP[] | null;
  ScheduledVsActualDurationList?: CFMModelScheduledVsActualDurationSP[] | null;
  MostDeviationStartDateOfWorkOrderList?: CFMModelWorkOrderDeviationView[] | null;
  MostDeviationDurationOfWorkOrderList?: CFMModelWorkOrderDeviationView[] | null;
  PmDeviationList?: CFMModelWorkOrderDeviationView[] | null;
}

export interface CFMModelWorkOrderSource {
  Name: string;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkOrderSourceStatus {
  /** @format int32 */
  WorkOrderSourceId?: number;

  /** @format int32 */
  WorkOrderStatusId?: number;
  TransitionRules?: string | null;
  WorkOrderSource?: CFMModelWorkOrderSource;
  WorkOrderStatus?: CFMModelWorkOrderStatus;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkOrderStatus {
  Name: string;

  /** @format int32 */
  ColorId?: number;
  Icon?: string | null;
  ApprovalRequired?: boolean;
  IsDeletable?: boolean;
  IsFormsAnswerable?: boolean;
  Color?: CFMModelColor;
  WorkOrderSourceStatuses?: CFMModelWorkOrderSourceStatus[] | null;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkOrderStatusDescription {
  Description: string;

  /** @format int32 */
  OrderStatusId?: number;
  OrderStatus?: CFMModelWorkOrderStatus;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkOrderSubStatus {
  Name: string;

  /** @format int32 */
  OrderStatusId?: number;
  OrderStatus?: CFMModelWorkOrderStatus;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkOrderSummaryView {
  /** @format int32 */
  Id?: number;
  WorkName?: string | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  OrderStatusId?: number;
  OrderStatusName?: string | null;
  IsFormsAnswerable?: boolean;
  IsEditable?: boolean;
}

export interface CFMModelWorkOrderType {
  Name: string;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkOrderUser {
  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  UserId?: number;

  /** @format int32 */
  UserPositionId?: number;

  /** @format date-time */
  ExecutionTime?: string | null;
  Comment?: string | null;
  WorkOrder?: CFMModelWorkOrder;
  User?: CFMModelUser;
  UserPosition?: CFMModelUserPosition;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkOrderUserView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  WorkOrderId?: number;

  /** @format int32 */
  WorkId?: number | null;

  /** @format int32 */
  UserId?: number;
  WorkName?: string | null;
  BreadCrumb?: string | null;

  /** @format date-time */
  StartDate?: string;

  /** @format date-time */
  EndDate?: string;
  ColorName?: string | null;
  UserPosition?: string | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface CFMModelWorkOrderView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  ParentId?: number | null;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  SequenceNumber?: number | null;

  /** @format int32 */
  WorkId?: number | null;

  /** @format int32 */
  OrderSourceId?: number;

  /** @format int32 */
  OrderStatusId?: number;
  OrderStatusName?: string | null;
  IsDeletable?: boolean;
  ColorCode?: string | null;
  ColorName?: string | null;

  /** @format int32 */
  OrderTypeId?: number | null;
  OrderType?: string | null;

  /** @format date-time */
  ScheduledTime?: string;
  BreadCrumb?: string | null;
  Note?: string | null;

  /** @format int32 */
  DeviceId?: number | null;
  DeviceName?: string | null;

  /** @format int32 */
  MaintenanceGroupId?: number | null;
  MaintenanceGroupName?: string | null;

  /** @format int32 */
  WorkGroupId?: number | null;
  WorkGroupName?: string | null;

  /** @format int32 */
  BrandId?: number | null;

  /** @format int32 */
  ModelId?: number | null;

  /** @format int32 */
  CapacityTypeId?: number | null;
  WorkName?: string | null;
  CategoryName?: string | null;

  /** @format int32 */
  CategoryId?: number | null;

  /** @format int32 */
  RoomId?: number | null;
  RoomName?: string | null;
  ModelName?: string | null;

  /** @format int32 */
  Duration?: number;

  /** @format date-time */
  StartDate?: string;

  /** @format date-time */
  EndDate?: string;

  /** @format int32 */
  ContractId?: number

  ContractName?: string;

  /** @format date-time */
  ContractStartDate?: string;

  /** @format date-time */
  ContractEndDate?: string;

  /** @format int32 */
  ChildCount?: number;
  IsShowOnCalendar?: boolean;
  IsUserAssigned?: boolean;

  /** @format int32 */
  OrderSubStatusId?: number | null;
  OrderSubStatusName?: string | null;

  /** @format int32 */
  OrderStatusDescriptionId?: number | null;
  OrderStatusDescriptionName?: string | null;
  Name?: string | null;

  /** @format int32 */
  PriorityTypeId?: number | null;
  PriorityType?: string | null;

  /** @format int32 */
  WorkOrderCategoryId?: number | null;
  WorkOrderCategoryName?: string | null;
  Description?: string | null;
  OrderStatusNote?: string | null;

  /** @format int32 */
  VendorId?: number | null;

  /** @format int32 */
  VendorNotificationRemainingDay?: number | null;
  IsActive?: boolean;
  WorkOrderUsers?: CFMModelWorkOrderUser[] | null;
  WorkOrderForms?: CFMModelWorkOrderForm[] | null;
}

export interface CFMModelWorkPeriod {
  Name: string;

  /** @format int32 */
  Period?: number;

  /** @format int32 */
  UnitId?: number;
  Unit?: CFMModelUnit;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkPeriodView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;
  Name?: string | null;

  /** @format int32 */
  Period?: number;

  /** @format int32 */
  UnitId?: number;
  UnitName?: string | null;
}

export interface CFMModelWorkProcedure {
  /** @format int32 */
  ProcedureId?: number;

  /** @format int32 */
  WorkId?: number;
  Work?: CFMModelWork;
  Procedure?: CFMModelProcedure;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkProcedureView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  ProcedureId?: number;
  Name?: string | null;
}

export interface CFMModelWorkQualification {
  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  QualificationId?: number;

  /** @format int32 */
  UserPositionId?: number;
  Work?: CFMModelWork;
  Qualification?: CFMModelQualification;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkQualificationView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  QualificationId?: number;

  /** @format int32 */
  UserPositionId?: number;
  Name?: string | null;
}

export interface CFMModelWorkRecommendationView {
  /** @format int32 */
  RowNumber?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  DeviceId?: number | null;
  DeviceName?: string | null;

  /** @format int32 */
  WorkId?: number;
  WorkName?: string | null;
  WorkDescription?: string | null;

  /** @format int32 */
  OrderId?: number | null;
  OrderNote?: string | null;

  /** @format date-time */
  OrderScheduledTime?: string | null;

  /** @format int32 */
  OrderStatusId?: number | null;

  /** @format int32 */
  Period?: number;

  /** @format int32 */
  UnitId?: number;
  UnitName?: string | null;
}

export interface CFMModelWorkTraining {
  /** @format int32 */
  UserPositionId?: number;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  TrainingId?: number;
  Work?: CFMModelWork;
  Training?: CFMModelTraining;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CreatedUserId: number;

  /** @format date-time */
  CreatedDate: string;

  /** @format int32 */
  ModifiedUserId?: number | null;

  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive: boolean;
}

export interface CFMModelWorkTrainingForWorkDetailView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  WorkId?: number;

  /** @format int32 */
  TrainingId?: number;
  Name?: string | null;

  /** @format int32 */
  UserPositionId?: number;
}

export interface CFMModelWorkView {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  CompanyId?: number;

  /** @format int32 */
  WorkGroupId?: number;
  WorkGroupName?: string | null;
  Name?: string | null;
  Description?: string | null;

  /** @format int32 */
  CategoryId?: number;
  CategoryName?: string | null;

  /** @format int32 */
  DeviceId?: number | null;
  IsShowOnCalendar?: boolean;

  /** @format int32 */
  ModelId?: number | null;
  ModelName?: string | null;

  /** @format int32 */
  RoomId?: number | null;
  RoomName?: string | null;

  /** @format int32 */
  PeriodId?: number | null;
  PeriodName?: string | null;

  /** @format int32 */
  Duration?: number | null;
  BreadCrumb?: string | null;

  /** @format int32 */
  WorkOrderCount?: number;

  /** @format int32 */
  MaintenancePlanCount?: number;

  /** @format int32 */
  DefermentCondition?: number | null;
  IsActive?: boolean;
  WorkProcedures?: CFMModelWorkProcedureView[] | null;
  WorkTrainings?: CFMModelWorkTrainingForWorkDetailView[] | null;
  WorkQualifications?: CFMModelWorkQualificationView[] | null;
  WorkForms?: CFMModelWorkFormView[] | null;
  ObserverRoles?: number[] | null;
}

export interface CFMModelWorkWithBreadCrumbView {
  /** @format int32 */
  Id?: number;
  Name?: string | null;

  /** @format int32 */
  RoomId?: number | null;

  /** @format int32 */
  DeviceId?: number | null;

  /** @format int32 */
  Duration?: number | null;
  BreadCrumb?: string | null;

  /** @format int32 */
  CompanyId?: number;
}

export interface SystemCollectionsGenericKeyValuePairSystemStringSystemString {
  Key?: string | null;
  Value?: string | null;
}

export interface CFMModelQaqcView { // export interface CFMModelAvailableFormView {
  /** @format int32 */
  Id?: number;

  Name?: string | null;

  Description?: string | null;
}

export interface CFMDTOQAQCWorkView {
    /** @format int32 */
    Id?: number;
    QAQCName?: string | null;
    /** @format int32 */
    QAQCId?: number;
    /** @format int32 */
    WorkId?: number;
    /** @format int32 */
    QAQCOrder?: number;
    /** @format int32 */
    CompanyId?: number;
}

export interface CFMDTOWorkQaqc {
    /** @format int32 */
    Id?: number;
    /** @format int32 */
    QAQCId?: number;
    /** @format int32 */
    WorkId?: number;
    /** @format int32 */
    QAQCOrder?: number;
}
export interface CFMDTOWorkOrderQaqcList {
  /** @format int32 */
  Id?: number;

  /** @format int32 */
  WorkOrderId?: number;
  QAQCId?: number;
  IsChecked?: boolean;
  
  /** @format int32 */
  QAQC?: CFMDTOQAQC;
}

export interface CFMDTOQAQCOrderView{
  /** @format int32 */
  Id?: number;
  /** @format date-time */
  ModifiedDate?: string | null;
  IsActive?: boolean;
  /** @format int32 */
  QAQCId?: number;
  /** @format int32 */
  WorkOrderId?: number;
  IsChecked?: boolean;
  QAQCName?: string | null;
  ModifiedUser?: string | null;
}
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title CFM.WebApi
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Home
   * @name HomeIndex
   * @request GET:/
   */
  homeIndex = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/`,
      method: "GET",
      ...params,
    });

  access = {
    /**
     * No description
     *
     * @tags Access
     * @name AccessToken
     * @request POST:/Access/Token
     */
    accessToken: (data: CFMDTOTokenDTO, params: RequestParams = {}) =>
      this.request<CFMDTOSecurityTokenResponse, any>({
        path: `/Access/Token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  alternativeTool = {
    /**
     * No description
     *
     * @tags AlternativeTool
     * @name AlternativeToolGetById
     * @request GET:/AlternativeTool/GetById
     */
    alternativeToolGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOAlternativeTool, any>({
        path: `/AlternativeTool/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlternativeTool
     * @name AlternativeToolDelete
     * @request POST:/AlternativeTool/Delete
     */
    alternativeToolDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/AlternativeTool/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  answerProcess = {
    /**
     * No description
     *
     * @tags AnswerProcess
     * @name AnswerProcessGetList
     * @request GET:/AnswerProcess/GetList
     */
    answerProcessGetList: (query?: { questionProcessId?: number; selectionId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOAnswerProcess, any>({
        path: `/AnswerProcess/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnswerProcess
     * @name AnswerProcessSave
     * @request POST:/AnswerProcess/Save
     */
    answerProcessSave: (data: CFMDTOAnswerProcess, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/AnswerProcess/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnswerProcess
     * @name AnswerProcessGetById
     * @request GET:/AnswerProcess/GetById
     */
    answerProcessGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOAnswerProcess, any>({
        path: `/AnswerProcess/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnswerProcess
     * @name AnswerProcessDelete
     * @request POST:/AnswerProcess/Delete
     */
    answerProcessDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/AnswerProcess/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  assessment = {
    /**
     * No description
     *
     * @tags Assessment
     * @name AssessmentGetAssessments
     * @request GET:/Assessment/GetAssessments
     */
    assessmentGetAssessments: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Assessment/GetAssessments`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assessment
     * @name AssessmentGetList
     * @request GET:/Assessment/GetList
     */
    assessmentGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOAssessment, any>({
        path: `/Assessment/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assessment
     * @name AssessmentSave
     * @request POST:/Assessment/Save
     */
    assessmentSave: (data: CFMDTOAssessment, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Assessment/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assessment
     * @name AssessmentGet
     * @request GET:/Assessment/Get
     */
    assessmentGet: (params: RequestParams = {}) =>
      this.request<CFMModelAssessment[], any>({
        path: `/Assessment/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assessment
     * @name AssessmentGetById
     * @request GET:/Assessment/GetById
     */
    assessmentGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOAssessment, any>({
        path: `/Assessment/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assessment
     * @name AssessmentDelete
     * @request POST:/Assessment/Delete
     */
    assessmentDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Assessment/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  assessmentType = {
    /**
     * No description
     *
     * @tags AssessmentType
     * @name AssessmentTypeGetAssessmentTypes
     * @request GET:/AssessmentType/GetAssessmentTypes
     */
    assessmentTypeGetAssessmentTypes: (query?: { hasEvaluation?: boolean }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/AssessmentType/GetAssessmentTypes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AssessmentType
     * @name AssessmentTypeGetList
     * @request GET:/AssessmentType/GetList
     */
    assessmentTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOAssessmentType, any>({
        path: `/AssessmentType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AssessmentType
     * @name AssessmentTypeGetById
     * @request GET:/AssessmentType/GetById
     */
    assessmentTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOAssessmentType, any>({
        path: `/AssessmentType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AssessmentType
     * @name AssessmentTypeDelete
     * @request POST:/AssessmentType/Delete
     */
    assessmentTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/AssessmentType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  brand = {
    /**
     * No description
     *
     * @tags Brand
     * @name BrandGetBrands
     * @request GET:/Brand/GetBrands
     */
    brandGetBrands: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Brand/GetBrands`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name BrandGetList
     * @request GET:/Brand/GetList
     */
    brandGetList: (
      query?: {
        isForCapacityEquipment?: boolean;
        isForDevice?: boolean;
        isForTool?: boolean;
        isForSparePart?: boolean;
        isIncludeModel?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOBrand, any>({
        path: `/Brand/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name BrandSave
     * @request POST:/Brand/Save
     */
    brandSave: (data: CFMDTOBrand, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Brand/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name BrandGet
     * @request GET:/Brand/Get
     */
    brandGet: (params: RequestParams = {}) =>
      this.request<CFMModelBrand[], any>({
        path: `/Brand/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name BrandGetById
     * @request GET:/Brand/GetById
     */
    brandGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOBrand, any>({
        path: `/Brand/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name BrandDelete
     * @request POST:/Brand/Delete
     */
    brandDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Brand/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  building = {
    /**
     * No description
     *
     * @tags Building
     * @name BuildingGet
     * @request GET:/Building/Get
     */
    buildingGet: (params: RequestParams = {}) =>
      this.request<CFMModelBuildingView[], any>({
        path: `/Building/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Building
     * @name BuildingSave
     * @request POST:/Building/Save
     */
    buildingSave: (data: CFMDTOBuilding, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Building/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Building
     * @name BuildingSearch
     * @request GET:/Building/Search
     */
    buildingSearch: (
      query?: { continentId?: number; countryId?: number; cityId?: number; districtId?: number; campusId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelBuildingView, any>({
        path: `/Building/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Building
     * @name BuildingGetBuildingById
     * @request GET:/Building/GetBuildingById
     */
    buildingGetBuildingById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMModelBuildingView, any>({
        path: `/Building/GetBuildingById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Building
     * @name BuildingGetBuildingView
     * @request GET:/Building/GetBuildingView
     */
    buildingGetBuildingView: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMModelBuildingView, any>({
        path: `/Building/GetBuildingView`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Building
     * @name BuildingGetById
     * @request GET:/Building/GetById
     */
    buildingGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOBuilding, any>({
        path: `/Building/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Building
     * @name BuildingDelete
     * @request POST:/Building/Delete
     */
    buildingDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Building/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  campus = {
    /**
     * No description
     *
     * @tags Campus
     * @name CampusGet
     * @request GET:/Campus/Get
     */
    campusGet: (params: RequestParams = {}) =>
      this.request<CFMModelCampusView[], any>({
        path: `/Campus/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campus
     * @name CampusSave
     * @request POST:/Campus/Save
     */
    campusSave: (data: CFMDTOCampus, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Campus/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campus
     * @name CampusSearch
     * @request GET:/Campus/Search
     */
    campusSearch: (
      query?: { continentId?: number; countryId?: number; cityId?: number; districtId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelCampusView, any>({
        path: `/Campus/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campus
     * @name CampusGetList
     * @request GET:/Campus/GetList
     */
    campusGetList: (query?: { districtId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelCampusView[], any>({
        path: `/Campus/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campus
     * @name CampusGetWithRooms
     * @request GET:/Campus/GetWithRooms
     */
    campusGetWithRooms: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOCampus, any>({
        path: `/Campus/GetWithRooms`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campus
     * @name CampusGetCampusLogo
     * @request GET:/Campus/GetCampusLogo
     */
    campusGetCampusLogo: (query?: {workId?: number}, params: RequestParams = {}) =>
    this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOCampus, any>({
      path: `/Campus/GetCampusLogo`,
      method: "GET",
      format: "json",
      ...params,
    }),

    /**
     * No description
     *
     * @tags Campus
     * @name CampusGetById
     * @request GET:/Campus/GetById
     */
    campusGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOCampus, any>({
        path: `/Campus/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campus
     * @name CampusDelete
     * @request POST:/Campus/Delete
     */
    campusDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Campus/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  capacityEquipment = {
    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentGetCapacityEquipments
     * @request GET:/CapacityEquipment/GetCapacityEquipments
     */
    capacityEquipmentGetCapacityEquipments: (
      query?: {
        roomId?: number;
        maintenanceGroupId?: number;
        brandId?: number;
        modelId?: number;
        capacityTypeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/CapacityEquipment/GetCapacityEquipments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentGetCapacityEquipmentsBreadCrumb
     * @request GET:/CapacityEquipment/GetCapacityEquipmentsBreadCrumb
     */
    capacityEquipmentGetCapacityEquipmentsBreadCrumb: (
      query?: { roomId?: number; brandId?: number; modelId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/CapacityEquipment/GetCapacityEquipmentsBreadCrumb`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentGetList
     * @request GET:/CapacityEquipment/GetList
     */
    capacityEquipmentGetList: (query?: { roomId?: number; productFamilyId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODeviceView, any>({
        path: `/CapacityEquipment/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentSave
     * @request POST:/CapacityEquipment/Save
     */
    capacityEquipmentSave: (data: CFMDTODevice, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/CapacityEquipment/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentSearch
     * @request GET:/CapacityEquipment/Search
     */
    capacityEquipmentSearch: (
      query?: {
        buildingId?: number;
        floorId?: number;
        roomId?: number;
        capacityTypeId?: number;
        productFamilyId?: number;
        vendorId?: number;
        brandId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelInventoryView, any>({
        path: `/CapacityEquipment/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentGetCapacityEquipment
     * @request GET:/CapacityEquipment/GetCapacityEquipment
     */
    capacityEquipmentGetCapacityEquipment: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTODeviceView, any>({
        path: `/CapacityEquipment/GetCapacityEquipment`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
      capacityEquipmentCapacityEquipmentById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOCapacityEquipment, any>({
        path: `/CapacityEquipment/CapacityEquipmentById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentGetListForTreeView
     * @request GET:/CapacityEquipment/GetListForTreeView
     */
    capacityEquipmentGetListForTreeView: (query?: { id?: number; typeId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOTreeViewSource[], any>({
        path: `/CapacityEquipment/GetListForTreeView`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentGetListForTreeViewWithChilds
     * @request GET:/CapacityEquipment/GetListForTreeViewWithChilds
     */
    capacityEquipmentGetListForTreeViewWithChilds: (query?: { name?: string }, params: RequestParams = {}) =>
      this.request<CFMDTOTreeViewSource[], any>({
        path: `/CapacityEquipment/GetListForTreeViewWithChilds`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentGetDeviceCountWithScpFormCount
     * @request GET:/CapacityEquipment/GetDeviceCountWithScpFormCount
     */
    capacityEquipmentGetDeviceCountWithScpFormCount: (params: RequestParams = {}) =>
      this.request<CFMModelDeviceCountWithScpFormCountView, any>({
        path: `/CapacityEquipment/GetDeviceCountWithScpFormCount`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentGet
     * @request GET:/CapacityEquipment/Get
     */
    capacityEquipmentGet: (params: RequestParams = {}) =>
      this.request<CFMModelDevice[], any>({
        path: `/CapacityEquipment/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentGetById
     * @request GET:/CapacityEquipment/GetById
     */
    capacityEquipmentGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODevice, any>({
        path: `/CapacityEquipment/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityEquipment
     * @name CapacityEquipmentDelete
     * @request POST:/CapacityEquipment/Delete
     */
    capacityEquipmentDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/CapacityEquipment/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  capacityType = {
    /**
     * No description
     *
     * @tags CapacityType
     * @name CapacityTypeGetList
     * @request GET:/CapacityType/GetList
     */
    capacityTypeGetList: (query?: { infrastructureTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOCapacityType, any>({
        path: `/CapacityType/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityType
     * @name CapacityTypeSave
     * @request POST:/CapacityType/Save
     */
    capacityTypeSave: (data: CFMDTOCapacityType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/CapacityType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityType
     * @name CapacityTypeSearch
     * @request GET:/CapacityType/Search
     */
    capacityTypeSearch: (
      query?: { infrastructureId?: number; infrastructureTypeId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOCapacityType, any>({
        path: `/CapacityType/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityType
     * @name CapacityTypeGetCapacityTypes
     * @request GET:/CapacityType/GetCapacityTypes
     */
    capacityTypeGetCapacityTypes: (query?: { infrastructureTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/CapacityType/GetCapacityTypes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityType
     * @name CapacityTypeGet
     * @request GET:/CapacityType/Get
     */
    capacityTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelCapacityType[], any>({
        path: `/CapacityType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityType
     * @name CapacityTypeGetById
     * @request GET:/CapacityType/GetById
     */
    capacityTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOCapacityType, any>({
        path: `/CapacityType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CapacityType
     * @name CapacityTypeDelete
     * @request POST:/CapacityType/Delete
     */
    capacityTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/CapacityType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  city = {
    /**
     * No description
     *
     * @tags City
     * @name CityGet
     * @request GET:/City/Get
     */
    cityGet: (params: RequestParams = {}) =>
      this.request<CFMModelCityView[], any>({
        path: `/City/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags City
     * @name CitySave
     * @request POST:/City/Save
     */
    citySave: (data: CFMDTOCity, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/City/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags City
     * @name CityGetCities
     * @request GET:/City/GetCities
     */
    cityGetCities: (query?: { countryId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/City/GetCities`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags City
     * @name CitySearch
     * @request GET:/City/Search
     */
    citySearch: (query?: { continentId?: number; countryId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelCityView, any>({
        path: `/City/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags City
     * @name CityGetById
     * @request GET:/City/GetById
     */
    cityGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOCity, any>({
        path: `/City/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags City
     * @name CityDelete
     * @request POST:/City/Delete
     */
    cityDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/City/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  common = {
    /**
     * No description
     *
     * @tags Common
     * @name CommonGetLanguages
     * @request GET:/Common/GetLanguages
     */
    commonGetLanguages: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSourceSystemString[], any>({
        path: `/Common/GetLanguages`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetSecurityItemTypes
     * @request GET:/Common/GetSecurityItemTypes
     */
    commonGetSecurityItemTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Common/GetSecurityItemTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetColorList
     * @request GET:/Common/GetColorList
     */
    commonGetColorList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOColor, any>({
        path: `/Common/GetColorList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetWeeks
     * @request GET:/Common/GetWeeks
     */
    commonGetWeeks: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Common/GetWeeks`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetMonths
     * @request GET:/Common/GetMonths
     */
    commonGetMonths: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Common/GetMonths`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetDayOfWeeks
     * @request GET:/Common/GetDayOfWeeks
     */
    commonGetDayOfWeeks: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Common/GetDayOfWeeks`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetDayOfMonths
     * @request GET:/Common/GetDayOfMonths
     */
    commonGetDayOfMonths: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Common/GetDayOfMonths`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetContractFileTypes
     * @request GET:/Common/GetContractFileTypes
     */
    commonGetContractFileTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Common/GetContractFileTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetWorkOrderCategories
     * @request GET:/Common/GetWorkOrderCategories
     */
    commonGetWorkOrderCategories: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Common/GetWorkOrderCategories`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetWorkOrderStatuses
     * @request GET:/Common/GetWorkOrderStatuses
     */
    commonGetWorkOrderStatuses: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Common/GetWorkOrderStatuses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name CommonGetPointTypes
     * @request GET:/Common/GetPointTypes
     */
    commonGetPointTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Common/GetPointTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  company = {
    /**
     * No description
     *
     * @tags Company
     * @name CompanyGetById
     * @request GET:/Company/GetById
     */
    companyGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOCompany, any>({
        path: `/Company/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyDelete
     * @request POST:/Company/Delete
     */
    companyDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Company/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  comparisonOperator = {
    /**
     * No description
     *
     * @tags ComparisonOperator
     * @name ComparisonOperatorGetList
     * @request GET:/ComparisonOperator/GetList
     */
    comparisonOperatorGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOComparisonOperator[], any>({
        path: `/ComparisonOperator/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComparisonOperator
     * @name ComparisonOperatorGetById
     * @request GET:/ComparisonOperator/GetById
     */
    comparisonOperatorGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOComparisonOperator, any>({
        path: `/ComparisonOperator/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComparisonOperator
     * @name ComparisonOperatorDelete
     * @request POST:/ComparisonOperator/Delete
     */
    comparisonOperatorDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ComparisonOperator/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  component = {
    /**
     * No description
     *
     * @tags Component
     * @name ComponentGetComponents
     * @request GET:/Component/GetComponents
     */
    componentGetComponents: (query?: { modelId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Component/GetComponents`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name ComponentGetList
     * @request GET:/Component/GetList
     */
    componentGetList: (query?: { modelId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOComponent, any>({
        path: `/Component/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name ComponentSave
     * @request POST:/Component/Save
     */
    componentSave: (data: CFMDTOComponent, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Component/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name ComponentGetById
     * @request GET:/Component/GetById
     */
    componentGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOComponent, any>({
        path: `/Component/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Component
     * @name ComponentDelete
     * @request POST:/Component/Delete
     */
    componentDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Component/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  componentCheckItem = {
    /**
     * No description
     *
     * @tags ComponentCheckItem
     * @name ComponentCheckItemGetComponents
     * @request GET:/ComponentCheckItem/GetComponents
     */
    componentCheckItemGetComponents: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/ComponentCheckItem/GetComponents`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComponentCheckItem
     * @name ComponentCheckItemGetList
     * @request GET:/ComponentCheckItem/GetList
     */
    componentCheckItemGetList: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOComponentCheckItem, any>({
        path: `/ComponentCheckItem/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComponentCheckItem
     * @name ComponentCheckItemSave
     * @request POST:/ComponentCheckItem/Save
     */
    componentCheckItemSave: (data: CFMDTOComponentCheckItem, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ComponentCheckItem/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComponentCheckItem
     * @name ComponentCheckItemGetById
     * @request GET:/ComponentCheckItem/GetById
     */
    componentCheckItemGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOComponentCheckItem, any>({
        path: `/ComponentCheckItem/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComponentCheckItem
     * @name ComponentCheckItemDelete
     * @request POST:/ComponentCheckItem/Delete
     */
    componentCheckItemDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ComponentCheckItem/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  continent = {
    /**
     * No description
     *
     * @tags Continent
     * @name ContinentGet
     * @request GET:/Continent/Get
     */
    continentGet: (params: RequestParams = {}) =>
      this.request<CFMModelContinent[], any>({
        path: `/Continent/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Continent
     * @name ContinentGetList
     * @request GET:/Continent/GetList
     */
    continentGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContinent, any>({
        path: `/Continent/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Continent
     * @name ContinentSave
     * @request POST:/Continent/Save
     */
    continentSave: (data: CFMDTOContinent, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Continent/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Continent
     * @name ContinentGetContinents
     * @request GET:/Continent/GetContinents
     */
    continentGetContinents: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Continent/GetContinents`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Continent
     * @name ContinentGetById
     * @request GET:/Continent/GetById
     */
    continentGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOContinent, any>({
        path: `/Continent/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Continent
     * @name ContinentDelete
     * @request POST:/Continent/Delete
     */
    continentDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Continent/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  contract = {
    /**
     * No description
     *
     * @tags Contract
     * @name ContractGet
     * @request GET:/Contract/Get
     */
    contractGet: (params: RequestParams = {}) =>
      this.request<CFMModelContract[], any>({
        path: `/Contract/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractGetList
     * @request GET:/Contract/GetList
     */
    contractGetList: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContract, any>({
        path: `/Contract/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractGetContractViewList
     * @request GET:/Contract/GetContractViewList
     */
    contractGetContractViewList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelContractView, any>({
        path: `/Contract/GetContractViewList`,
        method: "GET",
        format: "json",
        ...params,
      }),


    /**
     * No description
     *
     * @tags Contract
     * @name ContractGetListByWorkId
     * @request GET:/Contract/GetListByWorkId
     */
    contractGetListByWorkId: (query: { workId: number }, params: RequestParams = {}) =>
    this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContract, any>({
      path: `/Contract/GetListByWorkId`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    }),
    /**
     * No description
     *
     * @tags Contract
     * @name ContractSave
     * @request POST:/Contract/Save
     */
    contractSave: (data: CFMDTOContract, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Contract/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractGetContractWithUser
     * @request GET:/Contract/GetContractWithUser
     */
    contractGetContractWithUser: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOContract, any>({
        path: `/Contract/GetContractWithUser`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractGetById
     * @request GET:/Contract/GetById
     */
    contractGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOContract, any>({
        path: `/Contract/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractDelete
     * @request POST:/Contract/Delete
     */
    contractDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Contract/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
      /**
     * No description
     *
     * @tags Contract
     * @name CheckContractWorkOrder
     * @request POST:/Contract/CheckContractWorkOrder
     */
    checkContractWorkOrder: (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<CFMCoreEntityCheckResultMessage, any>({
      path: `/Contract/CheckContractWorkOrder`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    }),
  };
  contractDevice = {
    /**
     * No description
     *
     * @tags ContractDevice
     * @name ContractDeviceGetList
     * @request GET:/ContractDevice/GetList
     */
    contractDeviceGetList: (query?: { contractId?: number; sectionId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContractDevice, any>({
        path: `/ContractDevice/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDevice
     * @name ContractDeviceSave
     * @request POST:/ContractDevice/Save
     */
    contractDeviceSave: (data: CFMDTOContractDevice, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractDevice/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDevice
     * @name ContractDeviceGetById
     * @request GET:/ContractDevice/GetById
     */
    contractDeviceGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOContractDevice, any>({
        path: `/ContractDevice/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDevice
     * @name ContractDeviceDelete
     * @request POST:/ContractDevice/Delete
     */
    contractDeviceDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractDevice/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  contractFile = {
    /**
     * No description
     *
     * @tags ContractFile
     * @name ContractFileGet
     * @request GET:/ContractFile/Get
     */
    contractFileGet: (params: RequestParams = {}) =>
      this.request<CFMModelContractFile[], any>({
        path: `/ContractFile/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractFile
     * @name ContractFileGetList
     * @request GET:/ContractFile/GetList
     */
    contractFileGetList: (query?: { contractId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelContractFileView[], any>({
        path: `/ContractFile/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractFile
     * @name ContractFileGetContractFileView
     * @request GET:/ContractFile/GetContractFileView
     */
    contractFileGetContractFileView: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMModelContractFileView, any>({
        path: `/ContractFile/GetContractFileView`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractFile
     * @name ContractFileSave
     * @request POST:/ContractFile/Save
     */
    contractFileSave: (data: CFMDTOContractFile, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractFile/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractFile
     * @name ContractFileGetById
     * @request GET:/ContractFile/GetById
     */
    contractFileGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOContractFile, any>({
        path: `/ContractFile/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractFile
     * @name ContractFileDelete
     * @request POST:/ContractFile/Delete
     */
    contractFileDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractFile/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  contractMaintenance = {
    /**
     * No description
     *
     * @tags ContractMaintenance
     * @name ContractMaintenanceGetList
     * @request GET:/ContractMaintenance/GetList
     */
    contractMaintenanceGetList: (query?: { contractId?: number; sectionId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelContractMaintenanceView[], any>({
        path: `/ContractMaintenance/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractMaintenance
     * @name ContractMaintenanceSave
     * @request POST:/ContractMaintenance/Save
     */
    contractMaintenanceSave: (data: CFMDTOContractMaintenance, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractMaintenance/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractMaintenance
     * @name ContractMaintenanceGetById
     * @request GET:/ContractMaintenance/GetById
     */
    contractMaintenanceGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOContractMaintenance, any>({
        path: `/ContractMaintenance/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractMaintenance
     * @name ContractMaintenanceDelete
     * @request POST:/ContractMaintenance/Delete
     */
    contractMaintenanceDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractMaintenance/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  contractSection = {
    /**
     * No description
     *
     * @tags ContractSection
     * @name ContractSectionGetContractSections
     * @request GET:/ContractSection/GetContractSections
     */
    contractSectionGetContractSections: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/ContractSection/GetContractSections`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractSection
     * @name ContractSectionGetList
     * @request GET:/ContractSection/GetList
     */
    contractSectionGetList: (query?: { contractId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContractSection, any>({
        path: `/ContractSection/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractSection
     * @name ContractSectionSave
     * @request POST:/ContractSection/Save
     */
    contractSectionSave: (data: CFMDTOContractSection, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractSection/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractSection
     * @name ContractSectionReOrder
     * @request POST:/ContractSection/ReOrder
     */
    contractSectionReOrder: (data: CFMDTOContractSection[], params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractSection/ReOrder`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractSection
     * @name ContractSectionGetById
     * @request GET:/ContractSection/GetById
     */
    contractSectionGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOContractSection, any>({
        path: `/ContractSection/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractSection
     * @name ContractSectionDelete
     * @request POST:/ContractSection/Delete
     */
    contractSectionDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractSection/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  contractSla = {
    /**
     * No description
     *
     * @tags ContractSla
     * @name ContractSlaGetContractSlas
     * @request GET:/ContractSla/GetContractSlas
     */
    contractSlaGetContractSlas: (query?: { contractId?: number; sectionId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/ContractSla/GetContractSlas`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractSla
     * @name ContractSlaGetList
     * @request GET:/ContractSla/GetList
     */
    contractSlaGetList: (query?: { contractId?: number; sectionId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOContractSla, any>({
        path: `/ContractSla/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractSla
     * @name ContractSlaSave
     * @request POST:/ContractSla/Save
     */
    contractSlaSave: (data: CFMDTOContractSla, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractSla/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractSla
     * @name ContractSlaGetById
     * @request GET:/ContractSla/GetById
     */
    contractSlaGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOContractSla, any>({
        path: `/ContractSla/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractSla
     * @name ContractSlaDelete
     * @request POST:/ContractSla/Delete
     */
    contractSlaDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ContractSla/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  country = {
    /**
     * No description
     *
     * @tags Country
     * @name CountryGet
     * @request GET:/Country/Get
     */
    countryGet: (params: RequestParams = {}) =>
      this.request<CFMModelCountryView[], any>({
        path: `/Country/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Country
     * @name CountryGetList
     * @request GET:/Country/GetList
     */
    countryGetList: (query?: { continentId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelCountryView, any>({
        path: `/Country/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Country
     * @name CountrySave
     * @request POST:/Country/Save
     */
    countrySave: (data: CFMDTOCountry, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Country/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Country
     * @name CountryGetCountries
     * @request GET:/Country/GetCountries
     */
    countryGetCountries: (query?: { continentId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Country/GetCountries`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Country
     * @name CountryGetById
     * @request GET:/Country/GetById
     */
    countryGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOCountry, any>({
        path: `/Country/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Country
     * @name CountryDelete
     * @request POST:/Country/Delete
     */
    countryDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Country/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  definition = {
    /**
     * No description
     *
     * @tags Definition
     * @name DefinitionGet
     * @request GET:/Definition/Get
     */
    definitionGet: (params: RequestParams = {}) =>
      this.request<CFMModelDefinition[], any>({
        path: `/Definition/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Definition
     * @name DefinitionSave
     * @request POST:/Definition/Save
     */
    definitionSave: (data: CFMDTODefinition, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Definition/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Definition
     * @name DefinitionGetDefinitions
     * @request GET:/Definition/GetDefinitions
     */
    definitionGetDefinitions: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Definition/GetDefinitions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Definition
     * @name DefinitionGetList
     * @request GET:/Definition/GetList
     */
    definitionGetList: (query?: { pointTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODefinition[], any>({
        path: `/Definition/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Definition
     * @name DefinitionGetById
     * @request GET:/Definition/GetById
     */
    definitionGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODefinition, any>({
        path: `/Definition/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Definition
     * @name DefinitionDelete
     * @request POST:/Definition/Delete
     */
    definitionDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Definition/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  description = {
    /**
     * No description
     *
     * @tags Description
     * @name DescriptionGetDescriptions
     * @request GET:/Description/GetDescriptions
     */
    descriptionGetDescriptions: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Description/GetDescriptions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Description
     * @name DescriptionGetList
     * @request GET:/Description/GetList
     */
    descriptionGetList: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODescription, any>({
        path: `/Description/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Description
     * @name DescriptionSave
     * @request POST:/Description/Save
     */
    descriptionSave: (data: CFMDTODescription, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Description/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Description
     * @name DescriptionGetById
     * @request GET:/Description/GetById
     */
    descriptionGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODescription, any>({
        path: `/Description/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Description
     * @name DescriptionDelete
     * @request POST:/Description/Delete
     */
    descriptionDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Description/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  device = {
    /**
     * No description
     *
     * @tags Device
     * @name DeviceGetList
     * @request GET:/Device/GetList
     */
    deviceGetList: (query?: { capacityEquipmentId?: number; onlyDevices?: boolean }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODevice, any>({
        path: `/Device/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceGetDevicesBreadCrumb
     * @request GET:/Device/GetDevicesBreadCrumb
     */
    deviceGetDevicesBreadCrumb: (query?: { isOnlyDevices?: boolean }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Device/GetDevicesBreadCrumb`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceGetHistory
     * @request GET:/DeviceHistory/GetByDeviceId
     */
    getDeviceHistoryByDeviceId: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODeviceHistory[], any>({
        path: `/DeviceHistory/GetByDeviceId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Device
     * @name DeviceGetDevicesByContractId
     * @request GET:/Device/GetDevicesByContractId
     */
    deviceGetDevicesByContractId: (query?: { contractId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Device/GetDevicesByContractId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceSave
     * @request POST:/Device/Save
     */
    deviceSave: (data: CFMDTODevice, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Device/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceGetAllDevices
     * @request GET:/Device/GetAllDevices
     */
    deviceGetAllDevices: (query?: { roomId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMCoreEntityComboSource, any>({
        path: `/Device/GetAllDevices`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceGetAllDevicesByModel
     * @request GET:/Device/GetAllDevicesByModel
     */
    deviceGetAllDevicesByModel: (query?: { modelId?: number; buildingId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Device/GetAllDevicesByModel`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceGetDevicesByProductFamily
     * @request GET:/Device/GetDevicesByProductFamily
     */
    deviceGetDevicesByProductFamily: (query?: { productFamilyId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Device/GetDevicesByProductFamily`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceSearchInventory
     * @request GET:/Device/SearchInventory
     */
    deviceSearchInventory: (
      query?: {
        buildingId?: number;
        floorId?: number;
        roomId?: number;
        capacityTypeId?: number;
        productFamilyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelInventoryView, any>({
        path: `/Device/SearchInventory`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceGetFromToList
     * @request GET:/Device/GetFromToList
     */
    deviceGetFromToList: (query?: { buildingId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelFromToView[], any>({
        path: `/Device/GetFromToList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceFilter
     * @request GET:/Device/Filter
     */
    deviceFilter: (
      query?: {
        brandId?: number;
        modelId?: number;
        productFamilyId?: number;
        vendorId?: number;
        buildingId?: number;
        capacityTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelMassMaintenancePlanView, any>({
        path: `/Device/Filter`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceGetCapacityEquipmentCountWithInfrastructure
     * @request GET:/Device/GetCapacityEquipmentCountWithInfrastructure
     */
    deviceGetCapacityEquipmentCountWithInfrastructure: (params: RequestParams = {}) =>
      this.request<CFMModelCapacityEquipmentCountWithInfrastructureView[], any>({
        path: `/Device/GetCapacityEquipmentCountWithInfrastructure`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceGet
     * @request GET:/Device/Get
     */
    deviceGet: (params: RequestParams = {}) =>
      this.request<CFMModelDevice[], any>({
        path: `/Device/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceGetById
     * @request GET:/Device/GetById
     */
    deviceGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODevice, any>({
        path: `/Device/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DeviceDelete
     * @request POST:/Device/Delete
     */
    deviceDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Device/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  deviceMaintenancePeriod = {
    /**
     * No description
     *
     * @tags DeviceMaintenancePeriod
     * @name DeviceMaintenancePeriodGetList
     * @request GET:/DeviceMaintenancePeriod/GetList
     */
    deviceMaintenancePeriodGetList: (query?: { modelId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODeviceMaintenancePeriod, any>({
        path: `/DeviceMaintenancePeriod/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceMaintenancePeriod
     * @name DeviceMaintenancePeriodSave
     * @request POST:/DeviceMaintenancePeriod/Save
     */
    deviceMaintenancePeriodSave: (data: CFMDTODeviceMaintenancePeriod[], params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/DeviceMaintenancePeriod/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceMaintenancePeriod
     * @name DeviceMaintenancePeriodGet
     * @request GET:/DeviceMaintenancePeriod/Get
     */
    deviceMaintenancePeriodGet: (params: RequestParams = {}) =>
      this.request<CFMModelDeviceMaintenancePeriod[], any>({
        path: `/DeviceMaintenancePeriod/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceMaintenancePeriod
     * @name DeviceMaintenancePeriodGetById
     * @request GET:/DeviceMaintenancePeriod/GetById
     */
    deviceMaintenancePeriodGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODeviceMaintenancePeriod, any>({
        path: `/DeviceMaintenancePeriod/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceMaintenancePeriod
     * @name DeviceMaintenancePeriodDelete
     * @request POST:/DeviceMaintenancePeriod/Delete
     */
    deviceMaintenancePeriodDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/DeviceMaintenancePeriod/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  deviceModel = {
    /**
     * No description
     *
     * @tags DeviceModel
     * @name DeviceModelGetModels
     * @request GET:/DeviceModel/GetModels
     */
    deviceModelGetModels: (query?: { brandId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/DeviceModel/GetModels`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceModel
     * @name DeviceModelGetList
     * @request GET:/DeviceModel/GetList
     */
    deviceModelGetList: (query?: { brandId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODeviceModel, any>({
        path: `/DeviceModel/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceModel
     * @name DeviceModelSave
     * @request POST:/DeviceModel/Save
     */
    deviceModelSave: (data: CFMDTODeviceModel, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/DeviceModel/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceModel
     * @name DeviceModelGet
     * @request GET:/DeviceModel/Get
     */
    deviceModelGet: (params: RequestParams = {}) =>
      this.request<CFMModelDeviceModel[], any>({
        path: `/DeviceModel/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceModel
     * @name DeviceModelGetById
     * @request GET:/DeviceModel/GetById
     */
    deviceModelGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODeviceModel, any>({
        path: `/DeviceModel/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceModel
     * @name DeviceModelDelete
     * @request POST:/DeviceModel/Delete
     */
    deviceModelDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/DeviceModel/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  deviceType = {
    /**
     * No description
     *
     * @tags DeviceType
     * @name DeviceTypeGetList
     * @request GET:/DeviceType/GetList
     */
    deviceTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODeviceType, any>({
        path: `/DeviceType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceType
     * @name DeviceTypeSave
     * @request POST:/DeviceType/Save
     */
    deviceTypeSave: (data: CFMDTODeviceType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/DeviceType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceType
     * @name DeviceTypeGet
     * @request GET:/DeviceType/Get
     */
    deviceTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelDeviceType[], any>({
        path: `/DeviceType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceType
     * @name DeviceTypeGetById
     * @request GET:/DeviceType/GetById
     */
    deviceTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODeviceType, any>({
        path: `/DeviceType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeviceType
     * @name DeviceTypeDelete
     * @request POST:/DeviceType/Delete
     */
    deviceTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/DeviceType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  district = {
    /**
     * No description
     *
     * @tags District
     * @name DistrictGet
     * @request GET:/District/Get
     */
    districtGet: (params: RequestParams = {}) =>
      this.request<CFMModelDistrictView[], any>({
        path: `/District/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags District
     * @name DistrictSave
     * @request POST:/District/Save
     */
    districtSave: (data: CFMDTODistrict, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/District/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags District
     * @name DistrictSearch
     * @request GET:/District/Search
     */
    districtSearch: (
      query?: { continentId?: number; countryId?: number; cityId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelDistrictView, any>({
        path: `/District/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags District
     * @name DistrictGetDistricts
     * @request GET:/District/GetDistricts
     */
    districtGetDistricts: (query?: { cityId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/District/GetDistricts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags District
     * @name DistrictGetById
     * @request GET:/District/GetById
     */
    districtGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODistrict, any>({
        path: `/District/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags District
     * @name DistrictDelete
     * @request POST:/District/Delete
     */
    districtDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/District/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  documentStatus = {
    /**
     * No description
     *
     * @tags DocumentStatus
     * @name DocumentStatusGetDocumentStatuses
     * @request GET:/DocumentStatus/GetDocumentStatuses
     */
    documentStatusGetDocumentStatuses: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/DocumentStatus/GetDocumentStatuses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentStatus
     * @name DocumentStatusGetList
     * @request GET:/DocumentStatus/GetList
     */
    documentStatusGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODocumentStatus, any>({
        path: `/DocumentStatus/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentStatus
     * @name DocumentStatusGetById
     * @request GET:/DocumentStatus/GetById
     */
    documentStatusGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODocumentStatus, any>({
        path: `/DocumentStatus/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentStatus
     * @name DocumentStatusDelete
     * @request POST:/DocumentStatus/Delete
     */
    documentStatusDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/DocumentStatus/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  documentValidityStatus = {
    /**
     * No description
     *
     * @tags DocumentValidityStatus
     * @name DocumentValidityStatusGetDocumentValidityStatuses
     * @request GET:/DocumentValidityStatus/GetDocumentValidityStatuses
     */
    documentValidityStatusGetDocumentValidityStatuses: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/DocumentValidityStatus/GetDocumentValidityStatuses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentValidityStatus
     * @name DocumentValidityStatusGetList
     * @request GET:/DocumentValidityStatus/GetList
     */
    documentValidityStatusGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODocumentValidityStatus, any>({
        path: `/DocumentValidityStatus/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentValidityStatus
     * @name DocumentValidityStatusGetById
     * @request GET:/DocumentValidityStatus/GetById
     */
    documentValidityStatusGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODocumentValidityStatus, any>({
        path: `/DocumentValidityStatus/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentValidityStatus
     * @name DocumentValidityStatusDelete
     * @request POST:/DocumentValidityStatus/Delete
     */
    documentValidityStatusDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/DocumentValidityStatus/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  file = {
    /**
     * No description
     *
     * @tags File
     * @name FileGetList
     * @request GET:/File/GetList
     */
    fileGetList: (query?: { fileTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOFile, any>({
        path: `/File/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileSaveFile
     * @request POST:/File/SaveFile
     */
    fileSaveFile: (
      data: {
        ContentType?: string;
        ContentDisposition?: string;
        Headers?: Record<string, string[]>;
        Length?: number;
        Name?: string;
        FileName?: string;
      },
      query?: { id?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/File/SaveFile`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileGetFile
     * @request GET:/File/GetFile
     */
    fileGetFile: (query?: { id?: number; isContainsData?: boolean }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTOFile, any>({
        path: `/File/GetFile`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileSaveContent
     * @request POST:/File/SaveContent
     */
    fileSaveContent: (data: { file?: File }, query?: { baseUrl?: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/File/SaveContent`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileGetById
     * @request GET:/File/GetById
     */
    fileGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFile, any>({
        path: `/File/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileDelete
     * @request POST:/File/Delete
     */
    fileDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/File/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  fileType = {
    /**
     * No description
     *
     * @tags FileType
     * @name FileTypeGetList
     * @request GET:/FileType/GetList
     */
    fileTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOFileType[], any>({
        path: `/FileType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileType
     * @name FileTypeGetById
     * @request GET:/FileType/GetById
     */
    fileTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFileType, any>({
        path: `/FileType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileType
     * @name FileTypeDelete
     * @request POST:/FileType/Delete
     */
    fileTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FileType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  financial = {
    /**
     * No description
     *
     * @tags Financial
     * @name FinancialGetList
     * @request GET:/Financial/GetList
     */
    financialGetList: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOFinancial, any>({
        path: `/Financial/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name FinancialSave
     * @request POST:/Financial/Save
     */
    financialSave: (data: CFMDTOFinancial, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Financial/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name FinancialGetById
     * @request GET:/Financial/GetById
     */
    financialGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFinancial, any>({
        path: `/Financial/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Financial
     * @name FinancialDelete
     * @request POST:/Financial/Delete
     */
    financialDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Financial/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  floor = {
    /**
     * No description
     *
     * @tags Floor
     * @name FloorGet
     * @request GET:/Floor/Get
     */
    floorGet: (params: RequestParams = {}) =>
      this.request<CFMModelFloor[], any>({
        path: `/Floor/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Floor
     * @name FloorSave
     * @request POST:/Floor/Save
     */
    floorSave: (data: CFMDTOFloor, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Floor/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Floor
     * @name FloorGetById
     * @request GET:/Floor/GetById
     */
    floorGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFloor, any>({
        path: `/Floor/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Floor
     * @name FloorDelete
     * @request POST:/Floor/Delete
     */
    floorDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Floor/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  form = {
    /**
     * No description
     *
     * @tags Form
     * @name FormGet
     * @request GET:/Form/Get
     */
    formGet: (params: RequestParams = {}) =>
      this.request<CFMModelFormDetailView[], any>({
        path: `/Form/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Form
     * @name FormGetAvailableFormList
     * @request GET:/Form/GetAvailableFormList
     */
    formGetAvailableFormList: (query?: { workId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelAvailableFormView[], any>({
        path: `/Form/GetAvailableFormList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Form
     * @name FormUpgradeVersion
     * @request GET:/Form/UpgradeVersion
     */
    formUpgradeVersion: (query?: { refFormId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Form/UpgradeVersion`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Form
     * @name FormClone
     * @request POST:/Form/Clone
     */
    formClone: (data: CFMDTOFormClone, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Form/Clone`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Form
     * @name FormSave
     * @request POST:/Form/Save
     */
    formSave: (data: CFMDTOForm, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Form/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Form
     * @name FormGetAvailableSopFormListForDeviceOrRoom
     * @request GET:/Form/GetAvailableSOPFormListForDeviceOrRoom
     */
    formGetAvailableSopFormListForDeviceOrRoom: (
      query?: { deviceId?: number; roomId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Form/GetAvailableSOPFormListForDeviceOrRoom`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Form
     * @name FormGetAvailableSopFormListForWorkOrder
     * @request GET:/Form/GetAvailableSOPFormListForWorkOrder
     */
    formGetAvailableSopFormListForWorkOrder: (query?: { workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Form/GetAvailableSOPFormListForWorkOrder`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Form
     * @name FormGetFormWithProcess
     * @request GET:/Form/GetFormWithProcess
     */
    formGetFormWithProcess: (
      query?: {
        formId?: number;
        workOrderId?: number;
        formProcessId?: number;
        isIncludeAnswerCondition?: boolean;
        isIncludePoint?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMDTOForm, any>({
        path: `/Form/GetFormWithProcess`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Form
     * @name FormGetFormListForFilter
     * @request GET:/Form/GetFormListForFilter
     */
    formGetFormListForFilter: (params: RequestParams = {}) =>
      this.request<CFMModelFormView[], any>({
        path: `/Form/GetFormListForFilter`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Form
     * @name FormGetById
     * @request GET:/Form/GetById
     */
    formGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOForm, any>({
        path: `/Form/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Form
     * @name FormDelete
     * @request POST:/Form/Delete
     */
    formDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Form/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formBrand = {
    /**
     * No description
     *
     * @tags FormBrand
     * @name FormBrandDeleteFormBrand
     * @request POST:/FormBrand/DeleteFormBrand
     */
    formBrandDeleteFormBrand: (data: number, query?: { brandId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormBrand/DeleteFormBrand`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormBrand
     * @name FormBrandGetById
     * @request GET:/FormBrand/GetById
     */
    formBrandGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormBrand, any>({
        path: `/FormBrand/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormBrand
     * @name FormBrandDelete
     * @request POST:/FormBrand/Delete
     */
    formBrandDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormBrand/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formDevice = {
    /**
     * No description
     *
     * @tags FormDevice
     * @name FormDeviceDeleteFormDevice
     * @request POST:/FormDevice/DeleteFormDevice
     */
    formDeviceDeleteFormDevice: (data: number, query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormDevice/DeleteFormDevice`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormDevice
     * @name FormDeviceGetById
     * @request GET:/FormDevice/GetById
     */
    formDeviceGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormDevice, any>({
        path: `/FormDevice/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormDevice
     * @name FormDeviceDelete
     * @request POST:/FormDevice/Delete
     */
    formDeviceDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormDevice/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formFile = {
    /**
     * No description
     *
     * @tags FormFile
     * @name FormFileDeleteFormFile
     * @request POST:/FormFile/DeleteFormFile
     */
    formFileDeleteFormFile: (data: number, query?: { fileId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormFile/DeleteFormFile`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormFile
     * @name FormFileGetById
     * @request GET:/FormFile/GetById
     */
    formFileGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormFile, any>({
        path: `/FormFile/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormFile
     * @name FormFileDelete
     * @request POST:/FormFile/Delete
     */
    formFileDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormFile/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formModel = {
    /**
     * No description
     *
     * @tags FormModel
     * @name FormModelDeleteFormModel
     * @request POST:/FormModel/DeleteFormModel
     */
    formModelDeleteFormModel: (data: number, query?: { fileId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormModel/DeleteFormModel`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormModel
     * @name FormModelGetById
     * @request GET:/FormModel/GetById
     */
    formModelGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormModel, any>({
        path: `/FormModel/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormModel
     * @name FormModelDelete
     * @request POST:/FormModel/Delete
     */
    formModelDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormModel/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formProcess = {
    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessGetList
     * @request GET:/FormProcess/GetList
     */
    formProcessGetList: (query?: { formId?: number; workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOFormProcess, any>({
        path: `/FormProcess/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessGetFormProcess
     * @request GET:/FormProcess/GetFormProcess
     */
    formProcessGetFormProcess: (query?: { formId?: number; workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTOFormProcess, any>({
        path: `/FormProcess/GetFormProcess`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessSearch
     * @request GET:/FormProcess/Search
     */
    formProcessSearch: (
      query?: {
        formId?: number;
        workOrderId?: number;
        version?: number;
        formProcessStatusId?: number;
        startDate?: string;
        endDate?: string;
        procedureTypeId?: number;
        showOnlyHasFeedbackOnes?: boolean;
        showOnlyHasInvalidAnswerOnes?: boolean;
        deviceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericListCFMModelAnsweredFormView, any>({
        path: `/FormProcess/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessGetFormProcessResult
     * @request GET:/FormProcess/GetFormProcessResult
     */
    formProcessGetFormProcessResult: (query?: { formId?: number; workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOFormProcess, any>({
        path: `/FormProcess/GetFormProcessResult`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessSave
     * @request POST:/FormProcess/Save
     */
    formProcessSave: (
      data: {
        Id?: number;
        WorkOrderId?: number;
        FormId?: number;
        FormProcessStatusId?: number;
        CompanyId?: number;
        CreatedUserId?: number;
        CreatedDate?: string;
        "WorkOrder.Id"?: number;
        "WorkOrder.IsActive"?: boolean;
        "WorkOrder.CompanyId"?: number;
        "WorkOrder.ParentId"?: number;
        "WorkOrder.SequenceNumber"?: number;
        "WorkOrder.OrderSourceId"?: number;
        "WorkOrder.DeviceId"?: number;
        "WorkOrder.WorkId"?: number;
        "WorkOrder.RoomId"?: number;
        "WorkOrder.OrderStatusId"?: number;
        "WorkOrder.OrderSubStatusId"?: number;
        "WorkOrder.OrderTypeId"?: number;
        "WorkOrder.MaintenanceGroupId"?: number;
        "WorkOrder.VendorId"?: number;
        "WorkOrder.IsMainWork"?: boolean;
        "WorkOrder.Note"?: string;
        "WorkOrder.OrderStatusDescriptionId"?: number;
        "WorkOrder.ScheduledTime"?: string;
        "WorkOrder.Duration"?: number;
        "WorkOrder.ModifiedUser.Id"?: number;
        "WorkOrder.ModifiedUser.Email": string;
        "WorkOrder.ModifiedUser.Phone"?: string;
        "WorkOrder.ModifiedUser.Username"?: string;
        "WorkOrder.ModifiedUser.Name": string;
        "WorkOrder.ModifiedUser.Surname": string;
        "WorkOrder.ModifiedUser.NameSurname"?: string;
        "WorkOrder.ModifiedUser.DateOfEmployment"?: string;
        "WorkOrder.ModifiedUser.CompanyId"?: number;
        "WorkOrder.ModifiedUser.IsActive"?: boolean;
        "WorkOrder.ModifiedUser.JobId"?: number;
        "WorkOrder.ModifiedUser.RoleId"?: number;
        "WorkOrder.ModifiedUser.UserTypeId"?: number;
        "WorkOrder.ModifiedUser.VendorId"?: number;
        "WorkOrder.ModifiedUser.UserExperienceId"?: number;
        "WorkOrder.ModifiedUser.IsUserTypeChangeable"?: boolean;
        "WorkOrder.ModifiedUser.Token"?: string;
        "WorkOrder.ModifiedUser.TokenExpireDate"?: string;
        "WorkOrder.ModifiedUser.PasswordResetLink"?: string;
        "WorkOrder.ModifiedUser.IsSendPasswordResetNotification"?: boolean;
        "WorkOrder.ModifiedUser.IsDomainUser"?: boolean;
        "WorkOrder.ModifiedUser.UserExperience.Id"?: number;
        "WorkOrder.ModifiedUser.UserExperience.Name": string;
        "WorkOrder.ModifiedUser.Role.Id"?: number;
        "WorkOrder.ModifiedUser.Role.IsActive"?: boolean;
        "WorkOrder.ModifiedUser.Role.Name": string;
        "WorkOrder.ModifiedUser.Role.ParentId"?: number;
        "WorkOrder.ModifiedUser.Role.CompanyId"?: number;
        "WorkOrder.ModifiedUser.Role.JobDescription"?: string;
        "WorkOrder.ModifiedUser.Role.IsShowOnCalendar"?: boolean;
        "WorkOrder.ModifiedUser.Role.IsShowOnOrganizationChart"?: boolean;
        "WorkOrder.ModifiedUser.Role.OrderNumber"?: number;
        "WorkOrder.ModifiedUser.Role.Parent.Id"?: number;
        "WorkOrder.ModifiedUser.Role.Parent.IsActive"?: boolean;
        "WorkOrder.ModifiedUser.Role.Parent.Name": string;
        "WorkOrder.ModifiedUser.Role.Parent.ParentId"?: number;
        "WorkOrder.ModifiedUser.Role.Parent.CompanyId"?: number;
        "WorkOrder.ModifiedUser.Role.Parent.JobDescription"?: string;
        "WorkOrder.ModifiedUser.Role.Parent.IsShowOnCalendar"?: boolean;
        "WorkOrder.ModifiedUser.Role.Parent.IsShowOnOrganizationChart"?: boolean;
        "WorkOrder.ModifiedUser.Role.Parent.OrderNumber"?: number;
        "WorkOrder.ModifiedUser.Role.Parent.Parent"?: CFMDTORole;
        "WorkOrder.ModifiedUser.Role.Parent.Predecessors"?: CFMDTORoleInheritance[];
        "WorkOrder.ModifiedUser.Role.Parent.Successors"?: CFMDTORoleInheritance[];
        "WorkOrder.ModifiedUser.Role.Parent.Children"?: CFMDTORole[];
        "WorkOrder.ModifiedUser.Role.Parent.WorkGroupRoles"?: CFMDTOWorkGroupRole[];
        "WorkOrder.ModifiedUser.Role.Parent.Users"?: CFMDTOUser[];
        "WorkOrder.ModifiedUser.Role.Parent.RoleQualifications"?: CFMDTORoleQualification[];
        "WorkOrder.ModifiedUser.Role.Predecessors"?: CFMDTORoleInheritance[];
        "WorkOrder.ModifiedUser.Role.Successors"?: CFMDTORoleInheritance[];
        "WorkOrder.ModifiedUser.Role.Children"?: CFMDTORole[];
        "WorkOrder.ModifiedUser.Role.WorkGroupRoles"?: CFMDTOWorkGroupRole[];
        "WorkOrder.ModifiedUser.Role.Users"?: CFMDTOUser[];
        "WorkOrder.ModifiedUser.Role.RoleQualifications"?: CFMDTORoleQualification[];
        "WorkOrder.ModifiedUser.Vendor.Id"?: number;
        "WorkOrder.ModifiedUser.Vendor.Name": string;
        "WorkOrder.ModifiedUser.Vendor.Email"?: string;
        "WorkOrder.ModifiedUser.Vendor.IsActive"?: boolean;
        "WorkOrder.ModifiedUser.Vendor.CompanyId"?: number;
        "WorkOrder.ModifiedUser.Vendor.Users"?: CFMDTOUser[];
        "WorkOrder.ModifiedUser.Job.Id"?: number;
        "WorkOrder.ModifiedUser.Job.IsActive"?: boolean;
        "WorkOrder.ModifiedUser.Job.Name": string;
        "WorkOrder.ModifiedUser.Job.Abbreviation"?: string;
        "WorkOrder.ModifiedUser.Job.CompanyId"?: number;
        "WorkOrder.ModifiedUser.UserType.Id"?: number;
        "WorkOrder.ModifiedUser.UserType.Name": string;
        "WorkOrder.ModifiedUser.UserTrainings"?: CFMDTOUserTraining[];
        "WorkOrder.ModifiedUser.UserQualifications"?: CFMDTOUserQualification[];
        "WorkOrder.ModifiedUser.OrganizationUsers"?: CFMDTOOrganizationUser[];
        "WorkOrder.ModifiedUserId"?: number;
        "WorkOrder.ModifiedDate"?: string;
        "WorkOrder.CreatedUser.Id"?: number;
        "WorkOrder.CreatedUser.Email": string;
        "WorkOrder.CreatedUser.Phone"?: string;
        "WorkOrder.CreatedUser.Username"?: string;
        "WorkOrder.CreatedUser.Name": string;
        "WorkOrder.CreatedUser.Surname": string;
        "WorkOrder.CreatedUser.NameSurname"?: string;
        "WorkOrder.CreatedUser.DateOfEmployment"?: string;
        "WorkOrder.CreatedUser.CompanyId"?: number;
        "WorkOrder.CreatedUser.IsActive"?: boolean;
        "WorkOrder.CreatedUser.JobId"?: number;
        "WorkOrder.CreatedUser.RoleId"?: number;
        "WorkOrder.CreatedUser.UserTypeId"?: number;
        "WorkOrder.CreatedUser.VendorId"?: number;
        "WorkOrder.CreatedUser.UserExperienceId"?: number;
        "WorkOrder.CreatedUser.IsUserTypeChangeable"?: boolean;
        "WorkOrder.CreatedUser.Token"?: string;
        "WorkOrder.CreatedUser.TokenExpireDate"?: string;
        "WorkOrder.CreatedUser.PasswordResetLink"?: string;
        "WorkOrder.CreatedUser.IsSendPasswordResetNotification"?: boolean;
        "WorkOrder.CreatedUser.IsDomainUser"?: boolean;
        "WorkOrder.CreatedUser.UserExperience.Id"?: number;
        "WorkOrder.CreatedUser.UserExperience.Name": string;
        "WorkOrder.CreatedUser.Role.Id"?: number;
        "WorkOrder.CreatedUser.Role.IsActive"?: boolean;
        "WorkOrder.CreatedUser.Role.Name": string;
        "WorkOrder.CreatedUser.Role.ParentId"?: number;
        "WorkOrder.CreatedUser.Role.CompanyId"?: number;
        "WorkOrder.CreatedUser.Role.JobDescription"?: string;
        "WorkOrder.CreatedUser.Role.IsShowOnCalendar"?: boolean;
        "WorkOrder.CreatedUser.Role.IsShowOnOrganizationChart"?: boolean;
        "WorkOrder.CreatedUser.Role.OrderNumber"?: number;
        "WorkOrder.CreatedUser.Role.Parent.Id"?: number;
        "WorkOrder.CreatedUser.Role.Parent.IsActive"?: boolean;
        "WorkOrder.CreatedUser.Role.Parent.Name": string;
        "WorkOrder.CreatedUser.Role.Parent.ParentId"?: number;
        "WorkOrder.CreatedUser.Role.Parent.CompanyId"?: number;
        "WorkOrder.CreatedUser.Role.Parent.JobDescription"?: string;
        "WorkOrder.CreatedUser.Role.Parent.IsShowOnCalendar"?: boolean;
        "WorkOrder.CreatedUser.Role.Parent.IsShowOnOrganizationChart"?: boolean;
        "WorkOrder.CreatedUser.Role.Parent.OrderNumber"?: number;
        "WorkOrder.CreatedUser.Role.Parent.Parent"?: CFMDTORole;
        "WorkOrder.CreatedUser.Role.Parent.Predecessors"?: CFMDTORoleInheritance[];
        "WorkOrder.CreatedUser.Role.Parent.Successors"?: CFMDTORoleInheritance[];
        "WorkOrder.CreatedUser.Role.Parent.Children"?: CFMDTORole[];
        "WorkOrder.CreatedUser.Role.Parent.WorkGroupRoles"?: CFMDTOWorkGroupRole[];
        "WorkOrder.CreatedUser.Role.Parent.Users"?: CFMDTOUser[];
        "WorkOrder.CreatedUser.Role.Parent.RoleQualifications"?: CFMDTORoleQualification[];
        "WorkOrder.CreatedUser.Role.Predecessors"?: CFMDTORoleInheritance[];
        "WorkOrder.CreatedUser.Role.Successors"?: CFMDTORoleInheritance[];
        "WorkOrder.CreatedUser.Role.Children"?: CFMDTORole[];
        "WorkOrder.CreatedUser.Role.WorkGroupRoles"?: CFMDTOWorkGroupRole[];
        "WorkOrder.CreatedUser.Role.Users"?: CFMDTOUser[];
        "WorkOrder.CreatedUser.Role.RoleQualifications"?: CFMDTORoleQualification[];
        "WorkOrder.CreatedUser.Vendor.Id"?: number;
        "WorkOrder.CreatedUser.Vendor.Name": string;
        "WorkOrder.CreatedUser.Vendor.Email"?: string;
        "WorkOrder.CreatedUser.Vendor.IsActive"?: boolean;
        "WorkOrder.CreatedUser.Vendor.CompanyId"?: number;
        "WorkOrder.CreatedUser.Vendor.Users"?: CFMDTOUser[];
        "WorkOrder.CreatedUser.Job.Id"?: number;
        "WorkOrder.CreatedUser.Job.IsActive"?: boolean;
        "WorkOrder.CreatedUser.Job.Name": string;
        "WorkOrder.CreatedUser.Job.Abbreviation"?: string;
        "WorkOrder.CreatedUser.Job.CompanyId"?: number;
        "WorkOrder.CreatedUser.UserType.Id"?: number;
        "WorkOrder.CreatedUser.UserType.Name": string;
        "WorkOrder.CreatedUser.UserTrainings"?: CFMDTOUserTraining[];
        "WorkOrder.CreatedUser.UserQualifications"?: CFMDTOUserQualification[];
        "WorkOrder.CreatedUser.OrganizationUsers"?: CFMDTOOrganizationUser[];
        "WorkOrder.CreatedUserId"?: number;
        "WorkOrder.CreatedDate"?: string;
        "WorkOrder.Name"?: string;
        "WorkOrder.WorkOrderCategoryId"?: number;
        "WorkOrder.OrderStatusNote"?: string;
        "WorkOrder.VendorNotificationRemainingDay"?: number;
        "WorkOrder.IsSentVendorNotification"?: boolean;
        "WorkOrder.SpentMinutes"?: number;
        "WorkOrder.IsHistoricalRecord"?: boolean;
        "WorkOrder.Work.Id"?: number;
        "WorkOrder.Work.IsActive"?: boolean;
        "WorkOrder.Work.CompanyId"?: number;
        "WorkOrder.Work.Name": string;
        "WorkOrder.Work.Description"?: string;
        "WorkOrder.Work.Breadcrumb"?: string;
        "WorkOrder.Work.WorkGroupId"?: number;
        "WorkOrder.Work.CategoryId"?: number;
        "WorkOrder.Work.DeviceId"?: number;
        "WorkOrder.Work.RoomId"?: number;
        "WorkOrder.Work.PeriodId"?: number;
        "WorkOrder.Work.Duration"?: number;
        "WorkOrder.Work.IsShowOnCalendar"?: boolean;
        "WorkOrder.Work.DefermentCondition"?: number;
        "WorkOrder.Work.WorkGroup.Id"?: number;
        "WorkOrder.Work.WorkGroup.IsActive"?: boolean;
        "WorkOrder.Work.WorkGroup.CompanyId"?: number;
        "WorkOrder.Work.WorkGroup.Name"?: string;
        "WorkOrder.Work.WorkGroup.WorkGroupRoles"?: CFMDTOWorkGroupRole[];
        "WorkOrder.Work.WorkGroup.Works"?: CFMDTOWork[];
        "WorkOrder.Work.Category.Id"?: number;
        "WorkOrder.Work.Category.Name": string;
        "WorkOrder.Work.Category.IsDailyWorkOrderCategory"?: boolean;
        "WorkOrder.Work.Category.IsActive"?: boolean;
        "WorkOrder.Work.Category.Works"?: CFMDTOWork[];
        "WorkOrder.Work.Device.Id"?: number;
        "WorkOrder.Work.Device.CompanyId"?: number;
        "WorkOrder.Work.Device.Name": string;
        "WorkOrder.Work.Device.Explanation"?: string;
        "WorkOrder.Work.Device.ParentId"?: number;
        "WorkOrder.Work.Device.RoomId"?: number;
        "WorkOrder.Work.Device.CapacityTypeId"?: number;
        "WorkOrder.Work.Device.ProductFamilyId"?: number;
        "WorkOrder.Work.Device.ModelId"?: number;
        "WorkOrder.Work.Device.SerialNumber"?: string;
        "WorkOrder.Work.Device.ProductionYear"?: number;
        "WorkOrder.Work.Device.DateOfInstallation"?: string;
        "WorkOrder.Work.Device.DateOfWarrantyStart"?: string;
        "WorkOrder.Work.Device.WarrantyDuration"?: number;
        "WorkOrder.Work.Device.ExpectedLifeEndDate"?: string;
        "WorkOrder.Work.Device.SuggestedRunHour"?: number;
        "WorkOrder.Work.Device.MeanTimeToFailureTheoreticalHour"?: number;
        "WorkOrder.Work.Device.MeanTimeToFailureStatisticalHour"?: number;
        "WorkOrder.Work.Device.MeanTimeBetweenFailureTheoreticalHour"?: number;
        "WorkOrder.Work.Device.MeanTimeBetweenFailureStatisticalHour"?: number;
        "WorkOrder.Work.Device.MeanTimeToRepairTheoreticalHour"?: number;
        "WorkOrder.Work.Device.MeanTimeToRepairStatisticalHour"?: number;
        "WorkOrder.Work.Device.PointCount"?: number;
        "WorkOrder.Work.Device.Input"?: number;
        "WorkOrder.Work.Device.Output"?: number;
        "WorkOrder.Work.Device.SourceMatrixId"?: number;
        "WorkOrder.Work.Device.TotalEffectiveCapacity"?: number;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnitId"?: number;
        "WorkOrder.Work.Device.DryBulbTemperatureMin"?: number;
        "WorkOrder.Work.Device.DryBulbTemperatureMax"?: number;
        "WorkOrder.Work.Device.DryBulbTemperatureUnitId"?: number;
        "WorkOrder.Work.Device.WetBulbTemperatureMin"?: number;
        "WorkOrder.Work.Device.WetBulbTemperatureMax"?: number;
        "WorkOrder.Work.Device.WetBulbTemperatureUnitId"?: number;
        "WorkOrder.Work.Device.DeviceTypeId"?: number;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.Id"?: number;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.IsActive"?: boolean;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.Name": string;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.CompanyId"?: number;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.Sign": string;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.UnitTypeId"?: number;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.FirstUnitId"?: number;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.SecondUnitId"?: number;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.UnitType.Id"?: number;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.UnitType.Name": string;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.UnitType.CompanyId"?: number;
        "WorkOrder.Work.Device.TotalEffectiveCapacityUnit.UnitType.Units"?: CFMDTOUnit[];
        "WorkOrder.Work.Device.CapacityType.Id"?: number;
        "WorkOrder.Work.Device.CapacityType.Name": string;
        "WorkOrder.Work.Device.CapacityType.CompanyId"?: number;
        "WorkOrder.Work.Device.CapacityType.InfrastructureTypeId"?: number;
        "WorkOrder.Work.Device.CapacityType.IsActive"?: boolean;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.Id"?: number;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.Name": string;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.CompanyId"?: number;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroupId"?: number;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.IsActive"?: boolean;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.Id"?: number;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.Name": string;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.CompanyId"?: number;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.InfrastructureId"?: number;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.IsActive"?: boolean;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.Infrastructure.Id"?: number;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.Infrastructure.Name": string;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.Infrastructure.CompanyId"?: number;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.Infrastructure.IsActive"?: boolean;
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.Infrastructure.InfrastructureGroups"?: CFMDTOInfrastructureGroup[];
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.InfrastructureGroup.InfrastructureTypes"?: CFMDTOInfrastructureType[];
        "WorkOrder.Work.Device.CapacityType.InfrastructureType.CapacityTypes"?: CFMDTOCapacityType[];
        "WorkOrder.Work.Device.ProductFamily.Id"?: number;
        "WorkOrder.Work.Device.ProductFamily.Name": string;
        "WorkOrder.Work.Device.ProductFamily.CompanyId"?: number;
        "WorkOrder.Work.Device.Model.Id"?: number;
        "WorkOrder.Work.Device.Model.Name": string;
        "WorkOrder.Work.Device.Model.IsActive"?: boolean;
        "WorkOrder.Work.Device.Model.CompanyId"?: number;
        "WorkOrder.Work.Device.Model.BrandId"?: number;
        "WorkOrder.Work.Device.Model.IsForTool"?: boolean;
        "WorkOrder.Work.Device.Model.IsForCapacityEquipment"?: boolean;
        "WorkOrder.Work.Device.Model.IsForDevice"?: boolean;
        "WorkOrder.Work.Device.Model.IsForSparePart"?: boolean;
        "WorkOrder.Work.Device.Model.Brand.Id"?: number;
        "WorkOrder.Work.Device.Model.Brand.Name": string;
        "WorkOrder.Work.Device.Model.Brand.IsForTool"?: boolean;
        "WorkOrder.Work.Device.Model.Brand.IsForCapacityEquipment"?: boolean;
        "WorkOrder.Work.Device.Model.Brand.IsForDevice"?: boolean;
        "WorkOrder.Work.Device.Model.Brand.IsForSparePart"?: boolean;
        "WorkOrder.Work.Device.Model.Brand.CompanyId"?: number;
        "WorkOrder.Work.Device.Model.Brand.IsActive"?: boolean;
        "WorkOrder.Work.Device.Model.Brand.DeviceModels"?: CFMDTODeviceModel[];
        "WorkOrder.Work.Device.Model.Components"?: CFMDTOComponent[];
        "WorkOrder.Work.Device.Model.ModelMaintenancePeriods"?: CFMDTOModelMaintenancePeriod[];
        "WorkOrder.Work.Device.Room.Id"?: number;
        "WorkOrder.Work.Device.Room.Name": string;
        "WorkOrder.Work.Device.Room.Code": string;
        "WorkOrder.Work.Device.Room.CompanyId"?: number;
        "WorkOrder.Work.Device.Room.AreaSize"?: number;
        "WorkOrder.Work.Device.Room.FloorId"?: number;
        "WorkOrder.Work.Device.Room.RoomSubTypeId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Id"?: number;
        "WorkOrder.Work.Device.Room.Floor.Name": string;
        "WorkOrder.Work.Device.Room.Floor.CompanyId"?: number;
        "WorkOrder.Work.Device.Room.Floor.BuildingId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Id"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Name": string;
        "WorkOrder.Work.Device.Room.Floor.Building.CompanyId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.CampusId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.DisplayOrder"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.FloorCount"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.Id"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.Name": string;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.Altitude"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.RelativeHumidity"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.CompanyId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.DistrictId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.IsActive"?: boolean;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.Id"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.Name": string;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.Code": string;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.CompanyId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.CityId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.IsActive"?: boolean;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Id"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Name": string;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.CompanyId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.CountryId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.IsActive"?: boolean;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Country.Id"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Country.Name": string;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Country.CompanyId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Country.ContinentId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Country.IsActive"?: boolean;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Country.Continent.Id"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Country.Continent.Name": string;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Country.Continent.CompanyId"?: number;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.District.City.Country.Continent.IsActive"?: boolean;
        "WorkOrder.Work.Device.Room.Floor.Building.Campus.Buildings"?: CFMDTOBuilding[];
        "WorkOrder.Work.Device.Room.Floor.Building.Floors"?: CFMDTOFloor[];
        "WorkOrder.Work.Device.Room.Floor.Rooms"?: CFMDTORoom[];
        "WorkOrder.Work.Device.Room.RoomSubType.Id"?: number;
        "WorkOrder.Work.Device.Room.RoomSubType.Name": string;
        "WorkOrder.Work.Device.Room.RoomSubType.CompanyId"?: number;
        "WorkOrder.Work.Device.Room.RoomSubType.RoomTypeId"?: number;
        "WorkOrder.Work.Device.Room.RoomSubType.IsActive"?: boolean;
        "WorkOrder.Work.Device.Room.RoomSubType.RoomType.Id"?: number;
        "WorkOrder.Work.Device.Room.RoomSubType.RoomType.Name": string;
        "WorkOrder.Work.Device.Room.RoomSubType.RoomType.CompanyId"?: number;
        "WorkOrder.Work.Device.Room.Points"?: CFMDTOPoint[];
        "WorkOrder.Work.Device.Room.CapacityEquipments"?: CFMDTOCapacityEquipment[];
        "WorkOrder.Work.Device.Room.Devices"?: CFMDTODevice[];
        "WorkOrder.Work.Device.SourceMatrix.Id"?: number;
        "WorkOrder.Work.Device.SourceMatrix.Name": string;
        "WorkOrder.Work.Device.SourceMatrix.CompanyId"?: number;
        "WorkOrder.Work.Device.DeviceType.Id"?: number;
        "WorkOrder.Work.Device.DeviceType.Name": string;
        "WorkOrder.Work.Device.DeviceType.IsDeletable"?: boolean;
        "WorkOrder.Work.Device.Descriptions"?: CFMDTODescription[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Explanation"?: string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.ParentId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.RoomId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityTypeId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.ProductFamilyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.ModelId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.SerialNumber"?: string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.ProductionYear"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.DateOfInstallation"?: string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.DateOfWarrantyStart"?: string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.WarrantyDuration"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.ExpectedLifeEndDate"?: string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.SuggestedRunHour"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.MeanTimeToFailureTheoreticalHour"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.MeanTimeToFailureStatisticalHour"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.MeanTimeBetweenFailureTheoreticalHour"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.MeanTimeBetweenFailureStatisticalHour"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.MeanTimeToRepairTheoreticalHour"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.MeanTimeToRepairStatisticalHour"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.PointCount"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Input"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Output"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.SourceMatrixId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacity"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnitId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.DryBulbTemperatureMin"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.DryBulbTemperatureMax"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.DryBulbTemperatureUnitId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.WetBulbTemperatureMin"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.WetBulbTemperatureMax"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.WetBulbTemperatureUnitId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.DeviceTypeId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.Sign": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.UnitTypeId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.FirstUnitId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.SecondUnitId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.UnitType.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.UnitType.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.UnitType.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.TotalEffectiveCapacityUnit.UnitType.Units"?: CFMDTOUnit[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureTypeId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroupId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.InfrastructureId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.Infrastructure.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.Infrastructure.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.Infrastructure.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.Infrastructure.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.Infrastructure.InfrastructureGroups"?: CFMDTOInfrastructureGroup[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.InfrastructureGroup.InfrastructureTypes"?: CFMDTOInfrastructureType[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.CapacityType.InfrastructureType.CapacityTypes"?: CFMDTOCapacityType[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.ProductFamily.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.ProductFamily.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.ProductFamily.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.BrandId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.IsForTool"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.IsForCapacityEquipment"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.IsForDevice"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.IsForSparePart"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Brand.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Brand.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Brand.IsForTool"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Brand.IsForCapacityEquipment"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Brand.IsForDevice"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Brand.IsForSparePart"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Brand.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Brand.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Brand.DeviceModels"?: CFMDTODeviceModel[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.Components"?: CFMDTOComponent[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.Model.ModelMaintenancePeriods"?: CFMDTOModelMaintenancePeriod[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Code": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.AreaSize"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.FloorId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.RoomSubTypeId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.BuildingId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.CampusId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.DisplayOrder"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.FloorCount"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.Altitude"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.RelativeHumidity"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.DistrictId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.Code": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.CityId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.CountryId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Country.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Country.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Country.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Country.ContinentId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Country.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Country.Continent.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Country.Continent.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Country.Continent.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.District.City.Country.Continent.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Campus.Buildings"?: CFMDTOBuilding[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Building.Floors"?: CFMDTOFloor[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Floor.Rooms"?: CFMDTORoom[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.RoomSubType.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.RoomSubType.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.RoomSubType.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.RoomSubType.RoomTypeId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.RoomSubType.IsActive"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.RoomSubType.RoomType.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.RoomSubType.RoomType.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.RoomSubType.RoomType.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Points"?: CFMDTOPoint[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.CapacityEquipments"?: CFMDTOCapacityEquipment[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.Room.Devices"?: CFMDTODevice[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.SourceMatrix.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.SourceMatrix.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.SourceMatrix.CompanyId"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.DeviceType.Id"?: number;
        "WorkOrder.Work.Device.ParentCapacityEquipment.DeviceType.Name": string;
        "WorkOrder.Work.Device.ParentCapacityEquipment.DeviceType.IsDeletable"?: boolean;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Descriptions"?: CFMDTODescription[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.ParentCapacityEquipment"?: CFMDTODevice;
        "WorkOrder.Work.Device.ParentCapacityEquipment.Parameters"?: CFMDTOParameter[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.ParameterValues"?: CFMDTOParameterValue[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.InputDevices"?: CFMDTOFromTo[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.OutputDevices"?: CFMDTOFromTo[];
        "WorkOrder.Work.Device.ParentCapacityEquipment.Points"?: CFMDTOPoint[];
        "WorkOrder.Work.Device.Parameters"?: CFMDTOParameter[];
        "WorkOrder.Work.Device.ParameterValues"?: CFMDTOParameterValue[];
        "WorkOrder.Work.Device.InputDevices"?: CFMDTOFromTo[];
        "WorkOrder.Work.Device.OutputDevices"?: CFMDTOFromTo[];
        "WorkOrder.Work.Device.Points"?: CFMDTOPoint[];
        "WorkOrder.Work.Room.Id"?: number;
        "WorkOrder.Work.Room.Name": string;
        "WorkOrder.Work.Room.Code": string;
        "WorkOrder.Work.Room.CompanyId"?: number;
        "WorkOrder.Work.Room.AreaSize"?: number;
        "WorkOrder.Work.Room.FloorId"?: number;
        "WorkOrder.Work.Room.RoomSubTypeId"?: number;
        "WorkOrder.Work.Room.Floor.Id"?: number;
        "WorkOrder.Work.Room.Floor.Name": string;
        "WorkOrder.Work.Room.Floor.CompanyId"?: number;
        "WorkOrder.Work.Room.Floor.BuildingId"?: number;
        "WorkOrder.Work.Room.Floor.Building.Id"?: number;
        "WorkOrder.Work.Room.Floor.Building.Name": string;
        "WorkOrder.Work.Room.Floor.Building.CompanyId"?: number;
        "WorkOrder.Work.Room.Floor.Building.CampusId"?: number;
        "WorkOrder.Work.Room.Floor.Building.DisplayOrder"?: number;
        "WorkOrder.Work.Room.Floor.Building.FloorCount"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.Id"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.Name": string;
        "WorkOrder.Work.Room.Floor.Building.Campus.Altitude"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.RelativeHumidity"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.CompanyId"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.DistrictId"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.IsActive"?: boolean;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.Id"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.Name": string;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.Code": string;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.CompanyId"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.CityId"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.IsActive"?: boolean;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Id"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Name": string;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.CompanyId"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.CountryId"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.IsActive"?: boolean;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Country.Id"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Country.Name": string;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Country.CompanyId"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Country.ContinentId"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Country.IsActive"?: boolean;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Country.Continent.Id"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Country.Continent.Name": string;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Country.Continent.CompanyId"?: number;
        "WorkOrder.Work.Room.Floor.Building.Campus.District.City.Country.Continent.IsActive"?: boolean;
        "WorkOrder.Work.Room.Floor.Building.Campus.Buildings"?: CFMDTOBuilding[];
        "WorkOrder.Work.Room.Floor.Building.Floors"?: CFMDTOFloor[];
        "WorkOrder.Work.Room.Floor.Rooms"?: CFMDTORoom[];
        "WorkOrder.Work.Room.RoomSubType.Id"?: number;
        "WorkOrder.Work.Room.RoomSubType.Name": string;
        "WorkOrder.Work.Room.RoomSubType.CompanyId"?: number;
        "WorkOrder.Work.Room.RoomSubType.RoomTypeId"?: number;
        "WorkOrder.Work.Room.RoomSubType.IsActive"?: boolean;
        "WorkOrder.Work.Room.RoomSubType.RoomType.Id"?: number;
        "WorkOrder.Work.Room.RoomSubType.RoomType.Name": string;
        "WorkOrder.Work.Room.RoomSubType.RoomType.CompanyId"?: number;
        "WorkOrder.Work.Room.Points"?: CFMDTOPoint[];
        "WorkOrder.Work.Room.CapacityEquipments"?: CFMDTOCapacityEquipment[];
        "WorkOrder.Work.Room.Devices"?: CFMDTODevice[];
        "WorkOrder.Work.Period.Id"?: number;
        "WorkOrder.Work.Period.IsActive"?: boolean;
        "WorkOrder.Work.Period.CompanyId"?: number;
        "WorkOrder.Work.Period.Name": string;
        "WorkOrder.Work.Period.Period"?: number;
        "WorkOrder.Work.Period.UnitId"?: number;
        "WorkOrder.Work.Period.Unit.Id"?: number;
        "WorkOrder.Work.Period.Unit.IsActive"?: boolean;
        "WorkOrder.Work.Period.Unit.Name": string;
        "WorkOrder.Work.Period.Unit.CompanyId"?: number;
        "WorkOrder.Work.Period.Unit.Sign": string;
        "WorkOrder.Work.Period.Unit.UnitTypeId"?: number;
        "WorkOrder.Work.Period.Unit.FirstUnitId"?: number;
        "WorkOrder.Work.Period.Unit.SecondUnitId"?: number;
        "WorkOrder.Work.Period.Unit.UnitType.Id"?: number;
        "WorkOrder.Work.Period.Unit.UnitType.Name": string;
        "WorkOrder.Work.Period.Unit.UnitType.CompanyId"?: number;
        "WorkOrder.Work.Period.Unit.UnitType.Units"?: CFMDTOUnit[];
        "WorkOrder.Work.WorkTrainings"?: CFMDTOWorkTraining[];
        "WorkOrder.Work.WorkQualifications"?: CFMDTOWorkQualification[];
        "WorkOrder.Work.WorkProcedures"?: CFMDTOWorkProcedure[];
        "WorkOrder.Work.WorkForms"?: CFMDTOWorkForm[];
        "WorkOrder.Work.ObserverRoles"?: number[];
        "WorkOrder.Vendor.Id"?: number;
        "WorkOrder.Vendor.Name": string;
        "WorkOrder.Vendor.Email"?: string;
        "WorkOrder.Vendor.IsActive"?: boolean;
        "WorkOrder.Vendor.CompanyId"?: number;
        "WorkOrder.Vendor.Users"?: CFMDTOUser[];
        "WorkOrder.OrderStatus.Id"?: number;
        "WorkOrder.OrderStatus.Name": string;
        "WorkOrder.OrderStatus.ColorId"?: string;
        "WorkOrder.OrderStatus.TransitionRules"?: string;
        "WorkOrder.OrderStatus.Icon"?: string;
        "WorkOrder.OrderStatus.ApprovalRequired"?: boolean;
        "WorkOrder.OrderStatus.IsDeletable"?: boolean;
        "WorkOrder.OrderStatus.IsFormsAnswerable"?: boolean;
        "WorkOrder.OrderStatus.IsActive"?: boolean;
        "WorkOrder.OrderStatus.Color.Id"?: number;
        "WorkOrder.OrderStatus.Color.Name": string;
        "WorkOrder.OrderStatus.Color.Code"?: string;
        "WorkOrder.OrderSubStatus.Id"?: number;
        "WorkOrder.OrderSubStatus.IsActive"?: boolean;
        "WorkOrder.OrderSubStatus.CompanyId"?: number;
        "WorkOrder.OrderSubStatus.Name": string;
        "WorkOrder.OrderSubStatus.OrderStatusId"?: number;
        "WorkOrder.OrderType.Id"?: number;
        "WorkOrder.OrderType.IsActive"?: boolean;
        "WorkOrder.OrderType.CompanyId"?: number;
        "WorkOrder.OrderType.Name": string;
        "WorkOrder.WorkOrderUsers"?: CFMDTOWorkOrderUser[];
        "WorkOrder.ChildWorkOrders"?: CFMModelWorkOrderView[];
        "CreatedUser.Id"?: number;
        "CreatedUser.Email": string;
        "CreatedUser.Phone"?: string;
        "CreatedUser.Username"?: string;
        "CreatedUser.Name": string;
        "CreatedUser.Surname": string;
        "CreatedUser.NameSurname"?: string;
        "CreatedUser.DateOfEmployment"?: string;
        "CreatedUser.CompanyId"?: number;
        "CreatedUser.IsActive"?: boolean;
        "CreatedUser.JobId"?: number;
        "CreatedUser.RoleId"?: number;
        "CreatedUser.UserTypeId"?: number;
        "CreatedUser.VendorId"?: number;
        "CreatedUser.UserExperienceId"?: number;
        "CreatedUser.IsUserTypeChangeable"?: boolean;
        "CreatedUser.Token"?: string;
        "CreatedUser.TokenExpireDate"?: string;
        "CreatedUser.PasswordResetLink"?: string;
        "CreatedUser.IsSendPasswordResetNotification"?: boolean;
        "CreatedUser.IsDomainUser"?: boolean;
        "CreatedUser.UserExperience.Id"?: number;
        "CreatedUser.UserExperience.Name": string;
        "CreatedUser.Role.Id"?: number;
        "CreatedUser.Role.IsActive"?: boolean;
        "CreatedUser.Role.Name": string;
        "CreatedUser.Role.ParentId"?: number;
        "CreatedUser.Role.CompanyId"?: number;
        "CreatedUser.Role.JobDescription"?: string;
        "CreatedUser.Role.IsShowOnCalendar"?: boolean;
        "CreatedUser.Role.IsShowOnOrganizationChart"?: boolean;
        "CreatedUser.Role.OrderNumber"?: number;
        "CreatedUser.Role.Parent.Id"?: number;
        "CreatedUser.Role.Parent.IsActive"?: boolean;
        "CreatedUser.Role.Parent.Name": string;
        "CreatedUser.Role.Parent.ParentId"?: number;
        "CreatedUser.Role.Parent.CompanyId"?: number;
        "CreatedUser.Role.Parent.JobDescription"?: string;
        "CreatedUser.Role.Parent.IsShowOnCalendar"?: boolean;
        "CreatedUser.Role.Parent.IsShowOnOrganizationChart"?: boolean;
        "CreatedUser.Role.Parent.OrderNumber"?: number;
        "CreatedUser.Role.Parent.Parent"?: CFMDTORole;
        "CreatedUser.Role.Parent.Predecessors"?: CFMDTORoleInheritance[];
        "CreatedUser.Role.Parent.Successors"?: CFMDTORoleInheritance[];
        "CreatedUser.Role.Parent.Children"?: CFMDTORole[];
        "CreatedUser.Role.Parent.WorkGroupRoles"?: CFMDTOWorkGroupRole[];
        "CreatedUser.Role.Parent.Users"?: CFMDTOUser[];
        "CreatedUser.Role.Parent.RoleQualifications"?: CFMDTORoleQualification[];
        "CreatedUser.Role.Predecessors"?: CFMDTORoleInheritance[];
        "CreatedUser.Role.Successors"?: CFMDTORoleInheritance[];
        "CreatedUser.Role.Children"?: CFMDTORole[];
        "CreatedUser.Role.WorkGroupRoles"?: CFMDTOWorkGroupRole[];
        "CreatedUser.Role.Users"?: CFMDTOUser[];
        "CreatedUser.Role.RoleQualifications"?: CFMDTORoleQualification[];
        "CreatedUser.Vendor.Id"?: number;
        "CreatedUser.Vendor.Name": string;
        "CreatedUser.Vendor.Email"?: string;
        "CreatedUser.Vendor.IsActive"?: boolean;
        "CreatedUser.Vendor.CompanyId"?: number;
        "CreatedUser.Vendor.Users"?: CFMDTOUser[];
        "CreatedUser.Job.Id"?: number;
        "CreatedUser.Job.IsActive"?: boolean;
        "CreatedUser.Job.Name": string;
        "CreatedUser.Job.Abbreviation"?: string;
        "CreatedUser.Job.CompanyId"?: number;
        "CreatedUser.UserType.Id"?: number;
        "CreatedUser.UserType.Name": string;
        "CreatedUser.UserTrainings"?: CFMDTOUserTraining[];
        "CreatedUser.UserQualifications"?: CFMDTOUserQualification[];
        "CreatedUser.OrganizationUsers"?: CFMDTOOrganizationUser[];
        "Form.Id"?: number;
        "Form.Name": string;
        "Form.Code": string;
        "Form.Description"?: string;
        "Form.Version"?: number;
        "Form.ParentId"?: number;
        "Form.ResultText"?: string;
        "Form.FormStatusId"?: number;
        "Form.ProcedureTypeId"?: number;
        "Form.CompanyId"?: number;
        "Form.IsTargetEditable"?: boolean;
        "Form.IsEditable"?: boolean;
        "Form.RemainingDayUntilExpiry"?: number;
        "Form.ModifiedDate"?: string;
        "Form.CreatedDate"?: string;
        "Form.RevisionDate"?: string;
        "Form.ProcedureType.Id"?: number;
        "Form.ProcedureType.Name": string;
        "Form.ProcedureType.FormSectionProcedureTypes"?: CFMDTOFormSectionProcedureType[];
        "Form.FormStatus.Id"?: number;
        "Form.FormStatus.Name": string;
        "Form.FormStatus.TransitionRules"?: string;
        "Form.FormStatus.ColorId"?: number;
        "Form.FormStatus.Color.Id"?: number;
        "Form.FormStatus.Color.Name": string;
        "Form.FormStatus.Color.Code"?: string;
        "Form.FormProcesses"?: CFMDTOFormProcess[];
        "Form.QuestionGroups"?: CFMDTOQuestionGroup[];
        "Form.FormFiles"?: CFMDTOFormFile[];
        "Form.FormRooms"?: CFMDTOFormRoom[];
        "Form.FormBrands"?: CFMDTOFormBrand[];
        "Form.FormModels"?: CFMDTOFormModel[];
        "Form.FormDevices"?: CFMDTOFormDevice[];
        "Form.WorkOrderView.Id"?: number;
        "Form.WorkOrderView.ParentId"?: number;
        "Form.WorkOrderView.CompanyId"?: number;
        "Form.WorkOrderView.SequenceNumber"?: number;
        "Form.WorkOrderView.WorkId"?: number;
        "Form.WorkOrderView.OrderSourceId"?: number;
        "Form.WorkOrderView.OrderStatusId"?: number;
        "Form.WorkOrderView.OrderStatusName"?: string;
        "Form.WorkOrderView.IsDeletable"?: boolean;
        "Form.WorkOrderView.ColorCode"?: string;
        "Form.WorkOrderView.ColorName"?: string;
        "Form.WorkOrderView.OrderTypeId"?: number;
        "Form.WorkOrderView.OrderType"?: string;
        "Form.WorkOrderView.ScheduledTime"?: string;
        "Form.WorkOrderView.BreadCrumb"?: string;
        "Form.WorkOrderView.Note"?: string;
        "Form.WorkOrderView.DeviceId"?: number;
        "Form.WorkOrderView.DeviceName"?: string;
        "Form.WorkOrderView.MaintenanceGroupId"?: number;
        "Form.WorkOrderView.MaintenanceGroupName"?: string;
        "Form.WorkOrderView.WorkGroupId"?: number;
        "Form.WorkOrderView.WorkGroupName"?: string;
        "Form.WorkOrderView.BrandId"?: number;
        "Form.WorkOrderView.ModelId"?: number;
        "Form.WorkOrderView.CapacityTypeId"?: number;
        "Form.WorkOrderView.WorkName"?: string;
        "Form.WorkOrderView.CategoryName"?: string;
        "Form.WorkOrderView.CategoryId"?: number;
        "Form.WorkOrderView.RoomId"?: number;
        "Form.WorkOrderView.RoomName"?: string;
        "Form.WorkOrderView.ModelName"?: string;
        "Form.WorkOrderView.Duration"?: number;
        "Form.WorkOrderView.StartDate"?: string;
        "Form.WorkOrderView.EndDate"?: string;
        "Form.WorkOrderView.ChildCount"?: number;
        "Form.WorkOrderView.IsShowOnCalendar"?: boolean;
        "Form.WorkOrderView.IsUserAssigned"?: boolean;
        "Form.WorkOrderView.OrderSubStatusId"?: number;
        "Form.WorkOrderView.OrderSubStatusName"?: string;
        "Form.WorkOrderView.OrderStatusDescriptionId"?: number;
        "Form.WorkOrderView.OrderStatusDescriptionName"?: string;
        "Form.WorkOrderView.Name"?: string;
        "Form.WorkOrderView.PriorityTypeId"?: number;
        "Form.WorkOrderView.PriorityType"?: string;
        "Form.WorkOrderView.WorkOrderCategoryId"?: number;
        "Form.WorkOrderView.WorkOrderCategoryName"?: string;
        "Form.WorkOrderView.Description"?: string;
        "Form.WorkOrderView.OrderStatusNote"?: string;
        "Form.WorkOrderView.VendorId"?: number;
        "Form.WorkOrderView.VendorNotificationRemainingDay"?: number;
        "Form.WorkOrderView.IsActive"?: boolean;
        "Form.WorkOrderView.WorkOrderUsers"?: CFMModelWorkOrderUser[];
        "Form.WorkOrderView.WorkOrderForms"?: CFMModelWorkOrderForm[];
        "FormProcessStatus.Id"?: number;
        "FormProcessStatus.Name": string;
        "FormProcessStatus.ColorId"?: number;
        "FormProcessStatus.Color.Id"?: number;
        "FormProcessStatus.Color.Name": string;
        "FormProcessStatus.Color.Code"?: string;
        QuestionProcesses?: CFMDTOQuestionProcess[];
        FormProcessFiles?: CFMDTOFormProcessFile[];
        WorkOrderHtmlContents?: CFMDTOWorkOrderHtmlContent[];
        InvalidAnswerCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormProcess/Save`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessStartProcess
     * @request POST:/FormProcess/StartProcess
     */
    formProcessStartProcess: (data: CFMDTOStartProcessDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormProcess/StartProcess`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessRestartProcess
     * @request POST:/FormProcess/RestartProcess
     */
    formProcessRestartProcess: (data: CFMDTORestartProcessDTO, params: RequestParams = {}) =>
    this.request<CFMCoreEntityResultMessage, any>({
      path: `/FormProcess/RestartProcess`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessEndProcess
     * @request POST:/FormProcess/EndProcess
     */
    formProcessEndProcess: (data: CFMDTOEndProcessDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntitySaveResult, any>({
        path: `/FormProcess/EndProcess`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessUpdateFeedback
     * @request POST:/FormProcess/UpdateFeedback
     */
    formProcessUpdateFeedback: (data: CFMDTOUpdateFeedbackDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormProcess/UpdateFeedback`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessGetFormProcessFiles
     * @request GET:/FormProcess/GetFormProcessFiles
     */
    formProcessGetFormProcessFiles: (query?: { formId?: number; workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOFile, any>({
        path: `/FormProcess/GetFormProcessFiles`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessGetById
     * @request GET:/FormProcess/GetById
     */
    formProcessGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormProcess, any>({
        path: `/FormProcess/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcess
     * @name FormProcessDelete
     * @request POST:/FormProcess/Delete
     */
    formProcessDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormProcess/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formProcessFile = {
    /**
     * No description
     *
     * @tags FormProcessFile
     * @name FormProcessFileSave
     * @request POST:/FormProcessFile/Save
     */
    formProcessFileSave: (data: CFMDTOFormProcessFileDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormProcessFile/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcessFile
     * @name FormProcessFileGetById
     * @request GET:/FormProcessFile/GetById
     */
    formProcessFileGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormProcessFile, any>({
        path: `/FormProcessFile/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcessFile
     * @name FormProcessFileDelete
     * @request POST:/FormProcessFile/Delete
     */
    formProcessFileDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormProcessFile/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formProcessStatus = {
    /**
     * No description
     *
     * @tags FormProcessStatus
     * @name FormProcessStatusGetFormProcessStatuses
     * @request GET:/FormProcessStatus/GetFormProcessStatuses
     */
    formProcessStatusGetFormProcessStatuses: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/FormProcessStatus/GetFormProcessStatuses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcessStatus
     * @name FormProcessStatusGetList
     * @request GET:/FormProcessStatus/GetList
     */
    formProcessStatusGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOFormProcessStatus, any>({
        path: `/FormProcessStatus/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcessStatus
     * @name FormProcessStatusGetById
     * @request GET:/FormProcessStatus/GetById
     */
    formProcessStatusGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormProcessStatus, any>({
        path: `/FormProcessStatus/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormProcessStatus
     * @name FormProcessStatusDelete
     * @request POST:/FormProcessStatus/Delete
     */
    formProcessStatusDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormProcessStatus/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formQuestionType = {
    /**
     * No description
     *
     * @tags FormQuestionType
     * @name FormQuestionTypeGetFormQuestionTypes
     * @request GET:/FormQuestionType/GetFormQuestionTypes
     */
    formQuestionTypeGetFormQuestionTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/FormQuestionType/GetFormQuestionTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormQuestionType
     * @name FormQuestionTypeGetList
     * @request GET:/FormQuestionType/GetList
     */
    formQuestionTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOFormQuestionType[], any>({
        path: `/FormQuestionType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormQuestionType
     * @name FormQuestionTypeGetById
     * @request GET:/FormQuestionType/GetById
     */
    formQuestionTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormQuestionType, any>({
        path: `/FormQuestionType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormQuestionType
     * @name FormQuestionTypeDelete
     * @request POST:/FormQuestionType/Delete
     */
    formQuestionTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormQuestionType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formQuestionTypeComparisonOperator = {
    /**
     * No description
     *
     * @tags FormQuestionTypeComparisonOperator
     * @name FormQuestionTypeComparisonOperatorGetList
     * @request GET:/FormQuestionTypeComparisonOperator/GetList
     */
    formQuestionTypeComparisonOperatorGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOFormQuestionTypeComparisonOperator[], any>({
        path: `/FormQuestionTypeComparisonOperator/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormQuestionTypeComparisonOperator
     * @name FormQuestionTypeComparisonOperatorGetById
     * @request GET:/FormQuestionTypeComparisonOperator/GetById
     */
    formQuestionTypeComparisonOperatorGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormQuestionTypeComparisonOperator, any>({
        path: `/FormQuestionTypeComparisonOperator/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormQuestionTypeComparisonOperator
     * @name FormQuestionTypeComparisonOperatorDelete
     * @request POST:/FormQuestionTypeComparisonOperator/Delete
     */
    formQuestionTypeComparisonOperatorDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormQuestionTypeComparisonOperator/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formRoom = {
    /**
     * No description
     *
     * @tags FormRoom
     * @name FormRoomDeleteFormRoom
     * @request POST:/FormRoom/DeleteFormRoom
     */
    formRoomDeleteFormRoom: (query?: { formId?: number; brandId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormRoom/DeleteFormRoom`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormRoom
     * @name FormRoomGetById
     * @request GET:/FormRoom/GetById
     */
    formRoomGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormRoom, any>({
        path: `/FormRoom/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormRoom
     * @name FormRoomDelete
     * @request POST:/FormRoom/Delete
     */
    formRoomDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormRoom/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formSection = {
    /**
     * No description
     *
     * @tags FormSection
     * @name FormSectionGetFormSections
     * @request GET:/FormSection/GetFormSections
     */
    formSectionGetFormSections: (query?: { procedureTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/FormSection/GetFormSections`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormSection
     * @name FormSectionGetList
     * @request GET:/FormSection/GetList
     */
    formSectionGetList: (query?: { procedureTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormSection[], any>({
        path: `/FormSection/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormSection
     * @name FormSectionGetById
     * @request GET:/FormSection/GetById
     */
    formSectionGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormSection, any>({
        path: `/FormSection/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormSection
     * @name FormSectionDelete
     * @request POST:/FormSection/Delete
     */
    formSectionDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormSection/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  formStatus = {
    /**
     * No description
     *
     * @tags FormStatus
     * @name FormStatusGetFormSuitableStatuses
     * @request GET:/FormStatus/GetFormSuitableStatuses
     */
    formStatusGetFormSuitableStatuses: (query?: { formId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/FormStatus/GetFormSuitableStatuses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormStatus
     * @name FormStatusGetFormStatuses
     * @request GET:/FormStatus/GetFormStatuses
     */
    formStatusGetFormStatuses: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/FormStatus/GetFormStatuses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormStatus
     * @name FormStatusGetList
     * @request GET:/FormStatus/GetList
     */
    formStatusGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOFormStatus[], any>({
        path: `/FormStatus/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormStatus
     * @name FormStatusGetById
     * @request GET:/FormStatus/GetById
     */
    formStatusGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOFormStatus, any>({
        path: `/FormStatus/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FormStatus
     * @name FormStatusDelete
     * @request POST:/FormStatus/Delete
     */
    formStatusDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/FormStatus/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  home = {
    /**
     * No description
     *
     * @tags Home
     * @name HomeIndex
     * @request GET:/Home/Index
     */
    homeIndex: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Home/Index`,
        method: "GET",
        ...params,
      }),
  };
  incident = {
    /**
     * No description
     *
     * @tags Incident
     * @name IncidentSave
     * @request POST:/Incident/Save
     */
    incidentSave: (data: CFMDTOIncident, query?: { forceSave?: boolean }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Incident/Save`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident
     * @name IncidentGetIncidentDetail
     * @request GET:/Incident/GetIncidentDetail
     */
    incidentGetIncidentDetail: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMModelIncidentDetailView, any>({
        path: `/Incident/GetIncidentDetail`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident
     * @name IncidentGetPendingApprovals
     * @request GET:/Incident/GetPendingApprovals
     */
    incidentGetPendingApprovals: (params: RequestParams = {}) =>
      this.request<CFMModelPendingIncidentApprovalsView[], any>({
        path: `/Incident/GetPendingApprovals`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident
     * @name IncidentSearch
     * @request GET:/Incident/Search
     */
    incidentSearch: (
      query?: {
        workId?: number;
        roomId?: number;
        deviceId?: number;
        fromDate?: string;
        endDate?: string;
        orderStatusId?: number;
        isOnlyShowOnCalendar?: boolean;
        workGroupId?: number;
        priorityTypeId?: number;
        workOrderCategoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkOrderView, any>({
        path: `/Incident/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident
     * @name IncidentDelete
     * @request POST:/Incident/Delete
     */
    incidentDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Incident/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident
     * @name IncidentGetById
     * @request GET:/Incident/GetById
     */
    incidentGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOIncident, any>({
        path: `/Incident/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  infrastructure = {
    /**
     * No description
     *
     * @tags Infrastructure
     * @name InfrastructureGetList
     * @request GET:/Infrastructure/GetList
     */
    infrastructureGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOInfrastructure, any>({
        path: `/Infrastructure/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Infrastructure
     * @name InfrastructureSave
     * @request POST:/Infrastructure/Save
     */
    infrastructureSave: (data: CFMDTOInfrastructure, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Infrastructure/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Infrastructure
     * @name InfrastructureGetInfrastructures
     * @request GET:/Infrastructure/GetInfrastructures
     */
    infrastructureGetInfrastructures: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Infrastructure/GetInfrastructures`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Infrastructure
     * @name InfrastructureGetWithCapacityTypes
     * @request GET:/Infrastructure/GetWithCapacityTypes
     */
    infrastructureGetWithCapacityTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOInfrastructure, any>({
        path: `/Infrastructure/GetWithCapacityTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Infrastructure
     * @name InfrastructureGet
     * @request GET:/Infrastructure/Get
     */
    infrastructureGet: (params: RequestParams = {}) =>
      this.request<CFMModelInfrastructure[], any>({
        path: `/Infrastructure/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Infrastructure
     * @name InfrastructureGetById
     * @request GET:/Infrastructure/GetById
     */
    infrastructureGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOInfrastructure, any>({
        path: `/Infrastructure/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Infrastructure
     * @name InfrastructureDelete
     * @request POST:/Infrastructure/Delete
     */
    infrastructureDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Infrastructure/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  infrastructureGroup = {
    /**
     * No description
     *
     * @tags InfrastructureGroup
     * @name InfrastructureGroupGetList
     * @request GET:/InfrastructureGroup/GetList
     */
    infrastructureGroupGetList: (query?: { infrastructureId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOInfrastructureGroup, any>({
        path: `/InfrastructureGroup/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureGroup
     * @name InfrastructureGroupSave
     * @request POST:/InfrastructureGroup/Save
     */
    infrastructureGroupSave: (data: CFMDTOInfrastructureGroup, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/InfrastructureGroup/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureGroup
     * @name InfrastructureGroupGetInfrastructureGroups
     * @request GET:/InfrastructureGroup/GetInfrastructureGroups
     */
    infrastructureGroupGetInfrastructureGroups: (query?: { infrastructureId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/InfrastructureGroup/GetInfrastructureGroups`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureGroup
     * @name InfrastructureGroupGet
     * @request GET:/InfrastructureGroup/Get
     */
    infrastructureGroupGet: (params: RequestParams = {}) =>
      this.request<CFMModelInfrastructureGroup[], any>({
        path: `/InfrastructureGroup/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureGroup
     * @name InfrastructureGroupGetById
     * @request GET:/InfrastructureGroup/GetById
     */
    infrastructureGroupGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOInfrastructureGroup, any>({
        path: `/InfrastructureGroup/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureGroup
     * @name InfrastructureGroupDelete
     * @request POST:/InfrastructureGroup/Delete
     */
    infrastructureGroupDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/InfrastructureGroup/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  infrastructureType = {
    /**
     * No description
     *
     * @tags InfrastructureType
     * @name InfrastructureTypeGet
     * @request GET:/InfrastructureType/Get
     */
    infrastructureTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelInfrastructureType[], any>({
        path: `/InfrastructureType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureType
     * @name InfrastructureTypeGetList
     * @request GET:/InfrastructureType/GetList
     */
    infrastructureTypeGetList: (
      query?: { infrastructureId?: number; infrastructureGroupId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelInfrastructureTypeView, any>({
        path: `/InfrastructureType/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureType
     * @name InfrastructureTypeSave
     * @request POST:/InfrastructureType/Save
     */
    infrastructureTypeSave: (data: CFMDTOInfrastructureType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/InfrastructureType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureType
     * @name InfrastructureTypeGetInfrastructureTypes
     * @request GET:/InfrastructureType/GetInfrastructureTypes
     */
    infrastructureTypeGetInfrastructureTypes: (
      query?: { infrastructureGroupId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/InfrastructureType/GetInfrastructureTypes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureType
     * @name InfrastructureTypeGetInfrastructureTypeView
     * @request GET:/InfrastructureType/GetInfrastructureTypeView
     */
    infrastructureTypeGetInfrastructureTypeView: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMModelInfrastructureTypeView, any>({
        path: `/InfrastructureType/GetInfrastructureTypeView`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureType
     * @name InfrastructureTypeGetById
     * @request GET:/InfrastructureType/GetById
     */
    infrastructureTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOInfrastructureType, any>({
        path: `/InfrastructureType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InfrastructureType
     * @name InfrastructureTypeDelete
     * @request POST:/InfrastructureType/Delete
     */
    infrastructureTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/InfrastructureType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  job = {
    /**
     * No description
     *
     * @tags Job
     * @name JobGet
     * @request GET:/Job/Get
     */
    jobGet: (params: RequestParams = {}) =>
      this.request<CFMModelJob[], any>({
        path: `/Job/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobGetList
     * @request GET:/Job/GetList
     */
    jobGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOJob, any>({
        path: `/Job/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobSave
     * @request POST:/Job/Save
     */
    jobSave: (data: CFMDTOJob, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Job/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobGetAllJobs
     * @request GET:/Job/GetAllJobs
     */
    jobGetAllJobs: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Job/GetAllJobs`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobGetById
     * @request GET:/Job/GetById
     */
    jobGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOJob, any>({
        path: `/Job/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobDelete
     * @request POST:/Job/Delete
     */
    jobDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Job/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  location = {
    /**
     * No description
     *
     * @tags Location
     * @name LocationGetContinents
     * @request GET:/Location/GetContinents
     */
    locationGetContinents: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetContinents`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetCountries
     * @request GET:/Location/GetCountries
     */
    locationGetCountries: (query?: { continentId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetCountries`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetCities
     * @request GET:/Location/GetCities
     */
    locationGetCities: (query?: { countryId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetCities`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetDistricts
     * @request GET:/Location/GetDistricts
     */
    locationGetDistricts: (query?: { cityId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetDistricts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetCampuses
     * @request GET:/Location/GetCampuses
     */
    locationGetCampuses: (query?: { districtId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetCampuses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetBuildings
     * @request GET:/Location/GetBuildings
     */
    locationGetBuildings: (query?: { campusId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetBuildings`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetFloors
     * @request GET:/Location/GetFloors
     */
    locationGetFloors: (query?: { buildingId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetFloors`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetRooms
     * @request GET:/Location/GetRooms
     */
    locationGetRooms: (query?: { floorId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetRooms`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetRoomTypes
     * @request GET:/Location/GetRoomTypes
     */
    locationGetRoomTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetRoomTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetRoomSubTypes
     * @request GET:/Location/GetRoomSubTypes
     */
    locationGetRoomSubTypes: (query?: { roomTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetRoomSubTypes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetAllBuildings
     * @request GET:/Location/GetAllBuildings
     */
    locationGetAllBuildings: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetAllBuildings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetAllCampuses
     * @request GET:/Location/GetAllCampuses
     */
    locationGetAllCampuses: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetAllCampuses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name LocationGetRoomCodes
     * @request GET:/Location/GetRoomCodes
     */
    locationGetRoomCodes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Location/GetRoomCodes`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  maintenanceGroup = {
    /**
     * No description
     *
     * @tags MaintenanceGroup
     * @name MaintenanceGroupGetMaintenanceGroups
     * @request GET:/MaintenanceGroup/GetMaintenanceGroups
     */
    maintenanceGroupGetMaintenanceGroups: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/MaintenanceGroup/GetMaintenanceGroups`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenanceGroup
     * @name MaintenanceGroupGetList
     * @request GET:/MaintenanceGroup/GetList
     */
    maintenanceGroupGetList: (
      query?: { isIncludeWork?: boolean; startDate?: string; endDate?: string },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOMaintenanceGroup, any>({
        path: `/MaintenanceGroup/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenanceGroup
     * @name MaintenanceGroupDeleteWithDescription
     * @request POST:/MaintenanceGroup/DeleteWithDescription
     */
    maintenanceGroupDeleteWithDescription: (
      query?: { id?: number; description?: string },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MaintenanceGroup/DeleteWithDescription`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenanceGroup
     * @name MaintenanceGroupGetById
     * @request GET:/MaintenanceGroup/GetById
     */
    maintenanceGroupGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOMaintenanceGroup, any>({
        path: `/MaintenanceGroup/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenanceGroup
     * @name MaintenanceGroupDelete
     * @request POST:/MaintenanceGroup/Delete
     */
    maintenanceGroupDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MaintenanceGroup/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  maintenancePlan = {
    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanSave
     * @request POST:/MaintenancePlan/Save
     */
    maintenancePlanSave: (data: CFMDTOMaintenancePlan, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MaintenancePlan/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name GetMultipleMaintenancePlanUpdate
     * @request GET:/MaintenancePlan/GetMultipleMaintenancePlanUpdate
     */
    getMultipleMaintenancePlanUpdate: (query: {workOrderIds: number}, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MaintenancePlan/GetMultipleMaintenancePlanUpdate`,
        method: "GET",
        query: query,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name GetMultipleMaintenancePlanUpdate
     * @request GET:/MaintenancePlan/GetMultipleMaintenancePlanUpdate
     */
    updateMultipleMaintenance: (data: any, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MaintenancePlan/UpdateMultipleMaintenance`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    
    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanSaveMassMaintenancePlan
     * @request POST:/MaintenancePlan/SaveMassMaintenancePlan
     */
    maintenancePlanSaveMassMaintenancePlan: (data: CFMDTOMaintenanceGroup, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MaintenancePlan/SaveMassMaintenancePlan`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanUpdateMaintenance
     * @request POST:/MaintenancePlan/UpdateMaintenance
     */
    maintenancePlanUpdateMaintenance: (data: CFMDTOMaintenancePlanUpdate, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MaintenancePlan/UpdateMaintenance`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanTransFormToWorkOrder
     * @request POST:/MaintenancePlan/TransFormToWorkOrder
     */
    maintenancePlanTransFormToWorkOrder: (data: number, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MaintenancePlan/TransFormToWorkOrder`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanTransFormToWorkOrderList
     * @request POST:/MaintenancePlan/TransFormToWorkOrderList
     */
    maintenancePlanTransFormToWorkOrderList: (data: Array<number>, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MaintenancePlan/TransFormToWorkOrderList`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanGetPendingForAssignList
     * @request GET:/MaintenancePlan/GetPendingForAssignList
     */
    maintenancePlanGetPendingForAssignList: (
      query?: { fromDate?: string; endDate?: string },
      params: RequestParams = {},
    ) =>
      this.request<CFMModelWorkOrderView[], any>({
        path: `/MaintenancePlan/GetPendingForAssignList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanGetMaintenancePlan
     * @request GET:/MaintenancePlan/GetMaintenancePlan
     */
    maintenancePlanGetMaintenancePlan: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMModelWorkOrderView, any>({
        path: `/MaintenancePlan/GetMaintenancePlan`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanSearch
     * @request GET:/MaintenancePlan/Search
     */
    maintenancePlanSearch: (
      query?: {
        workId?: number;
        roomId?: number;
        capacityEquipmentId?: number;
        maintenanceGroupId?: number;
        brandId?: number;
        modelId?: number;
        fromDate?: string;
        endDate?: string;
        isOnlyShowOnCalendar?: boolean;
        categoryId?: number;
        workGroupId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkOrderView, any>({
        path: `/MaintenancePlan/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanSearchMonthly
     * @request GET:/MaintenancePlan/SearchMonthly
     */
    maintenancePlanSearchMonthly: (
      query?: {
        roomId?: number;
        year?: number;
        month?: number;
        maintenanceGroupId?: number;
        brandId?: number;
        modelId?: number;
        categoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkOrderView, any>({
        path: `/MaintenancePlan/SearchMonthly`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanGet
     * @request GET:/MaintenancePlan/Get
     */
    maintenancePlanGet: (params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderView[], any>({
        path: `/MaintenancePlan/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaintenancePlan
     * @name MaintenancePlanGetById
     * @request GET:/MaintenancePlan/GetById
     */
    maintenancePlanGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrder, any>({
        path: `/MaintenancePlan/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

  /**
   * No description
   *
   * @tags MaintenancePlan
   * @name MaintenancePlanDelete
   * @request POST:/MaintenancePlan/DeleteMaintenancePlan
   */
  maintenancePlanDelete: (data?: number[], params?: RequestParams) =>
    this.request<CFMCoreEntityResultMessage, any>({
      path: `/MaintenancePlan/DeleteMaintenancePlan`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    }),
  };
  minimumStaff = {
    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffSaveNetAvailableWorkHours
     * @request POST:/MinimumStaff/SaveNetAvailableWorkHours
     */
    minimumStaffSaveNetAvailableWorkHours: (data: CFMDTONetAvailableWorkHour, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MinimumStaff/SaveNetAvailableWorkHours`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffGetNetAvailableWorkHour
     * @request GET:/MinimumStaff/GetNetAvailableWorkHour
     */
    minimumStaffGetNetAvailableWorkHour: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTONetAvailableWorkHour, any>({
        path: `/MinimumStaff/GetNetAvailableWorkHour`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffSaveTurnOverRate
     * @request POST:/MinimumStaff/SaveTurnOverRate
     */
    minimumStaffSaveTurnOverRate: (data: CFMDTOTurnOver, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MinimumStaff/SaveTurnOverRate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffGetTurnOvers
     * @request GET:/MinimumStaff/GetTurnOvers
     */
    minimumStaffGetTurnOvers: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTurnOver, any>({
        path: `/MinimumStaff/GetTurnOvers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffGetAverageTurnOver
     * @request GET:/MinimumStaff/GetAverageTurnOver
     */
    minimumStaffGetAverageTurnOver: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemDecimal, any>({
        path: `/MinimumStaff/GetAverageTurnOver`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffDeleteTurnOver
     * @request POST:/MinimumStaff/DeleteTurnOver
     */
    minimumStaffDeleteTurnOver: (data: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/MinimumStaff/DeleteTurnOver`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffSaveCoveragePosition
     * @request POST:/MinimumStaff/SaveCoveragePosition
     */
    minimumStaffSaveCoveragePosition: (data: CFMDTOCoveragePosition, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MinimumStaff/SaveCoveragePosition`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffGetCoveragePosition
     * @request GET:/MinimumStaff/GetCoveragePosition
     */
    minimumStaffGetCoveragePosition: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTOCoveragePosition, any>({
        path: `/MinimumStaff/GetCoveragePosition`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffSaveHourlyProcessingCapability
     * @request POST:/MinimumStaff/SaveHourlyProcessingCapability
     */
    minimumStaffSaveHourlyProcessingCapability: (data: CFMDTOHourlyProcessingCapability, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MinimumStaff/SaveHourlyProcessingCapability`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffGetHourlyProcessingCapability
     * @request GET:/MinimumStaff/GetHourlyProcessingCapability
     */
    minimumStaffGetHourlyProcessingCapability: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTOHourlyProcessingCapability, any>({
        path: `/MinimumStaff/GetHourlyProcessingCapability`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffSaveVolumeInfluencedPosition
     * @request POST:/MinimumStaff/SaveVolumeInfluencedPosition
     */
    minimumStaffSaveVolumeInfluencedPosition: (data: CFMDTOVolumeInfluencedPosition, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MinimumStaff/SaveVolumeInfluencedPosition`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffGetVolumeInfluencedPosition
     * @request GET:/MinimumStaff/GetVolumeInfluencedPosition
     */
    minimumStaffGetVolumeInfluencedPosition: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTOVolumeInfluencedPosition, any>({
        path: `/MinimumStaff/GetVolumeInfluencedPosition`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffGetById
     * @request GET:/MinimumStaff/GetById
     */
    minimumStaffGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOToolSubType, any>({
        path: `/MinimumStaff/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MinimumStaff
     * @name MinimumStaffDelete
     * @request POST:/MinimumStaff/Delete
     */
    minimumStaffDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/MinimumStaff/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  model = {
    /**
     * No description
     *
     * @tags Model
     * @name ModelGet
     * @request GET:/Model/Get
     */
    modelGet: (params: RequestParams = {}) =>
      this.request<CFMModelDeviceModel[], any>({
        path: `/Model/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Model
     * @name ModelGetModels
     * @request GET:/Model/GetModels
     */
    modelGetModels: (query?: { brandId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Model/GetModels`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Model
     * @name ModelGetList
     * @request GET:/Model/GetList
     */
    modelGetList: (query?: { brandId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODeviceModel, any>({
        path: `/Model/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Model
     * @name ModelSave
     * @request POST:/Model/Save
     */
    modelSave: (data: CFMDTODeviceModel, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Model/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Model
     * @name ModelGetById
     * @request GET:/Model/GetById
     */
    modelGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODeviceModel, any>({
        path: `/Model/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Model
     * @name ModelDelete
     * @request POST:/Model/Delete
     */
    modelDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Model/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  operandType = {
    /**
     * No description
     *
     * @tags OperandType
     * @name OperandTypeGetOperandTypes
     * @request GET:/OperandType/GetOperandTypes
     */
    operandTypeGetOperandTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/OperandType/GetOperandTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags OperandType
     * @name OperandTypeGetList
     * @request GET:/OperandType/GetList
     */
    operandTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOOperandType[], any>({
        path: `/OperandType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags OperandType
     * @name OperandTypeGetById
     * @request GET:/OperandType/GetById
     */
    operandTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOOperandType, any>({
        path: `/OperandType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags OperandType
     * @name OperandTypeDelete
     * @request POST:/OperandType/Delete
     */
    operandTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/OperandType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  pendingWorkOrderApproval = {
    /**
     * No description
     *
     * @tags PendingWorkOrderApproval
     * @name PendingWorkOrderApprovalGet
     * @request GET:/PendingWorkOrderApproval/Get
     */
    pendingWorkOrderApprovalGet: (params: RequestParams = {}) =>
      this.request<CFMModelPendingWorkOrderApprovalsView[], any>({
        path: `/PendingWorkOrderApproval/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  point = {
    /**
     * No description
     *
     * @tags Point
     * @name PointGetPoints
     * @request GET:/Point/GetPoints
     */
    pointGetPoints: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Point/GetPoints`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Point
     * @name PointGetList
     * @request GET:/Point/GetList
     */
    pointGetList: (query?: { definitionId?: number; roomId?: number; deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOPoint[], any>({
        path: `/Point/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Point
     * @name PointSave
     * @request POST:/Point/Save
     */
    pointSave: (data: CFMDTOPoint, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Point/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Point
     * @name PointClone
     * @request GET:/Point/Clone
     */
    pointClone: (
      query?: { pointId?: number; roomId?: number; deviceId?: number; name?: string },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Point/Clone`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Point
     * @name PointMassClone
     * @request POST:/Point/MassClone
     */
    pointMassClone: (data: CFMDTOMassPointClone[], params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage[], any>({
        path: `/Point/MassClone`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Point
     * @name PointGetById
     * @request GET:/Point/GetById
     */
    pointGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOPoint, any>({
        path: `/Point/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Point
     * @name PointDelete
     * @request POST:/Point/Delete
     */
    pointDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Point/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  pointType = {
    /**
     * No description
     *
     * @tags PointType
     * @name PointTypeGetPointTypes
     * @request GET:/PointType/GetPointTypes
     */
    pointTypeGetPointTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/PointType/GetPointTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PointType
     * @name PointTypeGetList
     * @request GET:/PointType/GetList
     */
    pointTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOPointType, any>({
        path: `/PointType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PointType
     * @name PointTypeGetById
     * @request GET:/PointType/GetById
     */
    pointTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOPointType, any>({
        path: `/PointType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PointType
     * @name PointTypeDelete
     * @request POST:/PointType/Delete
     */
    pointTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/PointType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  priorityType = {
    /**
     * No description
     *
     * @tags PriorityType
     * @name PriorityTypeGetAll
     * @request GET:/PriorityType/GetAll
     */
    priorityTypeGetAll: (params: RequestParams = {}) =>
      this.request<CFMDTOPriorityType[], any>({
        path: `/PriorityType/GetAll`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PriorityType
     * @name PriorityTypeGetById
     * @request GET:/PriorityType/GetById
     */
    priorityTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOPriorityType, any>({
        path: `/PriorityType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PriorityType
     * @name PriorityTypeDelete
     * @request POST:/PriorityType/Delete
     */
    priorityTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/PriorityType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  procedure = {
    /**
     * No description
     *
     * @tags Procedure
     * @name ProcedureGetProcedures
     * @request GET:/Procedure/GetProcedures
     */
    procedureGetProcedures: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Procedure/GetProcedures`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Procedure
     * @name ProcedureGetList
     * @request GET:/Procedure/GetList
     */
    procedureGetList: (query?: { procedureTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOProcedure, any>({
        path: `/Procedure/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Procedure
     * @name ProcedureSave
     * @request POST:/Procedure/Save
     */
    procedureSave: (
      data: {
        ContentType?: string;
        ContentDisposition?: string;
        Headers?: Record<string, string[]>;
        Length?: number;
        Name?: string;
        FileName?: string;
      },
      query?: { procedureTypeId?: number; name?: string; id?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Procedure/Save`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Procedure
     * @name ProcedureDelete
     * @request POST:/Procedure/Delete
     */
    procedureDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Procedure/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Procedure
     * @name ProcedureGetById
     * @request GET:/Procedure/GetById
     */
    procedureGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOProcedure, any>({
        path: `/Procedure/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  procedureType = {
    /**
     * No description
     *
     * @tags ProcedureType
     * @name ProcedureTypeGetList
     * @request GET:/ProcedureType/GetList
     */
    procedureTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOProcedureType, any>({
        path: `/ProcedureType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProcedureType
     * @name ProcedureTypeGetProcedureTypes
     * @request GET:/ProcedureType/GetProcedureTypes
     */
    procedureTypeGetProcedureTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/ProcedureType/GetProcedureTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProcedureType
     * @name ProcedureTypeGetById
     * @request GET:/ProcedureType/GetById
     */
    procedureTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOProcedureType, any>({
        path: `/ProcedureType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProcedureType
     * @name ProcedureTypeDelete
     * @request POST:/ProcedureType/Delete
     */
    procedureTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ProcedureType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  productFamily = {
    /**
     * No description
     *
     * @tags ProductFamily
     * @name ProductFamilyGet
     * @request GET:/ProductFamily/Get
     */
    productFamilyGet: (params: RequestParams = {}) =>
      this.request<CFMModelProductFamily[], any>({
        path: `/ProductFamily/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFamily
     * @name ProductFamilyGetProductFamilies
     * @request GET:/ProductFamily/GetProductFamilies
     */
    productFamilyGetProductFamilies: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/ProductFamily/GetProductFamilies`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFamily
     * @name ProductFamilyGetList
     * @request GET:/ProductFamily/GetList
     */
    productFamilyGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOProductFamily, any>({
        path: `/ProductFamily/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFamily
     * @name ProductFamilySave
     * @request POST:/ProductFamily/Save
     */
    productFamilySave: (data: CFMDTOProductFamily, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ProductFamily/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFamily
     * @name ProductFamilyGetById
     * @request GET:/ProductFamily/GetById
     */
    productFamilyGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOProductFamily, any>({
        path: `/ProductFamily/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFamily
     * @name ProductFamilyDelete
     * @request POST:/ProductFamily/Delete
     */
    productFamilyDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ProductFamily/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  qualification = {
    /**
     * No description
     *
     * @tags Qualification
     * @name QualificationGet
     * @request GET:/Qualification/Get
     */
    qualificationGet: (params: RequestParams = {}) =>
      this.request<CFMModelQualification[], any>({
        path: `/Qualification/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Qualification
     * @name QualificationGetList
     * @request GET:/Qualification/GetList
     */
    qualificationGetList: (query?: { qualificationTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOQualification, any>({
        path: `/Qualification/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Qualification
     * @name QualificationSave
     * @request POST:/Qualification/Save
     */
    qualificationSave: (data: CFMDTOQualification, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Qualification/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Qualification
     * @name QualificationGetQualifications
     * @request GET:/Qualification/GetQualifications
     */
    qualificationGetQualifications: (query?: { qualificationTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Qualification/GetQualifications`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Qualification
     * @name QualificationGetById
     * @request GET:/Qualification/GetById
     */
    qualificationGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOQualification, any>({
        path: `/Qualification/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Qualification
     * @name QualificationDelete
     * @request POST:/Qualification/Delete
     */
    qualificationDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Qualification/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  qualificationType = {
    /**
     * No description
     *
     * @tags QualificationType
     * @name QualificationTypeGet
     * @request GET:/QualificationType/Get
     */
    qualificationTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelQualificationType[], any>({
        path: `/QualificationType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QualificationType
     * @name QualificationTypeGetQualificationTypes
     * @request POST:/QualificationType/GetQualificationTypes
     */
    qualificationTypeGetQualificationTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/QualificationType/GetQualificationTypes`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QualificationType
     * @name QualificationTypeGetList
     * @request POST:/QualificationType/GetList
     */
    qualificationTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOQualificationType, any>({
        path: `/QualificationType/GetList`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QualificationType
     * @name QualificationTypeSave
     * @request POST:/QualificationType/Save
     */
    qualificationTypeSave: (data: CFMDTOQualificationType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/QualificationType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QualificationType
     * @name QualificationTypeGetById
     * @request GET:/QualificationType/GetById
     */
    qualificationTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOQualificationType, any>({
        path: `/QualificationType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QualificationType
     * @name QualificationTypeDelete
     * @request POST:/QualificationType/Delete
     */
    qualificationTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/QualificationType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  question = {
    /**
     * No description
     *
     * @tags Question
     * @name QuestionGetQuestions
     * @request GET:/Question/GetQuestions
     */
    questionGetQuestions: (query?: { formId?: number; groupId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Question/GetQuestions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Question
     * @name QuestionGetList
     * @request GET:/Question/GetList
     */
    questionGetList: (query?: { formId?: number; questionGroupId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOQuestion[], any>({
        path: `/Question/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Question
     * @name QuestionSave
     * @request POST:/Question/Save
     */
    questionSave: (data: CFMDTOQuestion, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Question/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Question
     * @name QuestionReOrder
     * @request POST:/Question/ReOrder
     */
    questionReOrder: (data: CFMDTOReOrderParameter[], params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Question/ReOrder`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Question
     * @name QuestionRemoveTempPoint
     * @request POST:/Question/RemoveTempPoint
     */
    questionRemoveTempPoint: (query?: { questionId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Question/RemoveTempPoint`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Question
     * @name QuestionChangePoint
     * @request POST:/Question/ChangePoint
     */
    questionChangePoint: (data: CFMDTOChangePointDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Question/ChangePoint`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Question
     * @name QuestionGetById
     * @request GET:/Question/GetById
     */
    questionGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOQuestion, any>({
        path: `/Question/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Question
     * @name QuestionDelete
     * @request POST:/Question/Delete
     */
    questionDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Question/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  questionGroup = {
    /**
     * No description
     *
     * @tags QuestionGroup
     * @name QuestionGroupGetList
     * @request GET:/QuestionGroup/GetList
     */
    questionGroupGetList: (query?: { formId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOQuestionGroup[], any>({
        path: `/QuestionGroup/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuestionGroup
     * @name QuestionGroupSave
     * @request POST:/QuestionGroup/Save
     */
    questionGroupSave: (data: CFMDTOQuestionGroup, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/QuestionGroup/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuestionGroup
     * @name QuestionGroupReOrder
     * @request POST:/QuestionGroup/ReOrder
     */
    questionGroupReOrder: (data: CFMDTOReOrderParameter[], params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/QuestionGroup/ReOrder`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuestionGroup
     * @name QuestionGroupGetById
     * @request GET:/QuestionGroup/GetById
     */
    questionGroupGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOQuestionGroup, any>({
        path: `/QuestionGroup/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuestionGroup
     * @name QuestionGroupDelete
     * @request POST:/QuestionGroup/Delete
     */
    questionGroupDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/QuestionGroup/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  questionProcess = {
    /**
     * No description
     *
     * @tags QuestionProcess
     * @name QuestionProcessGetList
     * @request GET:/QuestionProcess/GetList
     */
    questionProcessGetList: (query?: { formProcessId?: number; questionId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOQuestionProcess, any>({
        path: `/QuestionProcess/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuestionProcess
     * @name QuestionProcessSave
     * @request POST:/QuestionProcess/Save
     */
    questionProcessSave: (data: CFMDTOSaveQuestionProcessDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntitySaveResult, any>({
        path: `/QuestionProcess/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuestionProcess
     * @name QuestionProcessCheckValidationStatusOfQuestionProcesses
     * @request GET:/QuestionProcess/CheckValidationStatusOfQuestionProcesses
     */
    questionProcessCheckValidationStatusOfQuestionProcesses: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/QuestionProcess/CheckValidationStatusOfQuestionProcesses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuestionProcess
     * @name QuestionProcessGetById
     * @request GET:/QuestionProcess/GetById
     */
    questionProcessGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOQuestionProcess, any>({
        path: `/QuestionProcess/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuestionProcess
     * @name QuestionProcessDelete
     * @request POST:/QuestionProcess/Delete
     */
    questionProcessDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/QuestionProcess/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  referenceLibrary = {
    /**
     * No description
     *
     * @tags ReferenceLibrary
     * @name ReferenceLibraryGetReferenceLibraries
     * @request GET:/ReferenceLibrary/GetReferenceLibraries
     */
    referenceLibraryGetReferenceLibraries: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/ReferenceLibrary/GetReferenceLibraries`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReferenceLibrary
     * @name ReferenceLibraryGetList
     * @request GET:/ReferenceLibrary/GetList
     */
    referenceLibraryGetList: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOReferenceLibrary, any>({
        path: `/ReferenceLibrary/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReferenceLibrary
     * @name ReferenceLibraryGetById
     * @request GET:/ReferenceLibrary/GetById
     */
    referenceLibraryGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOReferenceLibrary, any>({
        path: `/ReferenceLibrary/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReferenceLibrary
     * @name ReferenceLibraryDelete
     * @request POST:/ReferenceLibrary/Delete
     */
    referenceLibraryDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ReferenceLibrary/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  report = {
      /**
       * No description
       *
       * @tags Report
       * @name GetWorkOrderIncidentReport
       * @request GET:/Report/GetWorkOrderIncidentReport
       */
      getWorkOrderIncidentReport: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderIncidentReport[], any>({
        path: `/Report/GetWorkOrderIncidentReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGenerateGapReport
     * @request GET:/Report/GenerateGapReport
     */
    reportGenerateGapReport: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelGapReport, any>({
        path: `/Report/GenerateGapReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetRoundGapReport
     * @request GET:/Report/GetRoundGapReport
     */
    reportGetRoundGapReport: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelRoundGapReportSP[], any>({
        path: `/Report/GetRoundGapReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetRoundGapReportForStaff
     * @request GET:/Report/GetRoundGapReportForStaff
     */
    reportGetRoundGapReportForStaff: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelRoundGapReportForStaffSP[], any>({
        path: `/Report/GetRoundGapReportForStaff`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetRoundGapReportForDevice
     * @request GET:/Report/GetRoundGapReportForDevice
     */
    reportGetRoundGapReportForDevice: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelRoundGapReportForDeviceSP[], any>({
        path: `/Report/GetRoundGapReportForDevice`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetWorkOrderReport
     * @request GET:/Report/GetWorkOrderReport
     */
    reportGetWorkOrderReport: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderReport, any>({
        path: `/Report/GetWorkOrderReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetIncidentReport
     * @request GET:/Report/GetIncidentReport
     */
    reportGetIncidentReport: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelIncidentReport, any>({
        path: `/Report/GetIncidentReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetGapReportForPoints
     * @request GET:/Report/GetGapReportForPoints
     */
    reportGetGapReportForPoints: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelRoundGapReportForPoints[], any>({
        path: `/Report/GetGapReportForPoints`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Report
     * @name ReportStaffPerformanceReport
     * @request GET:/Report/GetStaffPerformanceReport
     */
    reportGetStaffPerformanceReport: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelStaffPerformanceReport, any>({
        path: `/Report/GetStaffPerformanceReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetRoundDurationStaffReport
     * @request GET:/Report/GetRoundDurationStaffReport
     */
    reportGetRoundDurationStaffReport: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelRoundDurationStaffReport[], any>({
        path: `/Report/GetRoundDurationStaffReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetStaffRoundCountsReport
     * @request GET:/Report/GetStaffRoundCountsReport
     */
    reportGetStaffRoundCountsReport: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelStaffRoundCountsReport[], any>({
        path: `/Report/GetStaffRoundCountsReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetVendorWorkTimeReport
     * @request GET:/Report/GetVendorWorkTimeReport
     */
    reportGetVendorWorkTimeReport: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelVendorWorkTimeReport[], any>({
        path: `/Report/GetVendorWorkTimeReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetAssetsReport
     * @request GET:/Report/GetAssetsReport
     */
    reportGetAssetsReport: (params: RequestParams = {}) =>
      this.request<CFMModelAssetReportView[], any>({
        path: `/Report/GetAssetsReport`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Report
     * @name ReportGetSparePartUsingReport
     * @request GET:/Report/GetSparePartUsingReport
     */
    reportGetSparePartUsingReport: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMDTOSparePartUsingReport[], any>({
        path: `/Report/GetSparePartUsingReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  reservation = {
    /**
     * No description
     *
     * @tags Reservation
     * @name ReservationSave
     * @request POST:/Reservation/Save
     */
    reservationSave: (data: CFMDTOReservation, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Reservation/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservation
     * @name ReservationGetList
     * @request GET:/Reservation/GetList
     */
    reservationGetList: (query?: { toolId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOReservation, any>({
        path: `/Reservation/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservation
     * @name ReservationGetByDateRange
     * @request GET:/Reservation/GetByDateRange
     */
    reservationGetByDateRange: (
      query?: { beginDate?: string; endDate?: string; toolId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOReservation, any>({
        path: `/Reservation/GetByDateRange`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservation
     * @name ReservationUpdate
     * @request POST:/Reservation/Update
     */
    reservationUpdate: (query?: { id?: number; toolId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Reservation/Update`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservation
     * @name ReservationLend
     * @request POST:/Reservation/Lend
     */
    reservationLend: (data: CFMDTOReservation, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Reservation/Lend`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservation
     * @name ReservationReturn
     * @request POST:/Reservation/Return
     */
    reservationReturn: (data: CFMDTOReservation, query?: { toolStatusId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Reservation/Return`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservation
     * @name ReservationGetById
     * @request GET:/Reservation/GetById
     */
    reservationGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOReservation, any>({
        path: `/Reservation/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservation
     * @name ReservationDelete
     * @request POST:/Reservation/Delete
     */
    reservationDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Reservation/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  role = {
    /**
     * No description
     *
     * @tags Role
     * @name RoleGet
     * @request GET:/Role/Get
     */
    roleGet: (params: RequestParams = {}) =>
      this.request<CFMModelRole[], any>({
        path: `/Role/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleSave
     * @request POST:/Role/Save
     */
    roleSave: (data: CFMDTORole, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Role/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleGetRoles
     * @request GET:/Role/GetRoles
     */
    roleGetRoles: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Role/GetRoles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleGetList
     * @request GET:/Role/GetList
     */
    roleGetList: (query?: { isShowOnOrganizationChart?: boolean }, params: RequestParams = {}) =>
      this.request<CFMDTORole[], any>({
        path: `/Role/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleGetById
     * @request GET:/Role/GetById
     */
    roleGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTORole, any>({
        path: `/Role/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RoleDelete
     * @request POST:/Role/Delete
     */
    roleDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Role/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  room = {
    /**
     * No description
     *
     * @tags Room
     * @name RoomGet
     * @request GET:/Room/Get
     */
    roomGet: (params: RequestParams = {}) =>
      this.request<CFMModelRoom[], any>({
        path: `/Room/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomGetRoomsWithDevices
     * @request GET:/Room/GetRoomsWithDevices
     */
    roomGetRoomsWithDevices: (query?: { roomId?: number; capacityEquipmentId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTORoom, any>({
        path: `/Room/GetRoomsWithDevices`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomGetRoomsWithCapacityEquipments
     * @request GET:/Room/GetRoomsWithCapacityEquipments
     */
    roomGetRoomsWithCapacityEquipments: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTORoom, any>({
        path: `/Room/GetRoomsWithCapacityEquipments`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomGetRoomWithPoints
     * @request GET:/Room/GetRoomWithPoints
     */
    roomGetRoomWithPoints: (query?: { roomId?: number; capacityEquipmentId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTORoom, any>({
        path: `/Room/GetRoomWithPoints`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomSave
     * @request POST:/Room/Save
     */
    roomSave: (data: CFMDTORoom, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Room/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomSearch
     * @request GET:/Room/Search
     */
    roomSearch: (
      query?: {
        continentId?: number;
        countryId?: number;
        cityId?: number;
        districtId?: number;
        campusId?: number;
        buildingId?: number;
        floorId?: number;
        roomTypeId?: number;
        roomSubTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMModelRoomView[], any>({
        path: `/Room/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomGetListForTreeView
     * @request GET:/Room/GetListForTreeView
     */
    roomGetListForTreeView: (params: RequestParams = {}) =>
      this.request<CFMDTOTreeViewSource[], any>({
        path: `/Room/GetListForTreeView`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomGetAllRooms
     * @request GET:/Room/GetAllRooms
     */
    roomGetAllRooms: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Room/GetAllRooms`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomGetById
     * @request GET:/Room/GetById
     */
    roomGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTORoom, any>({
        path: `/Room/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Room
     * @name RoomDelete
     * @request POST:/Room/Delete
     */
    roomDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Room/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  roomSubType = {
    /**
     * No description
     *
     * @tags RoomSubType
     * @name RoomSubTypeGetList
     * @request GET:/RoomSubType/GetList
     */
    roomSubTypeGetList: (query?: { roomTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTORoomSubType, any>({
        path: `/RoomSubType/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomSubType
     * @name RoomSubTypeSave
     * @request POST:/RoomSubType/Save
     */
    roomSubTypeSave: (data: CFMDTORoomSubType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/RoomSubType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomSubType
     * @name RoomSubTypeGet
     * @request GET:/RoomSubType/Get
     */
    roomSubTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelRoomSubType[], any>({
        path: `/RoomSubType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomSubType
     * @name RoomSubTypeGetRoomSubTypes
     * @request GET:/RoomSubType/GetRoomSubTypes
     */
    roomSubTypeGetRoomSubTypes: (query?: { roomTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/RoomSubType/GetRoomSubTypes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomSubType
     * @name RoomSubTypeGetById
     * @request GET:/RoomSubType/GetById
     */
    roomSubTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTORoomSubType, any>({
        path: `/RoomSubType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomSubType
     * @name RoomSubTypeDelete
     * @request POST:/RoomSubType/Delete
     */
    roomSubTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/RoomSubType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  roomType = {
    /**
     * No description
     *
     * @tags RoomType
     * @name RoomTypeGet
     * @request GET:/RoomType/Get
     */
    roomTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelRoomType[], any>({
        path: `/RoomType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomType
     * @name RoomTypeGetList
     * @request GET:/RoomType/GetList
     */
    roomTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTORoomType, any>({
        path: `/RoomType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomType
     * @name RoomTypeSave
     * @request POST:/RoomType/Save
     */
    roomTypeSave: (data: CFMDTORoomType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/RoomType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomType
     * @name RoomTypeGetRoomTypes
     * @request GET:/RoomType/GetRoomTypes
     */
    roomTypeGetRoomTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/RoomType/GetRoomTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomType
     * @name RoomTypeGetById
     * @request GET:/RoomType/GetById
     */
    roomTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTORoomType, any>({
        path: `/RoomType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RoomType
     * @name RoomTypeDelete
     * @request POST:/RoomType/Delete
     */
    roomTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/RoomType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  schedule = {
    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleGet
     * @request GET:/Schedule/Get
     */
    scheduleGet: (params: RequestParams = {}) =>
      this.request<CFMModelSchedule[], any>({
        path: `/Schedule/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleGetList
     * @request GET:/Schedule/GetList
     */
    scheduleGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOSchedule, any>({
        path: `/Schedule/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleSave
     * @request POST:/Schedule/Save
     */
    scheduleSave: (data: CFMDTOSchedule, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Schedule/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleGetSchedules
     * @request GET:/Schedule/GetSchedules
     */
    scheduleGetSchedules: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Schedule/GetSchedules`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleGetById
     * @request GET:/Schedule/GetById
     */
    scheduleGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSchedule, any>({
        path: `/Schedule/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Schedule
     * @name ScheduleDelete
     * @request POST:/Schedule/Delete
     */
    scheduleDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Schedule/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  scheduleDevice = {
    /**
     * No description
     *
     * @tags ScheduleDevice
     * @name ScheduleDeviceGet
     * @request GET:/ScheduleDevice/Get
     */
    scheduleDeviceGet: (params: RequestParams = {}) =>
      this.request<CFMModelScheduleDevice[], any>({
        path: `/ScheduleDevice/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleDevice
     * @name ScheduleDeviceGetList
     * @request GET:/ScheduleDevice/GetList
     */
    scheduleDeviceGetList: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleDevice, any>({
        path: `/ScheduleDevice/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleDevice
     * @name ScheduleDeviceSave
     * @request POST:/ScheduleDevice/Save
     */
    scheduleDeviceSave: (data: CFMDTOScheduleDevice, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ScheduleDevice/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleDevice
     * @name ScheduleDeviceGetDeviceById
     * @request GET:/ScheduleDevice/GetDeviceById
     */
    scheduleDeviceGetDeviceById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMModelScheduleDevice, any>({
        path: `/ScheduleDevice/GetDeviceById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleDevice
     * @name ScheduleDeviceValidate
     * @request GET:/ScheduleDevice/Validate
     */
    scheduleDeviceValidate: (
      query?: { beginDate?: string; endDate?: string; deviceId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleDevice, any>({
        path: `/ScheduleDevice/Validate`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleDevice
     * @name ScheduleDeviceGetById
     * @request GET:/ScheduleDevice/GetById
     */
    scheduleDeviceGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOScheduleDevice, any>({
        path: `/ScheduleDevice/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleDevice
     * @name ScheduleDeviceDelete
     * @request POST:/ScheduleDevice/Delete
     */
    scheduleDeviceDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ScheduleDevice/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  scheduleRoom = {
    /**
     * No description
     *
     * @tags ScheduleRoom
     * @name ScheduleRoomGet
     * @request GET:/ScheduleRoom/Get
     */
    scheduleRoomGet: (params: RequestParams = {}) =>
      this.request<CFMModelScheduleRoom[], any>({
        path: `/ScheduleRoom/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleRoom
     * @name ScheduleRoomGetList
     * @request GET:/ScheduleRoom/GetList
     */
    scheduleRoomGetList: (query?: { roomId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleRoom, any>({
        path: `/ScheduleRoom/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleRoom
     * @name ScheduleRoomSave
     * @request POST:/ScheduleRoom/Save
     */
    scheduleRoomSave: (data: CFMDTOScheduleRoom, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ScheduleRoom/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleRoom
     * @name ScheduleRoomGetRoomById
     * @request GET:/ScheduleRoom/GetRoomById
     */
    scheduleRoomGetRoomById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMModelScheduleRoom, any>({
        path: `/ScheduleRoom/GetRoomById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleRoom
     * @name ScheduleRoomValidate
     * @request GET:/ScheduleRoom/Validate
     */
    scheduleRoomValidate: (
      query?: { beginDate?: string; endDate?: string; roomId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleRoom, any>({
        path: `/ScheduleRoom/Validate`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleRoom
     * @name ScheduleRoomGetById
     * @request GET:/ScheduleRoom/GetById
     */
    scheduleRoomGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOScheduleRoom, any>({
        path: `/ScheduleRoom/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleRoom
     * @name ScheduleRoomDelete
     * @request POST:/ScheduleRoom/Delete
     */
    scheduleRoomDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ScheduleRoom/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  scheduleType = {
    /**
     * No description
     *
     * @tags ScheduleType
     * @name ScheduleTypeGet
     * @request GET:/ScheduleType/Get
     */
    scheduleTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelScheduleType[], any>({
        path: `/ScheduleType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleType
     * @name ScheduleTypeGetList
     * @request GET:/ScheduleType/GetList
     */
    scheduleTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleType, any>({
        path: `/ScheduleType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleType
     * @name ScheduleTypeSave
     * @request POST:/ScheduleType/Save
     */
    scheduleTypeSave: (data: CFMDTOScheduleType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ScheduleType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleType
     * @name ScheduleTypeGetById
     * @request GET:/ScheduleType/GetById
     */
    scheduleTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOScheduleType, any>({
        path: `/ScheduleType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleType
     * @name ScheduleTypeDelete
     * @request POST:/ScheduleType/Delete
     */
    scheduleTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ScheduleType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  scheduleWork = {
    /**
     * No description
     *
     * @tags ScheduleWork
     * @name ScheduleWorkGet
     * @request GET:/ScheduleWork/Get
     */
    scheduleWorkGet: (params: RequestParams = {}) =>
      this.request<CFMModelScheduleWork[], any>({
        path: `/ScheduleWork/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleWork
     * @name ScheduleWorkGetList
     * @request GET:/ScheduleWork/GetList
     */
    scheduleWorkGetList: (query?: { workId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleWork, any>({
        path: `/ScheduleWork/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleWork
     * @name ScheduleWorkSave
     * @request POST:/ScheduleWork/Save
     */
    scheduleWorkSave: (data: CFMDTOScheduleWork, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ScheduleWork/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleWork
     * @name ScheduleWorkGetScheduleWorkById
     * @request GET:/ScheduleWork/GetScheduleWorkById
     */
    scheduleWorkGetScheduleWorkById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMModelScheduleWork, any>({
        path: `/ScheduleWork/GetScheduleWorkById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleWork
     * @name ScheduleWorkValidate
     * @request GET:/ScheduleWork/Validate
     */
    scheduleWorkValidate: (
      query?: { beginDate?: string; endDate?: string; workId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOScheduleWork, any>({
        path: `/ScheduleWork/Validate`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleWork
     * @name ScheduleWorkGetById
     * @request GET:/ScheduleWork/GetById
     */
    scheduleWorkGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOScheduleWork, any>({
        path: `/ScheduleWork/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScheduleWork
     * @name ScheduleWorkDelete
     * @request POST:/ScheduleWork/Delete
     */
    scheduleWorkDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ScheduleWork/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  security = {
    /**
     * No description
     *
     * @tags Security
     * @name SecurityGetCaptcha
     * @request GET:/Security/GetCaptcha
     */
    securityGetCaptcha: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Security/GetCaptcha`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Security
     * @name SecurityRegister
     * @request POST:/Security/Register
     */
    securityRegister: (
      query?: {
        name?: string;
        surname?: string;
        email?: string;
        code?: string;
        captchaValue?: string;
        captchaToken?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Security/Register`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  securityAction = {
    /**
     * No description
     *
     * @tags SecurityAction
     * @name SecurityActionGetList
     * @request GET:/SecurityAction/GetList
     */
    securityActionGetList: (query?: { actionGroupId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelSecurityActionView[], any>({
        path: `/SecurityAction/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityAction
     * @name SecurityActionSave
     * @request POST:/SecurityAction/Save
     */
    securityActionSave: (data: CFMDTOSecurityAction, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityAction/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityAction
     * @name SecurityActionGetById
     * @request GET:/SecurityAction/GetById
     */
    securityActionGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSecurityAction, any>({
        path: `/SecurityAction/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityAction
     * @name SecurityActionDelete
     * @request POST:/SecurityAction/Delete
     */
    securityActionDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityAction/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  securityActionActor = {
    /**
     * No description
     *
     * @tags SecurityActionActor
     * @name SecurityActionActorSave
     * @request POST:/SecurityActionActor/Save
     */
    securityActionActorSave: (data: CFMDTOActionActor, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActionActor/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActionActor
     * @name SecurityActionActorGetList
     * @request GET:/SecurityActionActor/GetList
     */
    securityActionActorGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOActionActor[], any>({
        path: `/SecurityActionActor/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActionActor
     * @name SecurityActionActorGetById
     * @request GET:/SecurityActionActor/GetById
     */
    securityActionActorGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOActionActor, any>({
        path: `/SecurityActionActor/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActionActor
     * @name SecurityActionActorDelete
     * @request POST:/SecurityActionActor/Delete
     */
    securityActionActorDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActionActor/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  securityActionGroup = {
    /**
     * No description
     *
     * @tags SecurityActionGroup
     * @name SecurityActionGroupGetList
     * @request GET:/SecurityActionGroup/GetList
     */
    securityActionGroupGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOActionGroup[], any>({
        path: `/SecurityActionGroup/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActionGroup
     * @name SecurityActionGroupSave
     * @request POST:/SecurityActionGroup/Save
     */
    securityActionGroupSave: (data: CFMDTOActionGroup, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActionGroup/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActionGroup
     * @name SecurityActionGroupGetById
     * @request GET:/SecurityActionGroup/GetById
     */
    securityActionGroupGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOActionGroup, any>({
        path: `/SecurityActionGroup/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActionGroup
     * @name SecurityActionGroupDelete
     * @request POST:/SecurityActionGroup/Delete
     */
    securityActionGroupDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActionGroup/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  securityActor = {
    /**
     * No description
     *
     * @tags SecurityActor
     * @name SecurityActorGetActors
     * @request GET:/SecurityActor/GetActors
     */
    securityActorGetActors: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/SecurityActor/GetActors`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActor
     * @name SecurityActorGetList
     * @request GET:/SecurityActor/GetList
     */
    securityActorGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOActor[], any>({
        path: `/SecurityActor/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActor
     * @name SecurityActorSave
     * @request POST:/SecurityActor/Save
     */
    securityActorSave: (data: CFMDTOActor, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActor/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActor
     * @name SecurityActorGetActorRolesAndUsers
     * @request GET:/SecurityActor/GetActorRolesAndUsers
     */
    securityActorGetActorRolesAndUsers: (query?: { actorId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSecurityDimensionActorRolesAndUsers, any>({
        path: `/SecurityActor/GetActorRolesAndUsers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActor
     * @name SecurityActorSaveActorRolesAndUsers
     * @request POST:/SecurityActor/SaveActorRolesAndUsers
     */
    securityActorSaveActorRolesAndUsers: (
      data: CFMDTOSecurityDimensionActorRolesAndUsers,
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActor/SaveActorRolesAndUsers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActor
     * @name SecurityActorGetById
     * @request GET:/SecurityActor/GetById
     */
    securityActorGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOActor, any>({
        path: `/SecurityActor/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActor
     * @name SecurityActorDelete
     * @request POST:/SecurityActor/Delete
     */
    securityActorDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActor/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  securityActorRole = {
    /**
     * No description
     *
     * @tags SecurityActorRole
     * @name SecurityActorRoleGetList
     * @request GET:/SecurityActorRole/GetList
     */
    securityActorRoleGetList: (query?: { actorId?: number; roleId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOActorRole[], any>({
        path: `/SecurityActorRole/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActorRole
     * @name SecurityActorRoleSave
     * @request POST:/SecurityActorRole/Save
     */
    securityActorRoleSave: (data: CFMDTOActorRole, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActorRole/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActorRole
     * @name SecurityActorRoleGetById
     * @request GET:/SecurityActorRole/GetById
     */
    securityActorRoleGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOActorRole, any>({
        path: `/SecurityActorRole/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActorRole
     * @name SecurityActorRoleDelete
     * @request POST:/SecurityActorRole/Delete
     */
    securityActorRoleDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActorRole/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  securityActorUser = {
    /**
     * No description
     *
     * @tags SecurityActorUser
     * @name SecurityActorUserGetList
     * @request GET:/SecurityActorUser/GetList
     */
    securityActorUserGetList: (query?: { actorId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOActorUser[], any>({
        path: `/SecurityActorUser/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActorUser
     * @name SecurityActorUserSave
     * @request POST:/SecurityActorUser/Save
     */
    securityActorUserSave: (data: CFMDTOActorUser, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActorUser/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActorUser
     * @name SecurityActorUserGetById
     * @request GET:/SecurityActorUser/GetById
     */
    securityActorUserGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOActorUser, any>({
        path: `/SecurityActorUser/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityActorUser
     * @name SecurityActorUserDelete
     * @request POST:/SecurityActorUser/Delete
     */
    securityActorUserDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityActorUser/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  securityItem = {
    /**
     * No description
     *
     * @tags SecurityItem
     * @name SecurityItemGetList
     * @request GET:/SecurityItem/GetList
     */
    securityItemGetList: (query?: { itemTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOItem[], any>({
        path: `/SecurityItem/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityItem
     * @name SecurityItemSave
     * @request POST:/SecurityItem/Save
     */
    securityItemSave: (data: CFMDTOItem, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityItem/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityItem
     * @name SecurityItemGetById
     * @request GET:/SecurityItem/GetById
     */
    securityItemGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOItem, any>({
        path: `/SecurityItem/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SecurityItem
     * @name SecurityItemDelete
     * @request POST:/SecurityItem/Delete
     */
    securityItemDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SecurityItem/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  selection = {
    /**
     * No description
     *
     * @tags Selection
     * @name SelectionGetSelections
     * @request GET:/Selection/GetSelections
     */
    selectionGetSelections: (query?: { questionId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Selection/GetSelections`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Selection
     * @name SelectionGetList
     * @request GET:/Selection/GetList
     */
    selectionGetList: (query?: { questionId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOSelection, any>({
        path: `/Selection/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Selection
     * @name SelectionReOrder
     * @request POST:/Selection/ReOrder
     */
    selectionReOrder: (data: CFMDTOSelection[], params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Selection/ReOrder`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Selection
     * @name SelectionGetById
     * @request GET:/Selection/GetById
     */
    selectionGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSelection, any>({
        path: `/Selection/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Selection
     * @name SelectionDelete
     * @request POST:/Selection/Delete
     */
    selectionDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Selection/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  serviceProvider = {
    /**
     * No description
     *
     * @tags ServiceProvider
     * @name ServiceProviderGetList
     * @request GET:/ServiceProvider/GetList
     */
    serviceProviderGetList: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOServiceProvider, any>({
        path: `/ServiceProvider/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceProvider
     * @name ServiceProviderSave
     * @request POST:/ServiceProvider/Save
     */
    serviceProviderSave: (data: CFMDTOServiceProvider, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ServiceProvider/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceProvider
     * @name ServiceProviderGetById
     * @request GET:/ServiceProvider/GetById
     */
    serviceProviderGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOServiceProvider, any>({
        path: `/ServiceProvider/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceProvider
     * @name ServiceProviderDelete
     * @request POST:/ServiceProvider/Delete
     */
    serviceProviderDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ServiceProvider/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  shift = {
    /**
     * No description
     *
     * @tags Shift
     * @name ShiftSave
     * @request POST:/Shift/Save
     */
    shiftSave: (data: CFMDTOShift, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTOShift, any>({
        path: `/Shift/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name ShiftSearch
     * @request GET:/Shift/Search
     */
    shiftSearch: (query?: { year?: number; month?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelShiftView, any>({
        path: `/Shift/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name ShiftGetByDateRange
     * @request GET:/Shift/GetByDateRange
     */
    shiftGetByDateRange: (
      query?: { startDate?: string; endDate?: string; isCurrentUser?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelShiftView, any>({
        path: `/Shift/GetByDateRange`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name ShiftGetStaffCountInShift
     * @request GET:/Shift/GetStaffCountInShift
     */
    shiftGetStaffCountInShift: (query?: { startDate?: string; endDate?: string }, params: RequestParams = {}) =>
      this.request<CFMModelStaffCountInShiftSP[], any>({
        path: `/Shift/GetStaffCountInShift`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name ShiftDelete
     * @request POST:/Shift/Delete
     */
    shiftDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Shift/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name ShiftGetById
     * @request GET:/Shift/GetById
     */
    shiftGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOShift, any>({
        path: `/Shift/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name GetAvailableShiftDeletableDayCount
     * @request GET:/Shift/GetAvailableShiftDeletableDayCount
     */
    getAvailableShiftDeletableDayCount: (params: RequestParams = {}) =>
    this.request<CFMDTOShiftDeletableDayCount, any>({
      path: `/Shift/GetAvailableShiftDeletableDayCount`,
      method: "GET",
      format: "json",
      ...params,
    }),
  };
  shiftType = {
    /**
     * No description
     *
     * @tags ShiftType
     * @name ShiftTypeGet
     * @request GET:/ShiftType/Get
     */
    shiftTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelShiftType[], any>({
        path: `/ShiftType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShiftType
     * @name ShiftTypeSave
     * @request POST:/ShiftType/Save
     */
    shiftTypeSave: (data: CFMDTOShiftType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ShiftType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShiftType
     * @name ShiftTypeGetList
     * @request GET:/ShiftType/GetList
     */
    shiftTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOShiftType[], any>({
        path: `/ShiftType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShiftType
     * @name ShiftTypeGetById
     * @request GET:/ShiftType/GetById
     */
    shiftTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOShiftType, any>({
        path: `/ShiftType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShiftType
     * @name ShiftTypeDelete
     * @request POST:/ShiftType/Delete
     */
    shiftTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ShiftType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  pointAnalysis = {
    GetAllPointsHierarchical: (query?: {searchText?:string}) =>
      this.request<any[], any>({
        path:"/Point/GetAllPointsHierarchical",
        method:"GET",
        format:"json",
        query: query
      }),
      GetPointAnalysisReport: (query: {pointIds:string,startDate:string,endDate:string}) =>
      this.request<CFMModelPointAnalysisReport[], any>({
        path:"/Point/GetPointAnalysisReport",
        method:"GET",
        query: query,
        format:"json"
      })
  };
  sparePart = {
    /**
     * No description
     *
     * @tags SparePart
     * @name SparePartGet
     * @request GET:/SparePart/Get
     */
    sparePartGet: (params: RequestParams = {}) =>
      this.request<CFMModelSparePartView[], any>({
        path: `/odata/SparePart`,
        method: "GET",
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags SparePart
     * @name SparePartGetComboSource
     * @request GET:/SparePart/Get
     */
    sparePartGetComboSource: (params: RequestParams = {}) =>
      this.request<CFMModelSparePartView[], any>({
        path: `/SparePart/GetComboSource`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePart
     * @name SparePartSave
     * @request POST:/SparePart/Save
     */
    sparePartSave: (data: CFMDTOSparePart, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePart/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags TransferWarehouse
     * @name TransferWarehouse
     * @request POST:/SparePartWarehouse/TransferWarehouse
     */
    sparePartTransferWarehouse: (data: any, params: RequestParams = {}) =>
      this.request({
        path: `/SparePartWarehouse/TransferWarehouse`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePart
     * @name SparePartGetById
     * @request GET:/SparePart/GetById
     */
    sparePartGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSparePart, any>({
        path: `/SparePart/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags SparePart
     * @name GetSparePartSettings
     * @request GET:/SparePartSettings
     */
    getSparePartSettings: (params: RequestParams = {}) =>
      this.request<CFMDTOSparePart, any>({
        path: `/SparePartSettings`,
        method: "GET",
        format: "json",
        ...params,
      }),    
    sparePartGetDetails: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSparePart, any>({
        path: `/SparePart/GetDetails`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    
      sparePartGetHistory: (query?: { sparePartId?: number, warehouseId?: number, serialNumber?: String | undefined }, params: RequestParams = {}) =>
      this.request<CFMDTOSparePartHistory, any>({
        path: `/SparePartHistory/GetHistories`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
      sparePartAddComment: (data: CFMDTOSparePartComment, params: RequestParams = {}) =>
        this.request<CFMCoreEntityResultMessage, any>({
          path: `/SparePart/AddComment`,
          method: "POST",
          body: data,
          type: ContentType.Json,
          format: "json",
          ...params,
      }),

      sparePartSaveToWareHouse: (data:any, params: RequestParams = {}) => 
        this.request({
          path: `/SparePartWarehouse/SaveWarehouse`,
          method: "POST",
          body: data,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),

    /**
     * No description
     *
     * @tags SparePart
     * @name SparePartDelete
     * @request POST:/SparePart/Delete
     */
    sparePartDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePart/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags SparePartWarehouse
     * @name DeleteBySparePartIdAndWarehouseId
     * @request POST:/SparePartWarehouse/DeleteBySparePartIdAndWarehouseId
     */
    sparePartDeleteBySparePartIdAndWarehouseId: (query?: { sparePartId?: number , wareHouseId?: number  , serialNumber?: string }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartWarehouse/DeleteBySparePartIdAndWarehouseId`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),    
      updateSparePartStock: (data: CFMDTOSparePartWarehouse[], params: RequestParams = {}) =>
        this.request<CFMCoreEntityResultMessage, any>({
          path: `/SparePart/UpdateSparePartStock`,
          method: "POST",
          body: data,
          type: ContentType.Json,
          format: "json",
          ...params,
      }),
      changeSerialNumber: (data: CFMDTOSparePartWarehouse, params: RequestParams = {}) =>
        this.request<CFMCoreEntityResultMessage, any>({
          path: `/SparePartWarehouse/ChangeSerialNumber`,
          method: "POST",
          body: data,
          type: ContentType.Json,
          format: "json",
          ...params,
      }),
  };
  sparePartWarehouse = {
    sparePartWarehouseGetWarehouses: (query?: { sparePartId?: number , wareHouseId?: number}, params: RequestParams = {}) =>
      this.request<CFMDTOSparePartWarehouse[], any>({
        path: `/SparePartWarehouse/GetSparePartWarehouses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
      sparePartWarehouseTransfer: (data: CFMDTOSparePartWarehouse[], params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartWarehouse/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  sparePartComment = {
    sparePartGetComments: (query?: { sparePartId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSparePartComment, any>({
        path: `/SparePartComment/GetComments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    sparePartCommentSave: (data: CFMDTOSparePartComment, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartComment/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  sparePartHistory = {
    sparePartGetHistories: (query?: { sparePartId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSparePartHistory, any>({
        path: `/SparePartHistory/GetHistories`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    sparePartHistorySave: (data: CFMDTOSparePartHistory, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartHistory/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  sparePartCategory = {
    /**
     * No description
     *
     * @tags SparePartCategory
     * @name SparePartCategoryGet
     * @request GET:/SparePartCategory/Get
     */
    sparePartCategoryGet: (params: RequestParams = {}) =>
      this.request<CFMModelSparePartCategory[], any>({
        path: `/SparePartCategory/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartCategory
     * @name SparePartCategoryGetCategories
     * @request GET:/SparePartCategory/GetCategories
     */
    sparePartCategoryGetCategories: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/SparePartCategory/GetCategories`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartCategory
     * @name SparePartCategorySave
     * @request POST:/SparePartCategory/Save
     */
    sparePartCategorySave: (data: CFMDTOSparePartCategory, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartCategory/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartCategory
     * @name SparePartCategoryGetById
     * @request GET:/SparePartCategory/GetById
     */
    sparePartCategoryGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSparePartCategory, any>({
        path: `/SparePartCategory/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartCategory
     * @name SparePartCategoryDelete
     * @request POST:/SparePartCategory/Delete
     */
    sparePartCategoryDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartCategory/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  sparePartKind = {
    /**
     * No description
     *
     * @tags SparePartKind
     * @name SparePartKindGet
     * @request GET:/SparePartKind/Get
     */
    sparePartKindGet: (params: RequestParams = {}) =>
      this.request<CFMModelSparePartKind[], any>({
        path: `/SparePartKind/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartKind
     * @name SparePartKindGetTypes
     * @request GET:/SparePartKind/GetTypes
     */
    sparePartKindGetKinds: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/SparePartKind/GetKinds`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartKind
     * @name SparePartKindSave
     * @request POST:/SparePartKind/Save
     */
    sparePartKindSave: (data: CFMDTOSparePartKind, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartKind/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartKind
     * @name SparePartKindGetById
     * @request GET:/SparePartKind/GetById
     */
    sparePartKindGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSparePartKind, any>({
        path: `/SparePartKind/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartKind
     * @name SparePartKindDelete
     * @request POST:/SparePartKind/Delete
     */
    sparePartKindDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartKind/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  sparePartSubCategory = {
    /**
     * No description
     *
     * @tags SparePartSubCategory
     * @name SparePartSubCategoryGet
     * @request GET:/SparePartSubCategory/Get
     */
    sparePartSubCategoryGet: (params: RequestParams = {}) =>
      this.request<CFMModelSparePartSubCategory[], any>({
        path: `/SparePartSubCategory/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartSubCategory
     * @name SparePartSubCategoryGetCategories
     * @request GET:/SparePartSubCategory/GetCategories
     */
    sparePartSubCategoryGetCategories: (query?: { categoryId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/SparePartSubCategory/GetSubCategories`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartSubCategory
     * @name SparePartSubCategorySave
     * @request POST:/SparePartSubCategory/Save
     */
    sparePartSubCategorySave: (data: CFMDTOSparePartSubCategory, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartSubCategory/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartSubCategory
     * @name SparePartSubCategoryGetById
     * @request GET:/SparePartSubCategory/GetById
     */
    sparePartSubCategoryGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSparePartSubCategory, any>({
        path: `/SparePartSubCategory/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartSubCategory
     * @name SparePartSubCategoryDelete
     * @request POST:/SparePartSubCategory/Delete
     */
    sparePartSubCategoryDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartSubCategory/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  sparePartType = {
    /**
     * No description
     *
     * @tags SparePartType
     * @name SparePartTypeGet
     * @request GET:/SparePartType/Get
     */
    sparePartTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelSparePartType[], any>({
        path: `/SparePartType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartType
     * @name SparePartTypeGetTypes
     * @request GET:/SparePartType/GetTypes
     */
    sparePartTypeGetTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/SparePartType/GetTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartType
     * @name SparePartTypeSave
     * @request POST:/SparePartType/Save
     */
    sparePartTypeSave: (data: CFMDTOSparePartType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartType
     * @name SparePartTypeGetById
     * @request GET:/SparePartType/GetById
     */
    sparePartTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOSparePartType, any>({
        path: `/SparePartType/GetSparePartForDialog`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SparePartType
     * @name SparePartTypeDelete
     * @request POST:/SparePartType/Delete
     */
    sparePartTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  sparePartUsingHistory = {
  /**
   * No description
   *
   * @tags SparePartUsingHistory
   * @name getSparePartUsingStatusList
   * @request GET:/SparePartUsingHistory/GetSparePartUsingStatusList
   */
  getSparePartUsingStatusList: (params: RequestParams = {}) =>
    this.request<CFMCoreEntityComboSource[], any>({
      path: `/SparePartUsingHistory/GetSparePartUsingStatusList`,
      method: "GET",
      format: "json",
      ...params,
    }),

  /**
   * No description
   *
   * @tags SparePartUsingHistory
   * @name getSparePartUsingHistoryList
   * @request GET:/SparePartUsingHistory/GetByDeviceId
   */
  getSparePartUsingHistoryList: (query?: { deviceId?: number }, params: RequestParams = {}) =>
    this.request<CFMModelSparePartUsingHistorySP[], any>({
      path: `/SparePartUsingHistory/GetByDeviceId`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    }),

    /**
     * No description
     *
     * @tags SparePartUsingHistory
     * @name ChangeStatusByOrderSparePartId
     * @request POST:/SparePartUsingHistory/ChangeStatusByOrderSparePartId
     */
    changeStatusByOrderSparePartId: (data: CFMDTOSparePartUsingHistory, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartUsingHistory/ChangeStatusByOrderSparePartId`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    
    /**
     * No description
     *
     * @tags SparePartUsingHistory
     * @name TriggerSaveAfterReturnedSparePart
     * @request POST:/SparePartUsingHistory/TriggerSaveAfterReturnedSparePart
     */
    triggerSaveAfterReturnedSparePart: (data: CFMDTOSparePartUsingHistory, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/SparePartUsingHistory/TriggerSaveAfterReturnedSparePart`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      })
  };
  tool = {
    /**
     * No description
     *
     * @tags Tool
     * @name ToolSave
     * @request POST:/Tool/Save
     */
    toolSave: (data: CFMDTOToolSaveDto, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Tool/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tool
     * @name ToolSaveAll
     * @request POST:/Tool/SaveAll
     */
    toolSaveAll: (data: CFMDTOTool[], params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Tool/SaveAll`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tool
     * @name ToolUpdateToolStatus
     * @request POST:/Tool/UpdateToolStatus
     */
    toolUpdateToolStatus: (
      query?: { toolId?: number; status?: CFMCommonEnumsEnumToolStatus },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Tool/UpdateToolStatus`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tool
     * @name ToolGetList
     * @request GET:/Tool/GetList
     */
    toolGetList: (
      query?: { typeId?: number; subTypeId?: number; brandId?: number; modelId?: number; warehouseId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTool, any>({
        path: `/Tool/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tool
     * @name ToolGetBrands
     * @request GET:/Tool/GetBrands
     */
    toolGetBrands: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Tool/GetBrands`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tool
     * @name ToolDelete
     * @request POST:/Tool/Delete
     */
    toolDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Tool/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tool
     * @name ToolGetById
     * @request GET:/Tool/GetById
     */
    toolGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOTool, any>({
        path: `/Tool/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  toolHistory = {
    /**
     * No description
     *
     * @tags ToolHistory
     * @name ToolHistoryGetList
     * @request GET:/ToolHistory/GetList
     */
    toolHistoryGetList: (
      query?: { startDate?: string; endDate?: string; toolId?: number; logType?: string },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOToolHistory, any>({
        path: `/ToolHistory/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolHistory
     * @name ToolHistoryGetLogTypes
     * @request GET:/ToolHistory/GetLogTypes
     */
    toolHistoryGetLogTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableSystemString, any>({
        path: `/ToolHistory/GetLogTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolHistory
     * @name ToolHistoryGetById
     * @request GET:/ToolHistory/GetById
     */
    toolHistoryGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOToolHistory, any>({
        path: `/ToolHistory/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolHistory
     * @name ToolHistoryDelete
     * @request POST:/ToolHistory/Delete
     */
    toolHistoryDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ToolHistory/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  toolModel = {
    /**
     * No description
     *
     * @tags ToolModel
     * @name ToolModelGetToolModels
     * @request GET:/ToolModel/GetToolModels
     */
    toolModelGetToolModels: (query?: { brandId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/ToolModel/GetToolModels`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolModel
     * @name ToolModelGetList
     * @request GET:/ToolModel/GetList
     */
    toolModelGetList: (query?: { brandId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTODeviceModel, any>({
        path: `/ToolModel/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolModel
     * @name ToolModelSave
     * @request POST:/ToolModel/Save
     */
    toolModelSave: (data: CFMDTODeviceModel, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ToolModel/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolModel
     * @name ToolModelGetById
     * @request GET:/ToolModel/GetById
     */
    toolModelGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTODeviceModel, any>({
        path: `/ToolModel/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolModel
     * @name ToolModelDelete
     * @request POST:/ToolModel/Delete
     */
    toolModelDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ToolModel/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  toolStatus = {
    /**
     * No description
     *
     * @tags ToolStatus
     * @name ToolStatusGetToolStatuses
     * @request GET:/ToolStatus/GetToolStatuses
     */
    toolStatusGetToolStatuses: (query?: { toolId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/ToolStatus/GetToolStatuses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolStatus
     * @name ToolStatusGetList
     * @request GET:/ToolStatus/GetList
     */
    toolStatusGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOToolStatus, any>({
        path: `/ToolStatus/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolStatus
     * @name ToolStatusGetById
     * @request GET:/ToolStatus/GetById
     */
    toolStatusGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOToolStatus, any>({
        path: `/ToolStatus/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolStatus
     * @name ToolStatusDelete
     * @request POST:/ToolStatus/Delete
     */
    toolStatusDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ToolStatus/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  toolSubType = {
    /**
     * No description
     *
     * @tags ToolSubType
     * @name ToolSubTypeGet
     * @request GET:/ToolSubType/Get
     */
    toolSubTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelToolSubType[], any>({
        path: `/ToolSubType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolSubType
     * @name ToolSubTypeGetToolSubTypes
     * @request GET:/ToolSubType/GetToolSubTypes
     */
    toolSubTypeGetToolSubTypes: (query?: { toolTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/ToolSubType/GetToolSubTypes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolSubType
     * @name ToolSubTypeGetList
     * @request GET:/ToolSubType/GetList
     */
    toolSubTypeGetList: (query?: { toolTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOToolSubType, any>({
        path: `/ToolSubType/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolSubType
     * @name ToolSubTypeSave
     * @request POST:/ToolSubType/Save
     */
    toolSubTypeSave: (data: CFMDTOToolSubType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ToolSubType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolSubType
     * @name ToolSubTypeGetById
     * @request GET:/ToolSubType/GetById
     */
    toolSubTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOToolSubType, any>({
        path: `/ToolSubType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolSubType
     * @name ToolSubTypeDelete
     * @request POST:/ToolSubType/Delete
     */
    toolSubTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ToolSubType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  toolType = {
    /**
     * No description
     *
     * @tags ToolType
     * @name ToolTypeGet
     * @request GET:/ToolType/Get
     */
    toolTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelToolType[], any>({
        path: `/ToolType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolType
     * @name ToolTypeSave
     * @request POST:/ToolType/Save
     */
    toolTypeSave: (data: CFMDTOToolType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ToolType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolType
     * @name ToolTypeGetToolTypes
     * @request GET:/ToolType/GetToolTypes
     */
    toolTypeGetToolTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/ToolType/GetToolTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolType
     * @name ToolTypeGetList
     * @request GET:/ToolType/GetList
     */
    toolTypeGetList: (query?: { isIncludeSubData?: boolean; toolId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOToolType, any>({
        path: `/ToolType/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolType
     * @name ToolTypeGetById
     * @request GET:/ToolType/GetById
     */
    toolTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOToolType, any>({
        path: `/ToolType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToolType
     * @name ToolTypeDelete
     * @request POST:/ToolType/Delete
     */
    toolTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/ToolType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  training = {
    /**
     * No description
     *
     * @tags Training
     * @name TrainingGet
     * @request GET:/Training/Get
     */
    trainingGet: (params: RequestParams = {}) =>
      this.request<CFMModelTraining[], any>({
        path: `/Training/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name TrainingGetTrainings
     * @request GET:/Training/GetTrainings
     */
    trainingGetTrainings: (query?: { trainingTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Training/GetTrainings`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name TrainingGetList
     * @request GET:/Training/GetList
     */
    trainingGetList: (query?: { trainingTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTraining, any>({
        path: `/Training/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name TrainingGetMostNeedTrainings
     * @request GET:/Training/GetMostNeedTrainings
     */
    trainingGetMostNeedTrainings: (query?: { daysToCheck?: number; isUser?: boolean }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelTrainingDemand, any>({
        path: `/Training/GetMostNeedTrainings`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name TrainingGetMostNeedTrainingsWithStaffCount
     * @request GET:/Training/GetMostNeedTrainingsWithStaffCount
     */
    trainingGetMostNeedTrainingsWithStaffCount: (query?: { daysToCheck?: number }, params: RequestParams = {}) =>
      this.request<
        CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelMostNeededTrainingWithStaffCountSp,
        any
      >({
        path: `/Training/GetMostNeedTrainingsWithStaffCount`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name TrainingGetLastTrainingsForUsers
     * @request GET:/Training/GetLastTrainingsForUsers
     */
    trainingGetLastTrainingsForUsers: (query?: { daysToCheck?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelTrainingSp, any>({
        path: `/Training/GetLastTrainingsForUsers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name TrainingGetTrainingDemands
     * @request GET:/Training/GetTrainingDemands
     */
    trainingGetTrainingDemands: (query?: { daysToCheck?: number; isUser?: boolean }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelTrainingDemand, any>({
        path: `/Training/GetTrainingDemands`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name TrainingSave
     * @request POST:/Training/Save
     */
    trainingSave: (data: CFMDTOTraining, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Training/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name TrainingDelete
     * @request POST:/Training/Delete
     */
    trainingDelete: (data: number, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Training/Delete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name TrainingGetById
     * @request GET:/Training/GetById
     */
    trainingGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOTraining, any>({
        path: `/Training/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  trainingCondition = {
    /**
     * No description
     *
     * @tags TrainingCondition
     * @name TrainingConditionGetByTrainingId
     * @request GET:/TrainingCondition/GetByTrainingId
     */
    trainingConditionGetByTrainingId: (query?: { trainingId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTOTrainingCondition, any>({
        path: `/TrainingCondition/GetByTrainingId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingCondition
     * @name TrainingConditionSave
     * @request POST:/TrainingCondition/Save
     */
    trainingConditionSave: (data: CFMDTOTrainingCondition, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/TrainingCondition/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingCondition
     * @name TrainingConditionGetById
     * @request GET:/TrainingCondition/GetById
     */
    trainingConditionGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOTrainingCondition, any>({
        path: `/TrainingCondition/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingCondition
     * @name TrainingConditionDelete
     * @request POST:/TrainingCondition/Delete
     */
    trainingConditionDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/TrainingCondition/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  trainingMaterial = {
    /**
     * No description
     *
     * @tags TrainingMaterial
     * @name TrainingMaterialGetList
     * @request GET:/TrainingMaterial/GetList
     */
    trainingMaterialGetList: (query?: { trainingId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOTrainingMaterial[], any>({
        path: `/TrainingMaterial/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingMaterial
     * @name TrainingMaterialSave
     * @request POST:/TrainingMaterial/Save
     */
    trainingMaterialSave: (data: CFMDTOTrainingMaterial, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/TrainingMaterial/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingMaterial
     * @name TrainingMaterialGetById
     * @request GET:/TrainingMaterial/GetById
     */
    trainingMaterialGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOTrainingMaterial, any>({
        path: `/TrainingMaterial/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingMaterial
     * @name TrainingMaterialDelete
     * @request POST:/TrainingMaterial/Delete
     */
    trainingMaterialDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/TrainingMaterial/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  trainingMethod = {
    /**
     * No description
     *
     * @tags TrainingMethod
     * @name TrainingMethodGetTrainingMethods
     * @request GET:/TrainingMethod/GetTrainingMethods
     */
    trainingMethodGetTrainingMethods: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/TrainingMethod/GetTrainingMethods`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingMethod
     * @name TrainingMethodGetList
     * @request GET:/TrainingMethod/GetList
     */
    trainingMethodGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTrainingMethod, any>({
        path: `/TrainingMethod/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingMethod
     * @name TrainingMethodGetById
     * @request GET:/TrainingMethod/GetById
     */
    trainingMethodGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOTrainingMethod, any>({
        path: `/TrainingMethod/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingMethod
     * @name TrainingMethodDelete
     * @request POST:/TrainingMethod/Delete
     */
    trainingMethodDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/TrainingMethod/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  trainingRole = {
    /**
     * No description
     *
     * @tags TrainingRole
     * @name TrainingRoleGetList
     * @request GET:/TrainingRole/GetList
     */
    trainingRoleGetList: (query?: { trainingId?: number; roleId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTrainingRole, any>({
        path: `/TrainingRole/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingRole
     * @name TrainingRoleSave
     * @request POST:/TrainingRole/Save
     */
    trainingRoleSave: (data: CFMDTOTrainingRole[], params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/TrainingRole/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingRole
     * @name TrainingRoleGetById
     * @request GET:/TrainingRole/GetById
     */
    trainingRoleGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOTrainingRole, any>({
        path: `/TrainingRole/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingRole
     * @name TrainingRoleDelete
     * @request POST:/TrainingRole/Delete
     */
    trainingRoleDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/TrainingRole/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  trainingType = {
    /**
     * No description
     *
     * @tags TrainingType
     * @name TrainingTypeGet
     * @request GET:/TrainingType/Get
     */
    trainingTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelTrainingType[], any>({
        path: `/TrainingType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingType
     * @name TrainingTypeGetTrainingTypes
     * @request GET:/TrainingType/GetTrainingTypes
     */
    trainingTypeGetTrainingTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/TrainingType/GetTrainingTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingType
     * @name TrainingTypeGetList
     * @request GET:/TrainingType/GetList
     */
    trainingTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOTrainingType, any>({
        path: `/TrainingType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingType
     * @name TrainingTypeSave
     * @request POST:/TrainingType/Save
     */
    trainingTypeSave: (data: CFMDTOTrainingType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/TrainingType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingType
     * @name TrainingTypeGetById
     * @request GET:/TrainingType/GetById
     */
    trainingTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOTrainingType, any>({
        path: `/TrainingType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TrainingType
     * @name TrainingTypeDelete
     * @request POST:/TrainingType/Delete
     */
    trainingTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/TrainingType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  unit = {
    /**
     * No description
     *
     * @tags Unit
     * @name UnitGet
     * @request GET:/Unit/Get
     */
    unitGet: (params: RequestParams = {}) =>
      this.request<CFMModelUnit[], any>({
        path: `/Unit/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Unit
     * @name UnitGetList
     * @request GET:/Unit/GetList
     */
    unitGetList: (query?: { unitTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUnit, any>({
        path: `/Unit/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Unit
     * @name UnitSave
     * @request POST:/Unit/Save
     */
    unitSave: (data: CFMDTOUnit, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Unit/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Unit
     * @name UnitGetUnits
     * @request GET:/Unit/GetUnits
     */
    unitGetUnits: (query?: { unitTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Unit/GetUnits`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Unit
     * @name UnitGetUnitsByName
     * @request GET:/Unit/GetUnitsByName
     */
    unitGetUnitsByName: (query?: { unitName?: string }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUnit, any>({
        path: `/Unit/GetUnitsByName`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Unit
     * @name UnitGetById
     * @request GET:/Unit/GetById
     */
    unitGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOUnit, any>({
        path: `/Unit/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Unit
     * @name UnitDelete
     * @request POST:/Unit/Delete
     */
    unitDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Unit/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  unitType = {
    /**
     * No description
     *
     * @tags UnitType
     * @name UnitTypeGetUnitTypes
     * @request GET:/UnitType/GetUnitTypes
     */
    unitTypeGetUnitTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/UnitType/GetUnitTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UnitType
     * @name UnitTypeGetById
     * @request GET:/UnitType/GetById
     */
    unitTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOUnitType, any>({
        path: `/UnitType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UnitType
     * @name UnitTypeDelete
     * @request POST:/UnitType/Delete
     */
    unitTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/UnitType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags User
     * @name UserGet
     * @request GET:/User/Get
     */
    userGet: (params: RequestParams = {}) =>
      this.request<CFMModelUser[], any>({
        path: `/User/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUsers
     * @request GET:/User/GetUsers
     */
    userGetUsers: (query?: { userTypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/User/GetUsers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetVendorUsers
     * @request GET:/User/GetVendorUsers
     */
    userGetVendorUsers: (query?: { vendorId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/User/GetVendorUsers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUsersForCalendar
     * @request GET:/User/GetUsersForCalendar
     */
    userGetUsersForCalendar: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUser, any>({
        path: `/User/GetUsersForCalendar`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetList
     * @request GET:/User/GetList
     */
    userGetList: (query?: { usertypeId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUser, any>({
        path: `/User/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUserListWithoutIncludes
     * @request GET:/User/GetUserListWithoutIncludes
     */
    userGetUserListWithoutIncludes: (params: RequestParams = {}) =>
      this.request<CFMDTOUser[], any>({
        path: `/User/GetUserListWithoutIncludes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserSave
     * @request POST:/User/Save
     */
    userSave: (data: CFMDTOUser, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/User/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserChangePassword
     * @request POST:/User/ChangePassword
     */
    userChangePassword: (data: CFMDTOChangePasswordDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/User/ChangePassword`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserResetPassword
     * @request POST:/User/ResetPassword
     */
    userResetPassword: (data: CFMDTOResetPasswordDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/User/ResetPassword`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserResetPasswordById
     * @request POST:/User/ResetPasswordById
     */
    userResetPasswordById: (data: CFMDTOResetPasswordDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/User/ResetPasswordById`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserForgotPassword
     * @request GET:/User/ForgotPassword
     */
    userForgotPassword: (query?: { email?: string }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/User/ForgotPassword`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserSearch
     * @request GET:/User/Search
     */
    userSearch: (
      query?: {
        jobId?: number;
        roleId?: number;
        userTypeId?: number;
        vendorId?: number;
        organizationId?: number;
        qualificationId?: number;
        trainingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUser, any>({
        path: `/User/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUserInfo
     * @request GET:/User/GetUserInfo
     */
    userGetUserInfo: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMModelUserInfoView, any>({
        path: `/User/GetUserInfo`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetLdapUser
     * @request GET:/User/GetLdapUser
     */
    userGetLdapUser: (query?: { username?: string }, params: RequestParams = {}) =>
      this.request<CFMDTOUser, any>({
        path: `/User/GetLdapUser`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserDelete
     * @request POST:/User/Delete
     */
    userDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/User/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUserExperiences
     * @request GET:/User/GetUserExperiences
     */
    userGetUserExperiences: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/User/GetUserExperiences`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUserTypes
     * @request GET:/User/GetUserTypes
     */
    userGetUserTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/User/GetUserTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUserDetailsById
     * @request GET:/User/GetUserDetailsById
     */
    userGetUserDetailsById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOUser, any>({
        path: `/User/GetUserDetailsById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetById
     * @request GET:/User/GetById
     */
    userGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOUser, any>({
        path: `/User/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  userAssessment = {
    /**
     * No description
     *
     * @tags UserAssessment
     * @name UserAssessmentGetList
     * @request GET:/UserAssessment/GetList
     */
    userAssessmentGetList: (query?: { userId?: number; assessmentId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUserAssessment, any>({
        path: `/UserAssessment/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserAssessment
     * @name UserAssessmentSave
     * @request POST:/UserAssessment/Save
     */
    userAssessmentSave: (data: CFMDTOUserAssessment, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/UserAssessment/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserAssessment
     * @name UserAssessmentGetById
     * @request GET:/UserAssessment/GetById
     */
    userAssessmentGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOUserAssessment, any>({
        path: `/UserAssessment/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserAssessment
     * @name UserAssessmentDelete
     * @request POST:/UserAssessment/Delete
     */
    userAssessmentDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/UserAssessment/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  userExperience = {
    /**
     * No description
     *
     * @tags UserExperience
     * @name UserExperienceGetUserExperiences
     * @request GET:/UserExperience/GetUserExperiences
     */
    userExperienceGetUserExperiences: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/UserExperience/GetUserExperiences`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserExperience
     * @name UserExperienceGetById
     * @request GET:/UserExperience/GetById
     */
    userExperienceGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOUserExperience, any>({
        path: `/UserExperience/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserExperience
     * @name UserExperienceDelete
     * @request POST:/UserExperience/Delete
     */
    userExperienceDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/UserExperience/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  userPosition = {
    /**
     * No description
     *
     * @tags UserPosition
     * @name UserPositionGetUserPositions
     * @request GET:/UserPosition/GetUserPositions
     */
    userPositionGetUserPositions: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/UserPosition/GetUserPositions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserPosition
     * @name UserPositionGetList
     * @request GET:/UserPosition/GetList
     */
    userPositionGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUserPosition, any>({
        path: `/UserPosition/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserPosition
     * @name UserPositionGetById
     * @request GET:/UserPosition/GetById
     */
    userPositionGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOUserPosition, any>({
        path: `/UserPosition/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserPosition
     * @name UserPositionDelete
     * @request POST:/UserPosition/Delete
     */
    userPositionDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/UserPosition/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  userTraining = {
    /**
     * No description
     *
     * @tags UserTraining
     * @name UserTrainingGetList
     * @request GET:/UserTraining/GetList
     */
    userTrainingGetList: (query?: { userId?: number; trainingId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUserTraining, any>({
        path: `/UserTraining/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTraining
     * @name UserTrainingGetLastIssued
     * @request GET:/UserTraining/GetLastIssued
     */
    userTrainingGetLastIssued: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUserTraining, any>({
        path: `/UserTraining/GetLastIssued`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTraining
     * @name UserTrainingSave
     * @request POST:/UserTraining/Save
     */
    userTrainingSave: (data: CFMDTOUserTraining, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/UserTraining/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTraining
     * @name UserTrainingGetById
     * @request GET:/UserTraining/GetById
     */
    userTrainingGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOUserTraining, any>({
        path: `/UserTraining/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTraining
     * @name UserTrainingDelete
     * @request POST:/UserTraining/Delete
     */
    userTrainingDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/UserTraining/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  userOvertime = {
    /**
    * No description
    *
    * @tags UserOvertime
    * @name UserOvertimeGetById
    * @request GET:/UserOvertime/GetById
    */
    userOvertimeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOUserOvertime, any>({
        path: `/UserOvertime/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  ;
  userType = {
    /**
     * No description
     *
     * @tags UserType
     * @name UserTypeGetUserTypes
     * @request GET:/UserType/GetUserTypes
     */
    userTypeGetUserTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/UserType/GetUserTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserType
     * @name UserTypeGetList
     * @request GET:/UserType/GetList
     */
    userTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOUserType, any>({
        path: `/UserType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserType
     * @name UserTypeSave
     * @request GET:/UserType/Save
     */
    userTypeSave: (data: CFMDTOUserType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/UserType/Save`,
        method: "GET",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserType
     * @name UserTypeGetById
     * @request GET:/UserType/GetById
     */
    userTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOUserType, any>({
        path: `/UserType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserType
     * @name UserTypeDelete
     * @request POST:/UserType/Delete
     */
    userTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/UserType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  vendor = {
    /**
     * No description
     *
     * @tags Vendor
     * @name VendorGet
     * @request GET:/Vendor/Get
     */
    vendorGet: (params: RequestParams = {}) =>
      this.request<CFMModelVendor[], any>({
        path: `/Vendor/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendor
     * @name VendorSave
     * @request POST:/Vendor/Save
     */
    vendorSave: (data: CFMDTOVendor, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Vendor/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendor
     * @name VendorGetVendors
     * @request GET:/Vendor/GetVendors
     */
    vendorGetVendors: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Vendor/GetVendors`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendor
     * @name VendorGetList
     * @request GET:/Vendor/GetList
     */
    vendorGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOVendor, any>({
        path: `/Vendor/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendor
     * @name VendorGetById
     * @request GET:/Vendor/GetById
     */
    vendorGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOVendor, any>({
        path: `/Vendor/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vendor
     * @name VendorDelete
     * @request POST:/Vendor/Delete
     */
    vendorDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Vendor/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  warehouse = {
    /**
     * No description
     *
     * @tags Warehouse
     * @name WarehouseGetList
     * @request GET:/Warehouse/GetList
     */
    warehouseGetList: (query?: { parentId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWarehouse, any>({
        path: `/Warehouse/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Warehouse
     * @name WarehouseSave
     * @request POST:/Warehouse/Save
     */
    warehouseSave: (data: CFMDTOWarehouse, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTOWarehouse, any>({
        path: `/Warehouse/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Warehouse
     * @name WarehouseGetById
     * @request GET:/Warehouse/GetById
     */
    warehouseGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWarehouse, any>({
        path: `/Warehouse/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Warehouse
     * @name WarehouseDelete
     * @request POST:/Warehouse/Delete
     */
    warehouseDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Warehouse/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
    warehouseGetWareHouses: (query?: { parentId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Warehouse/GetWareHouses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  work = {
    /**
     * No description
     *
     * @tags Work
     * @name WorkGetWorks
     * @request GET:/Work/GetWorks
     */
    workGetWorks: (query?: { workCategoryId?: number; deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/Work/GetWorks`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work
     * @name WorkGet
     * @request GET:/Work/Get
     */
    workGet: (params: RequestParams = {}) =>
      this.request<CFMModelWorkView[], any>({
        path: `/Work/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work
     * @name WorkGetList
     * @request GET:/Work/GetList
     */
    workGetList: (query?: { isIncludeBreadCrumb?: boolean; deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWork, any>({
        path: `/Work/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work
     * @name WorkSave
     * @request POST:/Work/Save
     */
    workSave: (data: CFMDTOWork, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Work/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work
     * @name WorkGetWorksWithBreadCrumb
     * @request GET:/Work/GetWorksWithBreadCrumb
     */
    workGetWorksWithBreadCrumb: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkView, any>({
        path: `/Work/GetWorksWithBreadCrumb`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work
     * @name WorkGetWorkForDialog
     * @request GET:/Work/GetWorkForDialog
     */
    workGetWorkForDialog: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkView, any>({
        path: `/Work/GetWorkForDialog`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work
     * @name WorkGetListWithBreadCrumb
     * @request GET:/Work/GetListWithBreadCrumb
     */
    workGetListWithBreadCrumb: (query?: { deviceId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelWorkWithBreadCrumbView[], any>({
        path: `/Work/GetListWithBreadCrumb`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work
     * @name WorkGetListForMaintenancePlan
     * @request GET:/Work/GetListForMaintenancePlan
     */
    workGetListForMaintenancePlan: (params: RequestParams = {}) =>
      this.request<CFMDTOWorkForMaintenancePlan[], any>({
        path: `/Work/GetListForMaintenancePlan`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work
     * @name WorkSearch
     * @request GET:/Work/Search
     */
    workSearch: (
      query?: { workCategoryId?: number; deviceId?: number; workGroupId?: number; roomId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkView, any>({
        path: `/Work/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work
     * @name WorkGetById
     * @request GET:/Work/GetById
     */
    workGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWork, any>({
        path: `/Work/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Work
     * @name WorkDelete
     * @request POST:/Work/Delete
     */
    workDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/Work/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workCategory = {
    /**
     * No description
     *
     * @tags WorkCategory
     * @name WorkCategoryGet
     * @request GET:/WorkCategory/Get
     */
    workCategoryGet: (params: RequestParams = {}) =>
      this.request<CFMModelWorkCategory[], any>({
        path: `/WorkCategory/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkCategory
     * @name WorkCategoryGetWorkCategories
     * @request GET:/WorkCategory/GetWorkCategories
     */
    workCategoryGetWorkCategories: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/WorkCategory/GetWorkCategories`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkCategory
     * @name WorkCategoryGetList
     * @request GET:/WorkCategory/GetList
     */
    workCategoryGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkCategory, any>({
        path: `/WorkCategory/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkCategory
     * @name WorkCategorySave
     * @request POST:/WorkCategory/Save
     */
    workCategorySave: (data: CFMDTOWorkCategory, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkCategory/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkCategory
     * @name WorkCategoryGetById
     * @request GET:/WorkCategory/GetById
     */
    workCategoryGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkCategory, any>({
        path: `/WorkCategory/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkCategory
     * @name WorkCategoryDelete
     * @request POST:/WorkCategory/Delete
     */
    workCategoryDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkCategory/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workForm = {
    /**
     * No description
     *
     * @tags WorkForm
     * @name WorkFormGetList
     * @request GET:/WorkForm/GetList
     */
    workFormGetList: (query?: { workId?: number; formId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkForm, any>({
        path: `/WorkForm/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkForm
     * @name WorkFormGetById
     * @request GET:/WorkForm/GetById
     */
    workFormGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkForm, any>({
        path: `/WorkForm/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkForm
     * @name WorkFormDelete
     * @request POST:/WorkForm/Delete
     */
    workFormDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkForm/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workGroup = {
    /**
     * No description
     *
     * @tags WorkGroup
     * @name WorkGroupGet
     * @request GET:/WorkGroup/Get
     */
    workGroupGet: (params: RequestParams = {}) =>
      this.request<CFMModelWorkGroup[], any>({
        path: `/WorkGroup/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkGroup
     * @name WorkGroupGetList
     * @request GET:/WorkGroup/GetList
     */
    workGroupGetList: (params: RequestParams = {}) =>
      this.request<CFMDTOWorkGroup[], any>({
        path: `/WorkGroup/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkGroup
     * @name WorkGroupSave
     * @request POST:/WorkGroup/Save
     */
    workGroupSave: (data: CFMDTOWorkGroup, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkGroup/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkGroup
     * @name WorkGroupGetWorkGroups
     * @request GET:/WorkGroup/GetWorkGroups
     */
    workGroupGetWorkGroups: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/WorkGroup/GetWorkGroups`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkGroup
     * @name WorkGroupGetById
     * @request GET:/WorkGroup/GetById
     */
    workGroupGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkGroup, any>({
        path: `/WorkGroup/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkGroup
     * @name WorkGroupDelete
     * @request POST:/WorkGroup/Delete
     */
    workGroupDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkGroup/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workGroupRole = {
    /**
     * No description
     *
     * @tags WorkGroupRole
     * @name WorkGroupRoleGetList
     * @request GET:/WorkGroupRole/GetList
     */
    workGroupRoleGetList: (query?: { workGroupId?: number; roleId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkGroupRole, any>({
        path: `/WorkGroupRole/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkGroupRole
     * @name WorkGroupRoleSaveRaci
     * @request POST:/WorkGroupRole/SaveRaci
     */
    workGroupRoleSaveRaci: (data: CFMDTOSaveRaciDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkGroupRole/SaveRaci`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkGroupRole
     * @name WorkGroupRoleGetById
     * @request GET:/WorkGroupRole/GetById
     */
    workGroupRoleGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkGroupRole, any>({
        path: `/WorkGroupRole/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkGroupRole
     * @name WorkGroupRoleDelete
     * @request POST:/WorkGroupRole/Delete
     */
    workGroupRoleDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkGroupRole/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workOrder = {
    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGet
     * @request GET:/WorkOrder/Get
     */
    workOrderGet: (params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderView[], any>({
        path: `/WorkOrder/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetListById
     * @request GET:/WorkOrder/GetListById
     */
    workOrderGetListById: (query?: { workOrderId?: number; maintenanceGroupId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkOrderView, any>({
        path: `/WorkOrder/GetListById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetList
     * @request GET:/WorkOrder/GetList
     */
    workOrderGetList: (query?: { isContainsChildWorkOrders?: boolean }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrder, any>({
        path: `/WorkOrder/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetListByParentId
     * @request GET:/WorkOrder/GetListByParentId
     */
    workOrderGetListByParentId: (query?: { parentId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrder, any>({
        path: `/WorkOrder/GetListByParentId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetViewListByMaintenanceGroupId
     * @request GET:/WorkOrder/GetViewListByMaintenanceGroupId
     */
    workOrderGetViewListByMaintenanceGroupId: (query?: { maintenanceGroupId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkOrderView, any>({
        path: `/WorkOrder/GetViewListByMaintenanceGroupId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetPendingApprovals
     * @request GET:/WorkOrder/GetPendingApprovals
     */
    workOrderGetPendingApprovals: (params: RequestParams = {}) =>
      this.request<CFMModelPendingWorkOrderApprovalsView[], any>({
        path: `/WorkOrder/GetPendingApprovals`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderSave
     * @request POST:/WorkOrder/Save
     */
    workOrderSave: (data: CFMDTOWorkOrder, query?: { forceSave?: boolean }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrder/Save`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderUpdateStatus
     * @request POST:/WorkOrder/UpdateStatus
     */
    workOrderUpdateStatus: (data: CFMDTOUpdateStatus, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrder/UpdateStatus`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderSendBackFromPendingApproval
     * @request POST:/WorkOrder/SendBackFromPendingApproval
     */
    workOrderSendBackFromPendingApproval: (query?: { workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrder/SendBackFromPendingApproval`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderDelete
     * @request POST:/WorkOrder/Delete
     */
    workOrderDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrder/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderApproveWorkOrder
     * @request POST:/WorkOrder/ApproveWorkOrder
     */
    workOrderApproveWorkOrder: (data: CFMDTOApproveWorkOrderDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrder/ApproveWorkOrder`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetRecommendations
     * @request GET:/WorkOrder/GetRecommendations
     */
    workOrderGetRecommendations: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkRecommendationView, any>({
        path: `/WorkOrder/GetRecommendations`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderSearch
     * @request GET:/WorkOrder/Search
     */
    workOrderSearch: (
      query?: {
        workId?: number;
        roomId?: number;
        deviceId?: number;
        maintenanceGroupId?: number;
        fromDate?: string;
        endDate?: string;
        orderStatusId?: number;
        isOnlyShowOnCalendar?: boolean;
        categoryId?: number;
        workGroupId?: number;
        priorityTypeId?: number;
        workOrderCategoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkOrderView, any>({
        path: `/WorkOrder/Search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderSearchAssignedWorkOrder
     * @request GET:/WorkOrder/SearchAssignedWorkOrder
     */
    workOrderSearchAssignedWorkOrder: (
      query?: {
        roomId?: number;
        deviceId?: number;
        fromDate?: string;
        endDate?: string;
        orderStatusId?: number;
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelAssignedWorkOrderView, any>({
        path: `/WorkOrder/SearchAssignedWorkOrder`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetWorkOrderDetail
     * @request GET:/WorkOrder/GetWorkOrderDetail
     */
    workOrderGetWorkOrderDetail: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderDetailView, any>({
        path: `/WorkOrder/GetWorkOrderDetail`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetWorkOrderWithRelatedWorkOrders
     * @request GET:/WorkOrder/GetWorkOrderWithRelatedWorkOrders
     */
    workOrderGetWorkOrderWithRelatedWorkOrders: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderDetailView[], any>({
        path: `/WorkOrder/GetWorkOrderWithRelatedWorkOrders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderSaveVendorWorkTime
     * @request POST:/WorkOrder/SaveVendorWorkTime
     */
    workOrderSaveVendorWorkTime: (data?: CFMDTOWorkVendorWorkTime, params: RequestParams = {}) =>
    this.request<CFMCoreEntityResultMessageCFMDTOWorkVendorWorkTime, any>({
    //this.request<CFMCoreEntitySaveResult, any>({
      path: `/WorkOrder/SaveVendorWorkTime`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
    }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetMyWorkOrders
     * @request GET:/WorkOrder/GetMyWorkOrders
     */
    workOrderGetMyWorkOrders: (query?: { userId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMModelMyWorkOrders, any>({
        path: `/WorkOrder/GetMyWorkOrders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetHistory
     * @request GET:/WorkOrder/GetHistory
     */
    workOrderGetHistory: (query?: { entityId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrder[], any>({
        path: `/WorkOrder/GetHistory`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetAssignedUsers
     * @request GET:/WorkOrder/GetAssignedUsers
     */
    workOrderGetAssignedUsers: (
      query?: { workOrderId?: number; userPositionId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMModelWorkOrderAssignedUserView[], any>({
        path: `/WorkOrder/GetAssignedUsers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetProcedures
     * @request GET:/WorkOrder/GetProcedures
     */
    workOrderGetProcedures: (query?: { workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderProcedureView[], any>({
        path: `/WorkOrder/GetProcedures`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetFormStatusSummaryList
     * @request GET:/WorkOrder/GetFormStatusSummaryList
     */
    workOrderGetFormStatusSummaryList: (query?: { workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderFormStatusSummarySP[], any>({
        path: `/WorkOrder/GetFormStatusSummaryList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetFormStatusSummary
     * @request GET:/WorkOrder/GetFormStatusSummary
     */
    workOrderGetFormStatusSummary: (query?: { workOrderId?: number; formId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderFormStatusSummarySP, any>({
        path: `/WorkOrder/GetFormStatusSummary`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetWorkOrderHistory
     * @request GET:/WorkOrder/GetWorkOrderHistory
     */
    workOrderGetWorkOrderHistory: (query?: { workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderHistoryView[], any>({
        path: `/WorkOrder/GetWorkOrderHistory`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetWorkOrderConvertibleStatuses
     * @request GET:/WorkOrder/GetWorkOrderConvertibleStatuses
     */
    workOrderGetWorkOrderConvertibleStatuses: (query?: { workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderConvertibleStatusView, any>({
        path: `/WorkOrder/GetWorkOrderConvertibleStatuses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetPropertyCounts
     * @request GET:/WorkOrder/GetPropertyCounts
     */
    workOrderGetPropertyCounts: (query?: { workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderPropertyCountsSP, any>({
        path: `/WorkOrder/GetPropertyCounts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetWorkOrderSummary
     * @request GET:/WorkOrder/GetWorkOrderSummary
     */
    workOrderGetWorkOrderSummary: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderSummaryView, any>({
        path: `/WorkOrder/GetWorkOrderSummary`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetNextForm
     * @request GET:/WorkOrder/GetNextForm
     */
    workOrderGetNextForm: (
      query?: { workOrderId?: number; currentFormId?: number; isThrowException?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<CFMDTOForm, any>({
        path: `/WorkOrder/GetNextForm`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetCountByStatus
     * @request GET:/WorkOrder/GetCountByStatus
     */
    workOrderGetCountByStatus: (
      query?: { fromDate?: string; endDate?: string; isIncludeMaintenance?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/WorkOrder/GetCountByStatus`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderSearchWorkOrderName
     * @request GET:/WorkOrder/SearchWorkOrderName
     */
    workOrderSearchWorkOrderName: (query?: { name?: string; orderSourceId?: number }, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/WorkOrder/SearchWorkOrderName`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderAddForm
     * @request POST:/WorkOrder/AddForm
     */
    workOrderAddForm: (data: CFMDTOWorkOrderFormDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrder/AddForm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderDeleteForm
     * @request POST:/WorkOrder/DeleteForm
     */
    workOrderDeleteForm: (data: CFMDTOWorkOrderFormDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrder/DeleteForm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetPeriodicMaintenanceReport
     * @request GET:/WorkOrder/GetPeriodicMaintenanceReport
     */
    workOrderGetPeriodicMaintenanceReport: (
      query?: { startDate?: string; endDate?: string },
      params: RequestParams = {},
    ) =>
      this.request<CFMModelPeriodicMaintenanceView[], any>({
        path: `/WorkOrder/GetPeriodicMaintenanceReport`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderSetSpentMinutes
     * @request GET:/WorkOrder/SetSpentMinutes
     */
    workOrderSetSpentMinutes: (query?: { id?: number; spentMinutes?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrder/SetSpentMinutes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name WorkOrderGetById
     * @request GET:/WorkOrder/GetById
     */
    workOrderGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrder, any>({
        path: `/WorkOrder/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrder
     * @name GetWorkOrderApprovalsInformation
     * @request GET:/WorkOrder/GetWorkOrderApprovalsInformation
     */
    workOrderGetWorkOrderApprovalsInformation: (query?: { formId?: number, workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrder, any>({
        path: `/WorkOrder/GetWorkOrderApprovalsInformation`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
    /**
         * No description
         *
         * @tags WorkOrder
         * @name WorkOrderAnyWorkOrderByDeviceId
         * @request GET:/WorkOrder/AnyWorkOrderByDeviceId
         */
    workOrderAnyWorkOrderByDeviceId: (query?: {contractId?: number, deviceId?: number }, params: RequestParams = {}) =>
    this.request<CFMCoreEntityCheckResultMessage, any>({
      path: `/WorkOrder/AnyWorkOrderByDeviceId`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    }),
  };
  workOrderHtmlContent = {
    /**
     * No description
     *
     * @tags WorkOrderHtmlContent
     * @name WorkOrderHtmlContentGetView
     * @request GET:/WorkOrderHtmlContent/GetView
     */
    workOrderHtmlContentGetView: (query?: { workOrderId?: number; baseUrl?: string }, params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderHtmlContentView[], any>({
        path: `/WorkOrderHtmlContent/GetView`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderHtmlContent
     * @name WorkOrderHtmlContentGetList
     * @request GET:/WorkOrderHtmlContent/GetList
     */
    workOrderHtmlContentGetList: (
      query?: { workOrderId?: number; formId?: number; formProcessId?: number; questionId?: number; baseUrl?: string },
      params: RequestParams = {},
    ) =>
      this.request<CFMDTOWorkOrderHtmlContent[], any>({
        path: `/WorkOrderHtmlContent/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderHtmlContent
     * @name WorkOrderHtmlContentSave
     * @request POST:/WorkOrderHtmlContent/Save
     */
    workOrderHtmlContentSave: (
      data: CFMDTOWorkOrderHtmlContent,
      query?: { content?: string },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageCFMDTOWorkOrderHtmlContent, any>({
        path: `/WorkOrderHtmlContent/Save`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderHtmlContent
     * @name WorkOrderHtmlContentGetWithBaseUrl
     * @request GET:/WorkOrderHtmlContent/GetWithBaseUrl
     */
    workOrderHtmlContentGetWithBaseUrl: (query?: { id?: number; baseUrl?: string }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrderHtmlContent, any>({
        path: `/WorkOrderHtmlContent/GetWithBaseUrl`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderHtmlContent
     * @name WorkOrderHtmlContentGetById
     * @request GET:/WorkOrderHtmlContent/GetById
     */
    workOrderHtmlContentGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrderHtmlContent, any>({
        path: `/WorkOrderHtmlContent/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderHtmlContent
     * @name WorkOrderHtmlContentDelete
     * @request POST:/WorkOrderHtmlContent/Delete
     */
    workOrderHtmlContentDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderHtmlContent/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workOrderStatus = {
    /**
     * No description
     *
     * @tags WorkOrderStatus
     * @name WorkOrderStatusGetList
     * @request GET:/WorkOrderStatus/GetList
     */
    workOrderStatusGetList: (query?: { workOrderSourceId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderStatus, any>({
        path: `/WorkOrderStatus/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderStatus
     * @name WorkOrderStatusGetWorkOrderSuitableStatuses
     * @request GET:/WorkOrderStatus/GetWorkOrderSuitableStatuses
     */
    workOrderStatusGetWorkOrderSuitableStatuses: (query?: { workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/WorkOrderStatus/GetWorkOrderSuitableStatuses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderStatus
     * @name WorkOrderStatusGetWorkOrderStatuses
     * @request GET:/WorkOrderStatus/GetWorkOrderStatuses
     */
    workOrderStatusGetWorkOrderStatuses: (query?: { workOrderStatusId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/WorkOrderStatus/GetWorkOrderStatuses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderStatus
     * @name WorkOrderStatusSave
     * @request POST:/WorkOrderStatus/Save
     */
    workOrderStatusSave: (data: CFMDTOWorkOrderStatus, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderStatus/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderStatus
     * @name WorkOrderStatusGetById
     * @request GET:/WorkOrderStatus/GetById
     */
    workOrderStatusGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrderStatus, any>({
        path: `/WorkOrderStatus/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderStatus
     * @name WorkOrderStatusDelete
     * @request POST:/WorkOrderStatus/Delete
     */
    workOrderStatusDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderStatus/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workOrderStatusDescription = {
    /**
     * No description
     *
     * @tags WorkOrderStatusDescription
     * @name WorkOrderStatusDescriptionGet
     * @request GET:/WorkOrderStatusDescription/Get
     */
    workOrderStatusDescriptionGet: (params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderStatusDescription[], any>({
        path: `/WorkOrderStatusDescription/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderStatusDescription
     * @name WorkOrderStatusDescriptionGetList
     * @request GET:/WorkOrderStatusDescription/GetList
     */
    workOrderStatusDescriptionGetList: (query?: { orderStatusId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderStatusDescription, any>({
        path: `/WorkOrderStatusDescription/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderStatusDescription
     * @name WorkOrderStatusDescriptionSave
     * @request POST:/WorkOrderStatusDescription/Save
     */
    workOrderStatusDescriptionSave: (data: CFMDTOWorkOrderStatusDescription, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderStatusDescription/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderStatusDescription
     * @name WorkOrderStatusDescriptionGetWorkOrderStatusDescriptions
     * @request GET:/WorkOrderStatusDescription/GetWorkOrderStatusDescriptions
     */
    workOrderStatusDescriptionGetWorkOrderStatusDescriptions: (
      query?: { orderStatusId?: number },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/WorkOrderStatusDescription/GetWorkOrderStatusDescriptions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderStatusDescription
     * @name WorkOrderStatusDescriptionGetById
     * @request GET:/WorkOrderStatusDescription/GetById
     */
    workOrderStatusDescriptionGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrderStatusDescription, any>({
        path: `/WorkOrderStatusDescription/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderStatusDescription
     * @name WorkOrderStatusDescriptionDelete
     * @request POST:/WorkOrderStatusDescription/Delete
     */
    workOrderStatusDescriptionDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderStatusDescription/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workOrderSubStatus = {
    /**
     * No description
     *
     * @tags WorkOrderSubStatus
     * @name WorkOrderSubStatusGetWorkOrderSubStatuses
     * @request GET:/WorkOrderSubStatus/GetWorkOrderSubStatuses
     */
    workOrderSubStatusGetWorkOrderSubStatuses: (query?: { statusId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/WorkOrderSubStatus/GetWorkOrderSubStatuses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderSubStatus
     * @name WorkOrderSubStatusGetList
     * @request GET:/WorkOrderSubStatus/GetList
     */
    workOrderSubStatusGetList: (query?: { statusId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrderSubStatus[], any>({
        path: `/WorkOrderSubStatus/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderSubStatus
     * @name WorkOrderSubStatusSave
     * @request POST:/WorkOrderSubStatus/Save
     */
    workOrderSubStatusSave: (data: CFMDTOWorkOrderSubStatus, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderSubStatus/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderSubStatus
     * @name WorkOrderSubStatusGetById
     * @request GET:/WorkOrderSubStatus/GetById
     */
    workOrderSubStatusGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrderSubStatus, any>({
        path: `/WorkOrderSubStatus/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderSubStatus
     * @name WorkOrderSubStatusDelete
     * @request POST:/WorkOrderSubStatus/Delete
     */
    workOrderSubStatusDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderSubStatus/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workOrderType = {
    /**
     * No description
     *
     * @tags WorkOrderType
     * @name WorkOrderTypeGet
     * @request GET:/WorkOrderType/Get
     */
    workOrderTypeGet: (params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderType[], any>({
        path: `/WorkOrderType/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderType
     * @name WorkOrderTypeGetList
     * @request GET:/WorkOrderType/GetList
     */
    workOrderTypeGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderType, any>({
        path: `/WorkOrderType/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderType
     * @name WorkOrderTypeSave
     * @request POST:/WorkOrderType/Save
     */
    workOrderTypeSave: (data: CFMDTOWorkOrderType, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderType/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderType
     * @name WorkOrderTypeGetWorkOrderTypes
     * @request GET:/WorkOrderType/GetWorkOrderTypes
     */
    workOrderTypeGetWorkOrderTypes: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/WorkOrderType/GetWorkOrderTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderType
     * @name WorkOrderTypeGetById
     * @request GET:/WorkOrderType/GetById
     */
    workOrderTypeGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrderType, any>({
        path: `/WorkOrderType/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderType
     * @name WorkOrderTypeDelete
     * @request POST:/WorkOrderType/Delete
     */
    workOrderTypeDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderType/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workOrderUser = {
    /**
     * No description
     *
     * @tags WorkOrderUser
     * @name WorkOrderUserGetList
     * @request GET:/WorkOrderUser/GetList
     */
    workOrderUserGetList: (
      query?: { workOrderId?: number; isIncludeWorkOrders?: boolean; isIncludeUser?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderUser, any>({
        path: `/WorkOrderUser/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderUser
     * @name WorkOrderUserGetListWithUser
     * @request GET:/WorkOrderUser/GetListWithUser
     */
    workOrderUserGetListWithUser: (
      query?: { workOrderId?: number; getWithDeleted?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkOrderUser, any>({
        path: `/WorkOrderUser/GetListWithUser`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderUser
     * @name WorkOrderUserGetSuitableWorkUsers
     * @request GET:/WorkOrderUser/GetSuitableWorkUsers
     */
    workOrderUserGetSuitableWorkUsers: (
      query?: { workOrderId?: number; isFastAssign?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<CFMModelSuitableWorkUserSP[], any>({
        path: `/WorkOrderUser/GetSuitableWorkUsers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderUser
     * @name WorkOrderUserGetSuitableMultipleWorkUsers
     * @request GET:/WorkOrderUser/GetSuitableMultipleWorkUsers
     */
    workOrderUserGetSuitableMultipleWorkUsers: (
      query?: { workOrderIds?: string},
      params: RequestParams = {},
    ) =>
      this.request<CFMModelSuitableWorkUserSP[], any>({
        path: `/WorkOrderUser/GetSuitableMultipleWorkUsers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderUser
     * @name WorkOrderUserSave
     * @request POST:/WorkOrderUser/Save
     */
    workOrderUserSave: (data: CFMDTOWorkOrderUser, query?: { forceSave?: boolean }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderUser/Save`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderUser
     * @name WorkOrderUserDeleteWorkOrderUsers
     * @request POST:/WorkOrderUser/DeleteWorkOrderUsers
     */
    workOrderUserDeleteWorkOrderUsers: (data: CFMDTOWorkOrderUserDTO, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderUser/DeleteWorkOrderUsers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderUser
     * @name WorkOrderUserGetById
     * @request GET:/WorkOrderUser/GetById
     */
    workOrderUserGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkOrderUser, any>({
        path: `/WorkOrderUser/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkOrderUser
     * @name WorkOrderUserDelete
     * @request POST:/WorkOrderUser/Delete
     */
    workOrderUserDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderUser/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workOrderFormVendor = {
    /**
     * @tags WorkOrderFormVendor
     * @name workOrderFormVendorGetFormVendor
     * @request GET:/WorkOrderFormVendor/GetFormVendor
     */
    workOrderFormVendorGetFormVendor: (
      query?: { formId?: number; workOrderId?: number; },
      params: RequestParams = {}
      
    ) => this.request<CFMCoreEntityResultMessageCFMDTOWorkOrderFormVendor, any>({
      path: `/WorkOrderFormVendor/GetFormVendor`,
      method: "GET",
      query: query,
      format: "json",
      ...params
    }),

    /**
     * @tags WorkOrderFormVendor
     * @name workOrderFormVendorGetFormVendor
     * @request GET:/WorkOrderFormVendor/GetFormVendor
     */
    workOrderFormVendorGetList: (
      query?: { formId?: number | null; workOrderId?: number; },
      params: RequestParams = {}
      
    ) => this.request<CFMCoreEntityResultMessageCFMDTOWorkOrderFormVendorList, any>({
      path: `/WorkOrderFormVendor/GetList`,
      method: "GET",
      query: query,
      format: "json",
      ...params
    }),

    /**
     * @tags WorkOrderFormVendor
     * @name workOrderFormVendorGetFormVendor
     * @request GET:/WorkOrderFormVendor/GetFormVendor
     */
    workOrderFormVendorGetCount: (
      query?: { formId?: number; workOrderId?: number; },
      params: RequestParams = {}
      
    ) => this.request<CFMCoreEntityResultMessageCFMDTOWorkOrderFormVendorCount, any>({
      path: `/WorkOrderFormVendor/GetList`,
      method: "GET",
      query: query,
      format: "json",
      ...params
    }),

    /**
     * @tags WorkOrderFormVendor
     * @name WorkOrderFormVendorSave
     * @request POST:/WorkOrderFormVendor/Save
     */
    workOrderFormVendorSave: (
      data: CFMDTOWorkOrderFormVendor,
      query?: {}, 
      params: RequestParams = {}
    ) => 
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderFormVendor/SaveFormVendor`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

      /**
       * @tags WorkOrderFormVendor
       * @name WorkOrderFormVendorDelete
       * @request POST:/WorkOrderFormVendor/Delete
       */
      workOrderFormVendorDelete: (
        query?: {id?: number; },
        params: RequestParams = {}
      ) =>
        this.request<CFMCoreEntityResultMessage, any>({
          path: `/WorkOrderFormVendor/Delete`,
          method: "POST",
          query: query,
          type: ContentType.Json,
          format: "json",
          ...params,
        }),

      /**
       * @tags WorkOrderFormVendor
       * @name WorkOrderFormVendorDeleteFormVendor
       * @request POST:/WorkOrderFormVendor/DeleteFormVendor
       */
      workOrderFormVendorDeleteFormVendor: (
        query?: {id?: number },
        params: RequestParams = {}
      ) =>
        this.request<CFMCoreEntityResultMessage, any>({
          path: `/WorkOrderFormVendor/DeleteFormVendor`,
          method: "POST",
          query: query,
          type: ContentType.Json,
          format: "json",
          ...params,
        })
      
  }
  workPeriod = {
    /**
     * No description
     *
     * @tags WorkPeriod
     * @name WorkPeriodGet
     * @request GET:/WorkPeriod/Get
     */
    workPeriodGet: (params: RequestParams = {}) =>
      this.request<CFMModelWorkPeriod[], any>({
        path: `/WorkPeriod/Get`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkPeriod
     * @name WorkPeriodGetPeriods
     * @request GET:/WorkPeriod/GetPeriods
     */
    workPeriodGetPeriods: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityComboSource[], any>({
        path: `/WorkPeriod/GetPeriods`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkPeriod
     * @name WorkPeriodGetList
     * @request GET:/WorkPeriod/GetList
     */
    workPeriodGetList: (params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelWorkPeriodView, any>({
        path: `/WorkPeriod/GetList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkPeriod
     * @name WorkPeriodSave
     * @request POST:/WorkPeriod/Save
     */
    workPeriodSave: (data: CFMDTOWorkPeriod, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkPeriod/Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkPeriod
     * @name WorkPeriodGetById
     * @request GET:/WorkPeriod/GetById
     */
    workPeriodGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkPeriod, any>({
        path: `/WorkPeriod/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkPeriod
     * @name WorkPeriodDelete
     * @request POST:/WorkPeriod/Delete
     */
    workPeriodDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkPeriod/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workProcedure = {
    /**
     * No description
     *
     * @tags WorkProcedure
     * @name WorkProcedureGetList
     * @request GET:/WorkProcedure/GetList
     */
    workProcedureGetList: (query?: { workId?: number; procedureId?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMDTOWorkProcedure, any>({
        path: `/WorkProcedure/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkProcedure
     * @name WorkProcedureGetById
     * @request GET:/WorkProcedure/GetById
     */
    workProcedureGetById: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOWorkProcedure, any>({
        path: `/WorkProcedure/GetById`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkProcedure
     * @name WorkProcedureDelete
     * @request POST:/WorkProcedure/Delete
     */
    workProcedureDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkProcedure/Delete`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  workOrderSparePart = {
    /**
     * No description
     *
     * @tags WorkOrderSparePart
     * @name WorkOrderSparePartGet
     * @request GET:/WorkOrderSparePart/Get
     */
    workOrderSparePartGet: (params: RequestParams = {}) =>
      this.request<CFMModelWorkOrderSparePartView[], any>({
        path: `/odata/WorkOrderSparePart`,
        method: "GET",
        format: "json",
        ...params,
      }),    
    /**
     * No description
     *
     * @tags WorkOrderSparePart
     * @name SaveReservedSparePart
     * @request POST:/WorkOrderSparePart/SaveReservedSparePart
     */
    saveReservedSparePart: (data: CFMDTOWorkOrderSparePart[],params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderSparePart/SaveReservedSparePart`,
        method: "POST",
        body: data,
        format: "json",
        ...params,
      }),
      
      /**
     * No description
     *
     * @tags WorkOrderSparePart
     * @name SaveSparePart
     * @request POST:/WorkOrderSparePart/SaveSparePart
     */
    saveSparePart: (data: CFMDTOWorkOrderSparePart[],params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderSparePart/SaveSparePart`,
        method: "POST",
        body: data,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags WorkOrderSparePart
     * @name SaveReturnedSparePart
     * @request POST:/WorkOrderSparePart/SaveReturnedSparePart
     */
    saveReturnedSparePart: (data: CFMDTOWorkOrderSparePart,params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderSparePart/SaveReturnedSparePart`,
        method: "POST",
        body: data,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags WorkOrderSparePart
     * @name AnyNotReturnedSparePart
     * @request POST:WorkOrderSparePart/AnyNotReturnedSparePart
     */
    anyNotReturnedSparePart: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderSparePart/AnyNotReturnedSparePart`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
     /**
     * No description
     *
     * @tags WorkOrderSparePart
     * @name SaveDeliveredSparePart
     * @request POST:/WorkOrderSparePart/SaveDeliveredSparePart
     */
     saveDeliveredSparePart: (data: CFMDTOWorkOrderSparePart,params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderSparePart/SaveDeliveredSparePart`,
        method: "POST",
        body: data,
        format: "json",
        ...params,
      }),  
    /**
     * No description
     *
     * @tags WorkOrderSparePart
     * @name DeleteReservedSparePart
     * @request POST:/WorkOrderSparePart/DeleteReservedSparePart
     */
    deleteReservedSparePart: (data: CFMDTOWorkOrderSparePart,params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/WorkOrderSparePart/DeleteReservedSparePart`,
        method: "POST",
        body: data,
        format: "json",
        ...params,
      }),    
  };
  QAQC = {
    /**
     * No description
     * 
     * @tags QAQC
     * @name GetList
     * @request GET:/QAQC/GetList
     */
    qaqcGetList: (query?: { }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessageCFMDTOQAQCList, any>({
        path: `/QAQC/GetList`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QAQC
     * @name DeleteQAQC
     * @request POST:/QAQC/DeleteQAQC
     */
    qaqcDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/QAQC/DeleteQAQC`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

      /**
     * @tags QAQCSave
     * @name QAQCSave
     * @request POST:/QAQC/SaveQAQC
     */
    qaqcSave: (data: CFMDTOQAQC, query?: {}, params: RequestParams = {}) => 
      this.request<CFMCoreEntityResultMessage, any>({
        path: `/QAQC/SaveQAQC`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Qaqc
     * @name GetQaqcList
     * @request GET:/Qaqc/GetQaqcList
     */
    getQaqcList: (params: RequestParams = {}) =>
    this.request<CFMModelQaqcView[], any>({
      path: `/QAQC/GetQaqcList`,
      method: "GET",
      format: "json",
      ...params,
    }),
    /**
     * No description
     *
     * @tags QAQC
     * @name GetQAQCListByWorkOrderId
     * @request GET:/QAQCWorkOrder/GetByWorkOrderId
     */
      getQAQCListByWorkOrderId: (query?: { workOrderId?: number }, params: RequestParams = {}) =>
      this.request<CFMDTOQAQCOrderView[], any>({
        path: `/QAQCWorkOrder/GetByWorkOrderId`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
