























import { Component, Prop, Vue } from "vue-property-decorator";
import { mixinz } from "@/scripts/global/mixinz";
import * as blockui from '../../../helpers/block-ui';
import * as api from '@/scripts/services/api';
import * as dateFormat from '../../../helpers/dateFormat';
import { CFMCoreEntityComboSource, CFMModelSparePartUsingHistorySP, CFMDTOSparePartUsingHistory } from '@/scripts/services/ClientApiAuto';
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue';
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';

Vue.use(DropDownListPlugin);

@Component({
    components: {
        'cfm-multiselect': CFMMultiselect
    },
    mixins: [mixinz],
})

export default class SparePartUsingHistoryStep extends Vue {
    @Prop() capacityEquipmentId!: number;

    sparePartUsingHistoryList: CFMModelSparePartUsingHistorySP[] = [];
    sparePartUsingStatusList: CFMCoreEntityComboSource[] = [];

    isReady: boolean = false;

    gridProps: any = {
        ref: 'grid',
        allowPaging: true,
        allowFiltering: false,
        allowSorting: false,
        allowGrouping: false,
        allowResizing: true,
        allowExcelExport: false,
        allowPdfExport: false,
        allowCsvExport: false,
    };

    async mounted() {
        const self = this;
        //@ts-ignore
        self.$parent.blockPage();

        const promises = [
            self.getSparePartUsingStatuses(),
            self.getSparePartUsingHistories(self.capacityEquipmentId)
        ];

        await Promise.all(promises);
        self.isReady = true;
        //@ts-ignore
        self.$parent.unBlockPage();
    }

    async getSparePartUsingStatuses() {
        const self = this;
        const response = await self.$globalApiClient.sparePartUsingHistory.getSparePartUsingStatusList();
        self.sparePartUsingStatusList = response.data;
    }

    async getSparePartUsingHistories(deviceId: number) {
        const self = this;
        const response = await self.$globalApiClient.sparePartUsingHistory.getSparePartUsingHistoryList({deviceId: deviceId});
        self.sparePartUsingHistoryList = response.data;
    }

    gridDataBound(args: any) {
        const self = this;
        for (let index = 0; index < self.sparePartUsingHistoryList.length; index++) {
            if (self.sparePartUsingHistoryList[index].SparePartUsingStatusId === 1) {
                //@ts-ignore
                self.$refs.grid.getDataRows()[index].classList.add("b-ground-gy");
            }
            else if (self.sparePartUsingHistoryList[index].SparePartUsingStatusId === 2) {
                //@ts-ignore
                self.$refs.grid.getDataRows()[index].classList.add("b-ground-c");
            }
            else {
                //@ts-ignore
                self.$refs.grid.getDataRows()[index].classList.add("b-ground-dg");
            }
        }
    }

    statusTemplate(aaa: any, bbb: any) {
        return {
            template: Vue.component("statusTemplate", {
                template:
                      `<ejs-dropdownlist ref='dropdownlist' :value="populateModel" :readonly="setReadonly" @change="onChangeSparePartUsingStatusType" :fields="{ text: 'Text', value: 'Value'}" :dataSource='populateOptions'></ejs-dropdownlist>`,
                computed: {
                    populateModel() {
                        const self = <any>this;
                        return self.data.SparePartUsingStatusId;
                    },
                    populateOptions() {
                        const self = <any>this;
                        return self.$parent.$parent.sparePartUsingStatusList;
                    },
                    setReadonly() {
                        const self = <any>this;
                        return self.data.SparePartUsingStatusId === 3;
                    }
                },
                mounted() {

                },
                methods: {
                    async onChangeSparePartUsingStatusType(data: any) {
                        const self = <any>this;

                        if (data === undefined || data.value === undefined || data.previousItemData === undefined || data.previousItemData.Value === undefined) {
                            toastr.error(self.$parent.$parent.translate('undefined_error'));
                            return;
                        }

                        if(data.previousItemData.Value === 3) {
                            toastr.error(self.$parent.$parent.translate('spare_part_using_history_change_not_allowed').formatString(data.previousItemData.Text));
                            return;
                        }
                        
                        let formData:CFMDTOSparePartUsingHistory = {
                            Id: 0,
                            OrderSparePartId: (<CFMModelSparePartUsingHistorySP>self.data).OrderSparePartId,
                            SparePartUsingStatusId: data.value
                        };

                        try {
                            self.$parent.$parent.$parent.blockPage();

                            const response = await self.$globalApiClient.sparePartUsingHistory.changeStatusByOrderSparePartId(formData);
                            if (response.data.IsSuccess) {
                                toastr.success(self.translate("save_successfully"));
                                await self.$parent.$parent.getSparePartUsingHistories(self.$parent.$parent.capacityEquipmentId);
                            } 
                            else {
                                const errorMessage = response.data.Message || "Error";
                                toastr.error(errorMessage);
                            }
                            
                            self.$parent.$parent.$parent.unBlockPage();
                        } 
                        catch (error) {
                            console.error("Error sending form data to API:", error);
                            self.$parent.$parent.$parent.unBlockPage();
                        }
                    } 
                }
            })
        };
    }
}
