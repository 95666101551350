





































import { Component, Vue } from "vue-property-decorator";
import CFMGridPage from "@/scripts/CFMGridPage";
import { CFMModelWorkOrderSparePartView } from "@/scripts/services/ClientApiAuto";
import { mixinz } from "@/scripts/global/mixinz";
import * as swal from '../../helpers/swal';
import { L10n } from '@syncfusion/ej2-base';
import * as blockui from '@/helpers/block-ui'
@Component({
  mixins: [mixinz],
})
export default class SparePartTaskIdGrid extends CFMGridPage<CFMModelWorkOrderSparePartView> {
  workOrderId!: string;
  rowDataArgsList: any[] = [];
  entityType = "WorkOrderSparePart";
  sorts: { field: string; direction: string }[] = [
    { field: "WorkOrderId", direction: "Descending" },
  ];

  gridSparePartProps: any = {
      ref: 'grid',
      allowPaging: true,
      allowFiltering: true,
      allowSorting: true,
      allowGrouping: false,
      allowResizing: true,
      allowExcelExport: false,
      allowPdfExport: false,
      allowCsvExport: false,
    }
  //@ts-ignore
  filterSettings = {
    type: "Menu",
    columns: [
      {
        field: "WorkOrderId",
        matchCase: false,
        operator: "equal",
        predicate: "and",
        value: 0,
      },
    ],
  };
  created() {
      L10n.load({
              "tr": {
                  "grid": {
                      "FilterButton": "Filtrele",
                      "ClearButton": "Temizle"
                  }
              }
      });
    }
  async mounted() {
    this.$eventHub.$on("sparePartAdded", this.refreshGrid);
    await this.dataStateChange(this.state);
  }

  async refreshGrid() {
    await this.dataStateChange(this.state);
  }

  async updateFilterSettings(newId: string) {
    this.filterSettings = {
      ...this.filterSettings,
      columns: [
        {
          ...this.filterSettings.columns[0],
          value: parseInt(newId),
        },
      ],
    };
    this.workOrderId = newId;
    await this.refreshGrid();
  }

  rowDataBound(args: any) {
    this.rowDataArgsList.push(args);

    this.setRowBackground(args);
  }

  setRowBackground(args: any) {
    if (!args || !args.data) return;

    switch (Number(args.data["ReserveStatus"])) {
      case 1:
        args.row.classList.add("bg-white");
        break;
      case 2:
        args.row.classList.add("bg-yellow");
        break;
      case 3:
        args.row.classList.add("bg-green");
        break;
      default:
        break;
    }
  }

  processDataId(id: number) {
    this.rowDataArgsList.forEach((args) => {
      if (args.data.Id === id) {
        args.row.classList.add("bg-white");
      }
    });
  }
  processDataIds(id: number) {
    this.rowDataArgsList.forEach((args) => {
      if (args.data.Id === id) {
        args.row.classList.remove("bg-white");
      }
    });
  }
  updateReserveAmount() {
    const self = this;
    return {
      template: Vue.component("updateReserveAmount", {
        data() {
          return {
            showInput: false,
            showApproveButton: false,
            previousReserveStatus: null,
            previousReservedCount: null,
          };
        },
        template: `<div @click.stop>
                      <div v-if="showButton(data)">
                          <span v-if="!showInput" style="margin-right: 11px;">{{ data.ReservedCount }}</span>
                          <input v-else ref="inputField" type="number" v-model.number="data.ReservedCount" style="width: 85px; margin-right: 11px; outline: 0; border: 1.5px solid #c1c1c1; background-color: #ffffff" min="0" />
                          <button v-if="!showApproveButton" class="e-lib e-btn e-control e-icon-btn" @click="edit(data)">
                            <span class="e-btn-icon e-edit e-icons"></span>
                          </button>
                          
                          <button v-else @click="reserveApproved(data)" class="e-lib e-btn e-control e-icon-btn">
                            <span class="e-btn-icon e-icons e-approve-icon"></span>
                          </button>                      
                          <button v-if="showApproveButton" @click="cancelEdit(data)" class="e-lib e-btn e-control e-icon-btn">
                            <span class="e-btn-icon e-icons e-reject-icon"></span>
                          </button>                          
                        </div>
                      <div v-else>
                          <span style="margin-right: 11px;">{{ data.ReservedCount }}</span>
                        </div>
                </div>`,
        methods: {
          showButton(args: any) {
            return args.ReserveStatus === 2 || args.ReserveStatus === 1;
          },
          edit(data: any) {
            this.showInput = true;
            this.showApproveButton = true;
            this.previousReserveStatus = data.ReserveStatus;
            this.previousReservedCount = data.ReservedCount;      
            self.processDataId(data.Id);
            this.$root.$emit('editMode', true);
          },
          cancelEdit(data: any) {
            this.showInput = false;
            this.showApproveButton = false;
            self.processDataIds(data.Id);       
            data.ReservedCount = this.previousReservedCount;    
            this.$root.$emit('editMode', false);
          },
          async reserveApproved(dataList: any) {       
            const formData = {
              SparePartId: dataList.SparePartId,
              WorkOrderId: Number(self.workOrderId),
              WarehouseId: dataList.WarehouseId,
              SerialNumber: dataList.SerialNumber,
              ReservedCount: dataList.ReservedCount,
              ReturnedCount: dataList.ReturnedCount || 0,
              Status: dataList.ReserveStatus,
            };
            try {
              blockui.pageBlock(self);
              const response =
                await self.apiClient.workOrderSparePart.saveReservedSparePart([
                  formData,
                ]);
              if (response.data.IsSuccess) {
                toastr.success(self.translate("save_successfully"));
                await self.refreshGrid();
                this.showInput = false;
                this.showApproveButton = false;
                this.$root.$emit('editMode', false);
              } else {
                const errorMessage = response.data.Message || "Error";
                toastr.error(errorMessage);
              }
            } catch (error) {
              console.error("Error sending form data to API:", error);
            }
            finally {
              blockui.unBlockPage();
            }            
          },
        },
        created() {
          this.$root.$on('editMode', (isEditing: any) => {
            //@ts-ignore
            this.isEditing = isEditing;
          });
        },
        beforeDestroy() {
          this.$root.$off('editMode');
        },
      }),
    };
  }

  updateReturnedCount() {
    const self = this;
    return {
      template: Vue.component("updateReturnedCount", {
        data() {
          return {
            isEditing: false,
          };
        },
        template: `<div @click.stop>
                      <div v-if="showButton(data) && !isEditing">
                          <input type="number" v-model.number="data.ReturnedCount" style="width: 85px; margin-right: 11px; outline: 0; border: 1.5px solid #c1c1c1" min="0" />
                          <button @click="anyReturned(data)" class="btn btn-success" v-lang.returned_sparepart></button>
                        </div>
                      <div v-else>
                        <span style="margin-right: 11px;">{{ data.ReturnedCount }}</span>
                        </div>
                </div>`,
        methods: {
          showButton(args: any) {
            return args.ReserveStatus === 2;
          },
          async anyReturned(dataList: any) {
            const deliveredCount = dataList.DeliveredCount;
            const returnedCount = dataList.ReturnedCount;
            if (deliveredCount < returnedCount) {
              toastr.error(self.translate("reserveCount_greater_returnCount"));
              return;
            }            
            const confirm = await swal.check(self, self.translate('are_you_sure'), self.translate('spare_parts_return_notice'));
            if (confirm.value) {
                const formData = {
                  SparePartId: dataList.SparePartId,
                  WorkOrderId: Number(self.workOrderId),
                  WarehouseId: dataList.WarehouseId,
                  SerialNumber: dataList.SerialNumber,
                  ReservedCount: dataList.ReservedCount,
                  ReturnedCount: Number(dataList.ReturnedCount),
                  Status: 3,
                };
                  try {
                  blockui.pageBlock(self);                  
                  const response =
                    await self.apiClient.workOrderSparePart.saveReturnedSparePart(
                      formData
                    );
                  if (response.data.IsSuccess) {
                    blockui.unBlockPage();
                    toastr.success(self.translate("save_successfully"));
                    await self.refreshGrid();
                  } else {
                    const errorMessage = response.data.Message || "Error";
                    toastr.error(errorMessage);
                  }
                } catch (error) {
                  console.error("Error sending form data to API:", error);
              }
              finally {
                  blockui.unBlockPage();
              }
            }
          },
        },
        created() {
          this.$root.$on('editMode', (isEditing:any) => {
            this.isEditing = isEditing;
          });
        },
        beforeDestroy() {
          this.$root.$off('editMode');
        },
      }),
    };
  }

  reserveDeliverTemplate() {
    const self = this;
    return {
      template: Vue.component("columnTemplate", {
        template: `<div @click.stop>
                      <div v-if="showButton(data)">
                          <button @click="saveDeliveredSparePart(data)" class="btn btn-warning" v-lang.deliver></button>                       
                        </div>
                </div>`,
        methods: {
          showButton(args: any) {
            return args.ReserveStatus === 1;
          },
          async saveDeliveredSparePart(dataList: any) {       
            if (dataList.ReservedCount > dataList.StockCount) {
              toastr.error(self.translate("reserveCount_greater_stockCount"));
              return;
            }
            const formData = {
              SparePartId: dataList.SparePartId,
              WorkOrderId: Number(self.workOrderId),
              WarehouseId: dataList.WarehouseId,
              SerialNumber: dataList.SerialNumber,
              DeliveredCount: dataList.ReservedCount,
              ReturnedCount: dataList.ReturnedCount,
              Status: 2,
            };
            try {
              blockui.pageBlock(self);
              const response =
                await self.apiClient.workOrderSparePart.saveDeliveredSparePart(
                  formData
                );
              if (response.data.IsSuccess) {
                toastr.success(self.translate("deliver_successfully"));
                await self.refreshGrid();
              } else {
                const errorMessage = response.data.Message || "Error";
                toastr.error(errorMessage);
              }
            } catch (error) {
              console.error("Error sending form data to API:", error);
            }
            finally {
              blockui.unBlockPage();
            }                    
          },
        },
      }),
    };
  }

  reserveDeleteTemplate() {
    const self = this;
    return {
      template: Vue.component("columnTemplate", {
        template: `<div @click.stop>
                      <div v-if="showButton(data)">
                          <button @click="reserveDeleted(data)" class="btn btn-danger" v-lang.delete>Delete</button>  
                        </div>
                </div>`,
        methods: {
          showButton(args: any) {
            return args.ReserveStatus === 1;
          },
          async reserveDeleted(dataList: any) {
            const formData = {
              SparePartId: dataList.SparePartId,
              WorkOrderId: Number(self.workOrderId),
              WarehouseId: dataList.WarehouseId,
              SerialNumber: dataList.SerialNumber,
              ReservedCount: dataList.ReservedCount,
              ReturnedCount: dataList.ReturnedCount || 0,
            };
            try {
              blockui.pageBlock(self);
              const response =
                await self.apiClient.workOrderSparePart.deleteReservedSparePart(
                  formData
                );
              if (response.data.IsSuccess) {
                toastr.success(self.translate("delete_successfully"));
                await self.refreshGrid();
              } else {
                const errorMessage = response.data.Message || "Error";
                toastr.error(errorMessage);
              }
            } catch (error) {
              console.error("Error sending form data to API:", error);
            }
            finally {
              blockui.unBlockPage();
            }              
          },
        },
      }),
    };
  }
}
